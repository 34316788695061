const verifyOtpPending = state => state.otp.verifyOtpPending;
const verifyOtpRes = state => state.otp.verifyOtpRes;
const verifyOtpError = state => state.otp.verifyOtpError;
const sendOtpPending = state => state.otp.sendOtpPending;
const sendOtpRes = state => state.otp.sendOtpRes;
const sendOtpError = state => state.otp.sendOtpError;

export default {
    verifyOtpPending,
    verifyOtpRes,
    verifyOtpError,
    sendOtpPending,
    sendOtpRes,
    sendOtpError,
};