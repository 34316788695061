import { createReducer } from 'redux-act';
import actions from './commonActions';

const initialState = {
  sidebarOptions: { minimize: false, show: 'responsive' },
  messageNotification: null,
  getNotification:[]
};

//
const handleToggleSidebar = (state, payload) => {
  return {
    ...state,
    ...{
      sidebarOptions: payload,
    },
  };
};

// clear existing data
const clearCommonStore = (state, payload) => {
  switch (payload) {
    case 'HANDLE_CLEAR':
      return {
        ...state,
        ...{
          sidebarOptions: { minimize: false, show: 'responsive' },
        },
      };
    default:
      return state;
  }
};
const handleMessageNotificationRequestReducer = (state, payload) => {
  return {
    ...state,
    ...{
      messageNotification: null,
    },
  };
};
const handleMessageNotificationSuccessReducer = (state, payload) => {
  return {
    ...state,
    ...{
      messageNotification: payload,
    },
  };
};
const handleNotificationSuccessReducer = (state,payload)=>{
  console.log("jksdf")
  return{
    ...state,
    ...{
      getNotification:payload,
    }
  }
}
const handleClearAllNotificationReducer = (state)=>{
  return{
    ...state,
    ...{
      getNotification:[],
    }
  }
}
const handleNotificationRequestReducer = (state)=>{
 
  return{
    ...state,
    ...{
      getNotification:[],
    }
  }
}
export default createReducer(
  {
    [actions.toggleSidebar]: handleToggleSidebar,
    [actions.handleClearCommonStore]: clearCommonStore,
    [actions.handleMessageNotificationRequest]: handleMessageNotificationRequestReducer,
    [actions.handleMessageNotificationSuccess]: handleMessageNotificationSuccessReducer,
    [actions.handleNotificationSuccess]:handleNotificationSuccessReducer,
    [actions.handleNotificationRequest]:handleNotificationRequestReducer,
    [actions.handleClearAllNotification]:handleClearAllNotificationReducer

  },
  initialState,
);
