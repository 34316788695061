import React, { useEffect, useState } from 'react';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CButton,
  CBadge,
  CCollapse,
  CCardBody,
} from '@coreui/react';
import moment from 'moment';
import { toast } from 'react-toastify';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { timeGap } from 'src/utils/common';
import { CDataTable } from '@coreui/react';

const SelectedApproveAndRejectLeave = ({
  isOpen,
  isViewModalOpen,
  heading,
  selectedData,
  leaveType,
  setSelectedData,
  getLeaveForApproval,
}) => {
  const api = new API();
  const [reason, setReason] = useState('');
  const [leaveData, setLeaveData] = useState([]);
  const [details, setDetails] = useState([]);

  const handleApproveFor = (e, index) => {
    let newArr = [...leaveData];
    let obj = newArr[index];
    obj = { ...obj, approve_for: e.target.value };
    newArr[index] = obj;
    setLeaveData(newArr);
  };

  const approveRejectRequest = async () => {
    if (reason) {
      let leaveDatas =
        leaveData &&
        leaveData.length > 0 &&
        leaveData.map(item => {
          return {
            id: item?.id,
            approve_for: item?.approve_for,
            comment: item?.comment,
          };
        });

      let payload = {
        type: 'leave',
        is_approved: leaveType === 'Approve' ? 'approve' : 'reject',
        comment: reason,
        id: 1, //id is fixed not in use
        correction_data: JSON.stringify(leaveDatas),
      };

      let result = await api.post(config.approveRejectAttendance, payload);
      if (result && result.code === 200) {
        toast.success(result && result?.message);
        isViewModalOpen();
        setReason('');
        getLeaveForApproval();
        setSelectedData([]);
      } else {
        toast.error(result && result?.message);
      }
    } else alert('Please type reason');
  };

  const fields = [
    {
      label: 'ID',
      key: 'id',
      //_style: { width: '5%' },
      sorter: true,
      filter: true,
    },
    {
      label: 'QD ID',
      key: 'emp_id',
      sorter: false,
      filter: true,
    },
    {
      label: 'Employee Name',
      key: 'employee_name',
      sorter: false,
      filter: true,
    },
    {
      label: 'Leave Date',
      key: 'leave_date',
      sorter: true,
      filter: true,
    },
    {
      label: 'Leave Type',
      key: 'leave_key',
      sorter: true,
      filter: true,
    },

    {
      label: 'Reason',
      key: 'reason',
      sorter: false,
      filter: true,
    },

    {
      label: 'Created Date',
      key: 'created_at',
      sorter: true,
      filter: true,
    },
  ];

  useEffect(() => {
    if (isOpen) {
      let newArr =
        selectedData &&
        selectedData.map(item => {
          return {
            id: item?.id,
            employee_name: item?.employee_name,
            assigned_to: item?.assigned_to,
            created_at: moment(item?.created_at).format("DD-MM-YYYY"),
            emp_id: item?.emp_id,
            emp_user_id: item?.emp_user_id,
            leave_date: moment(item?.leave_date).format("DD-MM-YYYY"),
            leave_key: item?.leave_key,
            leave_status: item?.leave_status,
            leave_type: item?.leave_type,
            reason: item?.reason,
          };
        });

      setLeaveData(newArr);
    } else {
    }
  }, [isOpen]);
  return (
    <div>
      <CModal
        className="mt-5"
        show={isOpen}
        onClose={() => isViewModalOpen(!isOpen)}
        size="lg"
      >
        <CModalHeader closeButton>
          <CModalTitle>
            {leaveType === 'Approve'
              ? 'Approve Leave(s)'
              : 'Reject Leave(s)'}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CDataTable
            items={leaveData && leaveData}
            fields={fields}
            // columnFilter
            // tableFilter
            // footer
            itemsPerPageSelect
            itemsPerPage={5}
            hover
            // sorter
            pagination
            scopedSlots={{
            }}
          />
          <form>
            <div className="form-group">
              <label for="exampleFormControlInput1">Reason</label>
              <textarea
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                value={reason}
                onChange={e => setReason(e.target.value)}
                placeholder="Reason"
                rows="3"
              ></textarea>
            </div>
          </form>
          <CButton
            className="btn btn-primary text-start m-2"
            onClick={approveRejectRequest}
          >
            Submit
          </CButton>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default SelectedApproveAndRejectLeave;