import React , { useEffect , useState } from 'react';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';
import { useDispatch } from 'react-redux';
// import { actionStartEndDatePicker } from "../redux/actions/ActionStartEndDatePicker";

const DateRangePicker = (props) => {
const {DateStart,DateEnd,startDefaultValue,endDefaultValue, onDateChange} = props;
const { RangePicker } = DatePicker;
// const dispatch = useDispatch();
const [DateStrings, setDateStrings] = useState();

const  onChange = (dates, dateStrings)=> {
 if(dates){

  DateStart(dateStrings[0])
  DateEnd(dateStrings[1])
  setDateStrings(dateStrings);
 }else{
  DateEnd('')
  DateStart('')
 }
 if (onDateChange) {
  onDateChange(dates, dateStrings);
 }
}
useEffect(() => {
  // dispatch(actionStartEndDatePicker(DateStrings));
  
}, [DateStrings])
    return ( 
        <Space className="" direction="vertical" size={14}>
        <RangePicker defaultValue={[moment(startDefaultValue), moment(endDefaultValue)]}
          ranges={{
            Today: [moment(), moment()],
            Yesterday: [moment().subtract(1,'day'), moment().subtract(1,'day')],
            'This Month': [moment().startOf('month'), moment()],
            'Last Month': [moment().subtract(1,'months').startOf('month'), moment().subtract(1,'months').endOf('month')],
            'Last 3 Month': [moment().subtract(3,'months').startOf('month'), moment().subtract(1,'months').endOf('month')],
            'Last 6 Month': [moment().subtract(6,'months').startOf('month'), moment().subtract(1,'months').endOf('month')],
            'Last Year': [moment().startOf('year').subtract(1,"year"),moment().endOf('year').subtract(1,"year")],
            'YTD': [moment().startOf('year'), moment()]
          }}
          onChange={onChange}
    
        />
        
      </Space>
     );
}
 
export default DateRangePicker;

