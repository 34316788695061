import React from 'react';
import TwoDDountChart from 'src/Chart/TwoDDountChart';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PresentAbsentDataTable = ({ presentabsentdata }) => {
  return (
    <div className="cardBox mb-3 position-relative">
      <h3 className="fs-6 fw-bold mb-3">Total Present / Absent</h3>
      {presentabsentdata ? (
        <div className="totalPresnet">
          <div className="row">
            <div className="col-md-6 pe-md-0">
              <div className="table-responsive overallTable mb-3">
                <table className="table">
                  <tbody>
                    <tr>
                      <th colSpan={3}>
                        Present Employees are further divided into :
                      </th>
                    </tr>
                    {presentabsentdata?.PresentEmployees &&
                      presentabsentdata?.PresentEmployees.length > 0 &&
                      presentabsentdata?.PresentEmployees.map(item => (
                        <tr key={item?.name}>
                          <td>{item?.name}</td>
                          <td>{item?.total}</td>
                          <td>{item?.Percentage}%</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="table-responsive overallTable">
                <table className="table">
                  <tbody>
                    <tr>
                      <th colSpan={3}>
                        Absent Employees are further divided into :
                      </th>
                    </tr>
                    {presentabsentdata?.AbsentEmployees &&
                      presentabsentdata?.AbsentEmployees.length > 0 &&
                      presentabsentdata?.AbsentEmployees.map(item => (
                        <tr key={item?.name}>
                          <td>{item?.name}</td>
                          <td>{item?.total}</td>
                          <td>{item?.Percentage}%</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-6">
              <div id="presentChart">
                <TwoDDountChart
                  graphData={[
                    parseInt(presentabsentdata?.PresentEmployeesPercentage),
                    parseInt(presentabsentdata?.AbsentEmployeesPercentage),
                  ]}
                  label={['Present', 'Absent']}
                  labemName={''}
                  labelColor={['rgb(254, 123, 30)', 'rgb(245, 245, 245)']}
                  borderWidth={30}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Skeleton count={8} />
      )}
    </div>
  );
};

export default PresentAbsentDataTable;
