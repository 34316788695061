import React, { useState, useEffect } from 'react';
import Dashboard from 'src/views/dashboard/Dashboard';
import { toast, ToastContainer } from 'react-toastify';
import { FilePreviewModal, InitCallInfoModal } from '../../modals';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile,
  CCollapse,
} from '@coreui/react';
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import ModalPopupReceivedRequisition from './modal';
import 'react-toastify/dist/ReactToastify.css';
import CondidateModal from '../condidate/modal';
import CandidateModalWithoutMRF from '../candidateWithoutMRF/modal';
import Moment from 'react-moment';
import moment from 'moment';
import HoldMrfModal from './holdMrfModal';
import ModalShowCandidate from './model1';
import { StatusDropdownMenu } from 'src/subcomponents';
import {
  setLocalDataAsObject,
  getLocalDataAsObject,
} from '../../utils/CoustomStorage';
import { Link } from 'react-router-dom';
import { isPermission } from 'src/utils/common';
const PendingRequisition = props => {
  const {} = props;
  let api = new API();

  const [mrfRecord, setMrfRecord] = useState([]);
  const [mrfSelectedRecord, setSelectedMrfRecord] = useState();
  const [initCallInfoModalOpen, setInitCallInfoModalOpen] = useState(false);
  const [callInfoData, setCallInfoData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingRequisition, setPendingRequisition] = useState();
  const [isCreateCondidateModalOpen, setIsCreateCondidateModalOpen] = useState(
    false,
  );
  const [
    isCreateCandidateWithoutMRFModalOpen,
    setIisCreateCandidateWithoutMRFModalOpen,
  ] = useState(false);
  const [mrfDetails, setMrfDetails] = useState();
  const [details, setDetails] = useState([]);
  const [sendMrfData, setSendMrfData] = useState();
  const [bodyData, setBodyData] = useState([]);
  const [isHoldMRFModalOpen, setIsHoldMRFModalOpen] = useState(false);
  const [candidate, setCandidate] = useState();
  const [isModal2, setIsModal2] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [dropdownFilterList, setDropdownFilterList] = useState([
    { value: 'all', label: 'All MRF' },
    { value: 'open', label: 'Open MRF' },
    { value: 'close', label: 'Close MRF' },
    { value: 'hold', label: 'Hold MRF' },
  ]);
  const [selectedDropdownFilter, setSelectedDropdownFilter] = useState('all');
  const [selectedDropdownFilterType, setSelectedDropdownFilterType] = useState(
    '',
  );

  const holdMRF = async mrf => {
    setSendMrfData(mrf);
    setIsHoldMRFModalOpen(!isHoldMRFModalOpen);
  };

  const handleOnDropdownItemClick = e => {
    const status = e.currentTarget.getAttribute('data-status');
    const type = e.currentTarget.getAttribute('data-type');
    setSelectedDropdownFilter(status);
    setSelectedDropdownFilterType(type);
  };

  useEffect(() => {
    getMrfRecord(selectedDropdownFilterType);
  }, [selectedDropdownFilter]);

  const isModalOpen2 = data => {
    console.log(data);
    setIsModal2(!isModal2);
    myFunction(data);
  };
  const myFunction = async value => {
    let data = {
      mrf_id: value.mrf_id,
      mrf_applying_for: value.applying_for,
    };

    let result = await api.get(config.candidateOnMRF, true, data);
    if (result && result.code == 200) {
      setCandidate(result && result.data);
    }
  };

  const getDataForBody = async () => {
    let result = await api.get(config.all_HRMS_status_details);
    if (result && result.code == 200) {
      setBodyData(result && result.data);
    }
  };

  useEffect(() => {
    getDataForBody();
    getMrfRecord();
    setFields();
  }, []);

  const getMrfRecord = async () => {
    let data = {
      status: selectedDropdownFilter,
    };
    let result = await api.get(config.showAssignedMrfForRecruiter, true, data);
    if (result && result.code == 200) {
      setMrfRecord(result.data);
    } else {
    }
  };

  const isViewModalOpen = mrf => {
    setIsModalOpen(!isModalOpen);
    setPendingRequisition(mrf);
    getAssignedMrfById(mrf);
  };

  const getAssignedMrfById = async mrf => {
    let data = {
      mrf_id: mrf && mrf.mrf_id,
      mrf_type: mrf && mrf.applying_for,
    };
    let result = await api.get(config.getMrfById, true, data);
    if (result && result.code == 200) {
      setSelectedMrfRecord(result.data[0]);
    } else {
    }
  };
  const SelectCV = async value => {
    console.log(value);
    let data = {
      mrf_id: value.mrf_id,
      applying_for: value.applying_for,
    };
    console.log(data);
    let result = await api.get(config.sendMailForApproval, true, data);
    if (result && result.code == 200) {
      alert('Mail Sent');
    }
  };

  const initiateCall = async value => {
    console.log('value', value);
    let user = getLocalDataAsObject('user');
    let empId = user && user.length && user[0].emp_id;
    let payload = {
      agent_id: empId,
      agent_number: '917339895773',
      candidate_number_1:
        value.candidate_number_1 &&
        value.candidate_number_1 !== null &&
        value.candidate_number_1 !== undefined
          ? value.candidate_number_1
          : '917728868573',
      candidate_name_1:
        value.candidate_name_1 &&
        value.candidate_name_1 !== null &&
        value.candidate_name_1 !== undefined
          ? value.candidate_name_1
          : 'Home',
    };
    let result = await api.post(config.intiateCall, payload);
    if (result && result.code == 200) {
      setInitCallInfoModalOpen(true);
      callInfoData(result?.data);
    }
  };

  const create_NewCondidate = mrf => {
    setMrfDetails(mrf);
    setIsCreateCondidateModalOpen(!isCreateCondidateModalOpen);
  };

  const createNewCandidateWithoutMRF = () => {
    setIisCreateCandidateWithoutMRFModalOpen(
      !isCreateCandidateWithoutMRFModalOpen,
    );
  };

  const fields = [
    { label: 'MRF ID', key: 'mrf_id', _style: { width: '5%' } },
    { label: 'Applying For', key: 'mrf_type', _style: { width: '10%' } },
    { label: "Department", key: "department", _style: { width: "10%" }, },
    { label: "Designation", key: "designation", _style: { width: "10%" }, },
    { label: 'Location', key: 'location', _style: { width: '10%' } },
    { label: 'MRF Assigned At', key: 'assigned_at', _style: { width: '10%' } },
    { label: 'MRF Status', key: 'status', _style: { width: '5%' } },
    {
      key: 'show_details',
      label: '',
      _style: { width: '5%' },
      sorter: false,
      filter: false,
    }
  ];

  const setFields = () => {
    // if(isPermission('view_candidate') || isPermission('create_candidate') || isPermission('hold_mrf') || isPermission('send_candidate_selection_mail') || isPermission('show_candidates')){
    //   fields.push({
    //     key: 'show_details',
    //     label: 'Action',
    //     _style: { width: '5%' },
    //     sorter: false,
    //     filter: false,
    //   });
    // }
    if(isPermission('new_call')){
      fields.push({ label: 'Initiate Call', key: 'intiate_call', _style: { width: '5%' } });
    }
  }
  const toggleDetails = index => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const getBadge = status => {
    switch (status) {
      case 'Close':
        return 'danger';
      case 'Hold':
        return 'warning';
      default:
        return 'info';
    }
  };

  const handleCancel = () => {
    setInitCallInfoModalOpen(false);
  };

  return (
    <div className="">
      <div className="container">
        <h3 className="text-uppercase text-center mt-3 mb-4">
          <u>List Of Pending Requisition</u>
        </h3>
        <CCard>
          <CCardBody>
            <div className="col-md-12 mb-3 btn2 flex-wrap">
              {isPermission('candidate_library') &&
              <Link to="/candidate-library">
                <CButton
                  onClick={() => createNewCandidateWithoutMRF()}
                  size="sm"
                  color="danger"
                >
                  Candidate Library
                </CButton>
              </Link>
              }
              {isPermission('create_candidate_without_mrf') && 
              <CButton
                onClick={() => createNewCandidateWithoutMRF()}
                size="sm"
                color="danger"
              >
                Create Candidate Without MRF
              </CButton>
}
            </div>
            <div className="col-md-12 mb-3 text-right">
              <StatusDropdownMenu
                onClick={handleOnDropdownItemClick}
                dropdownItems={dropdownFilterList}
                toggleBtnColor="primary"
                toggleBtnText={selectedDropdownFilter}
                activeTab="pending_requistion"
                isShowCounts={true}
                totalRecords={mrfRecord?.length || 0}
              />
            </div>
            <CDataTable
              items={mrfRecord}
              fields={fields}
              tableFilter={{label: 'Filter:', placeholder: 'Search'}}
              itemsPerPageSelect
              itemsPerPage={10}
              hover
              sorter
              pagination
              scopedSlots={{
                status: item => (
                  <td>
                    <CBadge color={getBadge(item.active_status)}>
                      {item.active_status}
                    </CBadge>
                  </td>
                ),
                show_details: (item, index) => {
                  return (
                    <td className="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        shape="square"
                        size="sm"
                        onClick={() => {
                          toggleDetails(index);
                        }}
                      >
                        {details.includes(index) ? 'Hide' : 'Show'}
                      </CButton>
                    </td>
                  );
                },
                intiate_call: (item, index) => {
                  return (
                    <td className="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        shape="square"
                        size="sm"
                        onClick={() => {
                          initiateCall(item);
                        }}
                      >
                        Call
                      </CButton>
                    </td>
                  );
                },
                details: (item, index) => {
                  return (
                    <CCollapse show={details.includes(index)}>
                      <CCardBody>
                       <p className="text-muted">
                          <b style={{color:"black"}}>MRF Raised By :</b> <b style={{color:"darkblue"}}>{item.created_by_name}</b>
                      </p>
                        {isPermission('view_candidate') &&
                        <CButton
                          onClick={() => isViewModalOpen(item)}
                          size="sm"
                          color="primary"
                          className="ml-1"
                        >
                          View
                        </CButton>
                        }
                        {isPermission('create_candidate') &&
                        <CButton
                          onClick={() => create_NewCondidate(item)}
                          size="sm"
                          color="success"
                          className="ml-1"
                        >
                          Create Candidate
                        </CButton>
                        }
                        {isPermission('hold_mrf') &&
                        <CButton
                          onClick={() => holdMRF(item)}
                          size="sm"
                          color="primary"
                          className="ml-1"
                        >
                          Hold MRF
                        </CButton>
                        }
                        {isPermission('send_candidate_selection_mail') &&
                        <CButton
                          color="success"
                          className="ml-1"
                          size="sm"
                          disabled={isDisabled}
                          onClick={() => {
                            setIsDisabled(true);
                            SelectCV(item);
                          }}
                        >
                          Send Mail For Selection
                        </CButton>
                       }
                       {isPermission('show_candidates') &&
                        <CButton
                          color="primary"
                          className="ml-1"
                          size="sm"
                          onClick={() => isModalOpen2(item)}
                        >
                          Show Candidates
                        </CButton>
                        }
                      </CCardBody>
                    </CCollapse>
                  );
                },
              }}
            />
          </CCardBody>
        </CCard>

        <ModalPopupReceivedRequisition
          isModalOpen={isModalOpen}
          message={'Selected Pending Requisition Full Details'}
          isViewModalOpen={isViewModalOpen}
          pendingRequisition={pendingRequisition}
          mrfSelectedRecord={mrfSelectedRecord}
        />
        <InitCallInfoModal
          open={initCallInfoModalOpen}
          handleCancel={handleCancel}
          callInfoData={callInfoData}
        />
        <CondidateModal
          create_NewCondidate={create_NewCondidate}
          isCreateCondidateModalOpen={isCreateCondidateModalOpen}
          mrfDetails={mrfDetails}
          setIsCreateCondidateModalOpen={setIsCreateCondidateModalOpen}
        />

        <CandidateModalWithoutMRF
          createNewCandidateWithoutMRF={createNewCandidateWithoutMRF}
          isCreateCandidateWithoutMRFModalOpen={
            isCreateCandidateWithoutMRFModalOpen
          }
          setIisCreateCandidateWithoutMRFModalOpen={setIisCreateCandidateWithoutMRFModalOpen}
        />

        <HoldMrfModal
          holdMRF={holdMRF}
          sendMrfData={sendMrfData}
          isHoldMRFModalOpen={isHoldMRFModalOpen}
          bodyData={bodyData}
        />

        <ModalShowCandidate
          isModal2={isModal2}
          isModalOpen2={isModalOpen2}
          candidate={candidate}
        />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PendingRequisition;
