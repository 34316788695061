import React, { useState } from 'react';
import { TheSidebar, TheFooter, TheHeader } from '../containers/index';
import CandidateLibrary from './candidateLIbrary/candidateLibraryList';
import MySelect from '../../src/reusable/CustMultiSelect';

const Candidate_library = () => {
  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />

        <div className="c-body">
          <CandidateLibrary />
        </div>
        <TheFooter />
      </div>
    </div>
  );
};
export default Candidate_library;
