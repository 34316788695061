import { getFomatedError } from '../../services/ApiService';
import { config } from '../../../utils/apiUrl';
import API from '../../../utils/apiCalling';
const api = new API();

const getSettingsByAdminDetails = params => {
  let res = api.get(config.settingsbyadmin, true, params)
    .then(res => {
      return res;
    })
    .catch(err => {
      return getFomatedError(err);
    });
  return res;
};
/*update api of setting */
const updateDataOfSetting = params => {
  let res = api
    .post(config.updatesettings, params)
    .then(res => {
      return res;
    })
    .catch(err => {
      return getFomatedError(err);
    });
  return res;
};
export default {
  getSettingsByAdminDetails,
  updateDataOfSetting,
};
