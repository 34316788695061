import React, { useEffect, useState } from 'react';
import DonutChart from 'src/Chart/DonutChart';
import ViewAllTeamPerformanceModal from '../modal/ViewAllTeamPerformanceModal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const OverAllPerformanceOfTeam = ({ teamPerformanceData }) => {
  const [teamData, setTeamData] = useState([]);
  const [showAllPerformance, setShowAllPerformance] = useState(false);
  const convertTeamPerformanceDataforChart = () => {
    let dataArray = [];
    teamPerformanceData &&
      teamPerformanceData.length > 0 &&
      teamPerformanceData.map(item => {
        return dataArray.push([item?.name, parseInt(item?.Percentage)]);
      });
    setTeamData(dataArray);
  };
  const handleAllPerformanceModal = () => {
    setShowAllPerformance(!showAllPerformance);
  };
  useEffect(() => {
    convertTeamPerformanceDataforChart();
  }, [teamPerformanceData]);

  return (
    <div className="cardBox mb-3">
      <h3 className="fs-6 fw-bold mb-3">Overall Performance</h3>
      <div className="row align-items-center">
        <div className="col-md-6">
          {teamPerformanceData != null && teamPerformanceData.length != 0 ? (
            <div className="table-responsive overallTable">
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Teams</th>
                    <th>Count</th>
                    <th>Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {teamPerformanceData &&
                    teamPerformanceData.length > 0 &&
                    teamPerformanceData.slice(0, 5).map((item, index) => (
                      <tr key={item?.name}>
                        <td>{index + 1}</td>
                        <td>{item?.name}</td>
                        <td>{item?.team_count}</td>
                        <td>{`${parseInt(
                          item?.Percentage ? item?.Percentage : 0,
                        )}%`}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="table-responsive overallTable">
              {/* <SkeletonTheme baseColor="#202020" highlightColor="#444"> */}
              {/* <p> */}
              <Skeleton count={9} />
              {/* </p> */}
              {/* </SkeletonTheme> */}
            </div>
          )}

          <div className="text-end m-2">
            <span
              style={{ cursor: 'pointer' }}
              onClick={handleAllPerformanceModal}
            >
              View More
            </span>
          </div>
        </div>
        <div className="col-md-6">
          {teamPerformanceData ? (
            <div id="">
              <DonutChart data={teamData} />
            </div>
          ) : (
            <Skeleton
              style={{ width: '200px', height: '200px', borderRadius: '50%' }}
              circle={true}
            ></Skeleton>
          )}
        </div>
      </div>
      <ViewAllTeamPerformanceModal
        showAllPerformance={showAllPerformance}
        handleAllPerformanceModal={handleAllPerformanceModal}
        teamPerformanceData={teamPerformanceData}
      />
    </div>
  );
};

export default OverAllPerformanceOfTeam;
