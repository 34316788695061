import React from 'react';
import TwoDDountChart from 'src/Chart/TwoDDountChart';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SummaryOfTeam = ({ teamSummary }) => {
  return (
    <div className="col-md-6 mb-3 ps-md-2">
      <div className="cardBox h-100">
        <h3 className="fs-6 fw-bold">Summary</h3>
        <div className="d-flex align-items-center">
          {teamSummary ? (
            <div className="summeryman">
              <div className="summerychart">
                <span>Gross Hrs</span>
                <strong>{teamSummary?.GrossHours}</strong>
                hrs
              </div>
              <div id="piechart2" style={{ width: '175px', height: '175px' }}>
                <TwoDDountChart
                  graphData={[
                    parseInt(
                      teamSummary?.TotalWorkingHours?.newWorkinghur
                        ? teamSummary?.TotalWorkingHours?.newWorkinghur.split(
                            '/',
                          )[1]
                        : '0',
                    ),
                    parseInt(
                      teamSummary?.TotalWorkingHours?.totalBreakHours
                        ? teamSummary?.TotalWorkingHours?.totalBreakHours.split(
                            '/',
                          )[1]
                        : '0',
                    ),
                  ]}
                  label={[
                    'Net Working Hrs',
                    'Break hours',

                    // 'Miscellanious',
                  ]}
                  labemName=""
                  labelColor={['rgb(13, 110, 253,1)', 'rgb(244, 115, 32,1)']}
                  borderWidth={2}
                />
              </div>
            </div>
          ) : (
            <div className="summeryman">
              <Skeleton count={6} />
            </div>
          )}

          {teamSummary ? (
            <div className="ps-2 w-100">
              <div className="chartcountData d-flex align-items-center">
                <span>1</span>
                <div className="progressman w-100">
                  <div className="d-flex justify-content-between mb-2">
                    <span>Net Working Hrs</span>
                    <strong className="text-black">{`${
                      teamSummary?.TotalWorkingHours?.newWorkinghur
                        .split('/')[0]
                        .split(':')[0]
                    } / ${
                      teamSummary?.TotalWorkingHours?.newWorkinghur.split(
                        '/',
                      )[1]
                    }%`}</strong>
                  </div>
                  <div
                    className="progress rounded-3"
                    role="progressbar"
                    aria-label="Basic example"
                    aria-valuenow={25}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                    <div
                      className="progress-bar rounded-3 bg-primary"
                      style={{ width: '25%' }}
                    />
                  </div>
                </div>
              </div>
              <div className="chartcountData breakhours d-flex align-items-center">
                <span>2</span>
                <div className="progressman w-100">
                  <div className="d-flex justify-content-between mb-2">
                    <span>Break hours</span>
                    <strong className="text-black">{`${
                      teamSummary?.TotalWorkingHours?.totalBreakHours
                        .split('/')[0]
                        .split(':')[0]
                    } / ${
                      teamSummary?.TotalWorkingHours?.totalBreakHours.split(
                        '/',
                      )[1]
                    }%`}</strong>
                  </div>
                  <div
                    className="progress rounded-3"
                    role="progressbar"
                    aria-label="Basic example"
                    aria-valuenow={25}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                    <div
                      className="progress-bar rounded-3 bg-orange"
                      style={{ width: '25%' }}
                    />
                  </div>
                </div>
              </div>
              <div className="chartcountData leaves d-flex align-items-center">
                <span>3</span>
                <div className="progressman w-100">
                  <div className="d-flex justify-content-between mb-2">
                    <span>Gross Hours</span>
                    <strong className="text-black">
                      {teamSummary?.TotalWorkingHours?.GrossHours.split(':')[0]}
                    </strong>
                  </div>
                  <div
                    className="progress rounded-3"
                    role="progressbar"
                    aria-label="Basic example"
                    aria-valuenow={25}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                    <div
                      className="progress-bar rounded-3 bg-light-orange"
                      style={{ width: '25%' }}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="chartcountData Miscellanious d-flex align-items-center">
              <span>
                <i className="bi bi-three-dots" />
              </span>
              <div className="progressman w-100">
                <div className="d-flex justify-content-between mb-2">
                  <span className="bg-transparent">Miscellanious</span>
                  <strong className="text-black">04 / 04%</strong>
                </div>
                <div
                  className="progress rounded-3"
                  role="progressbar"
                  aria-label="Basic example"
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  <div
                    className="progress-bar rounded-3 bg-secondary"
                    style={{ width: '25%' }}
                  />
                </div>
              </div>
            </div> */}
            </div>
          ) : (
            <div className="ps-2 w-100">
              <Skeleton count={6} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SummaryOfTeam;
