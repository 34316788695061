const getIsSettingsByAdminPending = state =>state.setting.getSettingsByAdminPending;
const getIsSettingsByAdminRes = state => state.setting.getSettingsByAdminRes;
const getIsSettingsByAdminResError = state =>state.setting.getSettingsByAdminResError;

const getIsupdateSettingDataPending = state =>state.setting.updateSettingDataPending;
const getIsupdateSettingDataRes = state => state.setting.updateSettingDataRes;
const getIsupdateSettingDataResError = state =>state.setting.updateSettingDataResError;

export default {
  getIsSettingsByAdminPending,
  getIsSettingsByAdminRes,
  getIsSettingsByAdminResError,

  getIsupdateSettingDataPending,
  getIsupdateSettingDataRes,
  getIsupdateSettingDataResError,
};
