import React, { useState, useEffect } from 'react';
import API from 'src/utils/apiCalling';
import { connect } from 'react-redux';
import OtpInput from 'react-otp-input';
import { toast, ToastContainer } from 'react-toastify';
import { config } from 'src/utils/apiUrl';
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton,
  CDataTable,
  CFormGroup,
  CCol,
  CLabel,
} from '@coreui/react';
import moment from 'moment';
import { useForceUpdate } from 'src/hooks';
import { getLocalDataAsObject, setLocalDataAsObject } from 'src/utils/CoustomStorage';
import { otpsActions, otpsSelector } from 'src/core/resource/otps';
import appConstants from 'src/core/configs/Constants.config';
const OtpModal = props => {
  const {
    isOtpModalOpen,
    viewOtpModal,
    firstTime, 
    setFirstTime,
    setIsOtpModalOpen,
    navigateToSendOtp,
    navigateToVerifyOtp,
    verifyOtpPending,
    verifyOtpRes,
    verifyOtpError,
    sendOtpRes,
    sendOtpPending,
    sendOtpError,
    navigateClearOtpStore,
  } = props;
  const otpExpireTime = appConstants.otpExpireTime;
  const api = new API();
  const forceUpdate = useForceUpdate();
  const user = getLocalDataAsObject('user');
  const [otp, setOtp] = useState('');
  const [disable, setDisable] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const toastId = moment().unix();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const setData = () => {
    setFirstTime(false);
    forceUpdate();
    sendOtp();
  }

  const sendOtp = async() => {
      navigateToSendOtp();
      setMinutes(1);
      setSeconds(59);
      forceUpdate();
  }

  useEffect(() => {
    console.log("--pending", { sendOtpPending })
    if(!sendOtpPending) {
      if(sendOtpError){
        console.log("--sendOtpError", { sendOtpError })
        if (!toast.isActive(toastId)) {
          toast.error(sendOtpError?.message, { toastId });
        }
      }else if(sendOtpRes){
        if (!toast.isActive(toastId)) {
          toast.success(sendOtpRes?.message);
        }
      }
    }
  },[sendOtpPending, sendOtpRes, sendOtpError]);



  const checkOtp = async (otp) => {
    let data = {
      ...sendOtpRes,
      'otp':otp,
    }
    navigateToVerifyOtp(data);
  };
  
  useEffect(() => {
    console.log("--pending", { verifyOtpPending })
    if(!verifyOtpPending) {
      if(verifyOtpError){
        console.log("--verifyOtpError", { verifyOtpError })
        if (!toast.isActive(toastId)) {
          toast.error(verifyOtpError?.message, { toastId });
        }
      }else if(verifyOtpRes){
        if (!toast.isActive(toastId)) {
          toast.success(verifyOtpRes?.message);
        }
        viewOtpModal(!isOtpModalOpen);
        sessionStorage.setItem('otpExpireTime', moment().add(otpExpireTime, 'minutes').format('YYYY-MM-DD HH:mm:ss'));
        console.log("--verifyOtpRes", { verifyOtpRes })
      }
      navigateClearOtpStore('HANDLE_CLEAR_VERIFY_OTP');
    }
  },[verifyOtpPending, verifyOtpRes, verifyOtpError]);


  return (
        <div className="">
          <CModal
            show={isOtpModalOpen}
            onClose={() => viewOtpModal(!isOtpModalOpen)}
            size="lg"
          >
            <CModalHeader>
              <CModalTitle>2FA Authentication</CModalTitle>
            </CModalHeader>
            {firstTime &&
              <CModalBody>
                    <CFormGroup row>
                        <CCol sm="12" className="text-center">
                          <CButton color="primary" onClick={e => setData()}>Click To Authenticate</CButton>
                        </CCol>
                    </CFormGroup>
              </CModalBody>
            }
            {!firstTime && <>
              <CModalBody>
                  <CFormGroup row className={'mt-5'}>
                      <CCol md="3">
                          <CLabel htmlFor="">Enter OTP</CLabel>
                      </CCol>
                      <CCol xs="12" md="9">
                          <OtpInput
                            className="otp-spacing"
                            inputStyle={{
                              width: '55%',
                              height: '180%',
                              color: '#212529',
                              backgroundClip: 'padding-box',
                              border: '1px solid #ced4da',
                              borderRadius: '0.375rem',
                              backgroundColor: '#e8f0fd',
                            }}
                            isDisabled={''}
                            value={otp}
                            onChange={e => setOtp(e)}
                            numInputs={6}
                            separator={<span></span>}
                            isInputNum={true}
                            renderInput={props => <input {...props} />}
                          />   
                      </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                        <div
                          className="text-right"
                          style={{ marginBottom: '5px', marginTop: '15px' }}
                        >
                          {seconds > 0 || minutes > 0 ? (
                            <p style={{ fontSize: '15px' }} className='text-secondary'>
                              <span>Resend OTP</span> {minutes < 10 ? `0${minutes}` : minutes}:
                              {seconds < 10 ? `0${seconds}` : seconds}
                            </p>
                          ) : (
                            <a
                              href="#"
                              style={{ fontSize: '15px'}}
                              onClick={e => sendOtp()}
                              className='text-primary'
                            >
                              Resend OTP
                            </a>
                          )}
                        </div>
                  </CFormGroup>
              </CModalBody>
              <CModalFooter>
                  <CButton color="primary" onClick={e => checkOtp(otp)} disabled = {!otp}>VERIFY</CButton>{' '}
              </CModalFooter>
            </>}
          </CModal>
          <ToastContainer />
    </div>
  );
};
const mapStateToProps = state => {
  return {
    verifyOtpRes: otpsSelector.verifyOtpRes(state),
    verifyOtpPending: otpsSelector.verifyOtpPending(state),
    verifyOtpError: otpsSelector.verifyOtpError(state),
    sendOtpRes: otpsSelector.sendOtpRes(state),
    sendOtpPending: otpsSelector.sendOtpPending(state),
    sendOtpError: otpsSelector.sendOtpPending(state),
  };
};
const mapDispatchToProps = dispatch => ({
  navigateToSendOtp: data =>
    dispatch(otpsActions.sendOtpRequestAction(data)),
  navigateToVerifyOtp: data => 
    dispatch(otpsActions.verifyOtpRequestAction(data)),
  navigateClearOtpStore: data => 
    dispatch(otpsActions.handleClearOtpStore(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OtpModal);