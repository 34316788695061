import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip } from 'chart.js';
Chart.register(ArcElement,Tooltip)

const TwoDDountChart = ({
  graphData,
  label,
  labemName,
  labelColor,
  borderWidth,
}) => {
  const data = {
    labels: label,
    datasets: [
      {
        label: labemName,
        data: graphData,
        backgroundColor: labelColor,
        hoverOffset: 4,
        borderWidth: 1,
      },
    ],
  };
  return <Doughnut data={data} />;
};

export default TwoDDountChart;
