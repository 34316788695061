import React from 'react';
import TwoDDountChart from 'src/Chart/TwoDDountChart';

const PercentageProductivityByDepart = ({ shrinkage }) => {
  return (
    <div className="col-md-7 mb-3">
      <div className="cardBox h-100">
        <h3 className="fs-6 fw-bold mb-3">Productivity In Dept (Mthly)</h3>
        <div className="row mb-4">
          <div className="col text-center">
            <div className="position-relative">
              <div id="techChart">
                <TwoDDountChart
                  graphData={[
                    parseInt(
                      shrinkage?.planned_shrinkage
                        ? shrinkage?.planned_shrinkage
                        : '0',
                    ),
                    100 -
                      parseInt(
                        shrinkage?.planned_shrinkage
                          ? shrinkage?.planned_shrinkage
                          : '0',
                      ),
                  ]}
                  label={['Shrinkage', '']}
                  labemName=""
                  labelColor={['rgb(254, 123, 30)', 'rgb(245, 245, 245)']}
                  borderWidth={10}
                />
              </div>
              <strong className="innerScore">
                {shrinkage?.planned_shrinkage
                  ? shrinkage?.planned_shrinkage
                  : '0'}
                <sub>%</sub>
              </strong>
            </div>
            <span className="text-primary fw-medium">Planned Shrinkage</span>
          </div>
          <div className="col text-center">
            <div className="position-relative">
              <div id="marketChart">
                <TwoDDountChart
                  graphData={[
                    parseInt(
                      shrinkage?.unplanned_shrinkage
                        ? shrinkage?.unplanned_shrinkage
                        : '0',
                    ),
                    100 -
                      parseInt(
                        shrinkage?.unplanned_shrinkage
                          ? shrinkage?.unplanned_shrinkage
                          : '0',
                      ),
                  ]}
                  label={['Unplanned shrinkage', '']}
                  labemName=""
                  labelColor={['rgb(254, 123, 30)', 'rgb(245, 245, 245)']}
                  borderWidth={10}
                />
              </div>
              <strong className="innerScore">
                {parseInt(
                  shrinkage?.unplanned_shrinkage
                    ? shrinkage?.unplanned_shrinkage
                    : '0',
                )}
                <sub>%</sub>
              </strong>
            </div>
            <span className="text-primary fw-medium">Unplanned Shrinkage</span>
          </div>
          <div className="col text-center">
            <div className="position-relative">
              <div id="purchaseChart">
                <TwoDDountChart
                  graphData={[
                    parseInt(
                      shrinkage?.total_shrikage
                        ? shrinkage?.total_shrikage
                        : '0',
                    ),
                    100 -
                      parseInt(
                        shrinkage?.total_shrikage
                          ? shrinkage?.total_shrikage
                          : '0',
                      ),
                  ]}
                  label={['total shrinkage', 'not achived']}
                  labemName=""
                  labelColor={['rgb(254, 123, 30)', 'rgb(245, 245, 245)']}
                  borderWidth={10}
                />
              </div>
              <strong className="innerScore">
                {parseInt(
                  shrinkage?.total_shrikage ? shrinkage?.total_shrikage : '0',
                )}
                <sub>%</sub>
              </strong>
            </div>
            <span className="text-primary fw-medium">Total Shrinkage</span>
          </div>
          {/* <div className="col text-center">
            <div className="position-relative">
              <div id="r&dChart">
                <TwoDDountChart
                  graphData={[60, 40]}
                  label={['achived', 'not achived']}
                  labemName=""
                  labelColor={['rgb(254, 123, 30)', 'rgb(245, 245, 245)']}
                  borderWidth={10}
                />
              </div>
              <strong className="innerScore">
                28<sub>%</sub>
              </strong>
            </div>
            <span className="text-primary fw-medium">R&amp;D</span>
          </div> */}
        </div>
        {/* <div className="row align-items-center">
          <div className="col">
            <div className="d-flex align-items-center avgLowerTxt">
              <strong className="fs-2 d-block text-center text-orange">
                45
                <span className="d-block fw-normal fs-8 text-secondary">
                  <b className="text-primary">Lower</b> Then Last Month
                </span>
              </strong>
              <lottie-player
                src="images/arrow-triplet.json"
                background="transparent"
                speed={1}
                style={{ width: '54px', height: '60px' }}
                loop
                autoPlay
              />
            </div>
          </div>
          <div className="col text-end">
            <a className="btn btn-primary">Download Report</a>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PercentageProductivityByDepart;
