import { getFomatedError } from '../../services/ApiService';
import { config } from '../../../utils/apiUrl';
import API from '../../../utils/apiCalling';
const api = new API();

const getSample = params => {
  return null;
};
const getMessageNotificationApiFun = () => {
  let apiRes = api
    .get(config.correctionNotification)
    .then(res => {
      return res;
    })
    .catch(error => {
      return getFomatedError(error);
    });

  return apiRes;
};
const getNotificationApi = (params) => {
  let apiRes = api
    .get(config.getNotification,true, params)
    .then(res => {
      return res;
    })
    .catch(error => {
      return getFomatedError(error);
    });

  return apiRes;
};
const clearOneNotificationApi = (params) => {
  let apiRes = api
    .delete(config.clearOneNotification,true, params)
    .then(res => {
      return res;
    })
    .catch(error => {
      return getFomatedError(error);
    });

  return apiRes;
};
const clearAllNotificationApi = () => {
  let apiRes = api
    .get(config.clearAllNotification)
    .then(res => {
      return res;
    })
    .catch(error => {
      return getFomatedError(error);
    });

  return apiRes;
};

export default {
  getSample,
  getMessageNotificationApiFun,
  getNotificationApi,
  clearOneNotificationApi,
  clearAllNotificationApi
};
