import React from 'react';
import HorizontalStackBarChart from 'src/Chart/HorizontalStackBarChart';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const LeaveVsPlannedCard = ({ leavevsplannedleavedata }) => {
  return (
    <div className="col-md-8 mb-3 pe-md-1">
      <div className="cardBox h-100">
        <h3 className="fs-6 fw-bold">Leave vs. Planned Working Time</h3>
        <div id="daysChart">
          {leavevsplannedleavedata ? (
            <HorizontalStackBarChart
              leavevsplannedleavedata={leavevsplannedleavedata}
            />
          ) : (
            <Skeleton count={10} />
          )}
        </div>
      </div>
    </div>
  );
};

export default LeaveVsPlannedCard;
