import React from 'react'
import {
    CModal,
    CModalBody,
    CButton} from '@coreui/react'

const ModalViewImage = (props) => {
    const { isOpen, isViewModalOpen, imagePath } = props

    return (
        <div className="">
            <CModal
                show={isOpen}
                onClose={() => isViewModalOpen(!isOpen)}
                size="lg"
            >

                <CModalBody>
                    <CButton className="float-right" color="danger" onClick={() => isViewModalOpen(!isOpen)}><i className="fa fa-times" aria-hidden="true"></i></CButton>{' '}
                    <div className="viewImage">
                        {imagePath && imagePath.includes(".pdf") ?
                        <embed src={imagePath} width="100%" height="600" />
                            :
                            <img src={imagePath} alt="" width="500px" height="400px" ></img>
                        }
                    </div>
                </CModalBody>
            </CModal>
        </div>
    )
};
export default ModalViewImage







