import React from 'react';
import CustomPagination from './CustomPagination';

const EmployeeList = ({ filterEmployeeList, setTab, setEmpId }) => {
  return (
    <div className="cardBox ">
      <div className="d-flex align-items-center justify-content-between pb-3">
        <h3 className="fs-6 fw-bold mb-0">Attendance Status</h3>
        <div className="position-relative me-2">
          <input
            type="search"
            placeholder="Search here"
            className="searchinput searchmn"
          />
          <img
            src="images/search.svg"
            alt="searchicon"
            width={20}
            className="searcicon"
          />
        </div>
      </div>
      <div className="table-responsive attendancTbl">
        <table className="table">
          <tbody>
            {filterEmployeeList.map((item, index) => (
              <tr className="border-top">
                <td className="ps-0 empNumberCOunt">
                  <span className="empNumber">{index + 1}</span>
                </td>
                {Object.keys(item).map(key => (
                  <td>
                    <div className="attendanceContent">
                      <h6>{key}</h6>
                      <span>{item[key]}</span>
                    </div>
                  </td>
                ))}

                <td
                  onClick={() => {
                    setTab('employee');
                    setEmpId(item['Emp ID']);
                  }}
                >
                  <a className="btn-outline-orange">
                    <img
                      src="images/eyeorange.svg"
                      width={16}
                      className="me-2"
                      alt=""
                    />{' '}
                    Insights
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CustomPagination />
    </div>
  );
};

export default EmployeeList;
