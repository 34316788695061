import React, { useState,useEffect} from "react";
import { CustButton } from 'src/reusable';

import {toast, ToastContainer} from 'react-toastify';
import swal from "sweetalert";
import OtpInput from 'react-otp-input';
import { AvForm, AvField } from "availity-reactstrap-validation";

const AadharCardVerify = (props) => {

  const { isOpen1, isViewModalOpen1, processedVerifiedFun } = props;

  const [aadharNoEntered, setAadharNoEntered] = useState(false);
  const [aadharNumber, setAadharNumber] = useState('');
  const [isMobileInAadharCard, setIsMobileInAadharCard] = useState(false);
  const [clientIdGetFormApi, setClientIdGetFormApi] = useState();
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otp, setOtp] = useState()
  const [userVerifiedDetails, setUserVerifiedDetails] = useState();
  const [button, setButton] = useState(false);
  const regexAadhar = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const checkOtp = e => {
    setAadharNumber(e);
    if (e.length === 12) {
      if (regexAadhar.test(e)) {
        setButton(true);
      }else{
        toast.error("Please enter a valid Aadhaar No.");
        setButton(false);
      }
    } else {
      setButton(false);
    }
  };

  const generateAadharOTPbtn = async () => {
    swal({
      html: true,
      title: "Are you sure?",
      text: `To proceed with aadhaar verification`,
      icon: "success",
      buttons: ["No, cancel it!", "Proceed"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        if (regexAadhar.test(aadharNumber)) {

        }else{
          toast.error("Please enter a valid Aadhaar No.")
        }
        let data = {
          "id_number": aadharNumber
        }
        const response = await fetch("https://kyc-api.surepass.io/api/v1/aadhaar-v2/generate-otp", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTYzNTMzMzk2NywianRpIjoiMTQzNmY1MjQtMWY3Zi00MjQ2LWIzODgtYzY5MmRkZWI4NGE2IiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnFkZWdyZWVzQGFhZGhhYXJhcGkuaW8iLCJuYmYiOjE2MzUzMzM5NjcsImV4cCI6MTk1MDY5Mzk2NywidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbInJlYWQiXX19.MaT1P2eR31wpoXKBwjG8yJkSmDGHzxDFb0zRCDaj7qg"
          },
          body: JSON.stringify(data)
        }).catch((error) => {
        });
        let result = await response.json();
        if(result?.data?.valid_aadhaar === false && result?.data?.status === "invalid_aadhaar"){
          toast.error("Please enter a valid Aadhaar No.")
        }
        if (result && result.data.if_number == true && result.data.otp_sent == true) {
          setClientIdGetFormApi(result.data.client_id);
          setAadharNoEntered(true);
          setIsMobileInAadharCard(true);
          setMinutes(1)
          setSeconds(59)
        }
      }
    });
  };

  // aadhar  otp verify api
  const verifyAadharOTPbtn = async () => {
        let otpNumbersConcate = otp;
        let data = {
          "client_id": clientIdGetFormApi,
          "otp": otpNumbersConcate
        }
        const response = await fetch("https://kyc-api.surepass.io/api/v1/aadhaar-v2/submit-otp", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTYzNTMzMzk2NywianRpIjoiMTQzNmY1MjQtMWY3Zi00MjQ2LWIzODgtYzY5MmRkZWI4NGE2IiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnFkZWdyZWVzQGFhZGhhYXJhcGkuaW8iLCJuYmYiOjE2MzUzMzM5NjcsImV4cCI6MTk1MDY5Mzk2NywidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbInJlYWQiXX19.MaT1P2eR31wpoXKBwjG8yJkSmDGHzxDFb0zRCDaj7qg"
          },
          body: JSON.stringify(data)
        }).catch((error) => {
        });

        let result = await response.json();
        if (result && result.success == true) {
          toast.success("Aadhaar OTP verification is successful")
          setUserVerifiedDetails(result.data);
          setIsMobileInAadharCard(false);
          setIsOtpVerified(true);
        } else {
          toast.error(result && result.message)
        }
  };

  const processedVerified = () => {
    processedVerifiedFun(true, userVerifiedDetails);
  }
  const handleChangeOTP = otp => {
    setOtp(otp)
  }
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  
  return (
    <div className="">
      {aadharNoEntered == false ? <>
        <div className="justify-content-center container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="head text-center">
                <div className="heading">
                  <span>Aadhaar Verification Number</span>
                </div>
              </div>
              <div className="card1 text-center">
                <h5 className="m-0">
                  <img src="images\aadhar.svg" width="150px" />
                </h5>
                <span className="mobile-text">
                  You need to enter your Aadhaar number and click on proceed
                  button to verify your aadhaar
                </span>
                <div className="text-center m-5" style={{ margin: "auto" }}>

                  <div className="" style={{ width: "70%", margin:"auto" }}>
                    <label className="font-weight-bold m-2" style={{paddingBottom:"5%"}}>
                      Aadhaar Number
                    </label>
                    <OtpInput
            inputStyle={{
              width: '100%',
              height: '170%',
              color: '#212529',
              backgroundColor: '#fff',
              backgroundClip: 'padding-box',
              border: '1px solid black',
              borderRadius: '0.375rem',
              fontWeight: "bold",
              fontSize: "medium"
            }}
            value={aadharNumber}
            onChange={(e) => checkOtp(e)}
            numInputs={12}
            separator={<span>&nbsp;</span>}
            isInputNum={true}
            renderInput={props => <input {...props} />}
          />
                  </div>
                </div>
                <div className="text-center">
                <CustButton
       type="button"
       name="Verify Aadhaar"
       text="Proceed"
       color="green"
       style={{backgroundColor:"green",color:"white"}}
       onClick={generateAadharOTPbtn}
       className="btn"
       icon="fa fa-check"
       isDisabled={button ? false : true}
       />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
        :
        <></>}
      {isMobileInAadharCard == true ? <>
        <div className="">
          <div className="justify-content-center container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="head text-center">
                  <div className="heading">
                    <span>Aadhaar Verification OTP</span>
                  </div>
                </div>
                <div className="card1 text-center">
                  <h5><img src="images/password.png" width="150px" /></h5>
                  <span>Please enter <b className="text-danger">OTP</b> received on your <b className="text-danger">Mobile Number</b> and click on verify button</span>
                    <div     style={{
                      paddingTop: '5%',
                      paddingBottom: '5%',
                      paddingLeft: '22%',
                      paddingRight: '20%',
                    }}>
                    <OtpInput 
                     inputStyle={{
                      width: '70%',
                      height: '200%',
                      color: '#212529',
                      backgroundColor: '#fff',
                      backgroundClip: 'padding-box',
                      border: '1px solid black',
                      borderRadius: '0.375rem',
                      fontSize:"medium",
                      fontWeight:"bold"
                    }}
                    value={otp}
                    onChange={handleChangeOTP}
                    numInputs={6}
                    isInputNum={true}
                    separator={<span></span>}
                    renderInput={props => <input {...props} />}
                    />
                    </div>
                  <div className="text-center">
                  <CustButton
       type="button"
       name="Verify Aadhaar"
       text="Verify"
       color="green"
       style={{backgroundColor:"green",color:"white"}}
       onClick={verifyAadharOTPbtn}
       className="btn"
       isDisabled={otp && otp.length === 6 ? false : true}
       />
                <CustButton
       type="button"
       name="Resend Otp"
       text={(seconds>0||minutes>0)?<> {minutes < 10 ? `0${minutes}` : minutes}:  {seconds < 10 ? `0${seconds}` : seconds}</>:"Resend Otp"}
       color="red"
       style={{backgroundColor:"red",color:"white"}}
       onClick={(seconds>0||minutes>0)?null:generateAadharOTPbtn}
       className="btn"
      
       />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </> : <></>}
      {isOtpVerified == true ? <>
        <div className="">
          <div className="justify-content-center container-fluid">

            <div className="row">
              <div className="col-12">
                <div className="head text-center"><div className="heading"><span>Aadhaar Details</span></div></div>
                <div className="card1 text-center">

                  <h5 className="m-0"><img src="images/approved.png" /></h5>
                  <span style={{color:"green",fontWeight:"bold",fontFamily:"cursive"}}>Aadhaar Verified Successfully</span>
                  <div className="text-left m-5"></div>

                  <div className="main">
                    <div className="row">
                      <div className=" col-sm-4 text-left m-1">
                        <label className="font-weight-bold text-left m-2">Full Name</label><br />
                        <label className="font-weight-bold text-left m-2">Aadhaar Number</label><br />
                        <label className="font-weight-bold text-left m-2">Date of Birth </label><br />
                        <label className="font-weight-bold text-left m-2">Address</label><br />
                      </div>

                      <div className="col-sm-6 text-left m-1">
                        <label className="font-weight-bold text-left m-2">{userVerifiedDetails.full_name}</label><br />
                        <label className="font-weight-bold text-left m-2">{userVerifiedDetails.aadhaar_number}</label><br />
                        <label className="font-weight-bold text-left m-2">{userVerifiedDetails.dob}</label><br />
                        <label className="font-weight-bold text-left m-2">P-{userVerifiedDetails.address.house} ,{userVerifiedDetails.address.landmark} ,{userVerifiedDetails.address.street} ,{userVerifiedDetails.address.loc} , PO - {userVerifiedDetails.address.po},{userVerifiedDetails.address.dist}, {userVerifiedDetails.address.state} - {userVerifiedDetails.zip}, {userVerifiedDetails.address.country}</label><br />
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                  <CustButton
                  type="button"
                  name="Proceed"
                  text="Proceed"
                  color="green"
                  style={{backgroundColor:"green",color:"white"}}
                  onClick={processedVerified}
                  icon="fa fa-arrow-right"
                  />
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>
      </> : <></>}
      {/* //   aadhar detail fatch screen end */}


< ToastContainer/>
    </div>

  );
};

export default AadharCardVerify;
