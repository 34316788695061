import { getFomatedError } from 'src/core/services/ApiService';
import { config } from 'src/utils/apiUrl';
import API from 'src/utils/apiCalling';
const api = new API();

const verifyOtpData = payload => {
  let apiRes = api
    .post(config.verifyOtpMailSMS, payload)
    .then(res => {
      return res;
    })
    .catch(error => {
      return getFomatedError(error);
    });
  return apiRes;
};

const sendOtpData = payload => {
    let apiRes = api
      .get(config.sendOtpMailSMS)
      .then(res => {
        return res;
      })
      .catch(error => {
        return getFomatedError(error);
      });
    return apiRes;
  };

export default {
    verifyOtpData,
    sendOtpData,
};