import React from 'react';
import TwoDDountChart from 'src/Chart/TwoDDountChart';

const PercentageWorkingHours = () => {
  return (
    <div className="col-md-5 mb-3 pe-md-1">
      <div className="cardBox h-100">
        <div className="row">
          <div className="col">
            <div className="position-relative">
              <div id="avgChart">
                <TwoDDountChart
                  graphData={[60, 40]}
                  label={['achived', 'not achived']}
                  labemName="% Of Employees During Ea. Shift Working Overtime"
                  labelColor={['rgb(254, 123, 30)', 'rgb(245, 245, 245)']}
                  borderWidth={2}
                />
              </div>
              <strong className="avgCount">60%</strong>
            </div>
            <span className="fs-8 text-center d-block fw-medium text-secondary">
              % Of Employees During Ea. Shift Working Overtime
            </span>
          </div>
          <div className="col">
            <div className="versusTxt flex-wrap border-0">
              <strong className="w-100 mb-4">
                <b>40</b>
                Weekly Avg Hours Per Employee
              </strong>
              <strong className="w-100">
                <b>31</b>
                Target
              </strong>
            </div>
            <div className="text-center">
              <a href="/" className="btn btn-primary px-3">
                Download Report
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PercentageWorkingHours;
