import React, { useState } from 'react';
import { useEffect } from 'react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import {
  CFormGroup,
  CCol,
  CLabel,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
} from '@coreui/react';
import { toast, ToastContainer } from 'react-toastify';

//const history = createHashHistory();
const AddCtcRange = props => {
  let api = new API();
  const {
    isOpenCTC,
    selectedCategory,
    setSelectedCategory,
    isViewModalOpenCTC,
    isEdit,
    setIsEdit,
    totalAssignedMrfToRecruiter,
    selectedDropdownFilter,
  } = props;
  const [applyingFor, setApplyingFor] = useState('');
  const [mrfId, setMrfId] = useState(0);
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [approvedMaxAmount, setApprovedMaxAmount] = useState(0);
  const [remark, setRemark] = useState('');
  const onSubmitRange = async () => {
    if(!maxAmount){
        toast.warning("Maximum Amount is required.")
        return;
    }
    let payload = {
        applying_for: applyingFor,
        mrf_id: mrfId || 0,
        min_amount: minAmount || 0,
        max_amount: maxAmount, 
    };
    let result = await api.post(config.saveMinMaxCtcForMRF, payload);
    if (result && result.code === 200) {
      toast.success(result && result?.message);
      setMinAmount(0);
      setMaxAmount(0);
      setSelectedCategory('');
      isViewModalOpenCTC(!isOpenCTC);
      totalAssignedMrfToRecruiter(selectedDropdownFilter);
    } else {
      toast.warning(result && result?.message);
    }
  };
  const selectedValues = () => {
    setApplyingFor(selectedCategory?.applying_for === "New Hiring" ? "vacancy" : "replacement");
    setMrfId(selectedCategory?.mrf_id);
    setMinAmount(selectedCategory?.min_amount);
    setMaxAmount(selectedCategory?.max_amount);
    setApprovedMaxAmount(selectedCategory?.approved_max_amount);
    setRemark(selectedCategory?.remark);
  };
  const onUpdateMaxAmount = async () => {
    if(!approvedMaxAmount){
        toast.warning("Approved Maximum Amount is required.")
        return;
    }
    if(!remark){
        toast.warning("Remark is required.")
        return;
    }
    let payload = {
        applying_for: applyingFor,
        mrf_id: mrfId || 0,
        approved_max_amount: approvedMaxAmount,
        remark: remark,
    };
    let result = await api.post(config.updateCtcForMRF, payload);
    if (result && result.code === 200) {
      toast.success(result && result?.message);
      setSelectedCategory('');
      setMinAmount(0);
      setMaxAmount(0);
      setApprovedMaxAmount(0);
      setRemark('');
      isViewModalOpenCTC(!isOpenCTC);
      setIsEdit(!isEdit);
      totalAssignedMrfToRecruiter(selectedDropdownFilter);
    } else {
      console.log(result);
    }
  };

  useEffect(() => {
    selectedValues();
  }, [selectedCategory]);
  return (
    <>
      <div className="row">
        <div className="col"></div>
      </div>

      <div>
        <CModal
          show={isOpenCTC}
          onClose={() => isViewModalOpenCTC(!isOpenCTC)}
          size="lg"
        >
          <CModalHeader closeButton>
            {isEdit ? 'Update' : 'Add'} CTC Range
          </CModalHeader>
          <CModalBody>
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="">
                  Min Amount
                </CLabel>
              </CCol>
              <CCol xs="12" md="8">
                <input
                  type="number"
                  className="is-untouched is-pristine av-invalid form-control"
                  name="min_amount"
                  id="min_amount"
                  value={minAmount}
                  onChange={e => setMinAmount(e.target.value)}
                  disabled = {isEdit}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="">
                  Max Amount <span className="required-field">*</span>
                </CLabel>
              </CCol>
              <CCol xs="12" md="8">
                <input
                  type="number"
                  className="is-untouched is-pristine av-invalid form-control"
                  name="max_amount"
                  id="max_amount"
                  value={maxAmount}
                  onChange={e => setMaxAmount(e.target.value)}
                  disabled = {isEdit}
                />
              </CCol>
            </CFormGroup>
            {isEdit && <>
                <CFormGroup row>
                <CCol md="3">
                    <CLabel htmlFor="">
                    Approved Maximum Amount <span className="required-field">*</span>
                    </CLabel>
                </CCol>
                <CCol xs="12" md="8">
                    <input
                    type="number"
                    className="is-untouched is-pristine av-invalid form-control"
                    name="amount"
                    id="amount"
                    value={approvedMaxAmount}
                    onChange={e => setApprovedMaxAmount(e.target.value)}
                    />
                </CCol>
                </CFormGroup>
                <CFormGroup row>
                <CCol md="3">
                    <CLabel htmlFor="">
                    Remark <span className="required-field">*</span>
                    </CLabel>
                </CCol>
                <CCol xs="12" md="8">
                    <input
                    type="text"
                    className="is-untouched is-pristine av-invalid form-control"
                    name="remark"
                    id="remark"
                    value={remark}
                    onChange={e => setRemark(e.target.value)}
                    />
                </CCol>
                </CFormGroup>
            </>}
          </CModalBody>
          <CModalFooter>
            {isEdit ? (
              <CButton color="primary" size="sm" onClick={onUpdateMaxAmount}>
                Update
              </CButton>
            ) : (
              <CButton color="primary" size="sm" onClick={onSubmitRange}>
                Submit
              </CButton>
            )}
          </CModalFooter>
        </CModal>
      </div>

      <ToastContainer />
    </>
  );
};

export default AddCtcRange;
