import React, { useState } from 'react';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile,
} from '@coreui/react';

const ShowAllTeamModal = ({
  showTeam,
  handleShowTeamMOdal,
  teamPerformanceData,
}) => {
  return (
    <CModal
      show={showTeam}
      onClose={() => handleShowTeamMOdal(!showTeam)}
      size="lg"
    >
      <CModalHeader closeButton>
        <CModalTitle>All Team</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="modal-body">
          <div className="allteams">
            <div className="row px-md-2">
              {teamPerformanceData &&
                teamPerformanceData.length > 0 &&
                teamPerformanceData.map((item, index) => (
                  <div key={item?.name} className="col-md-3 mb-2 px-md-1">
                    <a className="teamsCount">
                      <span>{index + 1}</span>
                      <strong>
                        {item?.name} <b>{item?.team_count}</b>
                      </strong>
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </CModalBody>
    </CModal>
  );
};

export default ShowAllTeamModal;
