import React, { useEffect, useState } from 'react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
} from '@coreui/react';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

const InvestmentSetting = () => {
    const api = new API();
    const [declarationReminderEmails, setDeclarationReminderEmails] = useState(true);
    const [requireApproval, setRequireApproval] = useState(false);
    const [allowSubmissionAfterCutoff, setAllowSubmissionAfterCutoff] = useState(false);
    const [proofRequired, setProofRequired] = useState(false);
    // const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())
    const [cutoffDate, setCutoffDate] = useState(new Date());
    const [proofSubmissionDate, setProofSubmissionDate] = useState(new Date());
    const [investmentSattingData,setInvestmentSattingData] =useState([])
    const [itDecFromData,setItDecFromData]=useState({
        declareFormDate:'',
        declareToDate:'',
        cutoffMonth:'',
        cutoffDate:'',
        newJoineeCutOffdays:'',
        newJoineeCutOffMonth:'',
        newJoineeCutOffDate:'',
        proofDueMonth:'',
        proofDueDate:'',

    })
    const days = Array.from({ length: 31 }, (_, i) => i + 1);
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const addInvestmentData = async () => {
        let {
            declareFormDate,
            declareToDate,
            cutoffMonth,
            cutoffDate,
            newJoineeCutOffdays,
            newJoineeCutOffMonth,
            newJoineeCutOffDate,
            proofDueMonth,
            proofDueDate,
    
        }=itDecFromData
        let cutOffDateSet1 =`${cutoffMonth}-${cutoffDate}`
        let newJoineeCutOffDate1=`${newJoineeCutOffMonth}-${newJoineeCutOffDate}`
        let proofDueDate1 =`${proofDueMonth}-${proofDueDate}`
        // if(cutoffMonth && cutoffDate){
        //     cutOffDateSet1 =`${cutoffMonth}-${cutoffDate}`
        // }
        // if(newJoineeCutOffMonth && newJoineeCutOffDate){
        //     newJoineeCutOffDate1 =`${newJoineeCutOffMonth}-${newJoineeCutOffDate}`
        // }
        // if(proofDueMonth &&proofDueDate )   {
        //     proofDueDate1 =`${proofDueMonth}-${proofDueDate}`
        // }
         
        let data = {
            declare_from :declareFormDate ||'', 
            declare_to:declareToDate, 
            cut_off_date:cutOffDateSet1 ||'', 
            new_joinee_cut_off_days:newJoineeCutOffdays ||'', 
            new_joinee_cut_off_date:newJoineeCutOffDate1 ||'', 
            require_approval:requireApproval? 1:0 ,
            submit_after_declaration:allowSubmissionAfterCutoff ?1:0,
            enable_reminder:declarationReminderEmails?1:0,
            required_proof:proofRequired?1:0,
            proof_due_date:proofDueDate1||'',
        };
        let result = await api.post(config.addPayrollInvestmentSettings, data);
        if (result && result.code === 200) {
          toast.success(result?.message);
        }
      };

    const styles = {
      
        section: {
            marginBottom: '20px',
        },
        h2: {
            fontSize: '24px',
            marginBottom: '10px',
        },
        h3: {
            fontSize: '20px',
            marginBottom: '10px',
        },
        h4: {
            fontSize: '16px',
            marginBottom: '10px',
        },
        label: {
            display: 'block',
            marginBottom: '10px',
        },
        checkboxLabel: {
            display: 'block',
            margin: '10px 0',
        },
        select: {
            marginLeft: "5px",
            marginRight: "5px",
            border: "1px solid",
            width: "89px",
            height: "32px",
            borderRadius: "3px",
            paddingLeft: "6px",
        },
        toggle: {
            position: 'relative',
            display: 'inline-block',
            width: '40px',
            height: '20px',
        },
        toggleInput: {
            opacity: 0,
            width: 0,
            height: 0,
        },
        slider: {
            position: 'absolute',
            cursor: 'pointer',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: declarationReminderEmails ? '#2196F3' : '#ccc',
            transition: '.4s',
            borderRadius: '34px',
        },
        sliderBefore: {
            position: 'absolute',
            content: '""',
            height: '16px',
            width: '16px',
            left: '4px',
            bottom: '2px',
            backgroundColor: 'white',
            transition: '.4s',
            borderRadius: '50%',
            transform: declarationReminderEmails ? 'translateX(20px)' : 'none',
        },
    };
  const handleChange =(event)=>{
    const {value ,name} =event.target
    setItDecFromData({...itDecFromData , [name]:value})
  }

  useEffect(()=>{
    getInvestmentSettings()
  },[])
const getInvestmentSettings =async()=>{
    let result = await api.get(config.getInvestmentSettings);
        if (result && result.code === 200) {
            let data =result?.data
            setInvestmentSattingData(data)
            setDefaultData(data)
         
        }
}
const  setDefaultData =(data)=>{
    if(data?.length >0){
        let cutOffDateMonth =data[0]?.cut_off_date?.split("-")
        let newJoineeCutOffDateMonth =data[0]?.new_joinee_cut_off_date?.split("-")
        let proofDueDateMonth=data[0]?.proof_due_date?.split("-")
        setItDecFromData({
            declareFormDate:data[0]?.declare_from,
            declareToDate:data[0]?.declare_to,
            cutoffMonth:cutOffDateMonth[0],
            cutoffDate:cutOffDateMonth[1],
            newJoineeCutOffdays:data[0]?.new_joinee_cut_off_days,
            newJoineeCutOffMonth:newJoineeCutOffDateMonth[0],
            newJoineeCutOffDate:newJoineeCutOffDateMonth[1],
            proofDueMonth:proofDueDateMonth[0],
            proofDueDate:proofDueDateMonth[1],
        })

        setDeclarationReminderEmails(data[0]?.enable_reminder===1?true:false)
        setProofRequired(data[0]?.required_proof==1?true:false)
        setRequireApproval(data[0]?.require_approval==1?true:false)
        setAllowSubmissionAfterCutoff(data[0]?.submit_after_declaration==1?true:false)

      }
}
    // Current year
    return (
        <>
            <CRow>
                <CCol xs="12">
                    <CCard>
                        <CCardHeader>
                            <strong> Income Tex Declaration Due Dates</strong>
                        </CCardHeader>
                        <CCardBody className="m-3">
                            <strong className=''> IT Declaration Due Dates</strong>
                            <CCard className="mt-2">
                                <CCardHeader >
                                    <strong > For All Employees</strong>
                                </CCardHeader>
                                <CCardBody className="m-3" style={{ padding: "3px" }}>
                                    <div>
                                        <label style={styles.label}>
                                            Employees can declare from
                                            <select 
                                            style={styles.select} defaultValue=""
                                            name='declareFormDate'
                                            value={itDecFromData?.declareFormDate}
                                            onChange={handleChange}
                                            >
                                                {days.map(day => (
                                                    <option key={day} value={day}>{day}</option>
                                                ))}
                                            </select> to
                                            <select 
                                            style={styles.select} defaultValue=""
                                            name='declareToDate'
                                            value={itDecFromData?.declareToDate}
                                            onChange={handleChange}
                                            >
                                                {days.map(day => (
                                                    <option key={day} value={day}>{day}</option>
                                                ))}
                                            </select> of every month but before the cutoff date in the financial year.
                                        </label>
                                        {/* <br /> */}
                                        <label style={styles.label}>
                                            Cutoff date for declaration changes in financial year is
                                            <select 
                                                style={styles.select} 
                                                defaultValue="February"  
                                                value={itDecFromData?.cutoffMonth ||''} 
                                                onChange={handleChange}
                                                name='cutoffMonth'
                                            >
                                                {months.map(month => (
                                                    <option key={month} value={month}>{month}</option>
                                                ))}
                                            </select>
                                           
                                            <select 
                                                style={styles.select}
                                                defaultValue="22"
                                                value={itDecFromData?.cutoffDate || ''}
                                                onChange={handleChange}
                                                name='cutoffDate'
                                            >
                                                {days.map(day => (
                                                    <option key={day} value={day}>{day}</option>
                                                ))}
                                            </select>
                                        </label>

                                    </div>

                                </CCardBody>
                            </CCard>
                            <CCard className="mt-2">
                                <CCardHeader >
                                    <strong > For employees joining after the Financial Year cut-off date</strong>
                                </CCardHeader>
                                <CCardBody className="m-3" style={{ padding: "3px" }}>
                                    <div>
                                        <label style={styles.label}>
                                            Employees can declare up to
                                            <select style={styles.select} defaultValue="30"
                                            value={itDecFromData?.newJoineeCutOffdays||''} 
                                            onChange={handleChange}
                                            name='newJoineeCutOffdays'
                                            >
                                                {days.map(day => (
                                                    <option key={day} value={day}>{day}</option>
                                                ))}
                                            </select> days within date of joining, or by
                                            <select style={styles.select} defaultValue="February"
                                             value={itDecFromData?.newJoineeCutOffMonth||''} 
                                             onChange={handleChange}
                                             name='newJoineeCutOffMonth'
                                            >
                                                {months.map(month => (
                                                    <option key={month} value={month}>{month}</option>
                                                ))}
                                            </select>
                                            <select style={styles.select} defaultValue="15"
                                            value={itDecFromData?.newJoineeCutOffDate||''} 
                                            onChange={handleChange}
                                            name='newJoineeCutOffDate'
                                            >
                                                {days.map(day => (
                                                    <option key={day} value={day}>{day}</option>
                                                ))}
                                            </select>, whichever is earlier.
                                        </label>


                                    </div>

                                </CCardBody>
                            </CCard>
                            <div>
                                <label style={styles.checkboxLabel}>
                                    <input
                                        type="checkbox"
                                        checked={requireApproval}
                                        onChange={() => setRequireApproval(!requireApproval)}
                                        className='me-2'
                                    />
                                    Require Approval of any changes in IT declaration submitted before Jan 22
                                </label>
                                <label style={styles.checkboxLabel}>
                                    <input
                                        type="checkbox"
                                        checked={allowSubmissionAfterCutoff}
                                        onChange={() => setAllowSubmissionAfterCutoff(!allowSubmissionAfterCutoff)}
                                        className='me-2'
                                    />
                                    Allow employee to submit IT Declaration after Jan 22
                                </label>

                                <label style={styles.checkboxLabel} className='ms-4'>
                                    <label style={styles.toggle} className='me-2'>
                                        <input
                                            type="checkbox"
                                            style={styles.toggleInput}
                                            checked={declarationReminderEmails}
                                            onChange={() => setDeclarationReminderEmails(!declarationReminderEmails)}
                                            className='me-2'
                                            />
                                        <span style={styles.slider}>
                                            <span style={styles.sliderBefore}></span>
                                        </span>
                                    </label>
                                            
                                            Enable Declaration Reminder Emails
                                            <br/>
                                            <span style={{
                                                    fontSize: "15px",
                                                    marginLeft: "46px",
                                                    padding: "0px",
                                                    color: "gray",
                                            }}> A reminder email awaiting approval will be sent to the administrator and an approval/rejection email will we sent to the employee</span>
                                </label>

                            </div>
                            <section style={styles.section}>
                                <h3 style={styles.h3}>IT Declaration proof submission</h3>
                                <label style={styles.checkboxLabel}>
                                    <input
                                        type="checkbox"
                                        checked={proofRequired}
                                        onChange={() => setProofRequired(!proofRequired)}
                                        className='me-2'
                                    />
                                    Employees are required to submit proof of IT declaration
                                </label>
                                <label style={styles.label}>
                                    IT declaration Proof submission by employees are due on
                                    <select style={styles.select} defaultValue=""
                                     name='proofDueMonth'
                                     value={itDecFromData?.proofDueMonth||''} 
                                     onChange={handleChange}
                                    >
                                        {months.map(month => (
                                            <option key={month} value={month}>{month}</option>
                                        ))}
                                    </select>
                                    <select style={styles.select} defaultValue=""
                                     name='proofDueDate'
                                     value={itDecFromData?.proofDueDate||''} 
                                     onChange={handleChange}
                                    >
                                        {days.map(day => (
                                            <option key={day} value={day}>{day}</option>
                                        ))}
                                    </select> of the Financial Year.
                                </label>
                            </section>
                        <CButton
                    className="btn btn-md btn-info float-left"
                    onClick={()=>addInvestmentData()}
                  >
                    <i className="fa fa-check" aria-hidden="true"></i>&nbsp;
                    Submit
                  </CButton>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>

        </>
    )
}

export default InvestmentSetting
