import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import './idCard.css'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
import { data } from 'jquery'

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
// import API from '../../utils/apiCalling';
// import { config } from '../../utils/apiUrl';

const ModalDocuments = (props) => {
    const { isOpen_Doc, isViewModalOpen_Doc, selectedViewDocument } = props
    // console.log(props,"jhbdfjhs")

    const [newConvertValue, setNewConvertValue] = useState([])
    const [isShowEducationDetails, setIsShowEducationDetails] = useState(false)

    useEffect(() => {
        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>....document", selectedViewDocument)
        setNewConvertValue([])
        filterData()
    }, [selectedViewDocument])
    const filterData = () => {
        if (selectedViewDocument && !selectedViewDocument.data) {
            let newVal = Object.entries(selectedViewDocument)
            // console.log("****************", newVal)
            setNewConvertValue(newVal)
        }
    }

    return (
        <div className="">
            <CModal
                show={isOpen_Doc}
                onClose={() => isViewModalOpen_Doc()}
                size="lg"
            >
                <CModalHeader closeButton>
                    <CModalTitle>{selectedViewDocument && selectedViewDocument.tag_name}</CModalTitle>
                </CModalHeader>
                <CModalBody>

                    <CForm action="" encType="multipart/form-data" className="form-horizontal">
                        <CRow>
                            <CCol md="12">

                                {newConvertValue && newConvertValue.length > 0 && newConvertValue.map((details, index) => (
                                    <div className="card-header-form" style={{fontSize:"15px", fontWeight:"bolder"}}>
                                        <CFormGroup row>
                                        <div className="col-md-6">
                                            <CCol sm="12" md="12">
                                                
                                                <CLabel ><span className="text-capitalize">{details[0].replace(/[^a-zA-Z ]/g, " ")}</span></CLabel>
                                            </CCol>
         </div>
                                            <CCol sm="6" md="6">
                                                <CInput disabled value={details[1]} name="id" placeholder="" />
                                            </CCol>
                                        </CFormGroup>
                                        
                                    </div>
                                ))}

                                <>
                                    {selectedViewDocument && selectedViewDocument.tag_name == "Education Details" && selectedViewDocument.data.map((details, index) => (
                                        <div>
                                            <div className="card">
                                                <div className="card-header">
                                                    Details {index + 1}

                                                </div>
                                                <div className="card-header">

                                                    <CFormGroup row>
                                                        <CCol xs="12" md="3">
                                                            <CLabel ><span className="text-capitalize">Emp Educational Class</span></CLabel>
                                                        </CCol>
                                                        <CCol xs="12" md="9">
                                                            <CInput disabled value={details.emp_educational_class} name="id" placeholder="" />
                                                        </CCol>
                                                    </CFormGroup>
                                                    <CFormGroup row>
                                                        <CCol xs="12" md="3">
                                                            <CLabel ><span className="text-capitalize">Emp Roll No</span></CLabel>
                                                        </CCol>
                                                        <CCol xs="12" md="9">
                                                            <CInput disabled value={details.emp_roll_no} name="id" placeholder="" />
                                                        </CCol>
                                                    </CFormGroup>
                                                    <CFormGroup row>
                                                        <CCol xs="12" md="3">
                                                            <CLabel ><span className="text-capitalize">Smp School</span></CLabel>
                                                        </CCol>
                                                        <CCol xs="12" md="9">
                                                            <CInput disabled value={details.emp_school} name="id" placeholder="" />
                                                        </CCol>
                                                    </CFormGroup>
                                                    <CFormGroup row>
                                                        <CCol xs="12" md="3">
                                                            <CLabel ><span className="text-capitalize">Emp Board</span></CLabel>
                                                        </CCol>
                                                        <CCol xs="12" md="9">
                                                            <CInput disabled value={details.emp_board} name="id" placeholder="" />
                                                        </CCol>
                                                    </CFormGroup>
                                                    <CFormGroup row>
                                                        <CCol xs="12" md="3">
                                                            <CLabel ><span className="text-capitalize">Emp Percentage</span></CLabel>
                                                        </CCol>
                                                        <CCol xs="12" md="9">
                                                            <CInput disabled value={details.emp_percentage} name="id" placeholder="" />
                                                        </CCol>
                                                    </CFormGroup>
                                                    <CFormGroup row>
                                                        <CCol xs="12" md="3">
                                                            <CLabel ><span className="text-capitalize">Year</span></CLabel>
                                                        </CCol>
                                                        <CCol xs="12" md="9">
                                                            <CInput disabled value={details.year} name="id" placeholder="" />
                                                        </CCol>
                                                    </CFormGroup>
                                                    <CFormGroup row>
                                                        <CCol xs="12" md="3">
                                                            <CLabel ><span className="text-capitalize">Emp Educational Class</span></CLabel>
                                                        </CCol>
                                                        <CCol xs="12" md="9">
                                                            <CInput disabled value={details.emp_educational_class} name="id" placeholder="" />
                                                        </CCol>
                                                    </CFormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>

                                <>
                                    {selectedViewDocument && selectedViewDocument.tag_name == "Document Details" && selectedViewDocument.data.map((details, index) => (
                                        <div>
                                            <div className="card">
                                                <div className="card-header bg-info">
                                                    <b style={{color:"white"}}>{details.document_name}&nbsp;</b>
                                                    {details.status == 1 &&
                                                        <i style={{color:"darkgreen"}} className="fa fa-check" aria-hidden="true"></i>
                                                    }
                                                </div>
                                                {/* class={details.status == 0 ? {color:"red"} : {color:""}} */}
                                                <div className="card-body" >
                                                    {/* <CFormGroup row>
                                                        <CCol xs="12" md="3">
                                                            <CLabel ><span className="text-capitalize">Document Name</span></CLabel>
                                                        </CCol>
                                                        <CCol xs="12" md="9">
                                                            <CInput disabled value={details.document_name} name="id" placeholder="" />
                                                        </CCol>
                                                    </CFormGroup> */}
                                                    <CFormGroup row>
                                                        <CCol xs="12" md="3">
                                                            <CLabel  ><span className="text-capitalize">Status</span></CLabel>
                                                        </CCol>
                                                        <CCol xs="12" md="9">
                                                            <CInput style={details.status == 0 ? { backgroundColor: "#ffff0052" } : { backgroundColor: "#00800052" }} disabled value={details.status == 0 ? "Pending" : "Complete"} name="id" placeholder="" />
                                                        </CCol>
                                                    </CFormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>

                                <>
                                    {selectedViewDocument && selectedViewDocument.tag_name == "ID Card Details" && selectedViewDocument.data.map((details, index) => (
                        <div className="container-fluid">
                        <div className="row" >
                        <div className="col-sm-3" style={{flex:"30%",maxWidth:"100%"}} />
                        <div className="col-sm-3" style={{flex:"30%",maxWidth:"100%"}}>
                        <div className="man_idcard text-center" style={{backgroundImage:'url(images/cardfront.png)',backgroundSize:"246px"}}>
                        <form action="">
                        <div className="bg-images">
                            <div className="logo_img">
                                <img src="images/Asset 1@4x.png" alt="" width="42px" />
                            </div>
                            <div className="ken">
                                <div className="Profile_img"> </div>
                                <img style={{ width: "110px", height: "110px", borderRadius: "50%",border:"1px black solid"}} src={details.employee_passport_size_photo ? details.employee_passport_size_photo : "images/logo.png"}></img>
                            </div>
                            <div className="">
                                <h6 className="Name_emp fw-semibold">{details?.employee_name}</h6>
                                <div className="normal_btm_text">
                                    <h6 className="Employee fw-semibold">Employee ID &nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;{details?.emp_id}</h6>
                                    <h6 className="Blood_group fw-semibold">Blood Group &nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;{details?.blood_group}</h6>
                                    <h6 className="Employee fw-semibold">Emergency No. : &nbsp;{details?.emergency}</h6>
                                    <h6 className="Employee fw-semibold">Contact No. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;{details?.mobile}</h6>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-sm-3" style={{flex:"30%",maxWidth:"100%"}} />
            </div>
        </div>
                                    ))}
                                </>


                            </CCol>
                        </CRow>
                    </CForm>
                </CModalBody>
                <CModalFooter>
                    <CButton color="danger" onClick={() => isViewModalOpen_Doc(!isOpen_Doc)}><i className="fa fa-times" aria-hidden="true"></i>   </CButton>{' '}

                </CModalFooter>
            </CModal>

        </div>

    )
}
export default ModalDocuments