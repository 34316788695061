import { fork } from 'redux-saga/effects';
import { rootCommonSaga } from './common';
import { rootMessageSaga } from './messages';
import { rootAttendanceSaga } from './attendance';
import { rootMqttSaga } from './mqtt';
import { rootLeaveDataSaga } from './leaveDateList';
// import { rootShiftDetailSaga } from './shiftDeatilOfUser';
import rootShiftDetailSaga from './shiftDeatilOfUser/shiftDetail.Saga';
import rootDashboardDataSaga from './dashboard/dashboard.Saga';
import { rootSettingSaga } from './Setting';
import { rootOtpSaga } from './otps';
function* rootSaga() {
  yield fork(rootCommonSaga);
  yield fork(rootMessageSaga);
  yield fork(rootMqttSaga);
  yield fork(rootAttendanceSaga);
  yield fork(rootSettingSaga);
  yield fork(rootLeaveDataSaga);
  yield fork(rootShiftDetailSaga);
  yield fork(rootDashboardDataSaga);
  // yield fork(rootSettingSaga);
  yield fork(rootOtpSaga);
}

export default rootSaga;
