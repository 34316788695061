// import { all, call, put, select, take } from 'redux-saga/effects';
import {
  take,
  put,
  call,
  all,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { commonActions as actions, commonService } from './index';

function* handleGetSampleRequest() {}
function* handleMessageNotification() {
  let res;
  try {
    res = yield call(commonService.getMessageNotificationApiFun);

    if (res.isError) {
    } else {
      yield put(actions.handleMessageNotificationSuccess(res));
    }
  } catch (error) {
    //   yield put(actions.getAttendanceBasicDetailsError());
  }
}

function* handleNotification() {
  const action = yield take(
    actions.handleNotificationRequest.getType(),
  );
  let res;
  const dataParams = action.payload;
  try {
    res = yield call(commonService.getNotificationApi,dataParams);

    if (res.isError) {
    } else {
      yield put(actions.handleNotificationSuccess(res));
    }
  } catch (error) {
    //   yield put(actions.getAttendanceBasicDetailsError());
  }
}
function* handleMessageNotificationWatcher() {
  yield takeEvery(
    actions.handleMessageNotificationRequest,
    handleMessageNotification,
  );
}
function* handleNotificationWatcher() {
  yield takeEvery(
    actions.handleNotificationRequest,
    handleNotification,
  );
}
function* rootCommonSaga() {
  yield all([
    handleGetSampleRequest(),
    handleMessageNotification(),
    handleMessageNotificationWatcher(),
    handleNotificationWatcher(),
    handleNotification()
  ]);
}

export default rootCommonSaga;
