import {
  take,
  put,
  call,
  all,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { settingActions as actions, settingService } from './index';

function* handleSettingsByAdminDetails() {
  const result = yield take(actions.getSettingsByAdminRequest.getType());

  let dataparams = result.payload;
  let res;
  try {
    res = yield call(settingService.getSettingsByAdminDetails, dataparams);

    if (res.isError) {
      yield put(actions.getSettingsByAdminError(res));
    } else {
      yield put(actions.getSettingsByAdminSuccess(res));
    }
  } catch (error) {
    yield put(actions.getSettingsByAdminError(res));
  }
}
function* handlegetsettingsbyadminsWatcher() {
  yield takeEvery(
    actions.getSettingsByAdminRequest,
    handleSettingsByAdminDetails,
  );
}

/* update setting*/
function* handleUpdateSettingData() {
  const result = yield take(actions.updateSettingDataRequest.getType());
  let dataparams = result.payload;

  let res;
  try {
    res = yield call(settingService.updateDataOfSetting, dataparams);
    if (res.isError) {
      yield put(actions.updateSettingDataError(res));
    } else {
      yield put(actions.updateSettingDataSuccess(res));
    }
  } catch (error) {
    yield put(actions.updateSettingDataError(res));
  }
}

function* handleUpdateSettingDataWatcher() {
  yield takeEvery(actions.updateSettingDataRequest, handleUpdateSettingData);
}

function* rootSettingSaga() {
  yield all([
    handleSettingsByAdminDetails(),
    handlegetsettingsbyadminsWatcher(),

    handleUpdateSettingData(),
    handleUpdateSettingDataWatcher(),
  ]);
}

export default rootSettingSaga;
