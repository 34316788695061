import Select from 'react-select';
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import { toast, ToastContainer } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import swal from "sweetalert";
import { isPermission } from 'src/utils/common';
import { City } from 'country-state-city';
// import InputRange from 'react-input-range';
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile,
    CCollapse,
} from '@coreui/react'

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import { CardBody } from 'reactstrap';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import Alert from 'src/alert/alert';

import Moment from "react-moment";
import StepperMrfHrManager from '../common/stepper/stepper';
import moment from 'moment';

const ViewSelectedReceivedRequisition = (props) => {
    const { fun, receivedRequisition, mrfReceivedforHrManager, isViewModalOpen } = props
    console.log("gj", receivedRequisition)
    let api = new API()
    // const [large, setLarge] = useState(false)
    const [hr, setHr] = useState()
    const [assignHrName, setAssignHrName] = useState()
    const [isAlert, setIsAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [accordion, setAccordion] = useState(0)
    const [MRF_JD, setMRF_JD] = useState()
    const [minAmount, setMinAmount] = useState(0)
    const [maxAmount, setMaxAmount] = useState(0)
    const [location, setLocation] = useState('');
    const [designation, setDesignation] = useState('');
    const [skills, setSkills] = useState('');
    const [remark, setRemark] = useState('');
    const [allCity, setAllCity] = useState();
    const [allDesignation, setAllDesignation] = useState();


    useEffect(() => {
        getMrfAssignHr()
        resetAccordain()
        // console.log("mrf in hr-manager panel", receivedRequisition)
    }, [fun])
    const getAllCity = () => {
        let allCity = City.getCitiesOfCountry('IN');
        let temp = [];
        allCity.map(data => {
            temp.push({ label: data?.name, value: data?.name });
        });
        setAllCity(temp);
    };
    useEffect(() => {
        getJdByMrfId()
        getAllCity()
        getDesignationByDepartment()
    }, [receivedRequisition]);

    const getMrfAssignHr = async () => {
        let result = await api.get(config.mrfAssignToHr)
        if (result && result.code == 200) {

            // console.log("result", result)
            // console.log("mrf in hr-manager panel", receivedRequisition)
            setHr(result.data)
        } else {
            // console.log("not getting")
        }
    }
    const getJdByMrfId = async () => {
        if (receivedRequisition && receivedRequisition !== undefined) {
            setAccordion(accordion === 1 ? null : 1)
            let data = {
                mrf_id: receivedRequisition && receivedRequisition.id,
                applying_for: receivedRequisition.id && receivedRequisition.applying_for
            }
            let result = await api.get(config.getJdForMrf, true, data)
            if (result && result.code == 200) {
                // console.log("mrf jd by id ", result.data)
                setMRF_JD(result.data[0])
            } else {
                alert("something wrong....")
            }
        }
    }
    const resetAccordain = () => {
        setAccordion(accordion == 0)
    }

    const assignInterviewToRecruiter = async (id, applying_for) => {
        let data = {
            mrf_id: id,
            applying_for: applying_for,
            assignHrName: assignHrName,
            minAmount: minAmount,
            maxAmount: maxAmount,
        }
        swal({
            html: true,
            title: "Are you sure?",
            text: `Are you sure to assign MRF to ${assignHrName}`,
            icon: "success",
            buttons: ["No, cancel it!", "Assign"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.assignMrfToRecruiter, data)
                if (result && result.code == 200) {
                    setAlertMessage(result && result.message)
                    AlertTrue()
                    mrfReceivedforHrManager()
                    isViewModalOpen()
                } else {
                    setAlertMessage(result && result.message)
                    AlertTrue()
                }

            }
        });
    }

    const updateMRF = async (id, applying_for) => {
        let data = {
            mrf_id: id,
            applying_for: applying_for,
            location: location?.value || MRF_JD && MRF_JD.location,
            skill_needed: skills || MRF_JD && MRF_JD.skill_needed,
            designation: designation?.value || receivedRequisition && receivedRequisition?.designation,
            remark: remark || '',
        }
        swal({
            html: true,
            title: "Are you sure?",
            text: `Are you sure to update MRF ${id}`,
            icon: "success",
            buttons: ["No, cancel it!", "Update"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.updateMRF, data)
                if (result && result.code == 200) {
                    toast.success(result && result.message)
                    mrfReceivedforHrManager()
                } else {
                    toast.error(result && result.message)
                }
            }
        });
    }
    const isAlertToggle = () => {
        setIsAlert(false)
    }
    const AlertTrue = () => {
        setIsAlert(true)
    }

    const getDesignationByDepartment = async () => {
        let data = {
            department_name: receivedRequisition && receivedRequisition?.requisition_for_department
        }
        let result = await api.get(config.getDepartmentWiseRoles, true, data)
        if (result && result.code == 200) {
            let temp = [];
            result?.data?.map(data => {
                temp.push({ label: data?.role_name, value: data?.role_name });
            });
            setAllDesignation(temp);
        }
    }


    return (
        <div className="">
            <StepperMrfHrManager receivedRequisition={receivedRequisition} />
            <CForm action="" encType="multipart/form-data" className="form-horizontal">
                <CRow>
                    <CCol md="6">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >MRF ID</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.id} name="firstname" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Applying For</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.applying_for == "vacancy" ? "New Hiring" : receivedRequisition && receivedRequisition.applying_for} name="lastname" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        {receivedRequisition && receivedRequisition.applying_for == "Replacement" &&
                            <CFormGroup row>
                                <CCol md="3">
                                    <CLabel >Replacement ID</CLabel>
                                </CCol>
                                <CCol xs="12" md="9">
                                    <CInput value={receivedRequisition && receivedRequisition.employee_id} name="country" placeholder="" disabled />
                                </CCol>
                            </CFormGroup>}
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Head of Department</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.head_of_department} name="role" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Requisition for department</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.requisition_for_department} name="department" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Requisition for other department</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.requisition_for_other_department} name="department" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Designation</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.designation} name="department" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Priority</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.priority} name="department" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Created At</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <label>
                                    {receivedRequisition && receivedRequisition.created_at}</label>
                            </CCol>

                        </CFormGroup>

                    </CCol>
                    <CCol md="6">

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Assigned To HR Manager</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.assigned_to_hr_manager} name="assigned_to_hr_manager" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Created By ID</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.created_by_id} name="created_by_id" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Created By Name</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.created_by_name} name="created_by_name" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Finance Status</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.finance_status == 0 ? "Incomplete" : "Complete"} name="finance_status" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >MRF Active Status</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.mrf_active_status} name="mrf_active_status" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >MRF Final Status</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.mrf_final_status == 0 ? "Incomplete" : "Complete"} name="mrf_final_status" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Updated By Name</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.updated_by_name} name="updated_by_name" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Updated By ID</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={receivedRequisition && receivedRequisition.updated_by_id} name="updated_by_id" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Updated At</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <label>
                                    {
                                        <Moment format="YYYY/MM/DD">
                                            {receivedRequisition && receivedRequisition.updated_at}
                                        </Moment>} </label>
                                {/* <CInput value={receivedRequisition && receivedRequisition.updated_at} name="updated_at" placeholder="" disabled /> */}
                            </CCol>
                        </CFormGroup>
                    </CCol>
                </CRow>


                <div className="container">
                    <CCard>
                        <CCardHeader onClick={getJdByMrfId}>
                            <strong style={{ color: "#f57729" }}>JOB DESCRIPTION</strong>
                            {/* <CButton color="info" c lassName="text-center btn btn-sm mr-3 mt-2" onClick={getJdByMrfId} >Job Description</CButton>{' '} */}
                        </CCardHeader>

                        <CardBody>
                            <div className="mb-3">
                                {/* JOB DESCRIPTION */}
                                <CCollapse show={accordion === 1}>
                                    <div className="jd">
                                        <strong>Location</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.location}<hr></hr>
                                        <strong>Role Summary</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.role_summary}<hr></hr>
                                        <strong>Responsibilities</strong> &nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.responsibilities}<hr></hr>
                                        <strong>Expirence</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.expirence}<hr></hr>
                                        <strong>Min Education Qualification</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp; {MRF_JD && MRF_JD.min_edu_qualification}<hr></hr>
                                        <strong>Preffered Education Qualification</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.preffered_edu_qualification}<hr></hr>
                                        <strong>Certificates</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp; {MRF_JD && MRF_JD.certificates}<hr></hr>
                                        <strong>Skill Needed</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp; {MRF_JD && MRF_JD.skill_needed}<hr></hr>
                                        <strong>Preffered Domain Knowledge</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp; {MRF_JD && MRF_JD.preffered_domain_knowledge}<hr></hr>
                                    </div>
                                </CCollapse>
                            </div>
                        </CardBody>

                    </CCard>
                </div>
                {isPermission('assign_requisition_to_hr') && <>
                    {receivedRequisition && receivedRequisition.hr_rejection_status == 0 &&
                        <>
                            <div className="container">
                                <CCard className={'ats-search-card-sect'}>
                                    <CCardHeader>
                                        <strong>Update MRF Details</strong>
                                    </CCardHeader>
                                    <CCardBody>
                                        <CFormGroup row>
                                            <CCol md="3">
                                                <CLabel htmlFor="jobLocation">Location</CLabel>
                                            </CCol>
                                            <CCol xs="12" md="9">
                                                <Select
                                                    value={location || { label: MRF_JD && MRF_JD.location, value: MRF_JD && MRF_JD.location }}
                                                    onChange={e => setLocation(e)}
                                                    options={allCity}
                                                />
                                            </CCol>
                                        </CFormGroup>

                                        <CFormGroup row>
                                            <CCol md="3">
                                                <CLabel htmlFor="jobLocation">Designation</CLabel>
                                            </CCol>
                                            <CCol xs="12" md="9">
                                                <Select
                                                    value={designation || { label: receivedRequisition && receivedRequisition.designation, value: receivedRequisition && receivedRequisition.designation }}
                                                    onChange={e => setDesignation(e)}
                                                    options={allDesignation}
                                                />
                                            </CCol>
                                        </CFormGroup>

                                        <CFormGroup row>
                                            <CCol md="3">
                                                <CLabel htmlFor="jobLocation">Skill Needed</CLabel>
                                            </CCol>

                                            <CCol md="9">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input type="text" name="skill_needed" className="form-control" onChange={(e) => setSkills(e.target.value)} value={skills || MRF_JD && MRF_JD.skill_needed} />
                                                    </div>
                                                </div>
                                            </CCol>

                                        </CFormGroup>
                                        <CFormGroup row>
                                            <CCol md="3">
                                                <CLabel htmlFor="jobLocation">Remarks <span style={{ color: "red" }}>*</span></CLabel>
                                            </CCol>
                                            <CCol md="9">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input type="text" className="form-control" name="remark" disabled={skills === "" && location === "" && designation === ""} onChange={(e) => setRemark(e.target.value)} placeholder='Please enter a remark after updation' />
                                                    </div>
                                                </div>

                                            </CCol>
                                        </CFormGroup>
                                        <div className="float-right">
                                            <CButton type="button" disabled={remark === "" || remark === undefined} onClick={() => updateMRF(receivedRequisition.id, receivedRequisition.applying_for)} className="btn btn-success btn-rigth mt-4">Update</CButton>
                                        </div>
                                    </CCardBody>
                                </CCard>
                            </div>
                            <div className="container">
                                <CCard>
                                    <CCardHeader>
                                        <strong>Assign to HR Recruiter</strong>
                                    </CCardHeader>
                                    <CCardBody>
                                        <CFormGroup row>
                                            <CCol md="3">
                                                <CLabel htmlFor="jobLocation">Assign MRF</CLabel>
                                            </CCol>
                                            <CCol xs="12" md="9">
                                                <CSelect custom value={assignHrName} onChange={(e) => setAssignHrName(e.target.value)} name="jobLocation" id="jobLocation">
                                                    <option value="">Please select</option>
                                                    {hr && hr.map((assigneeHr) => (
                                                        <option value={assigneeHr.email}>{assigneeHr.username}</option>
                                                    ))
                                                    }
                                                </CSelect>
                                            </CCol>
                                        </CFormGroup>

                                        <div className="float-right">
                                            <CButton type="button" disabled={assignHrName == "" || assignHrName == undefined} onClick={() => assignInterviewToRecruiter(receivedRequisition.id, receivedRequisition.applying_for)} className="btn btn-success btn-rigth mt-4">Assign</CButton>
                                        </div>
                                    </CCardBody>
                                </CCard>



                                {/* <CButton type="button" onClick={ctcFullDetails} className="btn btn-primary mr-3 mt-2">Submit</CButton> */}


                            </div>
                        </>
                    }
                </>}

            </CForm>
            <Alert isAlert={isAlert} isAlertToggle={isAlertToggle} alertMessage={alertMessage} />
            <ToastContainer />
        </div>

    )
}
export default ViewSelectedReceivedRequisition







