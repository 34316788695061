import React from 'react';
import { useEffect, useState } from 'react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { debounce } from 'lodash';
import { CustButton, CustLoader } from 'src/reusable';
import { getInputPrefix } from 'src/utils/common';
import { toast, ToastContainer } from 'react-toastify';
import { useForceUpdate } from 'src/hooks';
import { color } from 'highcharts';
const tmpObj = {
  earning: {},
  deduction: {},
};
const CtcGenerate = props => {
  const forceUpdate = useForceUpdate();
  const api = new API();
  const {
    employeeDetail,
    handleOnChange,
    handleOnPreviewOfferLetter,
    isGettingOfferLetter,
    offerLetterMethod,
    ctcRanges,
    type,
    saveSalaryData,
  } = props;
  const [employeeSalaryDetail, setEmployeeSalaryDetails] = useState({
    bonus: {},
    earnings: [],
    deductions: [],
    ctc: {},
    gross: {},
    total_employee: {},
    total_employer: {},
    total_ctc: {},
    take_home: {},
  });
  const [salaryComponents, setSalaryComponents] = useState();
  const [earningArr, setEarningArr] = useState([]);
  const [deductionArr, setDeductionArr] = useState();
  const [selectedEarningArr, setSelectedEarningaArr] = useState([]);
  const [selectedDeductionArr, setSelsctedDeductionArr] = useState([]);
  const [ctc, setCtc] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [callCount, setCallCount] = useState(0);
  const [includeBonus, setIncludeBonus] = useState(false);
  const [includeMealAllowance, setIncludeMealAllowance] = useState(false);
  const [includePF, setIncludePF] = useState(false);
  const [includeEsi, setIncludeEsi] = useState(false);
  const [departmentBudget, setDepartmentBudget] = useState(0);
  const [utilizedDepartmentBudget, setUtilizedDepartmentBudget] = useState(0);
  const getSalaryComponents = async () => {
    let result = await api.get(config.salaryComponents);
    return result && result.code === 200 && result.data;
  };

  useEffect(() => {
    const fetchSalaryComponents = async () => {
      const templateData = await getSalaryComponents();
      setEarningArr(templateData?.earnings || []);
      setDeductionArr(templateData?.deductions || []);
    };
    fetchSalaryComponents();
  }, []);

  useEffect(() => {
    debouncedReCalculate(null);
  }, [callCount]);

  useEffect(() => {
    // create array for selected salary components name
    if (employeeSalaryDetail?.earnings?.length) {
      const eArr = [];
      employeeSalaryDetail?.earnings?.forEach(item => {
        eArr.push(item.name);
      });
      setSelectedEarningaArr(eArr);
    }
    if (employeeSalaryDetail?.deductions?.length) {
      const dArr = [];
      employeeSalaryDetail?.deductions?.forEach(item => {
        dArr.push(item.name);
      });
      setSelsctedDeductionArr(dArr);
    }
  }, [employeeSalaryDetail]);

  useEffect(() => {
    setDepartmentBudget(ctcRanges.department_tolerance_budget);
    setUtilizedDepartmentBudget(ctcRanges.department_employee_salary);
  }, [ctcRanges]);

  const sendMailForApproval = async () => {
    let type = 'hod';
    let amount = ctcRanges.department_employee_salary + ctc;
    if(amount > ctcRanges.department_tolerance_budget){
      type = 'finance';
    }else if(ctcRanges.employee_exceeded == '1'){
      type = 'hod';
    }else if(ctc > ctcRanges.budgeted_salary && ctc < ctcRanges.tolerance_budget){
      type = 'hr';
    }
    let data = {
      ctcRanges: JSON.stringify(ctcRanges),
      ctc: ctc,
      candidate_id: employeeDetail?.id,
      type: type,
      // ctcRanges.employee_exceeded == '1' ? 'hod' : ctc > ctcRanges.budgeted_salary && ctc < ctcRanges.tolerance_budget ? 'hr' : 'hod',
    }
    let result = await api.post(config.sendMailForApproval, data);
    console.log("--employee details--", result);
    if(result && result.code == 200){
      toast.success(result?.message)
    }else{
      toast.error(result?.message);
    }
  }

  const getEmployeeSalaryDetails = async (params) => {
    // if(employeeDetail?.approved_amount < ctc){
    //   toast.error(`Approved Amount for this role is '${employeeDetail?.approved_amount}'`);
    //   return
    // }
    setIsLoader(true);
    forceUpdate();
    console.log("params", params);
    let isIncludeBonus = params?.includeBonus || includeBonus;
    let isIncludeMealAllowance = params?.includeMealAllowance || includeMealAllowance;
    let isIncludePf = params?.is_pf || includePF;
    let isIncludeEsi = params?.is_esi || includeEsi;
    let data = {
      is_include_bonus: isIncludeBonus ? 1 : 0,
      meal_allowance: isIncludeMealAllowance ? 1 : 0,
      is_pf: isIncludePf ? 1 : 0,
      is_esi: isIncludeEsi ? 1 : 0,
      ctc:ctc,
    };
    let result = await api.post(config.generateOfferLetter1, data);
    if (result && result.code === 200) {
      setEmployeeSalaryDetails(result.data);
    } else if(result && result.code === 201){
      setIncludePF(true);
      setEmployeeSalaryDetails(result.data);
      forceUpdate();
      toast.warning(result?.message || '');
    } else {
      toast.warning(result?.message || '')
    }
    setIsLoader(false);
  };

  const handleViewOfferLetter = async () => {
    let data = {
      id: employeeDetail?.id || null,
      earnings: JSON.stringify(employeeSalaryDetail?.earnings),
      gross: JSON.stringify(employeeSalaryDetail?.gross),
      deductions: JSON.stringify(employeeSalaryDetail?.deductions),
      total_employee: JSON.stringify(employeeSalaryDetail?.total_employee),
      total_employer: JSON.stringify(employeeSalaryDetail?.total_employer),
      ctc: JSON.stringify(employeeSalaryDetail?.ctc),
      bonus: JSON.stringify(employeeSalaryDetail?.bonus),
      total_ctc: JSON.stringify(employeeSalaryDetail?.total_ctc),
      take_home: JSON.stringify(employeeSalaryDetail?.take_home),
    };
    handleOnPreviewOfferLetter(data);
  };

  const handleSaveSalaryData = async () => {
    let data = {
      emp_id: employeeDetail?.emp_id || null,
      monthly_ctc: ctc || 0,
      earnings: JSON.stringify(employeeSalaryDetail?.earnings),
      gross: JSON.stringify(employeeSalaryDetail?.gross),
      deductions: JSON.stringify(employeeSalaryDetail?.deductions),
      total_employee: JSON.stringify(employeeSalaryDetail?.total_employee),
      total_employer: JSON.stringify(employeeSalaryDetail?.total_employer),
      ctc: JSON.stringify(employeeSalaryDetail?.ctc),
      bonus: JSON.stringify(employeeSalaryDetail?.bonus),
      total_ctc: JSON.stringify(employeeSalaryDetail?.total_ctc),
      take_home: JSON.stringify(employeeSalaryDetail?.take_home),
    };
    saveSalaryData(data);
  };

  const handleOnComponenetAdd = (comp, name) => {
    let selectedComponent;
    let eDetails = employeeSalaryDetail?.earnings
      ? employeeSalaryDetail.earnings
      : [];
    let dDetails =
      employeeSalaryDetail && employeeSalaryDetail?.deductions
        ? employeeSalaryDetail.deductions
        : [];
    if (comp === 'earning') {
      let earningsArr = [...earningArr];
      for (let val of earningsArr) {
        if (val.name === name) {
          selectedComponent = val;
        }
      }
      eDetails.push(selectedComponent);
    } else {
      let deductionsArr = [...deductionArr];
      for (let val of deductionsArr) {
        if (val.name === name) {
          selectedComponent = val;
        }
      }
      dDetails.push(selectedComponent);
    }

    setEmployeeSalaryDetails({
      ...employeeSalaryDetail,
      earnings: eDetails,
      deductions: dDetails,
    });
    setCallCount(callCount + 1);
  };

  const handleOnComponentRemove = (e, item, index, type) => {
    const { earnings, deductions } = employeeSalaryDetail;
    if (type === 'earning') {
      earnings.splice(index, 1);
      tmpObj.earning[item.name] = '';
    } else if (type === 'deduction') {
      deductions.splice(index, 1);
      tmpObj.deduction[item.name] = '';
    }
    setEmployeeSalaryDetails({ ...employeeSalaryDetail, earnings, deductions });
    setCallCount(callCount + 1);
  };

 

  const handleOnInputChange = e => {
    handleOnChange(e);
    const { name, value } = e.target;
    switch (name) {
      case 'monthly_ctc':
        setCtc(value);
        tmpObj.earning = {};
        tmpObj.deduction = {};
        break;
      case 'is_include_bonus':
        setIncludeBonus(e.target.checked);
        forceUpdate();
        getEmployeeSalaryDetails({includeBonus:e.target.checked});
        break;
      case 'is_pf':
        setIncludePF(e.target.checked);
        forceUpdate();
        getEmployeeSalaryDetails({is_pf:e.target.checked});
        break;
      case 'is_esi':
        setIncludeEsi(e.target.checked);
        forceUpdate();
        getEmployeeSalaryDetails({is_esi:e.target.checked});
        break;
      case 'meal_allowance':
        setIncludeMealAllowance(e.target.checked);
        forceUpdate();
        getEmployeeSalaryDetails({includeMealAllowance:e.target.checked});
        break;
      default:
        console.log('');
        break;
    }
  };

  const handleOnCtcReCalculate = async dataA => {
    const { earnings, deductions } = employeeSalaryDetail;
    earnings.forEach(item => {
      if (tmpObj?.earning?.[item.name])
        item.value = tmpObj?.earning?.[item.name];
      return item;
    });
    deductions.forEach(item => {
      if (tmpObj?.deduction?.[item.name])
        item.value = tmpObj?.deduction?.[item.name];
      return item;
    });
    const updatedDetails = { ...employeeSalaryDetail, earnings, deductions };

    const data = {
      ctc: ctc,
      earnings: JSON.stringify(updatedDetails?.earnings),
      deductions: JSON.stringify(updatedDetails?.deductions),
      bonus: JSON.stringify(updatedDetails?.bonus),
      is_include_bonus: includeBonus?1:0,
      meal_allowance: includeMealAllowance?1:0,
      is_pf: includePF?1:0,
      is_esi: includeEsi?1:0
    };
    console.log('Searching for:', data);
    const result = await api.post(config.updateCalculatedCtc1, data);
    if (result && result.code === 200) {
      console.log('Successfully calculated', result);
      setEmployeeSalaryDetails(result.data);
      if(result?.message !== 'CTC Calculated Successfully'){
        toast.error(result?.message);
      }
    } else {
      console.log(result?.message || result);
    }
  };

  const debouncedReCalculate = debounce(handleOnCtcReCalculate, 100);

  const handleOnSalaryChange = async (e, item, index, type) => {
    const { name, value } = e.target;
    tmpObj[type][item.name] = value;
    setCallCount(callCount + 1);
  };

  const isComponenetDisabled = (item, type) => {
    if (type === 'earning') {
      return selectedEarningArr.indexOf(item?.name) !== -1;
    }
    if (type === 'deduction') {
      return selectedDeductionArr.indexOf(item?.name) !== -1;
    }
  };

  const renderSalaryComponentsList = listType => {
    let dataList = earningArr;
    if (listType === 'deduction') {
      dataList = deductionArr;
    }
    return (
      dataList &&
      dataList.map((item, index) => {
        let onBtnClick = handleOnComponenetAdd;
        const isDisabled = isComponenetDisabled(item, listType);
        return (
          <div
            key={index}
            className="accordion-body accordion-salary-component"
          >
            <button
              className="btn add_new_template_sidebar_button w-100 add-comp-btn"
              style={{ display: 'flex' }}
              onClick={() => onBtnClick(listType, item.name)}
              disabled={isDisabled}
            >
              {item.payslip_name}
              <span>
                <i className="fa fa-plus add_new_template_sidebar_button_icon"></i>
              </span>
            </button>
          </div>
        );
      })
    );
  };
  return (
    <div>
      <div className="row">
        <div className="col-sm-3">
          <div className="">
            <div className="ml-4 mt-3">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item abd">
                  <h5 className="accordion-header" id="headingOne">
                    <button
                      className="text-left accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <b>Earning</b>
                    </button>
                  </h5>

                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse collapsed "
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    {renderSalaryComponentsList('earning')}
                  </div>
                </div>
                <div className="accordion-item">
                  <h5 className="accordion-header" id="headingFour">
                    <button
                      className="text-left accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="true"
                      aria-controls="collapseFour"
                    >
                      <b>Deductions</b>
                    </button>
                  </h5>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    {renderSalaryComponentsList('deduction')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-9">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" style={{ color: 'darkblue' }}>
                  <b>MONTHLY CTC</b>
                </th>
                <th>
                  {' '}
                  <input
                    type="number"
                    name="monthly_ctc"
                    className="form-control col-lg-5"
                    value={ctc}
                    min={10000}
                    max={5000000}
                    onChange={handleOnInputChange}
                    onBlur={getEmployeeSalaryDetails}
                    placeholder="Enter Monthly CTC"
                  />
                </th>
                <th scope="col"><div>Bonus &nbsp;<input type="checkbox" name="is_include_bonus" onChange={handleOnInputChange} /> </div></th>
                <th scope="col"><div>Meal &nbsp;<input type="checkbox" name="meal_allowance" onChange={handleOnInputChange} /> </div></th>
                <th scope="col"><div>PF &nbsp;<input type="checkbox" name="is_pf" onChange={handleOnInputChange} /> </div></th>
                <th scope="col"><div>ESI &nbsp;<input type="checkbox" name="is_esi" onChange={handleOnInputChange} /> </div></th>
              </tr>
            </thead>
          </table>

          {isLoader && <CustLoader size="lg" isShow={isLoader} />}

          {ctc > 0 && !isLoader && (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Salary Components</th>
                  <th scope="col">Calculation Type</th>
                  <th scope="col">Monthly Amount</th>
                  <th scope="col">Annnual Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <h4>Earnings</h4>
                  </th>
                </tr>
                {employeeSalaryDetail?.earnings &&
                  employeeSalaryDetail?.earnings?.map((item, index) => (
                    <tr key={item.name}>
                      <td>{item.payslip_name}</td>
                      <td>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text input-prefix-icon"
                              id="basic-addon1"
                            >
                              {getInputPrefix(item.value_in)}
                            </span>
                          </div>
                          <input
                            type="number"
                            name="earning"
                            value={
                              tmpObj?.earning?.[item.name] &&
                              tmpObj?.earning?.[item.name] !== ''
                                ? tmpObj?.earning?.[item.name]
                                : item.value
                            }
                            onChange={e =>
                              handleOnSalaryChange(e, item, index, 'earning')
                            }
                            className="form-control"
                            placeholder=""
                          />
                        </div>
                      </td>
                      <td>{item.monthly}</td>
                      <td>
                        {item.yearly}
                        {item.name !== 'basic' && (
                          <i
                            className={`fa fa-trash text-danger cursor`}
                            aria-hidden="true"
                            style={{ float: 'right' }}
                            onClick={e =>
                              handleOnComponentRemove(e, item, index, 'earning')
                            }
                          ></i>
                        )}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <th>
                    <h6 style={{ color: 'green' }}>
                      <b>GROSS</b>
                    </h6>
                  </th>
                  <th></th>
                  <th>
                    <h6 style={{ color: 'green' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.gross?.monthly}
                      </b>
                    </h6>
                  </th>
                  <th>
                    <h6 style={{ color: 'green' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.gross?.yearly}
                      </b>
                    </h6>
                  </th>
                </tr>
                <tr>
                  <th>
                    <h4>Deductions</h4>
                  </th>
                </tr>
                <tr>
                  <td style={{ color: 'darkblue' }}>
                    <b>Employee`s Contribution</b>
                  </td>
                </tr>
                {employeeSalaryDetail?.deductions &&
                  employeeSalaryDetail?.deductions.map((item, index) => (
                    <tr key={index}>
                      {item.name.match(/employee/i) && (
                        <>
                          <td>{item.payslip_name}</td>
                          <td>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text input-prefix-icon"
                                  id="basic-addon1"
                                >
                                  {getInputPrefix(item.value_in)}
                                </span>
                              </div>
                              <input
                                type="number"
                                name="deduction"
                                value={
                                  tmpObj?.deduction?.[item.name] &&
                                  tmpObj?.deduction?.[item.name] !== ''
                                    ? tmpObj?.deduction?.[item.name]
                                    : item.value
                                }
                                onChange={e =>
                                  handleOnSalaryChange(
                                    e,
                                    item,
                                    index,
                                    'deduction',
                                  )
                                }
                                className="form-control"
                                placeholder=""
                                disabled={item.name.match(/pf_employe/i) || item.name.match(/esi_employe/i)}
                              />
                            </div>
                          </td>
                          <td>{item.monthly}</td>
                          <td>
                            {item.yearly}
                            <i
                              className={`fa fa-trash text-danger cursor`}
                              aria-hidden="true"
                              style={{ float: 'right' }}
                              onClick={e =>
                                handleOnComponentRemove(
                                  e,
                                  item,
                                  index,
                                  'deduction',
                                )
                              }
                            ></i>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                <tr>
                  <th>
                    <h6 style={{ color: 'black' }}>
                      <b>Total Employee Contribution</b>
                    </h6>
                  </th>
                  <th></th>
                  <th>
                    <h6 style={{ color: 'black' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.total_employee?.monthly}
                      </b>
                    </h6>
                  </th>
                  <th>
                    <h6 style={{ color: 'black' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.total_employee?.yearly}
                      </b>
                    </h6>
                  </th>
                </tr>
                <tr>
                  <td style={{ color: 'darkblue' }}>
                    <b>Employer`s Contribution</b>
                  </td>
                </tr>
                {employeeSalaryDetail?.deductions &&
                  employeeSalaryDetail.deductions.map((item, index) => (
                    <tr key={index}>
                      {(item.name.match(/employer/i) ||
                        item.name.match(/gmc/i)) && (
                        <>
                          <td>{item.payslip_name}</td>
                          <td>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text input-prefix-icon"
                                  id="basic-addon1"
                                >
                                  {getInputPrefix(item.value_in)}
                                </span>
                              </div>
                              <input
                                type="number"
                                name="pf"
                                value={
                                  tmpObj?.deduction?.[item.name] &&
                                  tmpObj?.deduction?.[item.name] !== ''
                                    ? tmpObj?.deduction?.[item.name]
                                    : item.value
                                }
                                onChange={e =>
                                  handleOnSalaryChange(
                                    e,
                                    item,
                                    index,
                                    'deduction',
                                  )
                                }
                                className="form-control"
                                placeholder=""
                                disabled={item.name.match(/pf_employe/i) || item.name.match(/esi_employe/i)}
                              />
                            </div>
                          </td>
                          <td>{item.monthly}</td>
                          <td>{item.yearly}
                          <i
                              className={`fa fa-trash text-danger cursor`}
                              aria-hidden="true"
                              style={{ float: 'right' }}
                              onClick={e =>
                                handleOnComponentRemove(
                                  e,
                                  item,
                                  index,
                                  'deduction',
                                )
                              }
                            ></i>
                            </td>
                        </>
                      )}
                    </tr>
                  ))}
                <tr>
                  <th>
                    <h6 style={{ color: 'black' }}>
                      <b>Total Employer Contribution</b>
                    </h6>
                  </th>
                  <th></th>
                  <th>
                    <h6 style={{ color: 'black' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.total_employer?.monthly}
                      </b>
                    </h6>
                  </th>
                  <th>
                    <h6 style={{ color: 'black' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.total_employer?.yearly}
                      </b>
                    </h6>
                  </th>
                </tr>
                <tr>
                  <th>
                    <h6 style={{ color: 'RebeccaPurple' }}>
                      <b>CTC A</b>
                    </h6>
                  </th>
                  <th></th>
                  <th>
                    <h6 style={{ color: 'RebeccaPurple' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.ctc?.monthly}
                      </b>
                    </h6>
                  </th>
                  <th>
                    <h6 style={{ color: 'RebeccaPurple' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.ctc?.yearly}
                      </b>
                    </h6>
                  </th>
                </tr>
                <tr>
                  <th>
                    <h6 style={{ color: 'DarkSlateBlue' }}>
                      <b>BONUS</b>
                    </h6>
                  </th>
                  <th></th>
                  <th>
                    <h6 style={{ color: 'DarkSlateBlue' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.bonus?.monthly}
                      </b>
                    </h6>
                  </th>
                  <th>
                    <h6 style={{ color: 'DarkSlateBlue' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.bonus?.yearly}
                      </b>
                    </h6>
                  </th>
                </tr>
                <tr style={{ background: '#3f4f8d' }}>
                  <th>
                    <h5 style={{ color: 'white' }}>
                      <b>TOTAL CTC</b>
                    </h5>
                  </th>
                  <th></th>
                  <th>
                    <h5 style={{ color: 'white' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.total_ctc?.monthly}
                      </b>
                    </h5>
                  </th>
                  <th>
                    <h5 style={{ color: 'white' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.total_ctc?.yearly}
                      </b>
                    </h5>
                  </th>
                </tr>
                <tr style={{ background: '#eb7c45' }}>
                  <th>
                    <h5 style={{ color: 'black' }}>
                      <b>TAKE HOME</b>
                    </h5>
                  </th>
                  <th></th>
                  <th>
                    <h5 style={{ color: 'black' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.take_home?.monthly}
                      </b>
                    </h5>
                  </th>
                  <th>
                    <h5 style={{ color: 'black' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.take_home?.yearly}
                      </b>
                    </h5>
                  </th>
                </tr>
              </tbody>
            </table>
          )}
          {/* <div className="actions text-left pl-5">
            <button className="btn btn-primary ml-5" onClick={addEmployee}>
              Save
            </button>
            <button className="btn btn-outline-dark ml-2">Cancel</button>
          </div> */}
          {type == 'offer_letter' ?
            <div className="text-right">
              <CustButton
                type="button"
                name="pdf-view"
                text="Preview"
                color="primary"
                onClick={handleViewOfferLetter}
                className="btn-loading btn-ladda mr-3"
                icon="fa-paper-plane"
                // isDisabled={isGettingOfferLetter}
                isDisabled={isGettingOfferLetter || (ctcRanges.allowed_department == '0' && ( ctc>ctcRanges.budgeted_salary && ctc>ctcRanges.approved_salary) || (utilizedDepartmentBudget + ctc) > departmentBudget || ctcRanges.employee_exceeded == '1') }
                isLoading={isGettingOfferLetter}
              />
              &nbsp;
              {(ctcRanges.allowed_department == '0' && (ctc>ctcRanges.budgeted_salary && ctc>ctcRanges.approved_salary) || (ctcRanges.employee_exceeded == '1') || ((utilizedDepartmentBudget + ctc) > departmentBudget) ) && 
                  <CustButton
                  type="button"
                  name="pdf-view"
                  text="Send Mail for Approval"
                  color="success"
                  onClick={sendMailForApproval}
                  className="btn-loading btn-ladda mr-3"
                  icon="fa fa-envelope"
                />
              }
              {/* <CustButton
                      type="submit"
                      name="save-ctc"
                      text="Submit"
                      color="success"
                      onClick={() => handleViewOfferLetter()}
                      className="btn-loading btn-ladda"
                      icon="fa-paper-plane"
                      isDisabled={false}
                      isLoading={false}
                    /> */}
            </div>
            :
            <div className="text-right">
              <CustButton
                type="button"
                name="pdf-view"
                text="Save Salary"
                color="primary"
                onClick={handleSaveSalaryData}
                className="btn-loading btn-ladda mr-3"
                icon="fa-paper-plane"
              />
              &nbsp;
            </div>
          }
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CtcGenerate;