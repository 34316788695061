import React,{useState} from 'react'
import ModalForItDeclaration from './ModalForItDeclaration'
import API from 'src/utils/apiCalling'
import { config } from 'src/utils/apiUrl'
import { useEffect } from 'react'
import ModalForEditSubInvestment from './ModalForEditSubInvestment'
import ModalForEditItDeclaration from'./EditItDeclarationModal';
import { isAuthenticated } from 'src/utils/common';
import OtpModal from './OtpModal';
import { useForceUpdate } from 'src/hooks';

const AddITDeclaration = () => {
  const api=new API()
  const forceUpdate = useForceUpdate();
  const [firstTime, setFirstTime] = useState(false);
    const[isOpen,setIsOpen]=useState(false)
    const[isEditOpen,setIsEditOpen]=useState(false)
    const[isOpen1,setIsOpen1]=useState(false)
    const [investment, setInvestment]=useState({})
    const[allInvestment,setAllInvestment]=useState([])
    const[subtypeInvestment,setSubTypeInvestment]=useState()
    const [ isOtpModalOpen, setIsOtpModalOpen] = useState(false);
    
    useEffect(() => {
      let data = isAuthenticated();
      if (data) {
        setIsOtpModalOpen(false);
      }else {
        setFirstTime(true);
        setIsOtpModalOpen(true);
      }
    }, [isOtpModalOpen]);
  
  
    const viewOtpModal = () => {
      setIsOtpModalOpen(!isOtpModalOpen);
      forceUpdate();
    }
    const isViewModalOpen=()=>{
      setIsOpen(!isOpen)

    }
    const isViewModalOpen1=(item)=>{
      setInvestment(item);
      setIsOpen1(!isOpen1)
    }
    const isViewEditModalOpen=()=>{
      setIsEditOpen(!isEditOpen)
    }
    const getAllInvestment=async()=>{
      let result=await api.get(config.investment)
      console.log("all innvestment",result)
      if(result&&result.code===200){
        setAllInvestment(result.data)
      }

    }
    const editSubTypeOfInvestment=(items)=>{
      console.log("jdwegd",items)
      isViewEditModalOpen()
      setSubTypeInvestment(items)

    }
    const deleteSubTypeOfInvestment=async(id)=>{
      let data={
        "id":id
      }
      let result=await api.post(config.deleteSubcategory,data)

    }
  useEffect(() => {
  getAllInvestment()
   
  }, [])
  
  return (
    <div className=''>
      <div style={{display: isOtpModalOpen ? 'none' : 'block'}}>
        <div className="row" style={{backgroundColor:"whitesmoke"}}>
        <div className="col md-9">
          <h3>Declarations</h3>
          </div>
          <div className="col md-3">
          <button className='btn btn-success' style={{float:"right"}} onClick={()=>isViewModalOpen()}>
                    Add IT Declaration
                </button >
        </div>
        </div>
        {allInvestment&&allInvestment.length>0&&allInvestment.map((item)=>(
           <div>
           <div className='row text-left p-2'>
             <div className='col-md-12 '><strong style={{fontSize:"15px"}}>{`${item.category} Investment`}</strong><span className='px-2' style={{fontSize:"15px"}}>{`(MAX.LIMIT :${item.amount})`}</span>
             <button className="btn btn-primary" onClick={()=>isViewModalOpen1(item)}>Edit</button></div>
           </div>
          
           <table className="table table-borderless">
     <thead>
       <tr>
        
         <th scope="col">PARTICULARS</th>
         <th scope="col">MAX. LIMIT</th>
         
         
       </tr>
     </thead>
     <tbody>
      {
        item.sub_category.map((subTypeOfInvestment,index)=>(
          <tr key={index} >
          <td>{subTypeOfInvestment.subCategoriesName}</td> 
          <td >{subTypeOfInvestment.maxLimit?subTypeOfInvestment.maxLimit:`MAX LIMIT DOES NOT EXIST`}</td> 
          {/* <td><button className="btn btn-primary" onClick={()=>editSubTypeOfInvestment(subTypeOfInvestment)}>Edit</button></td>
          <td><button className="btn btn-danger" onClick={()=>deleteSubTypeOfInvestment()}>Delete</button></td>   */}
          </tr>
        ))
      }
       
     </tbody>
   </table>
   <hr/>
   
           </div>

        ))}
      </div>  
        <ModalForItDeclaration isOpen={isOpen} isViewModalOpen={isViewModalOpen} getAllInvestment={getAllInvestment}/>
        <ModalForEditSubInvestment isEditOpen={isEditOpen} isViewEditModalOpen={isViewEditModalOpen} subtypeInvestment={subtypeInvestment}/>
        <ModalForEditItDeclaration isOpen={isOpen1} isViewModalOpen={isViewModalOpen1} investment1={investment} getAllInvestment={getAllInvestment}/>
        <OtpModal
        isOtpModalOpen = {isOtpModalOpen}
        viewOtpModal = {viewOtpModal}
        firstTime = {firstTime}
        setFirstTime = {setFirstTime}
        setIsOtpModalOpen = {setIsOtpModalOpen}
      />
    </div>
  )
}

export default AddITDeclaration