import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const HorizontalStackBarChart = ({ leavevsplannedleavedata }) => {
  let option = {
    chart: {
      type: 'bar',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories:
        leavevsplannedleavedata && leavevsplannedleavedata.length > 0
          ? leavevsplannedleavedata.map(item => {
              return item?.month;
            })
          : [],
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Goals',
      },
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: 'Leaves',
        color: 'rgb(53, 76, 158)',
        data:
          leavevsplannedleavedata && leavevsplannedleavedata.length > 0
            ? leavevsplannedleavedata.map(item => {
                return parseInt(item?.thisMonthAllLeaveData);
              })
            : [],
      },
      {
        name: 'Unplanned',
        color: 'rgb(254, 123, 30)',
        data:
          leavevsplannedleavedata && leavevsplannedleavedata.length > 0
            ? leavevsplannedleavedata.map(item => {
                return parseInt(item?.thisMonthPlannedLeaveData);
              })
            : [],
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={option} />;
};

export default HorizontalStackBarChart;
