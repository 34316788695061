import React, { useState, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import { withRouter } from 'react-router-dom';

import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import MainChartExample from '../charts/MainChartExample.js';
import swal from 'sweetalert';
import { ToastContainer } from 'react-toastify';
import { setRef } from '@material-ui/core';
import ChatBotComponent from '../common/chatbot';
import moment from 'moment';
import BirthdayComponent from './birthday_component/birthday_component';
import MainDashbord from 'src/mainDashbord/index.js';
// import { connect } from 'react-redux';
// import dashboardDataAction from 'src/core/resource/dashboard/dashboardData.Action.js';
// import dashboardDataSelector from 'src/core/resource/dashboard/dashboard.Selector.js';
const WidgetsDropdown = lazy(() => import('../widgets/WidgetsDropdown.js'));
const WidgetsBrand = lazy(() => import('../widgets/WidgetsBrand.js'));
// const { milliseconds, seconds, minutes, hours, days } = require('time-convert')
const Dashboard = props => {
  const {
    // withRouter,
    // managerdashboardDataRes,
    // managerdashboardOtherDataRes,
    // managerdashboardRequestAction,
  } = props;
  // useEffect(() => {
  //   managerdashboardRequestAction({
  //     startDate: '2024-02-01',
  //     endDate: '2024-02-29',
  //     type: 'Grosshours',
  //   });
  // }, []);

  const api = new API();

  // const test = () => {
  //   let res = msToHms(29817000)
  //   console.log(res)
  // }
  // const msToHms = ms => (
  //   milliseconds.to(hours, minutes, seconds)(ms)
  //     .map(n => n < 10 ? '0' + n : n.toString()) // zero-pad
  //     .join(':')
  // )
  return (
    <div>
      {/* <button className='btn btn-primary' onClick={test}>test</button> */}
      {/* <BirthdayComponent/> */}
      <MainDashbord></MainDashbord>
    </div>
  );
};
// const mapStateToProps = state => {
//   return {
//     managerdashboardDataRes: dashboardDataSelector.dashboardDataRes(state),
//     managerdashboardOtherDataRes: dashboardDataSelector.dashboardOtherDataRes(
//       state,
//     ),
//   };
// };
// const mapDispatchToProps = dispatch => ({
//   managerdashboardRequestAction: data =>
//     dispatch(dashboardDataAction.dashboardDataRequestAction(data)),
// });
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(withRouter(Dashboard));

export default withRouter(Dashboard);
