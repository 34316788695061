import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DateRangePicker from 'src/views/common/dateRangePicker';
import { connect } from 'react-redux';
import dashboardDataAction from 'src/core/resource/dashboard/dashboardData.Action.js';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';

const InfoHeader = ({
  managerdashboardRequestAction,
  managerdashboardOtherRequestAction,
  setEndDate,
  endDate,
  setStartDate,
  startDate,
}) => {
  const [parameterType, setParameterType] = useState('total_present_days');
  const user = getLocalDataAsObject('user');
  console.log('sjhdjsdaskdhjx', user);
  const parameter = [
    { name: 'Total Present Days', key: 'total_present_days' },
    { name: 'Total Absent', key: 'total_absent' },
    { name: 'Timely In', key: 'timely_in' },
    { name: 'Late In', key: 'late_in' },
    { name: 'Gross Working', key: 'gross_working' },
    { name: 'Planned Leaves', key: 'planned_leaves' },
    { name: 'Unplanned Leaves', key: 'unplanned_leaves' },
    { name: 'Exceed Break', key: 'exceed_break' },
    { name: 'Exceed Correction', key: 'exceed_correction' },
    { name: 'Sick Leaves', key: 'sick_leaves' },
    { name: 'Maternity Leaves', key: 'maternity_leaves' },
    { name: 'Paternity Leaves', key: 'paternity_leaves' },
    { name: 'Short Leaves', key: 'short_leaves' },
    { name: 'Correction Approved', key: 'correction_approved' },
    { name: 'Comp-off Approved', key: 'comp_off_approved' },
  ];
  useEffect(() => {
    managerdashboardRequestAction({
      startDate: startDate,
      endDate: endDate,
      type: parameterType,
    });
  }, [endDate, parameterType]);
  useEffect(() => {
    managerdashboardOtherRequestAction({
      startDate: startDate,
      endDate: endDate,
    });
  }, [endDate]);
  return (
    <div className="dashboardTitle mb-3">
      <div>
        <span>
          Welcome, <b>{user && user.length > 0 && user[0]?.first_name}</b>
        </span>
        <h1>Total Employees</h1>
      </div>
      <div className="topfilter">
        <div className="selectDate">
          <DateRangePicker DateStart={setStartDate} DateEnd={setEndDate} />
        </div>
        <div className="selectTotal">
          <div className="dropdown">
            {/* <div className="dropdown-menu"> */}
            <select
              type="button"
              className="form-control"
              onChange={e => setParameterType(e.target.value)}
            >
              {parameter.map(item => (
                <option value={item?.key} key={item.key}>
                  {item?.name}
                </option>
              ))}
            </select>
            {/* <div className="text-center p-2">
                <button type="button" className="btn btn-orange px-4">
                  {' '}
                  Apply
                </button>
              </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = dispatch => ({
  managerdashboardRequestAction: data =>
    dispatch(dashboardDataAction.dashboardDataRequestAction(data)),
  managerdashboardOtherRequestAction: data =>
    dispatch(dashboardDataAction.dashboardOtherDataRequestAction(data)),
});
export default connect(null, mapDispatchToProps)(InfoHeader);

// export default InfoHeader;
