import React, { useState, useEffect } from 'react'

import {
    CNav,
    CNavItem,
    CNavLink,
 
    CTabContent,
    CTabPane,
   
    CTabs,
 
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
 
  
    CCollapse,

} from '@coreui/react'

import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl'
// import ModalPopupReceivedRequisition from '../received_requisition/modal';
// import ModalPopupReceivedRequisition from './modal'
import swal from 'sweetalert';
// import JustificationModal from './justificationModal';
import Alert from 'src/alert/alert';
import ModalPopupReceivedRequisition from "../../HR-MANAGER-PANEL/received_requisition/modal"

import moment from 'moment'
import { isPermission } from 'src/utils/common';
const ApprovedRequestion = (props) => {
    const { getCountApprovedRequisition } = props
    let api = new API()

    const [mrfRecord, setMrfRecord] = useState()
    const[approvedRequisition,setApprovedRequisition]=useState()
  
    const [isAlert, setIsAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [active, setActive] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const [details, setDetails] = useState([]);
    const sendCountApprovedRequisition=(count)=>{
        getCountApprovedRequisition(count)

    }
    const mrfReceivedforHrManager = async () => {
       
        let result = await api.get(config.getMrfReceivedforHrManager)
        if (result && result.code == 200) {

            let replacement = result.data.Replacements&&result.data.Replacements.length>0&&result.data.Replacements.filter((item)=>item.is_approved_by_hr_manager===1)?result.data.Replacements&&result.data.Replacements.length>0&&result.data.Replacements.filter((item)=>item.is_approved_by_hr_manager===1):[]
            let vacancy = result.data.Vacancy&&result.data.Vacancy.length>0&&result.data.Vacancy.filter((item)=>item.is_approved_by_hr_manager===1)?result.data.Vacancy&&result.data.Vacancy.length>0&&result.data.Vacancy.filter((item)=>item.is_approved_by_hr_manager===1):[]
          
            let replacementAndVacancy = replacement.concat(vacancy&&vacancy)
           



             sendCountApprovedRequisition(replacementAndVacancy.length)
            setMrfRecord(replacementAndVacancy)
        } else {
            // console.log("something wrong")
        }
    }


    const isViewModalOpen = (received_Requisition) => {
        setIsModalOpen(!isModalOpen)
        setApprovedRequisition(received_Requisition)
    }

 
    const isAlertToggle = (msg) => {
        setIsAlert(!isAlert)
        setAlertMessage(msg)
    }
    const isAlertToggleClose = () => {
        setIsAlert(false)
    }
    useEffect(()=>{
        mrfReceivedforHrManager()
    },[])

    const fields = [
        // { label: "Assest Code (Host Name)", key: "code", _style: { width: "20%" } },
        { label: "MRF ID", key: "id", _style: { width: "5%" } },
        { label: "Applying for", key: "applying_for", _style: { width: "10%" } },
        { label: "MRF raised by", key: "created_by_name", _style: { width: "10%" }, },
        { label: "Head of department", key: "head_of_department", _style: { width: "10%" }, },
        { label: "Designation", key: "designation", _style: { width: "10%" } },
        { label: "Priority", key: "priority", _style: { width: "10%" } },
        { label: "Created At", key: "created_at", _style: { width: "10%" }},
        {
            key: "show_details",
            label: "",
            _style: { width: "5%" },
            sorter: false,
            filter: false,
        },
    ];
    const toggleDetails = (index) => {
        const position = details.indexOf(index);
        let newDetails = details.slice();
        if (position !== -1) {
            newDetails.splice(position, 1);
        } else {
            newDetails = [...details, index];
        }
        setDetails(newDetails);
    };
    const getBadge = (status) => {
        switch (status) {
            case "In Stock":
                return "success";
            case "Inactive":
                return "secondary";
            case "In Use":
                return "danger";
            case "Inuse":
                return "danger";
            case "Banned":
                return "danger";
            case "Damage":
                return "warning";
            default:
                return "primary";
        }
    };
  return (
    <div className="">

            <div className="container">
                        <h3 className="text-center mt-3 mb-4" style={{ color: "#2a3c81" }}>List Of Received Requisition..</h3>
                <CCard>
                    {/* <CCardHeader>

                    </CCardHeader> */}
                    <CCardBody>


                        <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                            <CNav variant="tabs">
                                <CNavItem>
                                    <CNavLink>
                                        <b style={{ color: "#2a3c81" }}>Approved Requisition</b>
                                    </CNavLink>
                                </CNavItem>
                     
                            </CNav>

                            <CTabContent>
                                <CTabPane>
                                    <CDataTable
                                        items={mrfRecord}
                                        fields={fields}
                                        // columnFilter
                                        tableFilter={{label: 'Filter:', placeholder: 'Search'}}
                                        // footer
                                        itemsPerPageSelect
                                        itemsPerPage={10}
                                        hover
                                        sorter
                                        pagination
                                        scopedSlots={{
                                            asset_status: (item) => (
                                                <td>
                                                    <CBadge color={getBadge(item.asset_status)}>
                                                        {item.asset_status}
                                                    </CBadge>
                                                </td>
                                            ),
                                            show_details: (item, index) => {
                                                return (
                                                    <td className="py-2">
                                                        <CButton
                                                            color="primary"
                                                            variant="outline"
                                                            shape="square"
                                                            size="sm"
                                                            onClick={() => {
                                                                toggleDetails(index);
                                                            }}
                                                        >
                                                            {details.includes(index) ? "Hide" : "Show"}
                                                        </CButton>
                                                    </td>
                                                );
                                            },
                                            details: (item, index) => {
                                                return (
                                                    <CCollapse show={details.includes(index)}>
                                                        <CCardBody>
                                                            {/* <h4>{item.username}</h4>
                                                            <p className="text-muted">
                                                                Remark: {item.remark}
                                                            </p> */}


                                                            {isPermission('view_approved_requisition') && 

                                                            <CButton onClick={()=>isViewModalOpen(item)}
                                                                size="sm"
                                                                color="info"
                                                                className="ml-1"
                                                            >
                                                                View
                                                            </CButton>
                                                           }
                                                          
                                                        </CCardBody>
                                                    </CCollapse>
                                                );
                                            },
                                        }}
                                    />
               
                                </CTabPane>
                               
                            </CTabContent>
                        </CTabs>
                    </CCardBody>
                </CCard>
                <ModalPopupReceivedRequisition isModalOpen={isModalOpen} message={"Approved Requisition By Manager"}
                    isViewModalOpen={isViewModalOpen} approvedRequisition={approvedRequisition}
                   />

            
            </div>

            <Alert isAlert={isAlert} isAlertToggle={isAlertToggle} alertMessage={alertMessage} isAlertToggleClose={isAlertToggleClose} />
        </div>
  )
}

export default ApprovedRequestion