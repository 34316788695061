// import React, { useState, lazy, useEffect } from 'react'
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link
// } from "react-router-dom";

// import {
//   CBadge, CNav,
//   CInputRadio,
//   CNavItem,
//   CNavbar,
//   CListGroup, CListGroupItem,
//   CNavLink,
//   CTabContent,
//   CTabPane,
//   CTabs,
//   CButton,
//   CTextarea,
//   CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
//   CWidgetBrand,
//   CButtonGroup,
//   CWidgetIcon,
//   CCard,
//   CLink,
//   CCardBody,
//   CCardFooter,
//   CInputFile,
//   CCardHeader,
//   CCol,
//   CSwitch,
//   CCollapse,
//   CProgress,
//   CProgressBar,
//   CRow,
//   CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
//   CCallout
// } from '@coreui/react'
// import CIcon from '@coreui/icons-react'
// import API from 'src/utils/apiCalling';
// import { config } from 'src/utils/apiUrl';

// import Alert from "src/alert/alert";
// import swal from 'sweetalert'
// import { toast, ToastContainer } from 'react-toastify';

// const EducationDetails = (props) => {
//   const [isDisabled, setIsDisabled] = useState(false)
//   let { getEmployeeDetailStatus, empStatus, empDetails } = props
//   const api = new API()
//   const [allEducation, setAllEducation] = useState([])
//   const [isAlert, setIsAlert] = useState(false);
//   const [alertMessage, setAlertMessage] = useState("");
//   const isAlertToggle = (msg) => {
//     setIsAlert(!isAlert);
//     setAlertMessage(msg);
//   };
//   const isAlertToggleClose = () => {
//     setIsAlert(false);
//   }
//   const [education, setEducation] = useState({
//     education_class: "",
//     roll_no: "",
//     board: "",
//     university: "",
//     percentage: "",
//     year: "",
//   });
//   useEffect(() => {
//     getEducationDetails()
//   }, [])
//   const getEducationDetails = async () => {
//     let data = {
//       user_id: empDetails && empDetails.id
//     }
//     if (!data.user_id) {
//       delete data.user_id
//     }
//     let result = await api.get(config.getEmployeeEducationalDetails, true, data);
//     if (result && result.code === 200) {
//       if (empStatus && empStatus.education_detail_status == 1) {
//         setIsDisabled(true)

//         // console.log("_________________________________", result)
//         setAllEducation(result.data.Educational_details)
//       }
//     } else {
//       // alert(result && result.message);
//     }
//   };
//   const saveEducaionalDetails = async () => {
//     if(allEducation.length==0){
//       toast.warning("Please add documents")
//       return
//     }
//     let data = { "allEducation": JSON.stringify(allEducation) };
//     data.user_id = empDetails && empDetails.id
//     console.log(data)
//     if (!data.user_id) {
//       delete data.user_id
//     }
//     swal({
//       html: true,
//       title: "Are you sure?",
//       text: `Are you sure to add Educational Details`,
//       icon: "success",
//       buttons: ["No, cancel it!", "Submit"],
//       dangerMode: true,
//     }).then(async function (isConfirm) {
//       if (isConfirm) {
//         let result = await api.post(config.edudetails, data);
//         if (result && result.code == 200) {
//           toast.success("Education Details Are Added Successfully")
//           // isAlertToggle("Education Details Are Added Successfully");
//           getEmployeeDetailStatus()
//         } else {
//           toast.error(result && result.message)
//           // isAlertToggle(result && result.message);

//         }


//       }
//     })

//   }
//   const onChangeInputFamilyDetails = (e) => {
//     const { name, value } = e.target;
//     setEducation({
//       ...education,
//       [name]: value,
//     });
//   };
//   const addMoreEdu = () => {
//     if (!education.education_class || !education.roll_no || !education.board || !education.university || !education.percentage || !education.year) {
//       // isAlertToggle("Field cant blank");
//       toast.error("Field cant blank")
//     } else {
//       setAllEducation([...allEducation, education])
//       // console.log("all education", allEducation)
//       setEducation({
//         education_class: "",
//         roll_no: "",
//         board: "",
//         university: "",
//         percentage: "",
//         year: "",
//       });
//     }
//   }

//   return (
//     <div>
//       <div>
//         {allEducation && allEducation.length > 0 &&
//           <CRow>
//             <CCol xs="12" >
//               <CCard>
//                 <CCardHeader>
//                   Education Details
//                 </CCardHeader>
//                 <CCardBody>
//                   <CCardBody>
//                     <h4>
//                     </h4>
//                     <table className="table" style={{overflow:"auto"}}>
//                       <thead>
//                         <tr>
//                           <th scope="col">Education Class</th>
//                           <th scope="col">Roll No</th>
//                           <th scope="col">Board</th>
//                           <th scope="col">University</th>
//                           <th scope="col">Percentage</th>
//                           <th scope="col">Year</th>

//                         </tr>
//                       </thead>
//                       <tbody>
//                         {allEducation && allEducation.length > 0 && allEducation.map((edu, index) => (
//                           <tr>
//                             <td>{edu.education_class}</td>
//                             <td>{edu.roll_no}</td>
//                             <td>{edu.board}</td>
//                             <td className="letter">{edu.university}</td>
//                             <td>{edu.percentage}</td>
//                             <td>{edu.year}</td>
//                             <td>
//                               {/* {isDisabled == false &&
//                                 <CButton size="sm" color="primary">
//                                   Update
//                                 </CButton>

//                               } */}
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </table>

//                   </CCardBody>

//                   <CCardFooter>
//                   </CCardFooter>
//                 </CCardBody>
//               </CCard>

//             </CCol>
//           </CRow>
//         }
//       </div>
//       {isDisabled == false &&
//         <form>
//           <div className="row">

//             <div className="col-md-6">

//               <label for="exampleFormControlSelect1">Education Class</label>
//               <select className="form-control" id="exampleFormControlSelect1"
//                 name="education_class"
//                 onChange={onChangeInputFamilyDetails}
//                 placeholder="ex. 10th"
//                 value={education.education_class}>
//                 <option hidden>Please select</option>
//                 <option value="10th">10th</option>
//                 <option value="12th">12th</option>
//                 <option value="ITI">ITI</option>
//                 <option value="graduation">Graduation</option>
//                 <option value="post graduation">Post Graduation</option>
//                 <option value="diploma">Diploma</option>
//               </select>

//               <div className="form-group">
//                 <label for="exampleInputEmail1">Roll No</label>
//                 <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Roll No"
//                   name="roll_no"
//                   onChange={onChangeInputFamilyDetails}
//                   value={education.roll_no} />
//               </div>

//               <div className="form-group">
//                 <label for="exampleInputPassword1">BOARD/Study Field</label>
//                 <input type="text" className="form-control"
//                   name="board"
//                   onChange={onChangeInputFamilyDetails}
//                   value={education.board} />
//               </div>


//             </div>

//             <div className="col-md-6">
//               <div className="form-group">
//                 <label for="exampleInputEmail1">School/University</label>
//                 <input type="text" className="form-control" id="exampleInputEmail1" name="university"
//                   value={education.university}
//                   onChange={onChangeInputFamilyDetails}
//                   required />
//               </div>

//               <div className="form-group">
//                 <label for="exampleInputEmail1">PERCENTAGE</label>
//                 <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
//                   onChange={onChangeInputFamilyDetails}
//                   name="percentage"
//                   value={education.percentage} />
//               </div>

//               <div className="form-group">
//                 <label for="exampleInputEmail1">YEAR</label>
//                 <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
//                   onChange={onChangeInputFamilyDetails}
//                   name="year"
//                   value={education.year} />
//               </div>

//               <button type="button" className="btn btn-primary" onClick={addMoreEdu}>Add Education</button>
//             </div>
//           </div>

//           <button className="btn btn-primary" type="button" onClick={saveEducaionalDetails} disabled={allEducation.length==0 ? true : false}>Submit</button>
//           <Alert
//             isAlert={isAlert}
//             isAlertToggle={isAlertToggle}
//             alertMessage={alertMessage}
//             isAlertToggleClose={isAlertToggleClose}
//           />
//         </form>
//       }
//       <ToastContainer/>
//     </div>
//   )
// }
// export default EducationDetails
import React, { useState, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import {
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';

import Alert from 'src/alert/alert';
import swal from 'sweetalert';
import { toast, ToastContainer } from 'react-toastify';
import { all } from 'redux-saga/effects';
import moment from 'moment/moment';
const EducationDetails = props => {
  const [isDisabled, setIsDisabled] = useState(false);
  let {
    getEmployeeDetailStatus,
    empStatus,
    empDetails,
    onClickActiveOnboarding,
    onClickActive,
  } = props;
  const api = new API();
  const [allEducation, setAllEducation] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [eduYear,setEduYear]=useState([])
  const [updateEducationData,setUpdateEducationData]=useState([])
  const [alertMessage, setAlertMessage] = useState('');
  const isAlertToggle = msg => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
  };
  const isAlertToggleClose = () => {
    setIsAlert(false);
  };
  const [education, setEducation] = useState({
    education_class: '',
    roll_no: '',
    other_education: '',
    board: '',
    university: '',
    roll_no: '',
    year: '',
  });
  useEffect(() => {
    getEducationDetails();
  }, []);
  const getEducationDetails = async () => {
    let data = {
      user_id: empDetails && empDetails.id,
    };
    if (!data.user_id) {
      delete data.user_id;
    }
    let result = await api.get(
      config.getEmployeeEducationalDetails,
      true,
      data,
    );
    if (result && result.code === 200) {
      if (empStatus && empStatus.education_detail_status === 1) {
        setIsDisabled(true);
        setIsSubmit(false);
        setAllEducation(result.data.Educational_details);
      }
    } else {
      setIsDisabled(true);
      setAllEducation('')
    }
  };

  const deleteEducation = async id => {
    let data = {
      id: id,
    };
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to delete Education Details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Delete'],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.get(config.deleteEducationalDetails, true, data);
        if (result && result.code === 200) {
          toast.success(result && result.message);
          getEducationDetails();
          getEmployeeDetailStatus();
        } else {
          toast.error(result && result.message);
        }
      }
    });
  };

  const saveEducaionalDetails = async () => {
    if (allEducation.length === 0) {
      toast.warning('Please add all fields');
      return;
    }
    let data = { allEducation: JSON.stringify(allEducation) };
    data.user_id = empDetails && empDetails.id;
    if (!data.user_id) {
      delete data.user_id;
    }
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to add Education Details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.edudetails, data);
        if (result && result.code === 200) {
          toast.success(result.message);
          onClickActive(5);
          getEmployeeDetailStatus();
          setIsSubmit(false);
          getEducationDetails();
        } else {
          toast.error(result.message);
        }
      }
    });
  };
  const saveOneEducaionalDetails = async () => {
    let isSubmit =
        education?.education_class === 'other' &&
        education?.board != '' &&
        education?.education_class != '' &&
        education?.university != '' &&
        education?.year != '' &&
        education?.roll_no != '' &&
        education?.percentage &&
        education?.other_education != ''
        ? true
        : education?.education_class != 'other' && 
        education?.board != '' &&
          education?.education_class != '' &&
          education?.university != '' &&
          education?.year != '' &&
          education?.roll_no != '' &&
        education?.percentage
          ? true
          : false;

    if (isSubmit) {
      let allEducation = [{ ...education }];

      let data = { allEducation: JSON.stringify(allEducation) };
      data.user_id = empDetails && empDetails.id;
      if (!data.user_id) {
        delete data.user_id;
      }
      swal({
        html: true,
        title: 'Are you sure?',
        text: `Are you sure to add Education Details`,
        icon: 'success',
        buttons: ['No, cancel it!', 'Submit'],
        dangerMode: true,
      }).then(async function (isConfirm) {
        if (isConfirm) {
          let result = await api.post(config.edudetails, data);
          if (result && result.code === 200) {
            toast.success(result.message);
            onClickActive(5);
            getEmployeeDetailStatus();
            setIsSubmit(false);
            getEducationDetails();
          } else {
            toast.error(result.message);
          }
        }
      });
    } else {
      toast.warning('Please add all fields');
    }
  };

  const updateEducationDetails = async () => {
    let data = { allEducation: JSON.stringify(updateEducationData) };
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to update Education Details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Update'],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.edudetails, data);
        if (result && result.code === 200) {
          toast.success(result.message);
          getEmployeeDetailStatus();
          setIsSubmit(false);
          getEducationDetails();
        } else {
          toast.error(result.message);
        }
      }
    });
  };

  const numOnly = id => {
    // Get the element by id
    var element = document.getElementById(id);
    // Use numbers only pattern, from 0 to 9 with \-
    var regex = /[^0-9\-]/gi;
    // Replace other characters that are not in regex pattern
    element.value = element.value.replace(regex, '');
  };

  const onChangeInputFamilyDetails = e => {
    const { name, value } = e.target;
   
    // const regexYear = /^(19|20)\d{2}$/;
    // let year = document.getElementById('year');
    // year.setAttribute('maxLength', '4');
    // year.setAttribute('minLength', '4');
    // year.addEventListener('input', e => {
    //   const error = document.getElementById('error');
    //   const value = e.target.value;
    //   if (value.length > 4) {
    //     if (regexYear.test(value)) {
    //       error.textContent = '';
    //     } else {
    //       error.textContent = 'Enter a valid year like 1972 or 2010';
    //     }
    //   }
    //   else {
    //     error.textContent = '';
    //   }
    // });
    if (name === 'education_class' && value === 'other') {
      setVisible(true);
      setEducation({
        ...education,
        [name]: value,
      });
    } else if (name === 'education_class') {
      setVisible(false);
      setEducation({
        ...education,
        [name]: value,
      });
    } else {
      setEducation({
        ...education,
        [name]: value,
      });
    }
  };
  const addMoreEdu = () => {
    if (
      !visible &&
      (!education.education_class ||
        !education.board ||
        !education.university ||
        !education.year ||
        !education?.roll_no ||
        !education?.percentage
      )
    ) {
      toast.warning('Please provide your educational details');
    } else if (
      visible &&
      (!education?.education_class ||
      !education?.other_education ||
      !education.board ||
      !education.university ||
      !education.year ||
      !education?.roll_no ||
      !education?.percentage
    )
    ) {
      toast.warning('Please add other education...');
    }else {
      setAllEducation([...allEducation, education]);
      setUpdateEducationData([...updateEducationData,education])
      setIsDisabled(false);
      setEducation({
        education_class: '',
        other_education: '',
        roll_no: '',
        board: '',
        university: '',
        percentage: '',
        year: '',
      });
    }
  };

  const addMoreEduOnUpdate = () => {
    if (
      !visible &&
      (!education.education_class ||
        !education.board ||
        !education.university ||
        !education.year ||
        !education?.roll_no ||
        !education?.percentage
      )
    ) {
      toast.warning('Please provide your educational details');
    } else if (
      visible &&
      (!education?.education_class || !education?.other_education ||
        !education.board ||
        !education.university ||
        !education.year ||
        !education?.roll_no ||
        !education?.percentage
      ) 
    ) {
      toast.warning('Please add other education...');
    }  else {
      setAllEducation([...allEducation, education]);
      setUpdateEducationData([...updateEducationData,education])
      setIsDisabled(false);
      setEducation({
        education_class: '',
        other_education: '',
        roll_no: '',
        board: '',
        university: '',
        percentage: '',
        year: '',
      });
    }
  };

  const selectYearEdu = () => {
    let year = [];
    for (let i = 0; i <= 60; i++) {
      year.push(moment().subtract(i, 'year').format("YYYY"));
    }
    setEduYear(year)  
  }
  useEffect(() => {
    selectYearEdu() 
  }, [])
  return (
    <div>
      <div>
        {allEducation && allEducation.length > 0 && (
          <CRow>
            <CCol xs="12">
              <CCard>
                <CCardHeader>
                  <strong>Education Details</strong>
                </CCardHeader>
                <CCardBody>
                  <CCardBody>
                    <h4></h4>
                    <table className="table" style={{ overflow: 'auto' }}>
                      <thead>
                        <tr>
                          <th scope="col" width="10%">
                            Education Class
                          </th>
                          <th scope="col" width="10%">Roll No</th>
                          <th scope="col" width="10%">
                            Other Education
                          </th>
                          <th scope="col" width="10%">
                            Board
                          </th>
                          <th scope="col" width="20%">
                            University
                          </th>
                          <th scope="col" width="10%">Percentage</th>
                          <th scope="col" width="10%">
                            Year
                          </th>
                          {!isSubmit && (
                            <th scope="col" width="5%">
                              Delete
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {allEducation &&
                          allEducation.length > 0 &&
                          allEducation.map((edu, index) => (
                            <tr>
                              <td>{edu.education_class}</td>
                              <td>{edu.roll_no}</td>
                              <td>{edu.other_education || ''}</td>
                              <td>{edu.board}</td>
                              <td className="letter">{edu.university}</td>
                              <td>{edu.percentage}</td>
                              <td>{edu.year}</td>
                              {!isSubmit && (
                                <td>
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                    style={{ color: 'red' }}
                                    onClick={e => deleteEducation(edu.id)}
                                  ></i>
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </CCardBody>

                  <CCardFooter></CCardFooter>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        )}
      </div>
      <form>
        <div className="row">
          <div className="col-md-6" >
          <div className="form-group" style={{ paddingTop: '6px' }}>
            <label for="exampleFormControlSelect1">
              Education Class <span style={{ color: 'red' }}>*</span>{' '}
            </label>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              name="education_class"
              onChange={onChangeInputFamilyDetails}
              placeholder="ex. 10th"
              value={education.education_class}
            >
              <option hidden>Please select education</option>
              <option value="10th">10th</option>
              <option value="12th">12th</option>
              <option value="ITI">ITI</option>
              <option value="graduation">Graduation</option>
              <option value="post graduation">Post Graduation</option>
              <option value="diploma">Diploma</option>
              <option value="other">Other</option>
            </select>
            </div>
            {visible && (
              <div className="form-group" style={{ paddingTop: '16px' }}>
                <label for="exampleInputPassword1">Other Education Name <span style={{ color: 'red' }}>*</span>{' '}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Other Education"
                  name="other_education"
                  onChange={onChangeInputFamilyDetails}
                  value={education.other_education}
                />
              </div>
            )}

           
            <div className="form-group" >
                 <label for="exampleInputEmail1">Roll No <span style={{ color: 'red' }}>*</span>{' '}</label>
                 <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Roll No"
                  name="roll_no"
                  onChange={onChangeInputFamilyDetails}
                  value={education.roll_no} />
              </div>

            <div
              className="form-group"
              // style={!visible ? { paddingTop: '16px' } : {}}
            >
              <label for="exampleInputPassword1">
                Board / Study Field <span style={{ color: 'red' }}>*</span>{' '}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Board / Study Field"
                name="board"
                onChange={onChangeInputFamilyDetails}
                value={education.board}
              />
            </div>

            <button
              type="button"
              className="btn btn-info mt-5"
              onClick={isSubmit ? addMoreEdu : addMoreEduOnUpdate}
              style={{ color: 'white', backgroundColor: 'green' }}
            >
              {' '}
              <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add
              Education
            </button>
          </div>

          <div className="col-md-6">
            <div className="form-group" style={{ paddingTop: '6px' }}>
              <label for="exampleInputEmail1">
                School / University <span style={{ color: 'red' }}>*</span>{' '}
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                name="university"
                placeholder="Enter School / University"
                value={education.university}
                onChange={onChangeInputFamilyDetails}
                required
              />
            </div>
            <div className="form-group">
                 <label for="PERCENTAGE">PERCENTAGE <span style={{ color: 'red' }}>*</span>{' '}</label>
                <input type="number" className="form-control" id="PERCENTAGE" aria-describedby="PERCENTAGE"
                  onChange={onChangeInputFamilyDetails}
                  name="percentage"
                  value={education.percentage} />
              </div>

            <div className="form-group">
              <label for="exampleInputEmail1">
                Year <span style={{ color: 'red' }}>*</span>{' '}
              </label>
              {/* <input
                type="text"
                className="form-control"
                id="year"
                aria-describedby="emailHelp"
                onChange={onChangeInputFamilyDetails}
                placeholder="Enter Year"
                name="year"
                value={education.year}
                maxLength="4"
              /> */}

              <select className="form-control"
               value={education.year} 
                name="year" 
                onChange={onChangeInputFamilyDetails}  
                >
                    <option value="" selected hidden >select year </option>
                    {eduYear && eduYear.map((year)=>{
                     return <option value={year}>{year}</option>
                    })}
                   
              </select>

              <span id="error" className='text-danger'></span>
            </div>
            {isSubmit ? (
              allEducation && allEducation.length > 0 ? (
                <button
                  className="btn btn-primary float-right"
                  type="button"
                  style={
                    visible ? { marginTop: '130px' } : { marginTop: '48px' }
                  }
                  onClick={saveEducaionalDetails}
                >
                  Submit
                </button>
              ) : (
                <button
                  className="btn btn-primary float-right"
                  type="button"
                  style={
                    visible ? { marginTop: '130px' } : { marginTop: '48px' }
                  }
                  onClick={saveOneEducaionalDetails}
                >
                  Submit
                </button>
              )
            ) : (
              <>
                <button
                  className="btn btn-success float-right ml-1"
                  type="button"
                  style={
                    visible ? { marginTop: '130px' } : { marginTop: '48px' }
                  }
                  onClick={e => onClickActive(5)}
                >
                  Next{' '}
                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </button>
                <button
                  className="btn btn-info float-right"
                  type="button"
                  disabled={isDisabled ? true : false}
                  style={
                    visible ? { marginTop: '130px' } : { marginTop: '48px' }
                  }
                  onClick={updateEducationDetails}
                >
                  Update
                </button>
              </>
            )}
            {/* <button className="btn btn-primary float-right mt-5" type="button" onClick={saveEducaionalDetails}>Submit</button> */}
          </div>
        </div>
        {/* <Alert
            isAlert={isAlert}
            isAlertToggle={isAlertToggle}
            alertMessage={alertMessage}
            isAlertToggleClose={isAlertToggleClose}
          /> */}
      </form>
      <ToastContainer />
    </div>
  );
};
export default EducationDetails;