import React, { useState, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  CBadge,
  CNav,
  CInputRadio,
  CNavItem,
  CNavbar,
  CListGroup,
  CListGroupItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CTextarea,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CWidgetBrand,
  CButtonGroup,
  CWidgetIcon,
  CCard,
  CLink,
  CCardBody,
  CCardFooter,
  CInputFile,
  CCardHeader,
  CCol,
  CSwitch,
  CCollapse,
  CProgress,
  CProgressBar,
  CRow,
  CLabel,
  CInput,
  CForm,
  CFormGroup,
  CFormText,
  CSelect,
  CCallout,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { DocsLink } from 'src/reusable';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import ProfileBank from 'src/HR-PANEL/profile_bank/profile_bank.js';
import swal from 'sweetalert';
import CommentModal from './comment_modal.js';
import { toast } from 'react-toastify';
let moment = require('moment');
const WidgetsDropdown = lazy(() => import('../../widgets/WidgetsDropdown.js'));
const WidgetsBrand = lazy(() => import('../../widgets/WidgetsBrand.js'));

const ViewReceivedSeprationDetails = props => {
  let {
    seprationDetails,
    isViewModalOpen,
    isOpen,
    reloadTaskBox,
    taskBox,
  } = props;
  let api = new API();
  const [isOpenComment, setIsOpenComment] = useState(false);
  const [empResignationData, setEmpResignationData] = useState();
  const [changed_releaving_date, setChanged_releaving_date] = useState('');
  const [early_releaving_comment, setEarly_releaving_comment] = useState('');

  const [requestReleavingDate, setRequestReleavingDate] = useState(false);

  useEffect(() => {
    if (
      (seprationDetails && seprationDetails.request_relieving_date) <
      (seprationDetails && seprationDetails.actual_relieving_date)
    ) {
      setRequestReleavingDate(true);
    }
    // console.log("sepration details", seprationDetails)
  }, [seprationDetails]);
  const acceptResignation = () => {
    if (!changed_releaving_date) {
      toast.error('Please enter approved relieving date');
    }
    else if (requestReleavingDate && !early_releaving_comment) {
      toast.error('Please enter relieving comment');
    }else{
    let data = {
      emp_id: seprationDetails && seprationDetails.emp_id,
    };
    if (requestReleavingDate) {
      data.is_less_releaving_date = requestReleavingDate;
      data.relieving_date = changed_releaving_date || seprationDetails.request_relieving_date;
      data.early_releaving_comment = early_releaving_comment;
    }
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to Accept Resignation`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Accept'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.approveEmpSeparation, data);
        if (result && result.code === 200) {
          toast.success('Successfully accepted resignation');
          isViewModalOpen(!isOpen);
          taskBox && reloadTaskBox();
        } else {
          toast.warning('Unable to accept');
        }
      }
    });
  }
  };
  const declineResignation = comment => {
    swal({
      html: true,
      title: 'Are you sure?',
      input: 'text',
      text: `Are you sure to Decline Resignation`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Decline'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let data = {
          emp_id: seprationDetails && seprationDetails.emp_id,
          rejection_comment_by_manager: comment,
        };
        let result = await api.post(config.rejectEmpSeparation, data);
        if (result && result.code == 200) {
          alert('Successfully declined separation request!');
          setIsOpenComment(!isOpenComment);
          isViewModalOpen(!isOpen);
        } else {
          alert('unable to decline');
        }
      }
    });
  };
  const isViewModalOpenComment = data => {
    setIsOpenComment(!isOpenComment);
    setEmpResignationData(data);
  };
  return (
    <div>
      <CForm
        action=""
        encType="multipart/form-data"
        className="form-horizontal"
      >
        <CRow>
          <CCol>
            <CFormGroup row>
              <CCol md="4">
                <CLabel>Employee ID</CLabel>
              </CCol>

              <CCol xs="12" md="8">
                <CInput
                  style={{ color: 'black' }}
                  disabled
                  value={seprationDetails && seprationDetails?.emp_id}
                  name="id"
                  placeholder=""
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="4">
                <CLabel>Separation Type</CLabel>
              </CCol>

              <CCol xs="12" md="8">
                <CInput
                  disabled
                  style={{ color: 'black' }}
                  value={seprationDetails && seprationDetails?.separation_type}
                  name="name"
                  placeholder=""
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="4">
                <CLabel>Resignation Reason</CLabel>
              </CCol>

              <CCol xs="12" md="8">
                <CInput
                  disabled
                  style={{ color: 'black' }}
                  value={
                    seprationDetails && seprationDetails?.resignation_reason
                  }
                  name="email"
                  placeholder=""
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="4">
                <CLabel>Actual Relieving Date</CLabel>
              </CCol>

              <CCol xs="12" md="8">
                <CInput
                  disabled
                  style={{ color: 'black' }}
                  value={
                    seprationDetails?.actual_relieving_date
                      ? moment(seprationDetails?.actual_relieving_date).format(
                          'DD MMMM YYYY',
                        )
                      : ''
                  }
                  name="dob"
                  placeholder=""
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="4">
                <CLabel>Request Relieving Date</CLabel>
              </CCol>
              <CCol xs="12" md="8">
                <CInput
                  disabled
                  style={{ color: 'black' }}
                  value={
                    seprationDetails?.request_relieving_date
                      ? moment(seprationDetails?.request_relieving_date).format(
                          'DD MMMM YYYY',
                        )
                      : ''
                  }
                  name="designation"
                  placeholder=""
                />
              </CCol>
            </CFormGroup>

            {((seprationDetails &&
              seprationDetails?.is_approved_by_manager == '1') ||
              seprationDetails?.is_reject_by_manager == '1') && (
              <>
                <CFormGroup row>
                  <CCol md="4">
                    <CLabel>Approved Relieving Date</CLabel>
                  </CCol>
                  <CCol xs="12" md="8">
                    <CInput
                    required
                      style={{ color: 'black' }}
                      type="text"
                      disabled
                      value={
                        seprationDetails && seprationDetails?.releaving_date
                          ? moment(
                              seprationDetails &&
                                seprationDetails?.releaving_date,
                            ).format('DD MMMM YYYY')
                          : ''
                      }
                      onChange={e => setChanged_releaving_date(e.target.value)}
                      name="changed_releaving_date"
                      placeholder=""
                    />
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol md="4">
                    <CLabel>
                      Enter comment
                    </CLabel>
                  </CCol>
                  <CCol xs="12" md="8">
                    <CInput
                    required
                      type="text"
                      disabled
                      style={{ color: 'black' }}
                      value={
                        seprationDetails &&
                        seprationDetails?.early_releaving_comment
                      }
                      onChange={e => setEarly_releaving_comment(e.target.value)}
                      name="early_releaving_comment"
                      placeholder=""
                    />
                  </CCol>
                </CFormGroup>
              </>
            )}

            {seprationDetails &&
              seprationDetails?.is_approved_by_manager == '0' &&
              seprationDetails &&
              seprationDetails?.is_reject_by_manager == '0' && (
                <>
                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel>Approved Relieving Date <span style={{color:"red",fontWeight:"bold"}}>*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <CInput
                        required
                        type="date"
                        style={{ color: 'black' }}
                        value={changed_releaving_date}
                        onChange={e =>
                          setChanged_releaving_date(e.target.value)
                        }
                        name="changed_releaving_date"
                        placeholder=""
                      />
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel>
                        Enter Comment <span style={{color:"red",fontWeight:"bold"}}>*</span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <CInput
                      required
                        type="text"
                        style={{ color: 'black' }}
                        value={early_releaving_comment}
                        onChange={e =>
                          setEarly_releaving_comment(e.target.value)
                        }
                        name="early_releaving_comment"
                        placeholder="Enter approval comment"
                      />
                    </CCol>
                  </CFormGroup>
                </>
              )}
              <br/>

            {seprationDetails && seprationDetails?.is_reject_by_manager == '1' && (
              <CFormGroup row>
                <CCol md="4">
                  <CLabel>Rejection Comment</CLabel>
                </CCol>
                <CCol xs="12" md="8">
                  <CInput
                    disabled
                    style={{ color: 'black' }}
                    value={
                      seprationDetails &&
                      seprationDetails?.rejection_comment_by_manager
                    }
                    name="designation"
                    placeholder="Enter rejection comment"
                  />
                </CCol>
              </CFormGroup>
            )}
          </CCol>
        </CRow>

        <CCardFooter>
          <CRow>
            {seprationDetails &&
              seprationDetails?.is_approved_by_manager == 0 &&
              seprationDetails &&
              seprationDetails?.is_reject_by_manager == 0 && (
                <>
                  <CCol xs="12" md="6">
                    <CButton
                      block
                      className="btn btn-success"
                      onClick={acceptResignation}
                    >
                      Accept
                    </CButton>
                  </CCol>
                  <CCol xs="12" md="6">
                    <CButton
                      block
                      className="btn btn-danger"
                      onClick={isViewModalOpenComment}
                    >
                      Retained
                    </CButton>
                  </CCol>
                </>
              )}
          </CRow>
        </CCardFooter>
      </CForm>

      <CommentModal
        isOpenComment={isOpenComment}
        isViewModalOpenComment={isViewModalOpenComment}
        empResignationData={empResignationData}
        declineResignation={declineResignation}
      />
    </div>
  );
};
export default ViewReceivedSeprationDetails;
