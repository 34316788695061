import React, { useState } from 'react';
import ShowAllTeamModal from '../modal/ShowAllTeamModal';
import ShowAllManager from '../modal/ShowAllManager';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const TeamAndManagerCard = ({
  teamPerformanceData,
  allManagerData,
  setEndDate,
  endDate,
  setStartDate,
  startDate,
  tldashboardRequestAction,
  setTab,
  setTlId,
}) => {
  const [showTeam, setShowTeam] = useState(false);
  const [showAllManager, setShowAllManager] = useState(false);
  const handleShowAllManagerModal = () => {
    setShowAllManager(!showAllManager);
  };
  const handleShowTeamMOdal = () => {
    setShowTeam(!showTeam);
  };

  return (
    <div className="col-md-6 mb-3 pe-md-2">
      <div className="cardBox h-100">
        <div className="row">
          {teamPerformanceData ? (
            <div className="col-md-8 border-end">
              <h3 className="fs-6 fw-bold">My Teams</h3>
              <div className="row px-md-2">
                {teamPerformanceData &&
                  teamPerformanceData.length > 0 &&
                  teamPerformanceData.slice(0, 3).map((item, index) => (
                    <div className="col-md-4 px-md-1" key={index}>
                      <a className="teamsCount">
                        <span>{index + 1}</span>
                        <strong style={{ fontSize: '15px' }}>
                          {item?.name} <b>{item?.team_count}</b>
                        </strong>
                      </a>
                    </div>
                  ))}
              </div>
              <div className="d-flex justify-content-between align-items-center my-3">
                <span className="text-secondary">
                  View All Teams{' '}
                  <img src="images/arrow.svg" alt="arrow" width={40} />
                </span>
                <button
                  className="btn-orange"
                  type="button"
                  onClick={() => handleShowTeamMOdal()}
                >
                  View All
                </button>
              </div>
            </div>
          ) : (
            <div className="col-md-8 border-end">
              <Skeleton count={6} />
            </div>
          )}
          {allManagerData ? (
            <div className="col-md-4">
              <h3 className="fs-6 fw-bold">All Managers</h3>
              <div className="allManagers">
                <span>Total Managers </span>
                <strong className="d-block mb-2">
                  {allManagerData && allManagerData?.length}
                </strong>
                <ul className="mb-2">
                  {allManagerData &&
                    allManagerData.length > 0 &&
                    allManagerData.slice(0, 5).map((item, index) => (
                      <li key={index}>
                        <img
                          src={item?.url ? item?.url : 'images/fav.png'}
                          // onError={e => (e.target.src = 'images/logo.png')}
                          alt="profile image"
                        />
                      </li>
                    ))}

                  <li className="ps-2 d-flex align-items-center justify-content-center fw-bold">
                    +
                    {allManagerData &&
                      allManagerData.length > 0 &&
                      allManagerData.length - 5}
                  </li>
                </ul>
                <button
                  className="btn-outline-secondary"
                  type="button"
                  onClick={handleShowAllManagerModal}
                >
                  <img
                    src="images/eye.svg"
                    className="me-2"
                    alt="eye"
                    width={16}
                  />
                  View All
                </button>
              </div>
            </div>
          ) : (
            <div className="col-md-4">
              <Skeleton count={6} />
            </div>
          )}
        </div>
      </div>
      <ShowAllTeamModal
        showTeam={showTeam}
        handleShowTeamMOdal={handleShowTeamMOdal}
        teamPerformanceData={teamPerformanceData}
      />
      <ShowAllManager
        allManagerData={allManagerData}
        setTab={setTab}
        setTlId={setTlId}
        showAllManager={showAllManager}
        handleShowAllManagerModal={handleShowAllManagerModal}
      />
    </div>
  );
};

export default TeamAndManagerCard;
