import React, { useEffect, useState } from 'react'

import API from 'src/utils/apiCalling';
import MainChat from './MainChat';
import { config } from "src/utils/apiUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uniqid from 'uniqid';
import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton } from '@coreui/react';
import swal from "sweetalert";
import { uuidv4 } from '@firebase/util';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';


let socket;
const People = (props) => {
  let { hide, socket, chatUser } = props
  const api = new API();
  const [hideSearch, setHideSearch] = useState(false)
  const [user, setUser] = useState([])
  const [roomId, setRoomId] = useState('')
  const [searchField, setSearchField] = useState([])
  let [getAllChatData, setGetAllChatData] = useState("")
  const [name, setName] = useState('');
  const [currentUser, setCurrentUser] = useState({})
  let [chatScreen, setChatScreen] = useState(false);
  const [room, setRoom] = useState('');
  const [friend, setFriendName] = useState('USER');
  const [users, setUsers] = useState('');
  const [messages, setMessages] = useState([]);
  const [msgFromSocket, setMsgFromSocket] = useState([])
  const [showAddGroup, setShowAddGropp] = useState(false)
  const [groupName, setGroupName] = useState("")
  const [groupDesp, setGroupDesp] = useState("")
  const [groupMember, setGroupMember] = useState([])
  const [groupRoomId, setGroupRoomId] = useState()
  const [allRoomAndGroup, setAllRoomAndGroup] = useState([])
  const [allUnreadMsg, setAllUnreadMsg] = useState("")
  const [newUserId, setNewUserId] = useState('')
  const [showContact, setShowContact] = useState(false)
  const [deviceId, setDeviceId] = useState([])
  const[roomType,setRoomType]=useState()

  const AddMemberInGroup = (id, name,deviceId1) => {
    // console.log("deviceId123",deviceId)
   


    if (groupMember && groupMember.length > 0 && groupMember.map((item) => item.id).includes(id)) {
      let newarr = groupMember && groupMember.length > 0 && groupMember.filter((item) => item.id != id)
      let deviceIdArr=deviceId && deviceId.length > 0 && deviceId.filter((item) => item!= deviceId1)
      setDeviceId(deviceIdArr)
      setGroupMember(newarr)


    } else {
      setGroupMember([...groupMember, { id: id, name: name, room: groupRoomId }])
      setDeviceId([...deviceId,deviceId1])


    }


  }

  const createGroup = async () => {
    let formData = new FormData()
    formData.append("room", groupRoomId)
    formData.append("groupName", groupName)
    formData.append("deviceId", deviceId)
    formData.append("groupDescription", groupDesp)
    formData.append("groupMembers", JSON.stringify(groupMember))
    formData.append("admin", JSON.parse(localStorage.getItem('user'))[0].id)


    let result = await api.putPostFileFun(config.createGroup, formData)
    if (result && result.error === false) {
      let groupData = {
        user: {
          name: currentUser.first_name + " " + currentUser.last_name,
          id: currentUser.id,
        
          
        },
        type: "group",
        room: groupRoomId,
        message: "Welcome In group",
        name: groupName,
        deviceId: deviceId,
        attachment:[]
      }
      // console.log("xxxx",groupData)
      if (groupData) {
        socket.emit('sendMessage', groupData);
      }
   
      
     
      swal({
        title: "Group created successfully",

        icon: "success",
      });
      setShowAddGropp(false)
      getAllRoom()


    } else {
      swal({
        title: "Something went wrong",

        icon: "error",
      });
    }

  }

  const getAllRoom = async (showAllUnreadMsg) => {
    let data = {
      userId: JSON.parse(localStorage.getItem('user'))[0].id
    }
    let result = await api.get(config.userRoomDetails, true, data)
    if (result && result.error == false) {
      let allRoomsAndGroups = result.data && result.data.rooms&&result.data.rooms.length>0 && result.data.rooms

      // console.log("ghgh123",allRoomsAndGroups)

      let allRoomincludeUnreadMsg = await showAllUnreadMsg();

      let newArr = []

      allRoomsAndGroups&&allRoomsAndGroups.length>0&&allRoomsAndGroups.map((item) => (

        allRoomincludeUnreadMsg&&allRoomincludeUnreadMsg.length>0&&allRoomincludeUnreadMsg.map((items) => (<> {

          item.roomId == items.roomId ? newArr.push({ "name": item.name, "roomId": item.roomId, "type": item.roomType, "unreadMsg": items.unReadMessage,"members":item.members }) : ""
        }

        </>

        ))
      ))
      setAllRoomAndGroup(newArr)

    } else {

    }


  }


  const showAllUnreadMsg = async () => {
    let data = {
      userId: JSON.parse(localStorage.getItem('user'))[0].id
    }

    let result = await api.get(config.unReadMessage, true, data)

    if (result && result.error === false) {

      // console.log("cvcv", result && result.data && result.data.length > 0 && result.data)
      return result && result.data && result.data.length > 0 && result.data

    }


  }
  useEffect(() => {

    getAllRoom(showAllUnreadMsg);

  }, [])





  const getChatData = async () => {
    let result = await api.get(config.getAllEmployeeList)
    if (result && result.code == 200) {
      let arr = result.data.sort((a, b) => (a.first_name).toUpperCase() > (b.first_name).toUpperCase() ? 1 : -1)
      setGetAllChatData(arr)
      setUser(arr)


    }

  }


  const hideButton = () => {
    setHideSearch(!hideSearch)
  }
  function createRoomId(user) {
    let room = ''
    if (Number(user.id) <= Number(currentUser.id)) {
      room = `${user.id}_${currentUser.id}`
    }
    else {
      room = `${currentUser.id}_${user.id}`
    }
    return room.toString()
  }

  const navigate = () => {
    setChatScreen(!chatScreen);
    setGetAllChatData(user)
    getAllRoom(showAllUnreadMsg);

  }

  const joinChat = (user) => {
    // console.log("deviceId",user.device_id)
    setDeviceId([user.device_id && user.device_id])

// console.log("joinChat", user)
    
    let roomId = createRoomId(user);
    setNewUserId(user?.id);
    setRoomId(roomId)
    setFriendName({ name: user.first_name + " " + user.last_name, id: user.id })
    setChatScreen(true)
    let data = {
      user: {
        name: currentUser.first_name + " " + currentUser.last_name,
        id: currentUser.id,
        room: roomId
      },
      type: "individual",
      room: roomId
    }


    socket.emit('join', data, (error) => {
     
      if (error) {
        alert(error);
      }
    });
  }
  const joinChat1 = async(friendName, roomId, roomType,members) => {
   
    // console.log("joinchat1", {friendName, roomId, roomType,members})
    let newArr=[]
    if(roomType=="group"){
   
     
    
members&&members.length>0&&members.map((item)=>(
  
        getAllChatData&&getAllChatData.length>0&&getAllChatData.map((emp)=>(
          <>
          {
             emp.id==item.id?newArr.push(emp.device_id):" "
            
          }
          </>
        ))
          
        
      ))
      // console.log("group device Id",members)
     
    }else{
      let receiverId=roomId.split("_").filter((item)=>(item!=currentUser.id))
      // console.log("group members",receiverId)
      getAllChatData&&getAllChatData.length>0&&getAllChatData.filter((emp)=>(
        <>
        {
           emp.id==receiverId[0]?newArr.push(emp.device_id):" "
          
        }
        </>
      ))
      // console.log("room chat",newArr)
      
    }

    setDeviceId(newArr)
    getAllRoom(showAllUnreadMsg);
    // const allDeviceId = getAllDeviceId(roomId)
    setRoomId(roomId)
    setRoomType(roomType)

    setFriendName({ name: friendName, id: roomId.split("_").filter((id) => id != currentUser.id)[0] })
    setChatScreen(true)

    let data = {
      user: {
        name: currentUser.first_name + " " + currentUser.last_name,
        id: currentUser.id,
        room: roomId
      },
      type: roomType,
      room: roomId
    }
   


    socket.emit('join', data, (error) => {
    

      if (error) {
        alert(error);
      }
    });
  }
  // const getAllDeviceId = async (roomId) => {
  //   let data = {
  //     roomId
  //   }
  //   let result = await api.get(config.fetchGroupDetails, true, data)

  //   if (result && result.error === false) {
  //     let AllGroupMembers = result.data && result.data.length > 0 && result.data
  //     let deviceIdOfmembers = AllGroupMembers && AllGroupMembers.length > 0 && AllGroupMembers.map((item) => (
  //       getAllChatData.map((emp) => (
  //         emp.id === item


  //       ))



  //     ))
    

  //   }
  // }


  const saveOnChange = (value) => {
    value = value.toUpperCase()
    setSearchField([])
    user.forEach(use => {
      let name = (use.first_name + " " + use.last_name).toUpperCase()
      if (name.includes(value)) {
        searchField.push(use)
      }
    })

    setGetAllChatData(searchField);
  }



  useEffect(() => {
    getChatData()
    setCurrentUser(JSON.parse(localStorage.getItem('user'))[0]);
  }, []);

  useEffect(()=> {
    setCurrentUser(chatUser[0]);
  },[chatUser]);
  useEffect(() => {
      socket.on('message', message => {
        setMessages(messages => [...messages, message])
      console.log("bona vista", message)
      if (message.id != JSON.parse(localStorage.getItem('user'))[0].id && message.id != 'bot') {
        notify();
        // console.log(JSON.parse(localStorage.getItem('user'))[0].id)
      }
    },[]);


    socket.on("roomData", ({ users }) => {
    
      //this is for gettng the members in the room 
      setUsers(users);

    });
    
  }, []);
  const sendFile = (name,attachedFile) => {
   //we have to get url through thumbnail url
            // "url": "https://ik.imagekit.io/1vbjoibbf/chat_1690622665862_IIIUvf1tZ.png",
            // "thumbnailUrl": "https://ik.imagekit.io/1vbjoibbf/tr:n-ik_ml_thumbnail/chat_1690622665862_IIIUvf1tZ.png",
attachedFile.forEach((element)=> {
  let data = {
    user: {
      name: currentUser.first_name + " " + currentUser.last_name,
      id: currentUser.id,
      room: roomId,
    },
    type: roomType?roomType:"individual",
    room: roomId,
    message:"",
    name: name,
    deviceId: deviceId,
    attachment:element.url,
    newUserId: newUserId || ''
  }
  if (data&&data.attachment!="") {
    socket.emit('sendMessage', data);
  


  }else{
    alert("Empty message not allowed")
  }
})

    
  }


  const sendMessage = (event, name,attachedFile) => {
  
    if (event.key === 'Enter') {

      let data = {
        user: {
          name: currentUser.first_name + " " + currentUser.last_name,
          id: currentUser.id,
          room: roomId,
        },
        type: roomType?roomType:"individual",
        room: roomId,
        message: event.target.value,
        name: name,
        deviceId: deviceId,
        attachment:attachedFile || '',
        newUserId: newUserId || ''
      }
      if (data&&event.target.value!="") {
        console.log("this is msg",event.target.value )
        socket.emit('sendMessage', data);
      }else{
        alert("Empty message not allowed")
      }
    }
  }



  const notify = () => {

    toast("You Have a new Message !!", {
      position: toast.POSITION.BOTTOM_CENTER

    })

    getAllRoom(showAllUnreadMsg);

  }
  const createRoomForGroup = () => {
    setShowAddGropp(!showAddGroup)
    setGroupRoomId(uniqid())
    setGroupMember([ { id:  currentUser.id, name: currentUser.first_name + " " + currentUser.last_name, room: groupRoomId }])
  }

  return (

    <div className="mobile-chat " >
      <ToastContainer hideProgressBar={true} className="toaster-container" toastStyle={{ backgroundColor: "rgba(235,124,69,255 )", color: "white" }} />
      {chatScreen == false && (
        <>
          <div className="header mobile-header">
            <div className="t-head d-flex justify-content-between">
              <p className="fs-5 mb-1 fw-semibold text-white">QD ChatBox</p>
              {
                showContact === true &&
                <div>
                  <i className="fa fa-arrow-left mr-1" style={{ color: "white" }} onClick={() => setShowContact(false)} aria-hidden="true" ></i>
                </div>

              }
             

                <div>
                  {/* <button   className="bg-transparent border-0" data-bs-toggle="modal" data-bs-target="#addmember">
          <i className="bi bi-plus-circle-fill bg-transparent text-white" /></button> */}
                  <button onClick={hideButton} className="btn bg-transparent" type="button" id="button-search"><i className="bi text-white bi-search" /></button></div>
            </div>
            <div className='row'>
              <h6 className="text-white col-md">Chats</h6>
              <h6 className="text-white col-md" onClick={() => createRoomForGroup()}>Add Groups</h6>

            </div>


            {hideSearch == true &&  (
              <div className="input-group mb-2 rounded-pill border border-white">
                <input onChange={(e) => saveOnChange(e.target.value)} type="text" className="border-0 bg-transparent text-white form-control" placeholder="Search here" aria-label="Search here" aria-describedby="button-addon2" />
                {/* <button className="btn bg-transparent" type="button" id="button-addon2"><i className="bi text-white bi-search" /></button> */}
              </div>
            )}

          </div>


          {showContact === false &&
            <div className="users ">
              {
                allRoomAndGroup && allRoomAndGroup.length > 0 && allRoomAndGroup.map((user, index) => (
                  <div>
                    <div className="card mb-1" onClick={() => { joinChat1(user.name, user.roomId, user.type,user.members) }} key={index}>

                      <div className="card-body d-flex align-items-center">
                        <div className="profile-image">{user.roomType == "individual" ? <img src="../images/logo.png" className='chat-profile-img' alt="" /> : <img src="../images/logo.png" className='chat-profile-img' alt="" />}</div>
                        <div className="username">
                          <p className="fw-semibold mb-" >{user.name}</p>

                        </div>
                      </div>
                      {user.unreadMsg!=0&&
                       <div key={index}  className='unread-msg-count'>
                       {user.unreadMsg}

                     </div>
                      }

                     

                    </div>




                  </div>



                ))}

            </div>


          }








          {showContact === true &&
            <div className="users ">
              {getAllChatData && getAllChatData.length > 0 && getAllChatData.map((user, index) => (


                <div className="card mb-1" onClick={(e) => { joinChat(user) }}>

                  <div className="card-body d-flex align-items-center">
                    <div className="profile-image"><img src="../images/logo.png" className='chat-profile-img' alt="" /></div>
                    <div className="username">
                      <p className="fw-semibold mb-" >{user.first_name + " " + user.last_name}</p>
                      <small className="text-secondary">{user.empId}</small>
                    </div>
                  </div>

                </div>

              ))}

            </div>
          }

          {showContact === false &&
            <div className='person-icon' onClick={() => setShowContact(!showContact)}>
              <i className="fa fa-2x fa-users users-icon" aria-hidden="true"></i>
            </div>


          }




          <CModal
            show={showAddGroup}
            onClose={() => setShowAddGropp(!showAddGroup)}
            size="lg"
          >



            <div className="modal-content">
              <CModalHeader className="modal-header" closeButton>
                <h5 className="modal-title text-orange" id="exampleModalLabel">Create Group</h5>

              </CModalHeader>
              <div className="modal-body">
                <div className='row m-4'>
                  <label>Enter Group Name</label>
                  <input className='form-control' value={groupName} onChange={(e) => setGroupName(e.target.value)}></input>
                </div>
                <div className='row m-4'>
                  <label>Enter Group Description</label>
                  <input type="textarea" className='form-control' value={groupDesp} onChange={(e) => setGroupDesp(e.target.value)}></input>
                </div>
                <div className="input-group mb-2 rounded-pill border">
                  <input type="text" onChange={(e) => saveOnChange(e.target.value)} className="border-0 bg-transparent  form-control" placeholder="Search here" aria-label="Search here" aria-describedby="button-addon2" />
                  <button className="btn bg-transparent" type="button" id="button-addon2"><i className="bi bi-search" /></button>
                </div>
                <div className="users p-2" style={{ height: "250px", scrollBehavior: "auto" }}>
                  {getAllChatData && getAllChatData.length > 0 && getAllChatData.map((user, index) => (


                    <div className="card mb-1" >


                      <div className="card-body d-flex align-items-center" onClick={() => AddMemberInGroup(user.id, user.first_name,user.device_id&&user.device_id)}>
                        <div className="profile-image"><img src="../images/logo.png" alt="" /></div>
                        <div className="username">
                          <p className="fw-semibold mb-" >{user.first_name + " " + user.last_name}</p>
                          <small className="text-secondary">{user.empId}</small>
                          {groupMember && groupMember.length > 0 && groupMember.map((item) => item.id).includes(user.id) &&
                            <i className="fa fa-2x fa-check-circle" style={{ color: "green" }} aria-hidden="true"></i>

                          }
                        </div>
                      </div>

                    </div>

                  ))}

                </div>
              </div>

            </div>



            <CModalFooter>
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setShowAddGropp(!showAddGroup)}>Close</button>
              <button type="button" className="btn btn-primary" onClick={createGroup}>Create</button>
            </CModalFooter>
          </CModal>
          <div className="modal fade" id="sendaudio" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <h5 className="text-orange">Send Audio</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body d-flex flex-column align-items-center justify-content-between">
                  <i className="bi bi-mic-fill text-white" />
                  <h5 className>Click to record audio</h5>
                </div>
                <div className="modal-footer mx-auto">
                  <button type="button" className="btn">Send</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {chatScreen == true && (
        <MainChat sendFile={sendFile} sendMessage={sendMessage} friend={friend} currentUser={currentUser} navigate={navigate} roomId={roomId} messages={messages} />
      )}
    </div>
  )
}

export default People