import React from 'react'
import API from 'src/utils/apiCalling';
import { toast, ToastContainer } from 'react-toastify';
import { useState, useEffect } from 'react';
import { CWidgetIcon, CCol, CRow } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';
import { CustButton } from 'src/reusable';
import {
  CNav,
  CDataTable,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CButton,
  CLabel,
  CTextarea,
  CFormGroup,
  CCollapse
} from '@coreui/react';
import swal from 'sweetalert';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { config } from 'src/utils/apiUrl';
// import SoftwareModalAssign from './softwaremodalassgn';
import { useNotificationRemovedFromChannelListener } from 'stream-chat-react';
import { request } from 'http';

const Mysoftwareassets = () => {
  let api = new API();
  const [step, setStep] = useState(1)
  const [active, setActive] = useState(0);
  const [softwaremodellist, setSoftwaremodellist] = useState();
  const [appliedrequestlist, setAppliedrequestlist] = useState();
  const [softwareassignedtouser, setSoftwareassignedtouser] = useState();
  const [licensenames, setLicensenames] = useState();
  const [requestdetails, setRequestdetails] = useState({
    emp_id: getLocalDataAsObject('user')[0].emp_id,
    software_model_id: '',
    license_id: '',
    version: '',
    edition: '',
    priority: '',
    purpose: '',



  });
  useEffect(() => {
    getsoftwaremodels();
    getappliedrequest();
    getuserassignedsoftware();
  }, []);

  const fields7 = [
    { label: 'Emp ID', key: 'emp_id', _style: { width: '5%' } },
    { label: 'Name', key: 'name', _style: { width: '5%' } },
    { label: 'Software Name ', key: 'software_name', _style: { width: '5%' }, },
    { label: 'Version', key: 'version', _style: { width: '5%' } },
    { label: 'Edition', key: 'edition', _style: { width: '5%' } },
    { label: 'Priority', key: 'priority', _style: { width: '5%' } },
    { label: 'Requested Date', key: 'Requested_date', _style: { width: '5%' } },
    { label: 'Status', key: 'status', _style: { width: '5%' } },
    { label: 'Action', key: 'show_details', _style: { width: '10%' } },
  ];
  const mapStatus = (item) => {
    if (item.is_rejected == "1") {
      return 'Deleted';
    } else if (item.is_assigned == "1") {
      return 'Completed';
    } else if (item.is_pending == "1") {
      return 'Pending';
    } else {
      return 'Unknown';
    }
  };
  const fields2 = [
    { label: 'Emp ID', key: 'emp_id', _style: { width: '5%' } },
    { label: 'Name', key: 'name', _style: { width: '5%' } },
    { label: 'Software Name ', key: 'software_name', _style: { width: '5%' }, },
    { label: 'Version', key: 'version', _style: { width: '5%' } },
    { label: 'Edition', key: 'edition', _style: { width: '5%' } },
    { label: 'Priority', key: 'priority', _style: { width: '5%' } },
    { label: 'Requested Date', key: 'Requested_date', _style: { width: '5%' } },
    { label: 'Status', key: 'status', _style: { width: '5%' } },
    { label: 'Action', key: 'show_details', _style: { width: '10%' } },
  ];
  const mapStatus2 = (item) => {
    if (item.is_rejected == "1") {
      return 'Deleted';
    } else if (item.is_assigned == "1") {
      return 'Completed';
    } else if (item.is_pending == "1") {
      return 'Pending';
    } else {
      return 'Unknown';
    }
  };
  const getsoftwaremodels = async () => {
    let result = await api.get(config.getSoftwareModelAsset);
    if (result && result.code === 200) {

      setSoftwaremodellist(result.data.data)

    }
  }

  const getappliedrequest = async () => {
    let result = await api.get(config.getSoftwareAssignedAndPendingForUser);
    if (result && result.code === 200) {
      console.log("Emp Id", result.data)

      setAppliedrequestlist(result.data)

    }
  }
  const onChangerequest = async (e) => {
    if (e.target.name === "software_model_id") {
      let data = {
        software_model_id: e.target.value
      };
      // console.log("Data",data)
      let result = await api.post(config.getSoftwareAddentitlementBySoftwareModalId, data);
      console.log(result)
      if (result && result.code === 200) {
        console.log("Removal License", result)
        setLicensenames(result.data?.softwareAddentitlement)
        requestdetails.version = result.data?.version
        requestdetails.edition = result.data?.edition

      } else {
        toast.error(result.message);
      }


    }
    const { name, value } = e.target;

    setRequestdetails({
      ...requestdetails,
      [name]: value,
    });
  };
  const resetfunrequest = () => {
    setRequestdetails({
      emp_id: getLocalDataAsObject('user')[0].emp_id,
      software_model_id: '',
      license_id: '',
      version: '',
      edition: '',
      priority: '',
      purpose: '',
    })
  }
  const getuserassignedsoftware = async () => {
    console.log("localstoragedata", getLocalDataAsObject('user')[0])
    let data = {
      emp_id: getLocalDataAsObject('user')[0].emp_id,
    };
    console.log("Data", data)
    let result = await api.post(config.getSoftwareAssignedForUser, data);
    if (result && result.code === 200) {

      setSoftwareassignedtouser(result.data)
    } else {

    }
  };
  const addsoftwarerequest = async () => {
    let data = requestdetails;
    console.log("Data", data)
    let result = await api.post(config.addSoftwareRequestByUser, data);
    if (result && result.code === 200) {
      toast.success(result.message)
      // getremovedassests()
      getappliedrequest()
      resetfunrequest();
    } else {
      toast.error(result.message);
    }
  };
  const deleteassetrequest = async (item) => {
    {
      console.log("item", item)
      let data = {
        id: item.id,
        // type:"rejected",

      };

      swal({
        html: true,
        title: 'Are you sure?',
        text: `Are you sure you want to delete this Request ?`,
        icon: 'success',
        buttons: ['No, cancel it!', 'Delete'],
        dangerMode: true,
      }).then(async function (isConfirm) {
        if (isConfirm) {

          let result = await api.post(config.deleteSoftwareModelAsset, data);
          if (result && result.code === 200) {
            toast.success(result.message);
            getsoftwaremodels()
            //   isViewModalOpen=false;
            //   getReceivedAssetsRequest();
            // isViewModalOpen(!isOpen)
          } else {
            toast.warning(result.message);
          }
        }
      });
    };
  };
  return (<>
    <CRow>
      <CCol xs="18" sm="4" lg="4">
        <CWidgetIcon
          text="Software Asset Request"
          header={
            appliedrequestlist?.length ? appliedrequestlist?.length : '4'
          }
          onClick={() => setStep(1)}
          color="info"
          iconPadding={false}
        >
          <i className="fa fa-desktop fa-lg" />
        </CWidgetIcon>
      </CCol>
      <CCol xs="18" sm="4" lg="4">
        <CWidgetIcon
          text="Assigned Softwares"
          header={
            softwareassignedtouser?.length ? softwareassignedtouser?.length : '0'}
          onClick={() => setStep(2)}
          color="success"
          iconPadding={false}
        >
          <i className="fa fa-database fa-lg" />
        </CWidgetIcon>
      </CCol>
      {/* <CCol xs="18" sm="4" lg="3">
          <CWidgetIcon
            text="Pending Request"
            header={
              pendingrequestdata?.length ? pendingrequestdata?.length : '0'
            }
            onClick={() => setStep(1)}
            color="danger"
            iconPadding={false} 
          >
            <i className="fa fa-pencil-square-o fa-lg" />
          </CWidgetIcon>
        </CCol> */}

    </CRow>

    {step && step === 1 &&
      <div className="container">
        <CRow>
          <CCol xs="12">
            <CCard>
              <CCardHeader>
                <strong>Software Assest Requests</strong>
              
              </CCardHeader>
              <CCardBody>
                <CTabs
                  activeTab={active}
                  onActiveTabChange={idx => setActive(idx)}
                >
                  <CNav variant="tabs">
                    <CNavItem>
                      <CNavLink >
                        Applied Requests
                      </CNavLink>
                    </CNavItem>
                    <CNavItem>
                      <CNavLink >
                        + New Request
                      </CNavLink>
                    </CNavItem>
                    {/* <CNavItem>
                  <CNavLink >
                    Entitlement Summary
                  </CNavLink>
                </CNavItem> */}
                  </CNav>

                  <CTabContent>
                    <CTabPane>
                      <CDataTable
                        items={appliedrequestlist}
                        fields={fields7}
                        tableFilter={{ label: 'Filter:', placeholder: 'Search' }}
                        itemsPerPageSelect
                        itemsPerPage={10}
                        hover
                        pagination
                        scopedSlots={{
                          priority: (item, index) => {
                            return (
                              <td>
                                {item.priority}
                                <br />
                                {item && item.is_assign_by_admin === 1 && (
                                  <small
                                    className="badge badge-secondary"
                                    style={{ color: 'red' }}
                                  >
                                    {' '}
                                    Assing by HR
                                  </small>
                                )}
                              </td>
                            );
                          },
                          status: (item, index) => {
                            return <td>{mapStatus(item)}</td>;
                          },
                          show_details: (item, index) => {
                            return (
                              <CRow>
                                {/* <CCol xs="12" lg="4" md="4">
                              <CustButton
                                type="button"
                                name="ok"
                                text=""
                                color="success"
                                // onClick={() => isViewModalOpen(item)}
                                className="btn-loading btn-ladda mr-3"
                                icon="fa fa-eye"
                                title="View"
                              />
                            </CCol> */}
                                {/* <CCol xs="12" lg="4" md="4">
                              <CustButton
                                type="button"
                                name="ok"
                                text=""
                                color="primary"
                                // onClick={() => isViewModalOpenAssign(item)}
                                className="btn-loading btn-ladda mr-3"
                                icon="fa fa-tasks"
                                title="Assign"
                              />
                            </CCol> */}
                                <CCol xs="12" lg="4" md="4">
                                  <CustButton
                                    type="button"
                                    name="ok"
                                    text=""
                                    color="danger"
                                    onClick={() =>
                                      deleteassetrequest(item)
                                    }
                                    className="btn-loading btn-ladda mr-3"
                                    icon="fa fa-ban"
                                    title="Delete"
                                  />
                                </CCol>
                              </CRow>
                            );
                          },
                        }}
                      />
                    </CTabPane>
                    <CTabPane>
                      <CCard>


                        <CCardBody>
                          <AvForm
                            action=""
                            method="post"
                            encType="multipart/form-data"
                            className="form-horizontal"
                            onValidSubmit={addsoftwarerequest}
                          >
                            <CFormGroup row>
                              <CCol
                                md="12"
                                style={{ display: 'block', textAlign: 'right' }}
                              >
                                <CLabel
                                  style={{ fontSize: '14px', color: 'red' }}
                                >
                                  * Indicates Mandatory Fields
                                </CLabel>
                              </CCol>
                              <CCol md="3">
                                <CLabel>
                                  <strong>Emp Id</strong>
                                  <b style={{ color: 'red' }}>*</b>
                                </CLabel>
                              </CCol>
                              <CCol xs="12" md="9">
                                <AvInput
                                  required
                                  custom
                                  id=""
                                  name="emp_id"
                                  value={requestdetails.emp_id}
                                  onChange={onChangerequest}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Enter host name',
                                    },
                                  }}
                                ></AvInput>

                              </CCol>
                            </CFormGroup>

                            <CFormGroup row>
                              <CCol md="3">
                                <CLabel>
                                  <strong>Software Name </strong>
                                  <b style={{ color: 'red' }}>*</b>
                                </CLabel>
                              </CCol>
                              <CCol xs="12" md="9">

                                <AvInput
                                  type="select"
                                  custom
                                  id=""
                                  name="software_model_id"
                                  value={requestdetails.software_model_id}
                                  onChange={onChangerequest}
                                >
                                  <option hidden>
                                    Select Software{' '}
                                  </option>

                                  {softwaremodellist &&
                                    softwaremodellist.map((list, index) => (
                                      <option value={list.id}>
                                        {list.software_name}
                                      </option>
                                    ))}
                                  <option value={10}>Other</option>
                                </AvInput>
                              </CCol>
                            </CFormGroup>
                            <CFormGroup row>
                              <CCol md="3">
                                <CLabel>
                                  <strong>License Name</strong>
                                  <b style={{ color: 'red' }}>*</b>
                                  {/* <p>(If Any)</p> */}
                                </CLabel>
                              </CCol>
                              <CCol xs="12" md="9">
                                <AvInput
                                  type="select"
                                  required
                                  custom
                                  id=""
                                  name="license_id"
                                  value={requestdetails.license_id}
                                  onChange={onChangerequest}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Enter asset type',
                                    },
                                  }}
                                >
                                  <option hidden>
                                    Select License{' '}
                                  </option>

                                  {licensenames &&
                                    licensenames.map((list, index) => (
                                      <option value={list.id}>
                                        {list.license_name}
                                      </option>
                                    ))}
                                  {/* <option hidden>Select Asset Type.....</option>
                              ""}]]']']]]]]]]]]]]]]]'''''''']""den>Select Device Address</option>
                              <option value="B36"> B36</option>
                              <option value="A23"> A23</option>
                              <option value="A45"> A45</option> */}
                                </AvInput>
                              </CCol>
                            </CFormGroup>
                            <CFormGroup row>
                              <CCol md="3">
                                <CLabel>
                                  <strong>Version</strong>
                                  <b style={{ color: 'red' }}>*</b>
                                </CLabel>
                              </CCol>
                              <CCol xs="12" md="9">

                                <AvInput
                                  required
                                  custom
                                  id=""
                                  name="version"
                                  value={requestdetails.version}
                                  onChange={onChangerequest}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Enter host name',
                                    },
                                  }}
                                ></AvInput>
                              </CCol>
                            </CFormGroup>

                            <CFormGroup row>
                              <CCol md="3">
                                <CLabel>
                                  <strong>Edition</strong>
                                  <b style={{ color: 'red' }}>*</b>
                                </CLabel>
                              </CCol>
                              <CCol xs="12" md="9">

                                <AvInput
                                  required
                                  custom
                                  id=""
                                  name="edition"
                                  value={requestdetails.edition}
                                  onChange={onChangerequest}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Enter host name',
                                    },
                                  }}
                                ></AvInput>
                              </CCol>
                            </CFormGroup>

                            <CFormGroup row>
                              <CCol md="3">
                                <CLabel>
                                  <strong>Priority</strong>
                                  <b style={{ color: 'red' }}>*</b>
                                </CLabel>
                              </CCol>
                              <CCol xs="12" md="9">
                                <AvInput
                                  type="select"
                                  required
                                  custom
                                  id=""
                                  name="priority"
                                  value={requestdetails.priority}
                                  onChange={onChangerequest}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Enter asset type',
                                    },
                                  }}
                                >
                                  <option hidden>Select Device Type</option>
                                  <option value="Urgent"> Urgent</option>
                                  <option value="High"> High</option>
                                  <option value="Normal"> Normal</option>
                                  <option value="Low"> Low</option>
                                </AvInput>
                              </CCol>
                            </CFormGroup>
                            <CFormGroup row>
                              <CCol md="3">
                                <CLabel>
                                  <strong>Purpose </strong>
                                  <b style={{ color: 'red' }}>*</b>
                                </CLabel>
                              </CCol>
                              <CCol xs="12" md="9">
                                <AvInput
                                  required
                                  custom
                                  id=""
                                  name="purpose"
                                  value={requestdetails.purpose}
                                  onChange={onChangerequest}
                                  validate={{
                                    required: {
                                      value: false,
                                      errorMessage: 'Enter asset modal',
                                    },
                                  }}
                                ></AvInput>
                              </CCol>
                            </CFormGroup>
                            {/* <CFormGroup row>
                          <CCol md="3">
                            <CLabel>
                              <strong>Undertaking </strong>
                              <b style={{ color: 'red' }}>*</b>
                            </CLabel>
                          </CCol>
                          <CCol xs="12" md="9">
                            <AvInput
                              custom
                              id=""
                              name="undertaking"
                              value={addAssetDetails.undertaking}
                              onChange={onChangeFun}
                              validate={{
                                required: {
                                  value: false,
                                  errorMessage:
                                    'Enter asset undertaking status',
                                },
                              }}
                            ></AvInput>
                          </CCol>
                        </CFormGroup> */}

                            <CCardFooter>
                              <button
                                type="submit"
                                className="btn btn-primary mr-3 mt-2"
                              >
                                Add Asset Request
                              </button>
                              <button
                                type="reset"
                                className="btn btn-danger mr-3 mt-2"
                                onClick={resetfunrequest}
                              >
                                Reset
                              </button>
                            </CCardFooter>
                          </AvForm>
                        </CCardBody>
                      </CCard>
                    </CTabPane>
                  </CTabContent>
                </CTabs>
                <CCardFooter></CCardFooter>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>

      </div>}
    {step && step === 2 &&
      <div className="container">
        <CRow>
          <CCol xs="12">
            <CCard>
              <CCardHeader>
                <strong>Assigned Softwares</strong>
              </CCardHeader>
              <CCardBody>
                <CTabs
                  activeTab={active}
                  onActiveTabChange={idx => setActive(idx)}
                >


                  <CTabContent>
                    <CTabPane>
                      <CDataTable
                        items={softwareassignedtouser}
                        fields={fields2}
                        tableFilter={{ label: 'Filter:', placeholder: 'Search' }}
                        itemsPerPageSelect
                        itemsPerPage={10}
                        hover
                        pagination
                        scopedSlots={{
                          priority: (item, index) => {
                            return (
                              <td>
                                {item.priority}
                                <br />
                                {item && item.is_assign_by_admin === 1 && (
                                  <small
                                    className="badge badge-secondary"
                                    style={{ color: 'red' }}
                                  >
                                    {' '}
                                    Assing by HR
                                  </small>
                                )}
                              </td>
                            );
                          },
                          status: (item, index) => {
                            return <td>{mapStatus(item)}</td>;
                          },
                          show_details: (item, index) => {
                            return (
                              <CRow>
                                <CCol xs="12" lg="4" md="4">
                                  <CustButton
                                    type="button"
                                    name="ok"
                                    text=""
                                    color="success"
                                    // onClick={() => isViewModalOpen(item)}
                                    className="btn-loading btn-ladda mr-3"
                                    icon="fa fa-eye"
                                    title="View"
                                  />
                                </CCol>
                                <CCol xs="12" lg="4" md="4">
                                  <CustButton
                                    type="button"
                                    name="ok"
                                    text=""
                                    color="primary"
                                    // onClick={() => isViewModalOpenAssign(item)}
                                    className="btn-loading btn-ladda mr-3"
                                    icon="fa fa-tasks"
                                    title="Assign"
                                  />
                                </CCol>
                                <CCol xs="12" lg="4" md="4">
                                  <CustButton
                                    type="button"
                                    name="ok"
                                    text=""
                                    color="danger"
                                    // onClick={() =>
                                    //   isJustificationModalOpen(item)
                                    // }
                                    className="btn-loading btn-ladda mr-3"
                                    icon="fa fa-ban"
                                    title="Reject"
                                  />
                                </CCol>
                              </CRow>
                            );
                          },
                        }}
                      />
                    </CTabPane>
                    <CTabPane>

                    </CTabPane>
                  </CTabContent>
                </CTabs>
                <CCardFooter></CCardFooter>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>

      </div>}
  </>)
}

export default Mysoftwareassets