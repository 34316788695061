import { createAction } from 'redux-act';
const verifyOtpRequestAction = createAction(
  'REQUEST_VERIFY_OTP',
  data => data,
);
const verifyOtpSuccessAction = createAction(
  'SUCCESS_VERIFY_OTP',
  data => data,
);
const verifyOtpFailureAction = createAction(
  'FAILURE_VERIFY_OTP',
  data => data,
);

const sendOtpRequestAction = createAction(
  'REQUEST_SEND_OTP',
  data => data,
);
const sendOtpSuccessAction = createAction(
  'SUCCESS_SEND_OTP',
  data => data,
);
const sendOtpFailureAction = createAction(
  'FAILURE_SEND_OTP',
  data => data,
);

const handleClearOtpStore = createAction(
  'HANDLE_CLEAR_OTP_STORE',
  data => data,
);

export default {
  verifyOtpRequestAction,
  verifyOtpSuccessAction,
  verifyOtpFailureAction,
  sendOtpRequestAction,
  sendOtpSuccessAction,
  sendOtpFailureAction,
  handleClearOtpStore,
};
