import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CFormText,
  CLabel,
  CCardFooter,
  CBadge,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CCollapse,
  CRow,
  CDataTable,
  CModal, CModalHeader, CModalTitle, CModalFooter, CModalBody,
  CFooter
} from '@coreui/react';
import { Button } from 'reactstrap';
import { AvForm, AvInput, AvField } from 'availity-reactstrap-validation';
import React, { useState, useEffect } from 'react';
import { TheFooter, TheHeader, TheSidebar } from 'src/containers';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { toast, ToastContainer } from 'react-toastify';
import { CustMultiSelect } from '../../../reusable';
import swal from 'sweetalert';
import Alert from 'src/alert/alert';
import { async } from '@firebase/util';
import moment from 'moment';
import { MultiSelect } from 'react-multi-select-component';

const AddHoliday = () => {
  const api = new API();
  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [isBranches, setIsBranches] = useState(false);
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState([]);
  const [modelButton, setModelButton] = useState("create");
  const [active, setActive] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [details, setDetails] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [visible, setVisible] = useState(false);
  const [holidayList, setHolidayList] = useState([])
  const [holidayData, setHolidayData] = useState({
    holiday_name: '',
    holiday_from_date: '',
    holiday_to_date: '',
    state: '',
    branch: '',
  });

  const getStates = async () => {
    let result = await api.get(config.getStates);
    if (result && result.code === 200) {
      let temp = [];
      result?.data?.map(data => {
        temp.push({
          value: data?.id,
          label: data?.state,
        });
      });
      setStates(temp);
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  const handleOnSelectOptions = async (data) => {
    console.log(data)
    if (data == '') {
      setIsBranches(false);
    }
    setState(data);
    let temp = [];
    data.map(data => {
      temp.push(data?.value);
    });
    setHolidayData({...holidayData,state:temp?.toString()})
    if (temp.length) {
      let payload = {
        stateIds: temp.toString(),
      };
      let result = await api.get(config.getBranches, true, payload);
      if (result && result.code === 200 && result?.data?.length) {
        setIsBranches(true);
        let temp1 = [];
        result?.data?.map(data => {
          temp1.push({
            value: data?.id,
            label: data?.branch_name + ' (' + data?.city + ')',
          });
        });
        setBranches(temp1);
      } else {
        setIsBranches(false);
      }
    }
  };

  const handleOnSelectOptions1 = async (data) => {
    setBranch(data);
    let temp = [];
    data.map(data => {
      temp.push(data?.value);
    });
    setHolidayData({...holidayData,branch:temp?.toString()})
  };

  const onInputChange = e => {
    let { name, value } = e.target;
    setHolidayData({ ...holidayData, [name]: value });
  };

  const submitHolidayDetails = async () => {
    if (modelButton !== "edit") {
      let result = await api.post(config.addHolidays, holidayData);
      if (result && result.code == 200) {
        setVisible(false)
        toast.success(result?.message);
        getAllHoliDay()
        reset()
      } else {
        toast.error(result?.message);
      }
    } else {
      let result = await api.post(config.updateHolidays, holidayData)
      if (result.code === 200) {
        toast.success(result?.message);
        setVisible(false)
        getAllHoliDay()
      } else {
        toast.error(result?.message);
      }
    }
  };

  const reset = () => {
    setVisible(false)
    setHolidayData({
      holiday_name: '',
      holiday_from_date: '',
      holiday_to_date: '',
      state: '',
      branch: '',
    })
    setBranch([]);
    setState([]);
    setModelButton("create")
  }

  const isViewModalOpen1 = (item) => {
    let location_id = [];
    let state = [];
    let branch_id = [];
    let branch_name = [];
    setVisible(true)
    setModelButton("edit")
    item?.location?.length > 0 ? setIsBranches(true) : setIsBranches(false)
    item?.location != '' && item?.location?.map((data) => {
      location_id?.push({value:data})
    })
    item?.state != '' && item?.state?.map((data) => {
      state?.push({label:data})
    })
    let combined_state = location_id?.map(function(item, index) {
      return {value: parseFloat(item.value), label: state[index].label};
    });
    item?.branch != '' && item?.branch?.map((data) => {
      branch_id?.push({value:data})
    })
    item?.branch_name != '' && item?.branch_name?.map((data) => {
      branch_name?.push({label:data})
    })
    let combined_branch = branch_id?.map(function(item, index) {
      return {value: parseFloat(item.value), label: branch_name[index].label};
    });
    console.log("combined",combined_state)
    combined_state?.length > 0 ? handleOnSelectOptions(combined_state) : 
    setState(combined_state)
    setBranch(combined_branch)
    setHolidayData({
      holiday_name: item?.holiday,
      holiday_from_date: moment(item?.holiday_from,"DD-MM-YYYY").format("YYYY-MM-DD"),
      holiday_to_date: moment(item?.holiday_to,"DD-MM-YYYY").format("YYYY-MM-DD"),
      state: combined_state.map(data => data?.value),
      branch: combined_branch.map(data => data?.value),
      id: item?.id
    })
  };

  useEffect(() => {
    getAllHoliDay();
  }, []);
  const getAllHoliDay = async () => {
    let data = {
      type: "all",
    }
    let result = await api.get(config.getHolidayList,true, data);
    if (result && result.code == 200) {
      setHolidayList(result.data.holidays_list)
    }
  };


  const isViewModalOpen = () => {
    setModelButton("create")
    setHolidayData({
      holiday_name: '',
      holiday_from_date: '',
      holiday_to_date: '',
      state: '',
      branch: '',
    })
    setBranch([]);
    setState([]);
    setVisible(!visible)
    setIsOpen(true);
  };


  const isViewModalOpen2 = async item => {
    accountStatusChange(item)
    setIsOpen2(!isOpen2);
  };

  const fields = [
    {
      key: 'sn',
      label: 'S.N.',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
    { key: 'holiday_from', _style: { width: '10%' } },
    { key: 'holiday_to', _style: { width: '10%' } },
    { key: 'day', _style: { width: '10%' } },
    { key: 'holiday', _style: { width: '10%' } },
    { key: 'days', _style: { width: '10%' } },

    {
      key: 'update_details',
      label: 'Action',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
    {
      key: 'update_softwares',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ];

  const accountStatusChange = (status) => {
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to delete user ${status.holiday}`,
      icon: 'success',
      buttons: ['No, cancel it!', `Delete`],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.delete(config.delete_holidays, { id: status.id });
        if (result && result.code == 200) {
          getAllHoliDay()
          toast.success(`successfully ${status.holiday} deleted`);
        } else {
          toast.error(result.message);
        }
      }
    });
  };

  const isAlertToggle = msg => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
  };
  const isAlertToggleClose = () => {
    setIsAlert(false);
  };



  const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  return (
    <div>
      <div className="c-app c-default-layout">
        <div className="c-wrapper">
          <div className="c-body">

            <div className="container mt-3">
              <div className='text-end'>
                <CButton onClick={() => isViewModalOpen()} className="btn btn-info mb-3">
                  Add Holiday
                </CButton>
              </div>
              <CRow>
                <CCol xs="12">
                  <CCard>
                    <CCardHeader>
                      <strong> Holiday List</strong>
                    </CCardHeader>
                    <CCardBody>
                      <CTabs
                        activeTab={active}
                        onActiveTabChange={idx => setActive(idx)}
                      >
                        <CNav variant="tabs">
                          {/* <CNavItem>
                      <CNavLink>All Accounts Status</CNavLink>
                    </CNavItem> */}
                        </CNav>
                        <CTabContent>
                          <CTabPane>
                            <CDataTable
                              items={holidayList}
                              fields={fields}
                              columnFilter
                              tableFilter={{ label: 'Filter:', placeholder: 'Search' }}
                              // footer
                              itemsPerPageSelect
                              itemsPerPage={5}
                              hover
                              sorter
                              pagination
                              scopedSlots={{
                                sn: (item, index) => {
                                  return <td>{index + 1}</td>;
                                },
                                update_details: (item) => {
                                  return (
                                    <td className="py-2">
                                      <CButton
                                        color="primary"
                                        shape="square"
                                        size="sm"
                                        onClick={(e) => isViewModalOpen1(item)}
                                      >
                                      <i className="fa fa-edit" aria-hidden="true"></i>&nbsp;
                                        Edit
                                      </CButton>
                                    </td>
                                  );
                                },
                                update_softwares: (item) => {
                                  return (
                                    <td className="py-2">
                                      <CButton
                                        color="danger"
                                        shape="square"
                                        size="sm"
                                        onClick={() => isViewModalOpen2(item)}
                                      >
                                      <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;
                                        Delete
                                      </CButton>
                                    </td>
                                  );
                                },
                              }}
                            />
                          </CTabPane>
                        </CTabContent>
                      </CTabs>
                      <CCardFooter>
                      </CCardFooter>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>

              <CModal
                size='lg'
                show={visible}
                onClose={() => setVisible(false)}
              >
                <CModalHeader closeButton onClose={() => setVisible(false)}>
                  <CModalTitle id="LiveDemoExampleLabel">
                    {modelButton === "create" ? "Create New Holiday" : "Edit Holiday"}
                  </CModalTitle>
                </CModalHeader>
                <CModalBody>
                  <div>
                    <CCardBody>
                      <AvForm
                        action=""
                        method="post"
                        encType="multipart/form-data"
                        className="form-horizontal"
                        onValidSubmit={submitHolidayDetails}
                      >
                        <CFormGroup row>
                          <CCol md="3">
                            <CLabel htmlFor="">Holiday Name <span style={{ color: "red" }}>*</span></CLabel>
                          </CCol>
                          <CCol xs="12" md="9">
                            <AvInput
                              placeholder="Enter holiday name"
                              name="holiday_name"
                              id="Holiday Name"
                              value={holidayData.holiday_name}
                              onChange={e => onInputChange(e)}
                              required
                            />
                            {/* <CFormText>Please enter Holiday name</CFormText> */}
                          </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                          <CCol md="3">
                            <CLabel htmlFor="">From Date <span style={{ color: "red" }}>*</span></CLabel>
                          </CCol>
                          <CCol xs="12" md="9">
                            <AvInput
                              type="date"
                              name="holiday_from_date"
                              id="Holiday From Date"
                              value={holidayData?.holiday_from_date}
                              onChange={e => onInputChange(e)}
                              required
                            />
                            <CFormText>
                              Please enter holiday from date
                            </CFormText>
                          </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                          <CCol md="3">
                            <CLabel htmlFor="">To Date <span style={{ color: "red" }}>*</span></CLabel>
                          </CCol>
                          <CCol xs="12" md="9">
                            <AvInput
                              type="date"
                              name="holiday_to_date"
                              id="Holiday To Date"
                              value={holidayData.holiday_to_date}
                              onChange={e => onInputChange(e)}
                              required
                            />
                            <CFormText>Please enter holiday to date</CFormText>
                          </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                          <CCol md="3">
                            <CLabel htmlFor="">State <span style={{ color: "red" }}>*</span></CLabel>
                          </CCol>
                          <CCol xs="12" md="9">
                            {!state && (
                            <MultiSelect
                              required
                              onChange={handleOnSelectOptions}
                              value={state}
                              options={states}
                            />)}
                            {state && (
                            <MultiSelect
                              required
                              onChange={handleOnSelectOptions}
                              value={state}
                              options={states}
                            />)}
                            <CFormText>Please select states</CFormText>
                          </CCol>
                        </CFormGroup>
                        {isBranches && (
                          <CFormGroup row>
                            <CCol md="3">
                              <CLabel htmlFor="">Branch <span style={{ color: "red" }}>*</span></CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                              {!branch && (
                              <MultiSelect
                                required
                                onChange={handleOnSelectOptions1}
                                value={branch}
                                options={branches}
                              />)}
                              {branch && (
                              <MultiSelect
                                required
                                onChange={handleOnSelectOptions1}
                                value={branch}
                                options={branches}
                              />)}
                              <CFormText>Please select branches</CFormText>
                            </CCol>
                          </CFormGroup>
                        )}
                        <CFooter style={{ justifyContent: "end" }}>
                          <Button type="submit" size="md" color="primary" style={{ marginTop: "10px" }}>
                            Save
                          </Button>
                        </CFooter>
                      </AvForm>
                    </CCardBody>
                    <ToastContainer />
                  </div>
                </CModalBody>
              </CModal>
            </div>


            <Alert
              isAlert={isAlert}
              isAlertToggle={isAlertToggle}
              alertMessage={alertMessage}
              isAlertToggleClose={isAlertToggleClose}
            />


          </div>
        </div>
      </div>
    </div>
  );
};
export default AddHoliday;
