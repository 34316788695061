import { attendanceCorrectionSchema } from './ValidationSchema';
import appConstants from '../core/configs/Constants.config';

const emailRegix = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const panNumRegex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
const onlyDigitRegix = /^\d+$/;
const mobileRegix =/^(?!.*(\d)\1{4})[6-9]\d{9}$/
const nameRegix =/^[a-zA-Z\s]+$/
const regexPANRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
const scriptRegex = /<script[^>]*>[\s\S]*?<\/script>|<[^>]+>/gi;

const digitsOnly = value => {
  return onlyDigitRegix.test(value);
};
const validatePanNumber = value => {
  return panNumRegex.test(value);
};
const validateResponse = () => {
  return { status: 401, msg_code: 401, msg: '', isValid: false, errors: null };
};

const isBlank = value => {
  return value === '' || false;
};

const isNull = value => {
  return value === null || false;
};

const isNun = value => {
  return Number.isNaN(value) || false;
};

const isBlankOrNull = value => {
  return value === undefined || isBlank(value) || isNull(value) || false;
};
const validateMobile2 = mobile => {

  return mobileRegix.test(mobile)

};
const validateName = name =>{
  return nameRegix.test(name)
}

const validateMobile = mobile => {
  if (mobile.length === 0) {
    return {
      isValid: false,
      msg: appConstants.validations.fieldReq.replace('$FIELD', 'Mobile No.'),
    };
  }
  if (mobile.length < 10) {
    return {
      isValid: false,
      msg: appConstants.validations.minLength.replace('$1', 10),
    };
  }
  if (mobile.length > 10) {
    return {
      isValid: false,
      msg: appConstants.validations.maxLength.replace('$1', 10),
    };
  }
  return { isValid: true, msg: '' };
};

const validateEmail = email => {
  if (email.length === 0) {
    return {
      isValid: false,
      msg: appConstants.validations.fieldReq.replace('$FIELD', 'Email address'),
    };
  }
  if (!emailRegix.test(email)) {
    return { isValid: false, msg: appConstants.validations.emailInvalid };
  }
  return { isValid: true, msg: '' };
};

const validateSchema = async (schema, data) => {
  const validateDataRes = validateResponse();
  if (!schema) {
    validateDataRes.msg_code = 401;
    validateDataRes.msg = appConstants.validations.allFieldReq;
    return validateDataRes;
  }

  const result = await schema.isValid(data);
  const errorsList = {};
  if (!result) {
    await schema.validate(data, { abortEarly: false }).catch(err => {
      const errors = err.inner.reduce((acc, error) => {
        //console.log({[error.path]: error.message, acc, error});
        return (errorsList[error.path] = error.message);
      }, {});
    });
    validateDataRes.errors = errorsList;
    return validateDataRes;
  }
  validateDataRes.msg_code = 200;
  validateDataRes.status = 200;
  validateDataRes.isValid = true;
  return validateDataRes;
};

const validateAttendanceCorrection = async body => {
  console.log(body);
  const validateDataRes = await validateSchema(
    attendanceCorrectionSchema,
    body,
  );
  return validateDataRes;
};
function checkForScriptTags(input) {
  // const regex = /<script[^>]*>[\s\S]*?<\/script>|<[^>]+>/gi;
  if (scriptRegex.test(input)) {
    // alert("Input contains script tags, which is not allowed.");
    return false;
  }
  return true;
}

export { digitsOnly, validateAttendanceCorrection,validateMobile,validateEmail,validateMobile2,validateName,validatePanNumber ,checkForScriptTags};
