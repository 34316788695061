import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SideBar = ({
  tab,
  setTab,
  showEmployeeList,
  setShowEmployeeList,
  filterEmployeeListFun,
  getEmployeeStatus,
  sideBarData,
}) => {
  const showEmployeeListFun = showEmploeeListStatus => {
    setShowEmployeeList(showEmploeeListStatus);
  };
  return (
    <>
      {tab === 'employee' ? (
        <div className="temsSidebar">
          <div className="d-flex justify-content-between align-items-center mb-3 px-3">
            <div className="d-flex align-items-center gap-2">
              <h2>Overall </h2>
              <label className="toggle-label">
                <input type="checkbox" />
                <span className="back">
                  <span className="toggle" />
                  <span className="label on">#</span>
                  <span className="label off">%</span>
                </span>
              </label>
            </div>

            <span className="showTeam" onClick={() => setTab('manager')}>
              Overall Dashboard
            </span>
          </div>
          <ul className="teamList nav nav-tabs">
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('');
                }}
                className="active"
              >
                <img src="images/icon1.svg" width={29} height={29} />
                Overall Status
              </a>
            </li>

            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('total_present_days');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Total
                Present{' '}
                {/* <strong>
             500 / <b>542</b>
           </strong>{' '} */}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('total_absent');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Total
                Absent{' '}
                {/* <strong>
             29 / <b>542</b>
           </strong>{' '} */}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('timely_in');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Timely In
                {/* <strong>
             140 / <b>500</b>
           </strong>{' '} */}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('late_in');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Late In
                {/* <strong>
             11 / <b>500</b>
           </strong>{' '} */}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('late_in');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Gross
                Working{' '}
                {/* <strong>
             330 / <b>320 hrs</b>
           </strong>{' '} */}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('planned_leaves');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Planned
                Leaves {/* <strong>20</strong>{' '} */}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('unplanned_leaves');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Unplanned
                Leaves
                {/* <strong>9</strong>{' '} */}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('exceed_brake');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Exceed
                Brake
                {/* <strong>12</strong>{' '} */}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('exceed_brake');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Exceed
                Correction
                {/* <strong>18</strong>{' '} */}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('exceed_brake');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Sick Leave
                {/* <strong>12</strong>{' '} */}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('paternity_leave');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Paternity
                Leave
                {/* <strong>2</strong>{' '} */}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('maternity_leave');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Maternity
                Leave
                {/* <strong>3</strong>{' '} */}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('short_leave');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Short
                Leave
                {/* <strong>6</strong>{' '} */}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('correction_approved');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Correction
                Approved
                {/* <strong>80</strong>{' '} */}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  getEmployeeStatus('comp_off');
                }}
              >
                <img src="images/icon1.svg" width={29} height={29} /> Comp-off
                Approved
                {/* <strong>12</strong>{' '} */}
              </a>
            </li>
          </ul>
        </div>
      ) : (
        <div className="temsSidebar">
          <div className="d-flex justify-content-between align-items-center mb-3 px-3">
            <div className="d-flex align-items-center gap-2">
              <h2>Overall </h2>
              <label className="toggle-label">
                <input type="checkbox" />
                <span className="back">
                  <span className="toggle" />
                  <span className="label on">#</span>
                  <span className="label off">%</span>
                </span>
              </label>
            </div>

            <span
              className="showTeam"
              onClick={() => {
                setTab('manager');
                setShowEmployeeList(false);
              }}
            >
              Overall dashboard
            </span>
          </div>
          {sideBarData && sideBarData.length != '0' ? (
            <ul className="teamList nav nav-tabs">
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(false);
                  }}
                  className="active"
                >
                  <img src="images/icon1.svg" width={29} height={29} />{' '}
                  Dashboard
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('total_employee');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} /> Total
                  Employee {/* <strong>542</strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('total_present');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} /> Total
                  Present{' '}
                  {/* <strong>
              500 / <b>542</b>
            </strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('total_absent');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} /> Total
                  Absent{' '}
                  {/* <strong>
              29 / <b>542</b>
            </strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('timely_in');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} /> Timely
                  In
                  {/* <strong>
              140 / <b>500</b>
            </strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('late_in');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} /> Late In
                  {/* <strong>
              11 / <b>500</b>
            </strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('gross_working');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} /> Gross
                  Working{' '}
                  {/* <strong>
              330 / <b>320 hrs</b>
            </strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('planned_leaves');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} /> Planned
                  Leaves {/* <strong>20</strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('unplanned_leaves');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} />{' '}
                  Unplanned Leaves
                  {/* <strong>9</strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('exceed_break');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} /> Exceed
                  Brake
                  {/* <strong>12</strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('exceed_correction');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} /> Exceed
                  Correction
                  {/* <strong>18</strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('sick_leave');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} /> Sick
                  Leave
                  {/* <strong>12</strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('paternity_leaves');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} />{' '}
                  Paternity Leave
                  {/* <strong>2</strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('maternity_leaves');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} />{' '}
                  Maternity Leave
                  {/* <strong>3</strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('short_leaves');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} /> Short
                  Leave
                  {/* <strong>6</strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('correction_approved');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} />{' '}
                  Correction Approved
                  {/* <strong>80</strong>{' '} */}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    showEmployeeListFun(true);
                    filterEmployeeListFun('comp_off');
                  }}
                >
                  <img src="images/icon1.svg" width={29} height={29} /> Comp-off
                  Approved
                  {/* <strong>12</strong>{' '} */}
                </a>
              </li>
            </ul>
          ) : (
            <ul className="teamList nav nav-tabs">
              <li>
                <a className="">
                  <Skeleton count={1} />
                  {/* <img src="images/icon1.svg" width={29} height={29} /> Dashboard */}
                </a>
              </li>
              <li>
                <a className="">
                  <Skeleton count={1} />
                  {/* <img src="images/icon1.svg" width={29} height={29} /> Dashboard */}
                </a>
              </li>
              <li>
                <a className="">
                  <Skeleton count={1} />
                  {/* <img src="images/icon1.svg" width={29} height={29} /> Dashboard */}
                </a>
              </li>
              <li>
                <a className="">
                  <Skeleton count={1} />
                  {/* <img src="images/icon1.svg" width={29} height={29} /> Dashboard */}
                </a>
              </li>
              <li>
                <a className="">
                  <Skeleton count={1} />
                  {/* <img src="images/icon1.svg" width={29} height={29} /> Dashboard */}
                </a>
              </li>
              <li>
                <a className="">
                  <Skeleton count={1} />
                  {/* <img src="images/icon1.svg" width={29} height={29} /> Dashboard */}
                </a>
              </li>
              <li>
                <a className="">
                  <Skeleton count={1} />
                  {/* <img src="images/icon1.svg" width={29} height={29} /> Dashboard */}
                </a>
              </li>
              <li>
                <a className="">
                  <Skeleton count={1} />
                  {/* <img src="images/icon1.svg" width={29} height={29} /> Dashboard */}
                </a>
              </li>
              <var>
                {' '}
                <li>
                  <a className="">
                    <Skeleton count={1} />
                    {/* <img src="images/icon1.svg" width={29} height={29} /> Dashboard */}
                  </a>
                </li>
                <li>
                  <a className="">
                    <Skeleton count={1} />
                    {/* <img src="images/icon1.svg" width={29} height={29} /> Dashboard */}
                  </a>
                </li>
              </var>
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default SideBar;
