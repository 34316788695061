import React from 'react';

const CustomPagination = () => {
  return (
    <div className="customPagination">
      <span className="recordTitle">Showing 7 out of 24</span>
      <ul className="pagination">
        <li className="page-item previous-item">
          <a className="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">
              <img src="images/arrowprw.svg" width={8} />
            </span>
          </a>
        </li>
        <li className="page-item active">
          <a className="page-link" href="#">
            1
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">
            2
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">
            3
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">
            4
          </a>
        </li>
        <li className="page-item next-item">
          <a className="page-link" href="#" aria-label="Next">
            <span aria-hidden="true">
              <img src="images/arrownxt.svg" width={8} />
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default CustomPagination;
