import { all, takeEvery, put, call, take } from 'redux-saga/effects';
import dashboardDataService from './dashboard.Service';
import dashboardDataAction from './dashboardData.Action';

function* handleDashboardDataRequest() {
  const action = yield take(
    dashboardDataAction.dashboardDataRequestAction.getType(),
  );
  const dataparams = action.payload;
  let res;
  try {
    res = yield call(dashboardDataService.getManagerDashboardData, dataparams);
    console.log('data in saga', res);
    if (res.isError) {
      yield put(dashboardDataAction.dashboardDataFailureAction);
    } else {
      yield put(dashboardDataAction.dashboardDataSuccessAction(res?.data));
    }
  } catch {
    yield put(dashboardDataAction.dashboardDataFailureAction);
  }
}
function* handleDashboardRequestWatcher() {
  yield takeEvery(
    dashboardDataAction.dashboardDataRequestAction,
    handleDashboardDataRequest,
  );
}
function* handleDashboardOtherDataRequest() {
  const action = yield take(
    dashboardDataAction.dashboardOtherDataRequestAction.getType(),
  );
  const dataparams = action.payload;
  let res;
  try {
    res = yield call(
      dashboardDataService.getManagerDashboardOtherData,
      dataparams,
    );
    console.log('data in saga', res);
    if (res.isError) {
      yield put(dashboardDataAction.dashboardOtherDataFailureAction);
    } else {
      yield put(dashboardDataAction.dashboardOtherDataSuccessAction(res?.data));
    }
  } catch {
    yield put(dashboardDataAction.dashboardOtherDataFailureAction);
  }
}
function* handleDashboardOtherRequestWatcher() {
  yield takeEvery(
    dashboardDataAction.dashboardOtherDataRequestAction,
    handleDashboardOtherDataRequest,
  );
}
/////////////////////////////////
function* handleTlDashboardDataRequest() {
  const action = yield take(
    dashboardDataAction.tldashboardDataRequestAction.getType(),
  );
  const dataparams = action.payload;
  let res;
  try {
    res = yield call(
      dashboardDataService.getTeamLaederDashboardData,
      dataparams,
    );
    console.log('data in saga', res);
    if (res.isError) {
      yield put(dashboardDataAction.tldashboardDataFailureAction);
    } else {
      yield put(dashboardDataAction.tldashboardDataSuccessAction(res?.data));
    }
  } catch {
    yield put(dashboardDataAction.tldashboardDataFailureAction);
  }
}
function* handleTlDashboardRequestWatcher() {
  yield takeEvery(
    dashboardDataAction.tldashboardDataRequestAction,
    handleTlDashboardDataRequest,
  );
}

function* rootDashboardDataSaga() {
  yield all([
    handleDashboardDataRequest(),
    handleDashboardRequestWatcher(),
    handleDashboardOtherDataRequest(),
    handleDashboardOtherRequestWatcher(),
    handleTlDashboardDataRequest(),
    handleTlDashboardRequestWatcher(),
  ]);
}

export default rootDashboardDataSaga;
