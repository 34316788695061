import { createAction } from 'redux-act';
const dashboardDataRequestAction = createAction(
  'REQUEST_DASHBOARD_DATA',
  data => data,
);

const dashboardDataSuccessAction = createAction(
  'SUCCESS_DASHBOARD_DATA',
  data => data,
);
const dashboardDataFailureAction = createAction(
  'FAILURE_DASHBOARD_DATA',
  data => data,
);
const dashboardOtherDataRequestAction = createAction(
  'REQUEST_DASHBOARD_OTHER_DATA',
  data => data,
);

const dashboardOtherDataSuccessAction = createAction(
  'SUCCESS_DASHBOARD_OTHER_DATA',
  data => data,
);
const dashboardOtherDataFailureAction = createAction(
  'FAILURE_DASHBOARD_OTHER_DATA',
  data => data,
);
const tldashboardDataRequestAction = createAction(
  'REQUEST_DASHBOARD_TL_DATA',
  data => data,
);

const tldashboardDataSuccessAction = createAction(
  'SUCCESS_DASHBOARD_TL_DATA',
  data => data,
);
const tldashboardDataFailureAction = createAction(
  'FAILURE_DASHBOARD_TL_DATA',
  data => data,
);

export default {
  dashboardDataRequestAction,
  dashboardDataSuccessAction,
  dashboardDataFailureAction,
  dashboardOtherDataRequestAction,
  dashboardOtherDataFailureAction,
  dashboardOtherDataSuccessAction,
  tldashboardDataRequestAction,
  tldashboardDataSuccessAction,
  tldashboardDataFailureAction,
};
