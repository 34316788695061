import React, { useState } from 'react';
import { CDataTable } from '@coreui/react';
import { convertDate } from 'src/utils/common';
import { isPermission } from 'src/utils/common';
import ViewLeaveDateModal from './ViewLeaveDateModal';
import SelectedApproveAndRejectLeave from './SelectedApproveRejectLeave';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';

const LeaveApprovalList = props => {
  const {
    data,
    type,
    renderDataStatus,
    renderDataActionBtn,
    getLeaveForApproval,
  } = props;
  const [viewLaeveModal, setViewLeaveModal] = useState(false);
  const [leaveData, setLeaveData] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [heading, setHeading] = useState('');
  const [leaveType, setLeaveType] = useState('');
  let user_data = getLocalDataAsObject('user')
  let get_user_id = user_data && user_data[0].id;
  const handleLeaveModal = item => {
    setLeaveData(item);
    setViewLeaveModal(!viewLaeveModal);
  };
  function allLeaveStatus(arr) {
    let status = '';
    let p = 0;
    let a = 0;
    let r = 0;
    let c = 0;
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i]?.is_approve_status == 0 && arr[i]?.is_rejected_status == 0 && arr[i]?.is_cancel == 0) {
        p++;
      } else if (arr[i]?.is_approve_status == 0 && arr[i]?.is_rejected_status == 1 && arr[i]?.is_cancel == 0) {
        r++;
      } else if (arr[i]?.is_approve_status == 0 && arr[i]?.is_rejected_status == 0 && arr[i]?.is_cancel == 1) {
        c++;
      } else {
        a++;
      }
    }
    if (arr.length === a) {
      return (status = 'Approved');
    } if (arr.length === r) {
      return (status = 'Rejected');
    }if (arr.length === c) {
      return (status = 'Cancelled');
    }else {
      return (status = 'Pending');
    }
  }

  const fields = [
    {
      label: 'Select',
      key: 'select',
      //_style: { width: '5%' },
      sorter: true,
      filter: true,
    },
    {
      label: 'QD ID',
      key: 'emp_id',
      sorter: false,
      filter: true,
    },
    {
      label: 'Employee Name',
      key: 'employee_name',
      sorter: false,
      filter: true,
    },
    {
      label: 'Leave From',
      key: 'leave_from',
      sorter: true,
      filter: true,
    },
    {
      label: 'Leave To',
      key: 'leave_to',
      sorter: true,
      filter: true,
    },

    {
      label: 'Reason',
      key: 'reason',
      sorter: false,
      filter: true,
    },

    {
      label: 'Created Date',
      key: 'raised_date',
      sorter: true,
      filter: true,
    },
    {
      label: 'Approval Status',
      key: 'status',
      sorter: false,
      filter: false,
    },
    {
      label: 'Action',
      key: 'view',
      sorter: true,
      filter: true,
    },
    // {
    //   label: '',
    //   key: 'action',
    //   sorter: true,
    //   filter: true,
    // },
  ];

  const handleSelectedIds = (e, items) => {
    let newSelectedData = [...selectedData];
    if (e.target.checked) {
      newSelectedData.push(items);

      setSelectedData(newSelectedData);
    } else {
      let arr = newSelectedData.filter(item => item?.id != items?.id);

      setSelectedData(arr);
    }
  };

  const isViewModalOpen = type => {
    if (type === 'Approve') {
      setHeading('Approve Correction');
    }
    if (type === 'Reject') {
      setHeading('Reject Correction');
    } else {
      setHeading('');
    }
    setLeaveType(type);

    setIsOpen(!isOpen);
  };

  const allSelectedApprove = type => {
    isViewModalOpen(type);
  };

  return (
    <>
      <CDataTable
        items={data}
        fields={fields}
        tableFilter={{label: 'Filter:', placeholder: 'Search'}}
        itemsPerPageSelect
        itemsPerPage={10}
        hover
        sorter
        pagination
        scopedSlots={{
          select: (item, index) =>
            item?.is_cancel == 0 && item?.is_rejected_status == 0 && item.is_approve_status == 0 && item?.assigned_to == get_user_id ? (
              <td>
                <input
                  key={item?.id}
                  type="checkbox"
                  onChange={e => handleSelectedIds(e, item)}
                ></input>
              </td>
            ) : (
              <td></td>
            ),
          leave_from: item => (
            <td>
              {convertDate(item?.leaveDateArray[0].leave_date, 'DD-MM-YYYY')}
            </td>
          ),
          leave_to: item => (
            <td>
              {item.leaveDateArray.length > 0
                ? convertDate(
                    item?.leaveDateArray[item.leaveDateArray.length - 1]
                      .leave_date,
                    'DD-MM-YYYY',
                  )
                : convertDate(item?.leaveDateArray[0].leave_date, 'DD-MM-YYYY')}
            </td>
          ),
          status: item => <td>{allLeaveStatus(item?.leaveDateArray)==='Approved' ? <span>
          <i
            className="fa fa-circle danger"
            style={{ color: '#96f77c' }}
            aria-hidden="true"
          ></i>{' '}
          Approved{' '}
        </span> : allLeaveStatus(item?.leaveDateArray)==='Rejected' ? <span>
          <i
            className="fa fa-circle danger"
            style={{ color: '#f5a39a' }}
            aria-hidden="true"
          ></i>{' '}
          Rejected{' '}
        </span>: allLeaveStatus(item?.leaveDateArray)==='Cancelled' ? <span>
          <i
            className="fa fa-circle"
            style={{ color: '#19789e' }}
            aria-hidden="true"
          ></i>{' '}
          Cancelled{' '}
        </span>:<span>
        <i
          className="fa fa-circle danger"
          style={{ color: '#f5d99a' }}
          aria-hidden="true"
        ></i>{' '}
        Pending
      </span>}</td>,
          leave_date: item => (
            <td>{convertDate(item.leave_date, 'DD-MM-YYYY')}</td>
          ),
          raised_date: item => (
            <td>{convertDate(item.created_at, 'DD-MM-YYYY')}</td>
          ),
          view: item => (
            item?.assigned_to == get_user_id ? (
            <td onClick={() => handleLeaveModal(item)}>
              <i className="fa fa-tasks" aria-hidden="true"></i>
            </td>) : (
            <td></td>)
          ),
          action: item => (
            <td>
              {isPermission('approve_reject_leave_request') && (
                <>{renderDataActionBtn(item, type)}</>
              )}
            </td>
          ),
        }}
      />
      {selectedData && selectedData.length > 0 && (
          <div className="text-start">
            <div className="p-2 mx-2">
              <button
                className="btn btn-primary mr-2"
                onClick={() => allSelectedApprove('Approve')}
              >
                {` Approve Selected (${selectedData.length})`}
              </button>
              <button
                className="btn btn-danger"
                onClick={() => allSelectedApprove('Reject')}
              >
                {`  Reject Selected (${selectedData.length}) `}
              </button>
            </div>
          </div>
        )}

        <SelectedApproveAndRejectLeave
          isOpen={isOpen}
          isViewModalOpen={isViewModalOpen}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          heading={heading}
          leaveType={leaveType}
          getLeaveForApproval={getLeaveForApproval}
        />

      <ViewLeaveDateModal
        getLeaveForApproval={getLeaveForApproval}
        leaveData={leaveData}
        viewLaeveModal={viewLaeveModal}
        handleLeaveModal={handleLeaveModal}
      />
    </>
  );
};

export default LeaveApprovalList;
