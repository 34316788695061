import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';
import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const ShowAllManager = ({
  allManagerData,
  setTlId,
  setTab,
  showAllManager,
  handleShowAllManagerModal,
}) => {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <CModal
      show={showAllManager}
      onClose={() => handleShowAllManagerModal()}
      size="lg"
    >
      <CModalHeader>
        <CModalTitle></CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="modal-body p-0 pb-0">
          <div className="cardBox p-0">
            <div className="table-responsive attendancTbl">
              <table className="table">
                <tbody>
                  {allManagerData &&
                    allManagerData.length > 0 &&
                    allManagerData.map((item, index) => (
                      <tr className="border-top" key={index}>
                        <td className="ps-0 empNumberCOunt">
                          <span className="empNumber">{index + 1}</span>
                        </td>
                        <td>
                          <div className="attendanceContent">
                            <h6> {item?.full_name}</h6>
                            <span>{item?.role_name}</span>
                          </div>
                        </td>
                        <td>
                          <div className="attendanceContent">
                            <h6>Emp ID</h6>
                            <span>{item?.emp_id}</span>
                          </div>
                        </td>
                        <td>
                          <div className="attendanceContent">
                            <h6>Department</h6>
                            <span>
                              {item?.department_name} ({item?.panel_role})
                            </span>
                          </div>
                        </td>
                        <td>
                          <span
                            onClick={() => {
                              setTab('team');
                              setTlId(item?.id);
                            }}
                            className="btn-outline-orange"
                          >
                            <img src="images/eyeorange.svg" width={16} alt="" />{' '}
                            Insights
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="customPagination mb-0 pb-3">
              <span className="recordTitle">Showing 7 out of 24</span>
              <ul className="pagination">
                <li className="page-item previous-item">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">
                      <img src="images/arrowprw.svg" width={8} />
                    </span>
                  </a>
                </li>
                <li className="page-item active">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    4
                  </a>
                </li>
                <li className="page-item next-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">
                      <img src="images/arrownxt.svg" width={8} />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </CModalBody>
    </CModal>
    // <div className="modal fade " id="allManagers" area-hidden="true">
    //   <div className="modal-dialog modal-dialog-centered modal-lg">
    //     <div className="modal-content rounded-0">
    //       <div className="modal-header mb-0 border-0 d-inline">
    //         <div className="d-flex justify-content-between align-items-center">
    //           <div className="d-flex align-items-center">
    //             <button
    //               type="button"
    //               className="btn-close "
    //               data-bs-dismiss="modal"
    //               area-label="close"
    //             />
    //             <h1 className="modal-title fs-7 fw-bold ps-3">All Managers</h1>
    //           </div>
    //           <div className="position-relative me-2">
    //             <input
    //               type="search"
    //               placeholder="Search here"
    //               className="searchinput searchmn"
    //             />
    //             <img
    //               src="images/search.svg"
    //               alt="searchicon"
    //               width={20}
    //               className="searcicon"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="modal-body p-0 pb-0">
    //         <div className="cardBox p-0">
    //           <div className="table-responsive attendancTbl">
    //             <table className="table">
    //               <tbody>
    //                 {allManagerData &&
    //                   allManagerData.length > 0 &&
    //                   allManagerData.map((item, index) => (
    //                     <tr className="border-top" key={index}>
    //                       <td className="ps-0 empNumberCOunt">
    //                         <span className="empNumber">{index + 1}</span>
    //                       </td>
    //                       <td>
    //                         <div className="attendanceContent">
    //                           <h6> {item?.full_name}</h6>
    //                           <span>{item?.role_name}</span>
    //                         </div>
    //                       </td>
    //                       <td>
    //                         <div className="attendanceContent">
    //                           <h6>Emp ID</h6>
    //                           <span>{item?.emp_id}</span>
    //                         </div>
    //                       </td>
    //                       <td>
    //                         <div className="attendanceContent">
    //                           <h6>Department</h6>
    //                           <span>
    //                             {item?.department_name} ({item?.panel_role})
    //                           </span>
    //                         </div>
    //                       </td>
    //                       <td>
    //                         <span
    //                           onClick={() => {
    //                             setTab('team');
    //                             setTlId(item?.id);
    //                           }}
    //                           className="btn-outline-orange"
    //                         >
    //                           <img
    //                             src="images/eyeorange.svg"
    //                             width={16}
    //                             alt=""
    //                           />{' '}
    //                           Insights
    //                         </span>
    //                       </td>
    //                     </tr>
    //                   ))}
    //               </tbody>
    //             </table>
    //           </div>
    //           <div className="customPagination mb-0 pb-3">
    //             <span className="recordTitle">Showing 7 out of 24</span>
    //             <ul className="pagination">
    //               <li className="page-item previous-item">
    //                 <a className="page-link" href="#" aria-label="Previous">
    //                   <span aria-hidden="true">
    //                     <img src="images/arrowprw.svg" width={8} />
    //                   </span>
    //                 </a>
    //               </li>
    //               <li className="page-item active">
    //                 <a className="page-link" href="#">
    //                   1
    //                 </a>
    //               </li>
    //               <li className="page-item">
    //                 <a className="page-link" href="#">
    //                   2
    //                 </a>
    //               </li>
    //               <li className="page-item">
    //                 <a className="page-link" href="#">
    //                   3
    //                 </a>
    //               </li>
    //               <li className="page-item">
    //                 <a className="page-link" href="#">
    //                   4
    //                 </a>
    //               </li>
    //               <li className="page-item next-item">
    //                 <a className="page-link" href="#" aria-label="Next">
    //                   <span aria-hidden="true">
    //                     <img src="images/arrownxt.svg" width={8} />
    //                   </span>
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ShowAllManager;
