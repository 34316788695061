// import React, { useState, lazy, useEffect } from 'react';
// import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// import {
//   CBadge,
//   CNav,
//   CInputRadio,
//   CNavItem,
//   CNavbar,
//   CListGroup,
//   CListGroupItem,
//   CNavLink,
//   CTabContent,
//   CTabPane,
//   CTabs,
//   CButton,
//   CTextarea,
//   CModal,
//   CModalHeader,
//   CModalBody,
//   CModalFooter,
//   CModalTitle,
//   CWidgetBrand,
//   CButtonGroup,
//   CWidgetIcon,
//   CCard,
//   CLink,
//   CCardBody,
//   CCardFooter,
//   CInputFile,
//   CCardHeader,
//   CCol,
//   CSwitch,
//   CCollapse,
//   CProgress,
//   CProgressBar,
//   CRow,
//   CLabel,
//   CInput,
//   CForm,
//   CFormGroup,
//   CFormText,
//   CSelect,
//   CCallout,
// } from '@coreui/react';
// import CIcon from '@coreui/icons-react';
// import API from 'src/utils/apiCalling';
// import { config } from 'src/utils/apiUrl';
// import swal from 'sweetalert';
// import Alert from 'src/alert/alert';
// import moment from 'moment';
// import { toast } from 'react-toastify';

// const WorkExperienceDetails = props => {
//   let { getEmployeeDetailStatus, empStatus, empDetails } = props;
//   const api = new API();
//   const [isDisabled, setIsDisabled] = useState(false);
//   const [empWorkExperienceDetails, setEmpWorkExperienceDetails] = useState([]);
//   const [isAlert, setIsAlert] = useState(false);
//   const [alertMessage, setAlertMessage] = useState('');
//   const isAlertToggle = msg => {
//     setIsAlert(!isAlert);
//     setAlertMessage(msg);
//   };
//   const [workExperience, setWorkExperience] = useState({
//     emp_organisation_name: '',
//     emp_work_experience_from: '',
//     emp_work_experience_to: '',
//     emp_designation: '',
//     emp_reason_of_leaving: '',
//   });
//   useEffect(() => {
//     getWorkExperienceDetails();
//     console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<', empDetails);
//   }, []);
//   const isAlertToggleClose = () => {
//     setIsAlert(false);
//   };
//   const getWorkExperienceDetails = async () => {
//     let data = {
//       user_id: empDetails && empDetails.id,
//     };
//     if (!data.user_id) {
//       delete data.user_id;
//     }
//     let result = await api.get(config.getWorkExperienceDetails, true, data);
//     if (result && result.code === 200) {
//       if (empStatus && empStatus.work_experience_detail_status == 1) {
//         setIsDisabled(true);
//         setEmpWorkExperienceDetails(
//           result.data.Employee_work_Experience_details,
//         );
//       }
//     } else {
//       // alert(result && result.message);
//     }
//   };
//   const saveWorkExperience = async () => {
//     let data = {
//       empWorkExperienceDetails: JSON.stringify(empWorkExperienceDetails),
//     };
//     data.user_id = empDetails && empDetails.id;
//     if (!data.user_id) {
//       delete data.user_id;
//     }
//     swal({
//       html: true,
//       title: 'Are you sure?',
//       text: `Are you sure to add Work Experiance Details`,
//       icon: 'success',
//       buttons: ['No, cancel it!', 'Submit'],
//       dangerMode: true,
//     }).then(async function(isConfirm) {
//       if (isConfirm) {
//         let result = await api.post(config.fillWorkExeperienceDetails, data);
//         if (result && result.code === 200) {
//           toast.success('Work Experiance Details Are Added Successfully')
//           // isAlertToggle('Work Experiance Details Are Added Successfully');
//           getEmployeeDetailStatus();
//         } else {
//           toast.error(result && result.message)
//           // isAlertToggle(result && result.message);
//         }
//       }
//     });
//   };
//   const addMoreWorkExperience = () => {
//     if (
//       !workExperience.emp_organisation_name ||
//       !workExperience.emp_work_experience_from ||
//       !workExperience.emp_work_experience_to ||
//       !workExperience.emp_designation ||
//       !workExperience.emp_reason_of_leaving
//     ) {
//       toast.error('Please fill all field')
//       // isAlertToggle('Field cant blank');
//     } else {
//       setEmpWorkExperienceDetails([
//         ...empWorkExperienceDetails,
//         workExperience,
//       ]);
//       // console.log("all education", empWorkExperienceDetails);
//       setWorkExperience({
//         emp_organisation_name: '',
//         emp_work_experience_from: '',
//         emp_work_experience_to: '',
//         emp_designation: '',
//         emp_reason_of_leaving: '',
//       });
//     }
//   };
//   const onChangeInputReferenceDetails = e => {
//     const { name, value } = e.target;
//     setWorkExperience({
//       ...workExperience,
//       [name]: value,
//     });
//   };
//   return (
//     <div>
//       {empWorkExperienceDetails && empWorkExperienceDetails.length > 0 && (
//         <CRow>
//           <CCol xs="12">
//             <CCard>
//               <CCardHeader>
//                 {' '}
//                 <strong>Work Experience Details</strong>
//               </CCardHeader>
//               <CCardBody>
//                 <CCardBody>
//                   <h4></h4>
//                   <table className="table">
//                     <thead>
//                       <tr>
//                         <th scope="col">Organisation name</th>
//                         <th scope="col">From</th>
//                         <th scope="col">To</th>
//                         <th scope="col">Designation</th>
//                         <th scope="col">Reason of leaving</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {empWorkExperienceDetails &&
//                         empWorkExperienceDetails.length > 0 &&
//                         empWorkExperienceDetails.map(
//                           (workExperience, index) => (
//                             <tr>
//                               <td>{workExperience.emp_organisation_name}</td>
//                               <td>
//                                 {moment(
//                                   workExperience.emp_work_experience_from,
//                                 ).format('DD-MM-YYYY')}
//                               </td>
//                               <td>
//                                 {moment(
//                                   workExperience.emp_work_experience_to,
//                                 ).format('DD-MM-YYYY')}
//                               </td>
//                               <td>{workExperience.emp_designation}</td>
//                               <td>{workExperience.emp_reason_of_leaving}</td>
//                               {/* <td>
//                                 {isDisabled == false &&
//                                   <CButton size="sm" color="primary">
//                                     Update
//                                   </CButton>
//                                 }
//                               </td> */}
//                             </tr>
//                           ),
//                         )}
//                     </tbody>
//                   </table>
//                 </CCardBody>

//                 <CCardFooter></CCardFooter>
//               </CCardBody>
//             </CCard>
//           </CCol>
//         </CRow>
//       )}
//       {isDisabled == false && (
//         <div>
//           <div className="text-center mb-4">
//             <label>Work Experience (If Applicable)</label>
//           </div>
//           <form>
//             <div className="row">
//               <div className="col-md-6">
//                 <div className="form-group">
//                   <label for="exampleInputEmail1">Organisation Name</label>
//                   <input
//                     type="email"
//                     className="form-control"
//                     id="exampleInputEmail1"
//                     name="emp_organisation_name"
//                     value={workExperience.emp_organisation_name}
//                     onChange={onChangeInputReferenceDetails}
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label for="exampleInputEmail1">From</label>
//                   <input
//                     type="date"
//                     className="form-control"
//                     min="1950-01-01"
//                     id="exampleInputEmail1"
//                     name="emp_work_experience_from"
//                     value={workExperience.emp_work_experience_from}
//                     onChange={onChangeInputReferenceDetails}
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label for="exampleInputEmail1">To</label>
//                   <input
//                     type="date"
//                     className="form-control"
//                     id="exampleInputEmail1"
//                     name="emp_work_experience_to"
//                     value={workExperience.emp_work_experience_to}
//                     onChange={onChangeInputReferenceDetails}
//                   />
//                 </div>
//                 <div className="">
//                   <button
//                     type="button"
//                     className="btn btn-info"
//                     onClick={addMoreWorkExperience}
//                   >
//                     Add Work Experience
//                   </button>
//                 </div>
//               </div>

//               <div className="col-md-6">
//                 <div className="form-group">
//                   <label for="exampleInputEmail1">Designation</label>
//                   <input
//                     type="name"
//                     className="form-control"
//                     id="exampleInputEmail1"
//                     name="emp_designation"
//                     value={workExperience.emp_designation}
//                     onChange={onChangeInputReferenceDetails}
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label for="exampleInputEmail1">Reason Of Leaving</label>
//                   <input
//                     type="name"
//                     className="form-control"
//                     id="exampleInputEmail1"
//                     name="emp_reason_of_leaving"
//                     value={workExperience.emp_reason_of_leaving}
//                     onChange={onChangeInputReferenceDetails}
//                   />
//                 </div>
//                 <button
//                   type="button"
//                   className="btn btn-primary float-right mt-5"
//                   disabled={empWorkExperienceDetails.length == 0 ? true : false}
//                   onClick={saveWorkExperience}
//                 >
//                   Save
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       )}
//       <Alert
//         isAlert={isAlert}
//         isAlertToggle={isAlertToggle}
//         alertMessage={alertMessage}
//         isAlertToggleClose={isAlertToggleClose}
//       />
//     </div>
//   );
// };
// export default WorkExperienceDetails;


import React, { useState, lazy, useEffect } from 'react';

import { toast, ToastContainer } from 'react-toastify';
import {
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';

import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import swal from 'sweetalert';
import Alert from 'src/alert/alert';
import moment from 'moment';

const WorkExperienceDetails = props => {
  let {
    getEmployeeDetailStatus,
    empStatus,
    empDetails,
    onClickActive,
  } = props;
  const api = new API();
  const [isDisabled, setIsDisabled] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [empWorkExperienceDetails, setEmpWorkExperienceDetails] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [experienced, setExperienced] = useState('');
  const [updateWorkExp,setUpdateWorkExp]=useState([])
  const isAlertToggle = msg => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
  };
  const [workExperience, setWorkExperience] = useState({
    emp_organisation_name: '',
    emp_work_experience_from: '',
    emp_work_experience_to: '',
    emp_designation: '',
    emp_reason_of_leaving: '',
    experienced: '',
  });
  useEffect(() => {
    getWorkExperienceDetails();
  }, []);
  const isAlertToggleClose = () => {
    setIsAlert(false);
  };
  const getWorkExperienceDetails = async () => {
    let data = {
      user_id: empDetails && empDetails.id,
    };
    if (!data.user_id) {
      delete data.user_id;
    }
    let result = await api.get(config.getWorkExperienceDetails, true, data);
    if (result && result.code === 200) {
      if (empStatus && empStatus.work_experience_detail_status === 1) {
        setIsDisabled(true);
        setIsSubmit(false);
        setEmpWorkExperienceDetails(
          result.data.Employee_work_Experience_details,
        );
      }
    } else {
      setIsDisabled(true);
      setEmpWorkExperienceDetails([]);
    }
  };
  const saveWorkExperience = async () => {
    let data = {
      experienced: experienced,
      empWorkExperienceDetails: JSON.stringify(empWorkExperienceDetails),
    };
    data.user_id = empDetails && empDetails.id;
    if (!data.user_id) {
      delete data.user_id;
    }
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to add Work Experiance Details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.fillWorkExeperienceDetails, data);
        if (result && result.code === 200) {
          toast.success(result && result.message);
          onClickActive(9);
          setIsSubmit(false);
          getEmployeeDetailStatus();
          getWorkExperienceDetails();
        } else {
          toast.error(result && result.message);
        }
      }
    });
  };
  const saveSingleWorkExperience = async () => {
    let isSubmit =
      workExperience?.experienced == 'yes' && (workExperience?.emp_designation != '' &&
      workExperience?.emp_organisation_name != '' &&
      workExperience?.emp_reason_of_leaving != '' &&
      workExperience?.emp_work_experience_from != '' &&
      workExperience?.emp_work_experience_to != '') ? true : 
      workExperience?.experienced == 'no' && (workExperience?.emp_designation == '' &&
      workExperience?.emp_organisation_name == '' &&
      workExperience?.emp_reason_of_leaving == '' &&
      workExperience?.emp_work_experience_from == '' &&
      workExperience?.emp_work_experience_to == '') ? true
        : false;
        console.log(isSubmit,workExperience?.experienced)
    if (isSubmit) {
      let empWorkExperienceDetails = [{ ...workExperience }];
      let data = {
        experienced: experienced,
        empWorkExperienceDetails: JSON.stringify(empWorkExperienceDetails),
      };
      data.user_id = empDetails && empDetails.id;
      if (!data.user_id) {
        delete data.user_id;
      }
      swal({
        html: true,
        title: 'Are you sure?',
        text: `Are you sure to add Work Experiance Details`,
        icon: 'success',
        buttons: ['No, cancel it!', 'Submit'],
        dangerMode: true,
      }).then(async function(isConfirm) {
        if (isConfirm) {
          let result = await api.post(config.fillWorkExeperienceDetails, data);
          if (result && result.code === 200) {
            toast.success(result && result.message);
            onClickActive(9);
            setIsSubmit(false);
            getEmployeeDetailStatus();
            getWorkExperienceDetails();
          } else {
            toast.error(result && result.message);
          }
        }
      });
    } else {
      toast.warning('Please add all fields');
    }
  };

  const updateworkExperience = async () => {
    let data = {
      experienced: experienced,
      empWorkExperienceDetails: JSON.stringify(updateWorkExp),
    };
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to update Work Experiance Details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Update'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.fillWorkExeperienceDetails, data);
        if (result && result.code === 200) {
          toast.success(result && result.message);
          setIsSubmit(false);
          getEmployeeDetailStatus();
          getWorkExperienceDetails();
        } else {
          toast.error(result && result.message);
        }
      }
    });
  };

  const addMoreWorkExperience = () => {
    if (
      !workExperience.emp_organisation_name ||
      !workExperience.emp_work_experience_from ||
      !workExperience.emp_work_experience_to ||
      !workExperience.emp_designation ||
      !workExperience.emp_reason_of_leaving
    ) {
      toast.error('Please fill all the fields..');
    } else {
      setEmpWorkExperienceDetails([
        ...empWorkExperienceDetails,
        workExperience,
      ]);
      setUpdateWorkExp([...updateWorkExp,workExperience])
      setIsDisabled(false);
      setWorkExperience({
        emp_organisation_name: '',
        emp_work_experience_from: '',
        emp_work_experience_to: '',
        emp_designation: '',
        emp_reason_of_leaving: '',
        experienced: '',
      });
    }
  };

  const addMoreWorkExperienceOnUpdate = () => {
    if (
      !workExperience.emp_organisation_name ||
      !workExperience.emp_work_experience_from ||
      !workExperience.emp_work_experience_to ||
      !workExperience.emp_designation ||
      !workExperience.emp_reason_of_leaving
    ) {
      toast.error('Please fill all the fields..');
    } else {
      setEmpWorkExperienceDetails([...empWorkExperienceDetails ,workExperience]);
      setUpdateWorkExp([...updateWorkExp,workExperience])
      setIsDisabled(false);
      setWorkExperience({
        emp_organisation_name: '',
        emp_work_experience_from: '',
        emp_work_experience_to: '',
        emp_designation: '',
        emp_reason_of_leaving: '',
        experienced: '',
      });
    }
  };

  const onChangeInputReferenceDetails = e => {
    let ifFromDateGrater;
    const { name, value } = e.target;
    console.log(name,value,experienced)
    if (name === 'experienced' && value === 'no') {
      setDisabled(true);
      setExperienced(value);
      setIsDisabled(false);
    } else if(name === 'experienced' && value === 'yes') {
      setDisabled(false);
      setExperienced(value);
      setIsDisabled(true);
    } else{
      setDisabled(false);
      setIsDisabled(true);
    }
    if (name === 'emp_work_experience_from') {
      ifFromDateGrater =
        workExperience.emp_work_experience_to != ''
          ? moment(value).isAfter(moment(workExperience.emp_work_experience_to))
          : false;
    }
    if (name === 'emp_work_experience_to') {
      ifFromDateGrater =
        workExperience.emp_work_experience_from != ''
          ? moment(workExperience.emp_work_experience_from).isAfter(
              moment(value),
            )
          : false;
    }
    if (ifFromDateGrater) {
      toast.error('From date should be greater than To date');
      setWorkExperience({
        ...workExperience,
        ['emp_work_experience_from']: '',
        ['emp_work_experience_to']: '',
      });

      return;
    }
    setWorkExperience({
      ...workExperience,
      [name]: value,
    });
  };
  const deleteWorkExperience = async id => {
    let data = {
      id: id,
    };
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to delete Work Experience Details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Delete'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.get(
          config.deleteWorkExperienceDetails,
          true,
          data,
        );
        if (result && result.code === 200) {
          toast.success(result && result.message);
          getWorkExperienceDetails();
          getEmployeeDetailStatus();
        } else {
          toast.error(result && result.message);
        }
      }
    });
  };
  return (
    <div>
      {empWorkExperienceDetails && empWorkExperienceDetails.length > 0 && (
        <CRow>
          <CCol xs="12">
            <CCard>
              <CCardHeader>
                {' '}
                <strong>Work Experience Details</strong>
              </CCardHeader>
              <CCardBody>
                <CCardBody>
                  {empWorkExperienceDetails &&
                    empWorkExperienceDetails.length > 0 &&
                    empWorkExperienceDetails.map(
                      (workExperience1, index) =>
                        workExperience1.experienced === 'no' && (
                          <div style={{ display: 'inline-flex' }}>
                            <label
                              style={{ marginLeft: '325px', color: 'maroon' }}
                            >
                              Work Experience
                            </label>
                            <div
                              style={{
                                display: 'inline-flex',
                                marginLeft: '30px',
                                marginRight: '30px',
                              }}
                            >
                              <input
                                style={{ marginBottom: '5px' }}
                                type="radio"
                                name="experienced"
                                checked={workExperience1.experienced === 'no'}
                              />
                              <label
                                className="form-check-label"
                                for="exampleCheck1"
                              >
                                &nbsp;Fresher
                              </label>
                            </div>
                            <div style={{ display: 'inline-flex' }}>
                              <input
                                style={{ marginBottom: '5px' }}
                                type="radio"
                                name="experienced"
                                checked={workExperience1.experienced === 'yes'}
                              />
                              <label
                                className="form-check-label"
                                for="exampleCheck1"
                              >
                                &nbsp;Experienced
                              </label>
                            </div>
                          </div>
                        ),
                    )}
                  {(experienced == 'yes' || experienced == '') &&
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Organisation Name</th>
                        <th scope="col">Designation</th>
                        <th scope="col">From</th>
                        <th scope="col">To</th>
                        <th scope="col">Reason of leaving</th>
                        {!isSubmit && <th scope="col">Delete</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {empWorkExperienceDetails &&
                        empWorkExperienceDetails.length > 0 &&
                        empWorkExperienceDetails.map(
                          (workExperience, index) => (
                            <tr>
                              <td>{workExperience.emp_organisation_name}</td>
                              <td>{workExperience.emp_designation}</td>
                              <td>
                                {workExperience.emp_work_experience_from &&moment(
                                  workExperience.emp_work_experience_from 
                                ).format('DD-MM-YYYY')}
                              </td>
                              <td>
                                { workExperience.emp_work_experience_from &&moment(
                                  workExperience.emp_work_experience_to,
                                ).format('DD-MM-YYYY')}
                              </td>
                              <td>{workExperience.emp_reason_of_leaving}</td>
                              {!isSubmit && (
                                <td>
                                  { workExperience.emp_work_experience_from &&<i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                    style={{ color: 'red' }}
                                    onClick={e =>
                                      deleteWorkExperience(workExperience.id)
                                    }
                                  ></i>}
                                </td>
                              )}
                            </tr>
                          ),
                        )}
                    </tbody>
                  </table>}
                </CCardBody>
                <CCardFooter></CCardFooter>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      )}
      <div>
        <form>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label for="exampleInputEmail1">Work Experience</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  name="experienced"
                  onChange={onChangeInputReferenceDetails}
                  placeholder="ex. 10th"
                  value={workExperience.experienced}
                >
                  <option hidden>Select Work Experience</option>
                  <option value="no">Fresher</option>
                  <option value="yes">Experienced</option>
                </select>
              </div>
              <div className="form-group">
                <label for="exampleInputEmail1">From</label>
                <input
                  type="date"
                  className="form-control"
                  // min={workExperience.emp_work_experience_to}
                  id="exampleInputEmail1"
                  name="emp_work_experience_from"
                  value={workExperience.emp_work_experience_from}
                  onChange={onChangeInputReferenceDetails}
                  placeholder="Enter Working From"
                  disabled={disabled ? true : false}
                />
              </div>
              <div className="form-group">
                <label for="exampleInputEmail1">Designation</label>
                <input
                  type="name"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="emp_designation"
                  value={workExperience.emp_designation}
                  onChange={onChangeInputReferenceDetails}
                  placeholder="Enter Designation"
                  disabled={disabled ? true : false}
                />
              </div>
              <div className="">
                <button
                  type="button"
                  className="btn btn-info mt-5"
                  onClick={
                    isSubmit
                      ? addMoreWorkExperience
                      : addMoreWorkExperienceOnUpdate
                  }
                  style={{ color: 'white', backgroundColor: 'green' }}
                  disabled={disabled ? true : false}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>&nbsp; Add
                  Work Experience
                </button>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group" style={{ marginTop: '6px' }}>
                <label for="exampleInputEmail1">Organisation Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="emp_organisation_name"
                  value={workExperience.emp_organisation_name}
                  onChange={onChangeInputReferenceDetails}
                  placeholder="Enter Organisation Name"
                  disabled={disabled ? true : false}
                />
              </div>
              <div className="form-group">
                <label for="exampleInputEmail1">To</label>
                <input
                  type="date"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="emp_work_experience_to"
                  maxLength={workExperience.emp_work_experience_from}
                  value={workExperience.emp_work_experience_to}
                  onChange={onChangeInputReferenceDetails}
                  disabled={disabled ? true : false}
                />
              </div>
              <div className="form-group">
                <label for="exampleInputEmail1">Reason of Leaving</label>
                <input
                  type="name"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="emp_reason_of_leaving"
                  value={workExperience.emp_reason_of_leaving}
                  onChange={onChangeInputReferenceDetails}
                  placeholder="Enter Reason of Leaving"
                  disabled={disabled ? true : false}
                />
              </div>
              {isSubmit ? (
                empWorkExperienceDetails &&
                empWorkExperienceDetails.length > 0 ? (
                  <button
                    type="button"
                    className="btn btn-primary float-right mt-5"
                    onClick={saveWorkExperience}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary float-right mt-5"
                    onClick={saveSingleWorkExperience}
                  >
                    Submit
                  </button>
                )
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-success float-right ml-1 mt-5"
                    onClick={() => onClickActive(9)}
                  >
                    Next{' '}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-info float-right mt-5"
                    onClick={updateworkExperience}
                    disabled={isDisabled ? true : false}
                  >
                    Update
                  </button>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
      <Alert
        isAlert={isAlert}
        isAlertToggle={isAlertToggle}
        alertMessage={alertMessage}
        isAlertToggleClose={isAlertToggleClose}
      />
      <ToastContainer />
    </div>
  );
};
export default WorkExperienceDetails;