import { createReducer } from 'redux-act';
import action from './dashboardData.Action';
const initialState = {
  dashboardDataPending: false,
  dashboardDataRes: null,
  dashboardDataError: null,
  dashboardOtherDataPending: false,
  dashboardOtherDataRes: null,
  dashboardOtherDataError: null,
  tldashboardDataPending: false,
  tldashboardDataRes: null,
  tldashboardDataError: null,
};
const handleDashboardDataRequest = state => {
  return {
    ...state,
    ...{
      dashboardDataPending: true,
      dashboardDataRes: null,
      dashboardDataError: null,
    },
  };
};

const handleDashboardDataSuccess = (state, payload) => {
  return {
    ...state,
    ...{
      dashboardDataPending: true,
      dashboardDataRes: payload,
      dashboardDataError: null,
    },
  };
};

const handleDashboardDataError = (state, error) => {
  return {
    ...state,
    ...{
      dashboardDataPending: true,
      dashboardDataRes: null,
      dashboardDataError: error,
    },
  };
};
const handleDashboardOtherDataRequest = state => {
  return {
    ...state,
    ...{
      dashboardOtherDataPending: false,
      dashboardOtherDataRes: null,
      dashboardOtherDataError: null,
    },
  };
};

const handleDashboardOtherDataSuccess = (state, payload) => {
  return {
    ...state,
    ...{
      dashboardOtherDataPending: false,
      dashboardOtherDataRes: payload,
      dashboardOtherDataError: null,
    },
  };
};

const handleDashboardOtherDataError = (state, error) => {
  return {
    ...state,
    ...{
      dashboardOtherDataPending: true,
      dashboardOtherDataRes: null,
      dashboardOtherDataError: error,
    },
  };
};
const handleTlDashboardDataRequest = state => {
  return {
    ...state,
    ...{
      tldashboardDataPending: true,
      tldashboardDataRes: null,
      tldashboardDataError: null,
    },
  };
};

const handleTlDashboardDataSuccess = (state, payload) => {
  return {
    ...state,
    ...{
      tldashboardDataPending: true,
      tldashboardDataRes: payload,
      tldashboardDataError: null,
    },
  };
};

const handleTlDashboardDataError = (state, error) => {
  return {
    ...state,
    ...{
      tldashboardDataPending: false,
      tldashboardDataRes: null,
      tldashboardDataError: error,
    },
  };
};
export default createReducer(
  {
    [action.dashboardDataRequestAction]: handleDashboardDataRequest,
    [action.dashboardDataSuccessAction]: handleDashboardDataSuccess,
    [action.dashboardDataFailureAction]: handleDashboardDataError,
    [action.dashboardOtherDataRequestAction]: handleDashboardOtherDataRequest,
    [action.dashboardOtherDataSuccessAction]: handleDashboardOtherDataSuccess,
    [action.dashboardOtherDataFailureAction]: handleDashboardOtherDataError,
    [action.tldashboardDataRequestAction]: handleTlDashboardDataRequest,
    [action.tldashboardDataSuccessAction]: handleTlDashboardDataSuccess,
    [action.tldashboardDataFailureAction]: handleTlDashboardDataError,
  },
  initialState,
);
