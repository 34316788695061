import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
// import './messages.css';
import './style.css';
import { CListGroup, CListGroupItem } from '@coreui/react';
import { CustLoader } from '../../reusable';
import { getLocalDataAsObject } from '../../utils/CoustomStorage';
import { config } from '../../utils/apiUrl';
import API from '../../utils/apiCalling';
import {
  AddConnectionModal,
  AddUpdateGroupModal,
  ChatAttachmenstModal,
  FilePreviewModal,
} from 'src/modals';
import { useForceUpdate } from 'src/hooks';
import { toastAlert, convertDate, isTypeGroup } from 'src/utils/common';
import { ToastContainer } from 'react-toastify';
import appConstants from 'src/core/configs/Constants.config';
import { messageActions, messageSelectors } from 'src/core/resource/messages';
import { mqttActions, mqttSelectors } from 'src/core/resource/mqtt';
import emojiData from '@emoji-mart/data';
import EmojiPicker from '@emoji-mart/react';
import { ClickAwayListener } from '@material-ui/core';
import { playSoundByName } from 'src/core/managers/soundsManager';
import Distributor from './Distributor';

const MessagesScreen = props => {
  const msgDatesTmpArr = [];
  const api = new API();
  const user = getLocalDataAsObject('user');
  const messagesRef = useRef(null);
  const messagesBoxEndRef = useRef(null);
  const messagesInputRef = useRef(null);
  const forceUpdate = useForceUpdate();

  const {
    isShowChatPopup,
    hideChatPopup,

    getMqttClientConnectStatus,
    getMqttClientMessagePayload,

    navigateToClearMqttStore,
    navigateToMqttPublish,
    navigateToMqttTopicSubscribe,
    navigateToMqttClientOnMessage,

    navigateToGetUpdateUnreadCounts,
    getUnreadMessageCounts,

    navigateToGetConnectedUsersRequest,
    isConnectedUsersPending,
    getConnectedUsersRes,
    getConnectedUsersResError,

    navigateToGetConnectedGroupsRequest,
    isConnectedGroupsPending,
    getConnectedGroupsRes,
    getConnectedGroupsResError,
  } = props;

  const [isLoadingFriendList, setIsLoadingFriendList] = useState(true);
  const [isLoadingGroupList, setIsLoadingGroupList] = useState(true);
  const [isLoadingMessageList, setIsLoadingMessageList] = useState(true);
  const [isLoadingAddConnection, setIsLoadingAddConnection] = useState(false);
  const [isLoadingAddUpdateGroup, setIsLoadingAddUpdateGroup] = useState(false);
  const [isLoadingSendMessage, setIsLoadingSendMessage] = useState(false);

  const [connectingToUserData, setConnectingToUserData] = useState(null);

  const [client, setClient] = useState(null);
  const [isSubed, setIsSub] = useState(false);
  const [payload, setPayload] = useState({});
  const [messageText, setMessageText] = useState(null);
  const [searchTextConnectionList, setSearchTextConnectionList] = useState('');
  const [connectStatus, setConnectStatus] = useState('Connect');
  const [activeChat, setActiveChat] = useState(null);
  const [loggedUserInfo, setLoggedUserInfo] = useState(
    user?.length ? user[0] : null,
  );
  const [selectedGroupMembers, setSelectedGroupMembers] = useState([]);
  const [groupDesc, setGroupDesc] = useState('');
  const [groupName, setGroupName] = useState('');
  const [groupType, setGroupType] = useState('group');
  const [groupAction, setGroupAction] = useState('add');

  const [messages, setMessages] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [friendsList, setFriendsList] = useState([]);
  const [connectionList, setConnectionList] = useState([]);
  const [searchedConnectionList, setSearchedConnectionList] = useState([]);
  const [empList, setEmpList] = useState(null);
  const [unreadMessageCounts, setUnreadMessageCounts] = useState(null);
  const[unreadMessageCountsTemp,setUnreadMessageCountsTemp]=useState(null)


  const [addConnectionModalOpen, setAddConnectionModalOpen] = useState(false);
  const [addUpdateGroupModalOpen, setAddUpdateGroupModalOpen] = useState(false);

  const [isChatMinimized, setIsChatMinimized] = useState(true);
  const [activeChatTab, setActiveChatTab] = useState('chats');
  const [isShowEmpojiPicker, setIsShowEmpojiPicker] = useState(false);
  const [isActiveSendMsgBtn, setIsActiveSendMsgBtn] = useState(false);

  const [fileAttachment, setFileAttachment] = useState([]);
  const [isFileAttachmentModalOpen, setIsFileAttachmentModalOpen] = useState(
    false,
  );
  const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
  const [previewFileData, setPreviewFileData] = useState('');
  const[empNotInRoom,setEmpNotInroom]=useState([])
  const[loader,setLoader]=useState(false)
  const[unreadMsgOfPerson,setUnreadMsgOfPerson]=useState(null)
  const[unreadMsgOfGroup,setUnreadMsgOfGroup]=useState(null)
  const[groupMemberDetails,setGroupMemberDeatils]=useState([])
  const[showSugestion,setShowSugestion]=useState(false)
  const[autoSuggest,setAutoSuggest]=useState(null)



  useEffect(() => {
    setLoggedUserInfo(user?.length ? user[0] : null);
    setConnectStatus('Connecting');
    getAllEployeeList();
    getConnectionsList();
    getGroupList();
  }, []);

  useEffect(() => {
    setConnectStatus(getMqttClientConnectStatus?.text);
  }, [getMqttClientConnectStatus]);

  useEffect(() => {
    if (
      getMqttClientMessagePayload &&
      getMqttClientMessagePayload?.data?.topic === activeChat?._id
    ) {
      setMessages(messages => [...messages, getMqttClientMessagePayload.data]);
      navigateToClearMqttStore('CLEAR_MESSAGE_PAYLOAD');
    }
    if (
      getMqttClientMessagePayload &&
      getMqttClientMessagePayload?.data?.topic !== activeChat?._id
    ) {
      if (getMqttClientMessagePayload?.data?.type === 'p2p') {
        playSoundByName('MSG', 'msgSoundId');
      } else {
        playSoundByName('GROUP_MSG', 'groupSoundId');
      }
    }
  }, [getMqttClientMessagePayload]);

  useEffect(() => {
    if (getUnreadMessageCounts && getUnreadMessageCounts?.data?.counts) {
      const counts = getUnreadMessageCounts?.data?.counts;
      if (activeChat?._id) {
        counts[activeChat._id] = 0;
      }
      setUnreadMessageCounts(counts);
      getAllEployeeList();
      getConnectionsList();
    }

  }, [getUnreadMessageCounts]);

  useEffect(() => {
    scrollToMsg();
    // scrollToBottom();
  }, [messages]);

  useEffect(() => {
    handleSortConnectionList();
  }, [friendsList, groupsList]);

  // api handling
  useEffect(() => {
    if (!isConnectedUsersPending) {
      if (getConnectedUsersResError) {
        return;
      }
      if (getConnectedUsersRes) {
        if (getConnectedUsersRes?.data) {
          setFriendsList(getConnectedUsersRes.data);
        }
      }
    }
  }, [
    isConnectedUsersPending,
    getConnectedUsersRes,
    getConnectedUsersResError,
  ]);

  useEffect(() => {
    if (!isConnectedGroupsPending) {
      if (getConnectedGroupsResError) {
        return;
      }
      if (getConnectedGroupsRes) {
        if (getConnectedGroupsRes?.data) {
          setGroupsList(getConnectedGroupsRes.data);
        }
      }
    }
  }, [
    isConnectedGroupsPending,
    getConnectedGroupsRes,
    getConnectedGroupsResError,
  ]);

  const viewFileAttachmentModal = () => {
    setIsFileAttachmentModalOpen(!isFileAttachmentModalOpen);
    if (fileAttachment?.length !== 0) {
      setIsFileAttachmentModalOpen(true);
    } else {
      setIsFileAttachmentModalOpen(false);
    }
  };
  // set send msg btn active
  useEffect(() => {
    if (messageText?.length) {
      setIsActiveSendMsgBtn(true);
    }
  }, [messageText]);

  const scrollToMsg = () => {
    messagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToBottom = () => {
    messagesBoxEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getAllEployeeList = async () => {
    console.log('--GET_EMP_MESSAGE--', {empList: empList?.length || 'NA'});
    if (empList?.length) {
      return;
    }
    let apiRes = await api.get(config.getAllEmployeeList);
    

    if (apiRes?.data) {
      const list = apiRes.data;
     let newlist=list&&list.length>0?list.filter((emp)=>emp?.account_status!="inactive"):[]
      newlist.map(item => {
        item.fullname = `${item.first_name} ${item.last_name}`;
        item.name = `${item.first_name} ${item.last_name}`
      });
      newlist.sort(function (a, b) {
        return a.first_name?.trim().localeCompare(b.first_name.trim());
      });
      setEmpList(newlist);
    }
  };

  const getConnectionsList = async () => {
    navigateToGetConnectedUsersRequest({
      user_id: loggedUserInfo?.id,
    });
  };

  const getGroupList = async () => {
    navigateToGetConnectedGroupsRequest({
      user_id: loggedUserInfo?.id,
    });
  };

  const handleSendMsgOnEnter = e => {
    if(e?.keyCode==50){
      setShowSugestion(true)

    }if(showSugestion){
      setShowSugestion(false)

    }
    
    const keyCode = e?.keyCode || '';
    if (keyCode === 13) {
      handleOnClickSendButton();
    }
  };
  const handleOnChange = e => {
    
    const { name, value } = e.target;
   
  
    switch (name) {
      case 'message':
        setMessageText(value);
        break;
      case 'group-type':
        setGroupType(value);
        break;
      case 'group-name':
        setGroupName(value);
        break;
      case 'group-desc':
        setGroupDesc(value);
        break;
      case 'search-connection':
        setSearchTextConnectionList(value);
        if (value) {
         
          const datalist = [...friendsList, ...groupsList, ...empNotInRoom];
          // const datalist=connectionList
          const searchTxt = value.toLowerCase();
          const searchedData = datalist?.filter(item => {
            if (
              (item?.name && item?.name?.toLowerCase().includes(searchTxt)) ||
              (item?.group_name &&
                item?.group_name?.toLowerCase().includes(searchTxt))
            ) {
              return item;
            }
          });
          handleSortConnectionList(searchedData);
        } else {
          handleSortConnectionList();
        }
        break;
      default:
     
        break;
    }
  };

  const handleSelectAttachments = async e => {
    const { files } = e.target;
    let fileArr = [];
    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        fileArr.push(files[i]);
      }
      // filesArr = Object.values(files).map(item => item);
    }
    setFileAttachment([...fileAttachment, ...fileArr]);
    forceUpdate();
    if (fileArr?.length) {
      setIsFileAttachmentModalOpen(true);
    }
    fileArr = [];
  };

  const handleOnRemoveAttachment = (e, index) => {
    let temp = fileAttachment;
    temp.splice(index, 1);
    setFileAttachment(temp);
    forceUpdate();
    viewFileAttachmentModal();
  };

  const handleCloseActiveChat = () => {
    getConnectionsList()
    getGroupList()
    setActiveChat(null);
    setActiveChatTab('chats')
  };

  const handleOpenChat = async item => {
    
    if(item?.type=="group"){
      let groupMembers=[];
      groupMembers=item?.members.length>0&&item?.members.reduce((acc,curr)=>[...acc,curr?.info],[])
      // console.log("item nnnnnnnnnn",groupMembers)
      setGroupMemberDeatils(groupMembers)
    }else{
      setGroupMemberDeatils([])
    }

    setActiveChat(item);
    setMessages([]);
    setIsLoadingMessageList(true);

    let apiRes = await api.post(config.getMessages, {
      user_id: loggedUserInfo?.id,
      conversation_id: item._id,
      topic: item._id,
      type: item.type,
    });


    if (apiRes?.data) {
      setMessages(apiRes?.data);

      //
      if (getUnreadMessageCounts && getUnreadMessageCounts?.data?.counts) {
        const counts = getUnreadMessageCounts?.data?.counts;
        if (item?._id) {
          counts[item._id] = 0;
        }
        navigateToGetUpdateUnreadCounts(counts);
      }
    }
    setIsLoadingMessageList(false);
  };

  const handleOnClickSendButton = async () => {
    if (fileAttachment.length) {
      let formData = new FormData();
      for (let i = 0; i < fileAttachment.length; i++) {
        formData.append('image', fileAttachment[i]);
      }
      let apiRes = await api.putPostFileFun(config.addAttachments, formData);
      if (apiRes?.data) {
        for (let i = 0; i < apiRes?.data?.length; i++) {
          let obj = apiRes?.data[i];
          delete obj.versionInfo;
          delete obj.filePath;
          delete obj.AITags;
          if (obj.fileType !== 'image') {
            obj['thumbnailUrl'] = '';
          }
          handleOnSendMessage(obj);
        }
        if (messageText?.trim() !== '') {
       
          handleOnSendMessage();
        }
        setFileAttachment([]);
      }
    } else {
      handleOnSendMessage();
    }
  };

  const handleOnSendMessage = async (filePayload = null) => {
    let isError = false;
    let errorMsg = '';
    if (messageText?.trim() === '' && fileAttachment.length === 0) {
      isError = true;
      errorMsg = appConstants.validations.fieldReq.replace('$FIELD', 'Message');
    }
    if (isError) {
      toastAlert(errorMsg, { type: 'error' });
      return;
    }

    setIsLoadingSendMessage(true);
    let receiverId = '';
    let conversationId = activeChat._id;
    if (activeChat.type === 'p2p') {
      conversationId = '';
      receiverId =
        loggedUserInfo?.id == activeChat.user_id
          ? activeChat.friend_id
          : activeChat.user_id;
    }
    const params = {
      topic: activeChat._id,
      conversation_id: conversationId,
      qos: 2,
      payload: JSON.stringify({
        sender_id: loggedUserInfo?.id || null,
        receiver_id: receiverId,
        message: messageText,
        type: 'text',
      }),
    };
    if (filePayload) {
      const payload = JSON.stringify({
        ...filePayload,
        sender_id: loggedUserInfo?.id || null,
        receiver_id: receiverId,
        message: '',
        type: 'file',
      });
      params.payload = payload;
    }
    let apiRes = await api.post(config.sendMessage, params);
   

    if (apiRes?.data) {
      navigateToMqttPublish({
        topic: params.topic,
        qos: params.qos,
        payload: { type: 'message', data: apiRes?.data },
      });
      if (apiRes?.data.sender_id == loggedUserInfo?.id) {
        navigateToMqttClientOnMessage({ data: apiRes?.data });
      }
      setMessageText('');
      setIsActiveSendMsgBtn(false);
      setIsFileAttachmentModalOpen(false);
    }
    setIsLoadingSendMessage(false);
  };

  const handleAddConnection = async selectedUser => {
    setIsLoadingAddConnection(true);
    setConnectingToUserData(selectedUser);
    const params = {
      user_id: loggedUserInfo?.id || null,
      friend_id: selectedUser.id,
    };
    let apiRes = await api.post(config.addUserConnection, params);
  

    if (apiRes?.data) {
      toggleAddConnectionModal();
      getConnectionsList();
      navigateToMqttTopicSubscribe({ topic: apiRes?.data._id, qos: 1 });
    }
    setIsLoadingAddConnection(false);
    setConnectingToUserData(null);
  };

  const handleSeachAddConnection = async (id) => {
    setLoader(true)


    const params = {
      user_id: loggedUserInfo?.id || null,
      friend_id: id,
    };
    let apiRes = await api.post(config.addUserConnection, params);
  

    if (apiRes?.data) {
      let userConnection=await api.get(config.getUserConnections,true,{user_id:loggedUserInfo?.id || null})
      if(userConnection?.data.length){
        let AllFriendList=userConnection?.data
        AllFriendList=AllFriendList&&AllFriendList.length>0&&AllFriendList.filter((item)=>item?.friend_id==id)
        navigateToMqttTopicSubscribe({ topic: apiRes?.data._id, qos: 1 });
        setLoader(false)
        handleOpenChat(AllFriendList[0])
        
      }

      // getConnectionsList();
     
    }



  }

  const handleOnSelectGroupMemeber = userId => {
    const members = selectedGroupMembers;
    const index = members.indexOf(userId);
    if (index > -1) {
      members.splice(index, 1);
    } else {
      members.push(userId);
    }
    setSelectedGroupMembers(members);
    forceUpdate();
    
  };

  const handleAddUpdateGroup = async e => {
    const params = {
      user_id: loggedUserInfo?.id || null,
      group_id: e?.target?.name === 'update' ? activeChat?._id : null,
      group_desc: groupDesc,
      group_name: groupName,
      group_type: groupType,
      group_memebers: selectedGroupMembers?.length
        ? selectedGroupMembers.join(',')
        : '',
    };

    let isError = false;
    let errorMsg = '';
    if (params.group_name === '') {
      isError = true;
      errorMsg = appConstants.validations.fieldReq.replace(
        '$FIELD',
        'Group name',
      );
    } else if (params.group_desc === '') {
      isError = true;
      errorMsg = appConstants.validations.fieldReq.replace(
        '$FIELD',
        'Group description',
      );
    } else if (params.group_memebers === '') {
      isError = true;
      errorMsg = appConstants.validations.fieldReq.replace(
        '$FIELD',
        'Group member',
      );
    }
    if (isError) {
      toastAlert(errorMsg, { type: 'error' });
      return;
    }

    setIsLoadingAddUpdateGroup(true);
    let apiRes = await api.post(config.addUserGroup, params);
   

    if (apiRes?.data) {
      toggleAddUpdateGroupModal();
      getGroupList();
      navigateToMqttTopicSubscribe({ topic: apiRes?.data._id, qos: 1 });
    }
    setIsLoadingAddUpdateGroup(false);
  };

  

  const toggleAddConnectionModal = () => {
    setAddUpdateGroupModalOpen(false);
    setAddConnectionModalOpen(!addConnectionModalOpen);
  };

  const toggleAddUpdateGroupModal = e => {
    const name = e?.target?.name || '';
    if (name === 'updateGroup') {
      const { group_desc, group_name, type, members } = activeChat;
      const mIdsArr = [];
      if (members?.length) {
        members.forEach(elem => {
          mIdsArr.push(parseInt(elem.participant_id));
        });
      }
      setSelectedGroupMembers(mIdsArr);
      setGroupDesc(group_desc);
      setGroupName(group_name);
      setGroupType(type);
      setGroupAction('update');
    } else {
      setSelectedGroupMembers([]);
      setGroupDesc('');
      setGroupName('');
      setGroupType('group');
      setGroupAction('add');
    }

    setAddConnectionModalOpen(false);
    setAddUpdateGroupModalOpen(!addUpdateGroupModalOpen);
  };

  const handleDeleteConversation = async data => {
    let url = config.deleteUserGroup;
    if (data?.type === 'p2p') {
      url = config.deleteUserConnection;
      setIsLoadingFriendList(true);
    } else {
      setIsLoadingGroupList(true);
    }
    const params = {
      id: data?._id || null,
    };
    let apiRes = await api.post(url, params);
  

    if (apiRes?.data) {
      if (data?.type === 'p2p') {
        getConnectionsList();
      } else {
        getGroupList();
      }
    }
    setIsLoadingFriendList(false);
    setIsLoadingGroupList(false);
    setActiveChat(null);
  };

  const handleEditGroup = async data => { };

  const handleSortConnectionList = (dataArr = null) => {
    // const datalist = dataArr ? dataArr : [...friendsList, ...groupsList]
    const datalist = dataArr ? dataArr : [...friendsList]
    // if (datalist?.length) {
    //   datalist.sort(function (a, b) {
    //     const aName = a?.name || a.group_name || '';
    //     const bName = b?.name || b.group_name || '';
    //     return aName?.trim().localeCompare(bName.trim());
    //   });
    // }
    const sortedList = sortListByUnreadMsg(datalist);
    setConnectionList(sortedList);
    forceUpdate();
    return sortedList;
  };

  const renderFriendAndGroupList = () => {
    
    let list = null;
    if (connectionList?.length) {
      list = connectionList.map((item, key) => {
        if (
          isTypeGroup(item.type) ||
          loggedUserInfo?.id == item.user_id || !item?.friend_id ||
          loggedUserInfo?.id == item.friend_id
        ) {

          let name = '';
          if (isTypeGroup(item.type)) {
            name = item.group_name;
          } else {
            name = item.name;
          }
          return (
            <li key={key} >
              <a
                className={`chatToggle d-flex justify-content-between unread ${activeChat?._id === item._id ? 'active' : ''
                  }`}
                onClick={() => { (item?.friend_id||item?.group_name) ? handleOpenChat(item) : handleSeachAddConnection(item?.id) }}
              >
                <div className="d-flex" >
                  <div className="chat-profile position-relative" >
                    <img src="/images/logo.png"  alt="imagegirl" style={{border:"orange 2px solid",padding:'4px'}} />
                  { item?.type==='p2p'&& <span className='livebutton-container' >
                      <i className="fa fa-circle livebutton"></i>
                      </span>}
                    {/* <span className="profileMenshion">@</span> */}
                  </div>
                  <div className="conntxt">
                    <strong >{name}</strong>
                    {/* <p>Sample</p> */}
                  </div>
                </div>
                <div className="text-end">
                  {/* <strong className="msgTimes">2:04 PM</strong> */}
                  {unreadMessageCounts?.[item._id] > 0 && (
                    <span className="msgCount">
                      {unreadMessageCounts[item._id]}
                    </span>
                  )}
                </div>
              </a>
            </li>
          );
        } else {
          return null;
        }
      });
    }

    return <>{list}</>;
  };

  const renderMsgDateSeperator = (message, dateNum) => {
    msgDatesTmpArr.push(dateNum);
    return (
      <div className="msgtimer">
        {convertDate(message.createdAt, 'DD-MM-YYYY')}
      </div>
    );
  };

  const renderMsgItem = () => {
    let list = null;
    if (messages?.length) {
      list = messages.map((message, index) => {
        const dateNum = convertDate(message.createdAt, 'DDMMYYYY');

        return (
          <div
            key={index}
            id={`msg_${message._id}`}
            ref={el => {
              messagesRef.current = el;
              if (index === messages.length - 1) scrollToMsg();
            }}
            //ref={messagesRef}
            className="chat-box-wrapper mb-3"
          >
            {msgDatesTmpArr.indexOf(dateNum) > -1
              ? null
              : renderMsgDateSeperator(message, dateNum)}

            <Distributor
              message={message}
              user={loggedUserInfo}
              toggleFilePreviewModal={toggleFilePreviewModal}
            />
          </div>
        );
        // } else {
        //   return null;
        // }
      });
    } else {
      list = <CListGroupItem>No Messages Found</CListGroupItem>;
    }
    return (
      <CListGroup>
        {list}
        <div ref={messagesBoxEndRef} />
      </CListGroup>
    );
  };

  const getChatActiveInfo = field => {
    let data = null;
    if (!activeChat) {
      return data;
    }

    data = activeChat;
    data.isSendMsg = true;
    data.isGroupAdmin = false;
    data.isShowP2pDeleteBtn = false;
    data.isShowGroupDeleteBtn = false;

    if (activeChat.type === 'p2p') {
      data.name = activeChat.name;
      data.isShowP2pDeleteBtn =
        loggedUserInfo?.id == data.user_id ||
        loggedUserInfo?.id == data.friend_id;
    } else if (isTypeGroup(activeChat.type)) {
      data.name = activeChat.group_name;
      data.members = activeChat.members;

      if (activeChat?.members?.length) {
        const memberObj = activeChat.members.filter(
          item => item.participant_id == loggedUserInfo?.id,
        );
        data.loggedMember = memberObj?.length ? memberObj[0] : null;
        data.isGroupAdmin = data?.loggedMember?.is_admin || false;
        data.isShowGroupDeleteBtn = data?.loggedMember?.is_admin || false;

        if (data.type === 'broadcast') {
          data.isSendMsg = data?.loggedMember?.is_admin || false;
        }
      }
    }

    return data;
  };

  const handleAddEmoji = emojiData => {
    let emoji = emojiData.native;
    let position = messagesInputRef.current.selectionStart;
    let msg = [
      messageText?.slice(0, position) || '',
      emoji,
      messageText?.slice(position) || '',
    ].join('');
    setTimeout(() => {
      messagesInputRef.current.selectionStart = messagesInputRef.current.selectionEnd =
        position + 2;
    }, 70);
    setMessageText(msg);
  };

  const toggleEmojiPicker = () => {
    setIsShowEmpojiPicker(!isShowEmpojiPicker);
  };

  const toggleChatMinMaxSize = () => {
    setIsChatMinimized(!isChatMinimized);
  };

  const handleSwitchChatTab = e => {
    const { name } = e.target;
    setActiveChatTab(name);
    setActiveChat(null)
    if (name === 'activity') {
      handleSortConnectionList();
    } else if (name === 'chats') {
      handleSortConnectionList([...friendsList]);
    } else if (name === 'groups') {
      handleSortConnectionList(groupsList);
    }
  };

  const toggleFilePreviewModal = (file = null) => {
    setIsFilePreviewModal(!isFilePreviewModal);
    setPreviewFileData(file || '');
  };
  useEffect(()=>{
    let allFriendsId=friendsList&&friendsList.length>0?friendsList.reduce((acc,curr)=>[...acc,parseInt(curr?.friend_id)],[]):[]
    let newEmpList=empList&&empList.length>0?empList.filter((emp)=>!allFriendsId.includes(emp?.id)):[]
    setEmpNotInroom(newEmpList)
  },[friendsList,empList])

  const closeChat=()=>{
    hideChatPopup()
    handleCloseActiveChat()
  }

  const activeChatInfo = getChatActiveInfo();
  useEffect(()=>{
    let unreadMsgOfGroup=0;
    let unreadMsgOfPerson=0;
    for(let i=0;i<=friendsList.length;i++){
      if(friendsList&&friendsList.length>0&&getUnreadMessageCounts?.data?.counts?friendsList[i]?._id in getUnreadMessageCounts?.data?.counts:false){
        unreadMsgOfPerson=unreadMsgOfPerson+getUnreadMessageCounts?.data?.counts[friendsList[i]?._id]
        console.log("C:::", {getUnreadMessageCounts, f: friendsList[i]})
      }

    }
    for(let i=0;i<=groupsList.length;i++){
      if(groupsList&&groupsList.length>0&&getUnreadMessageCounts?.data?.counts?groupsList[i]?._id in getUnreadMessageCounts?.data?.counts:false){
        unreadMsgOfGroup=unreadMsgOfGroup+getUnreadMessageCounts?.data?.counts[groupsList[i]?._id]
      }
    }
    setUnreadMsgOfGroup(unreadMsgOfGroup)
    setUnreadMsgOfPerson(unreadMsgOfPerson)
  },[getUnreadMessageCounts])

  const handleAutoSuggest=(e)=>{
    setAutoSuggest(e.target.value)
    const messageTextWithAutosuggestName=messageText+e.target.value
    setMessageText(messageTextWithAutosuggestName)
    setShowSugestion(false)    
  }

  const sortListByUnreadMsg = (datalist, type = 'friendlist') => {
    let updatedlist = null;
    if (unreadMessageCounts && datalist?.length) {
      updatedlist = datalist.sort((a, b) => {
        const valueA = unreadMessageCounts[a._id];
        const valueB = unreadMessageCounts[b._id];

        if (valueA === undefined && valueB === undefined) {
          return a.name.localeCompare(b.name); // Sort by name if both ids are not found
        } else if (valueA === undefined) {
          return 1; // If a.id is not found, move it to the end
        } else if (valueB === undefined) {
          return -1; // If b.id is not found, move it to the end
        } else {
          return valueA - valueB; // Sort based on their value in array2
        }
      });
    }
    console.log('--SORT--', {counts: 0, datalist, updatedlist, unreadMessageCounts});
    return updatedlist || datalist;
  }

  return (
    <>
      <div
        className={`chatSection ${isChatMinimized ? 'minimize' : ''} ${isShowChatPopup ? 'open' : ''
          }`}
      >
        <div className="mainHeader d-flex flex-wrap justify-content-between align-items-center">
          <div className="logoLeft">
            <img src="/images/chat-img/glueple.png" alt="" width={100} />
          </div>
          <div className="userSearch">
            <input
              type="text"
              className="form-control"
              placeholder="Search people, groups"
              onChange={handleOnChange}
              name="search-connection"
              value={searchTextConnectionList}
            />
          </div>
          <div className="profileMain">
            <a className="profile-image">
              <img src="/images/chat-img/profile-image.png" alt="" />
            </a>
            <button
              type="button"
              className="sizeToggle"
              onClick={toggleChatMinMaxSize}
            >
              {isChatMinimized ? (
                <img
                  src="/images/chat-img/maximizeImg.svg"
                  className="maximizeImg"
                  alt=""
                  width={20}
                />
              ) : (
                <img
                  src="/images/chat-img/minimizeImg.svg"
                  className="minimizeImg"
                  alt=""
                  width={20}
                />
              )}
            </button>
            <button
              type="button"
              className="chatCloseToggle"
              onClick={closeChat}
            >
              <i className="bi bi-x-lg" />
            </button>
          </div>
        </div>
        <div className="chatMain d-flex flex-wrap bg-white">
          <div className="userleft d-flex ">
            <div className="chatTab">
              <ul className="nav justify-content-center text-center flex-column w-100">
                {/* <li>
                  <button
                    className="active"
                    data-bs-toggle="tab"
                    data-bs-target="#activityTab"
                    type="button"
                    name="activity"
                    onClick={handleSwitchChatTab}
                  >
                    <img
                      src="/images/chat-img/activity-icon.svg"
                      alt=""
                      className="d-block mx-auto mb-1"
                    />
                    <span>Activity</span>
                  </button>
                </li> */}
                <li>
                  <button
                    data-bs-toggle="tab"
                    className={activeChatTab==="chats"?'active':''}
                    data-bs-target="#ChatsTab"
                    type="button"
                    name="chats"
                    onClick={handleSwitchChatTab}
                  >
                    <img
                      src="/images/chat-img/chat-icon.svg"
                      alt=""
                      className="d-block mx-auto mb-1"
                    />
                    <span>Chats</span>
                    {unreadMsgOfPerson!=0&&<span style={{color:"inherit",fontSize:"15px"}} className='badge'>{unreadMsgOfPerson}</span>}
                  </button>
                </li>
                <li>
                  <button
                    // className="w-100"
                    className={activeChatTab==="groups"?'w-100 active':''}
                    data-bs-toggle="tab"
                    data-bs-target="#groupTab"
                    type="button"
                    name="groups"
                    onClick={handleSwitchChatTab}
                  >
                    <img
                      src="/images/chat-img/group-chat.svg"
                      alt=""
                      className="d-block mx-auto mb-1"
                      width={19}
                    />
                    <span>Groups</span>
                   {unreadMsgOfGroup!=0&& <span style={{color:"inherit",fontSize:"15px"}} className='badge'>{unreadMsgOfGroup}</span> }
                  </button>
                </li>
              </ul>
            </div>
            <div className="tab-content">
              <div
                className={`tab-pane fade ${activeChatTab === 'activity' ? 'show active' : ''
                  }`}
                id="activityTab"
              >
                <div className="chatGroup">
                  <div className="titleDiv d-flex align-items-center justify-content-between">
                    <h6>Activity</h6>
                    {isChatMinimized &&
                    <div className='emp-search-container'>
                      <input type="text"
                      style={{ width: '100%' }}
                      className=""
                     
                      onChange={handleOnChange}
                      name="search-connection"
                      value={searchTextConnectionList}></input>
                      <label>Search</label>

                    </div>
                    }
                    <div className="">
                      <button
                        className="createGroupButton2"
                        type="button"
                        title="Add Connection"
                        onClick={toggleAddConnectionModal}
                      >
                        <img
                          src="/images/chat-img/new-chat.svg"
                          alt=""
                          width={14}
                        />{' '}
                      </button>
                      <button
                        className="createGroupButton2"
                        type="button"
                        title="Add Group"
                        onClick={toggleAddUpdateGroupModal}
                      >
                        <img
                          src="/images/chat-img/createGroup2.svg"
                          alt=""
                          width={20}
                        />{' '}
                      </button>
                    </div>
                  </div>

                  <ul className="chatList">
                    {activeChatTab === 'activity' && renderFriendAndGroupList()}
                  </ul>
                </div>
              </div>
              <div
                className={`tab-pane fade ${activeChatTab === 'chats' ? 'show active' : ''
                  }`}
                id="ChatsTab"
              >
                <div className="chatGroup">
                  <div className="titleDiv d-flex align-items-center justify-content-between">
                    <h6>Chats</h6>
                    {isChatMinimized &&
                      <div className='emp-search-container'>
                        <input type="text"
                          onChange={handleOnChange}
                          name="search-connection"
                          value={searchTextConnectionList}></input>
                        <label>Search</label>
                      </div>
                    }

<div className="">
                      {/* <button
                        className="createGroupButton2"
                        type="button"
                        title="Add Connection"
                        onClick={toggleAddConnectionModal}
                      >
                        <img
                          src="/images/chat-img/new-chat.svg"
                          alt=""
                          width={14}
                        />{' '}
                      </button> */}
                      <button
                        className="createGroupButton2"
                        type="button"
                        title="Add Group"
                        onClick={toggleAddUpdateGroupModal}
                      >
                        <img
                          src="/images/chat-img/createGroup2.svg"
                          alt=""
                          width={20}
                        />{' '}
                      </button>
                    </div>
                  </div>
                  {loader?<div style={{display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center"}}>Wait connecting user....</div>:
                   <ul className="chatList">
                   {activeChatTab === 'chats' && renderFriendAndGroupList("chats")}
                 </ul>
                  }
                 
                </div>
              </div>
              <div
                className={`tab-pane fade ${activeChatTab === 'groups' ? 'show active' : ''
                  }`}
                id="groupTab"
              >
                <div className="chatGroup">
                  <div className="titleDiv d-flex align-items-center justify-content-between">
                    <h6>Groups</h6>
                    <div className="">
                      <button
                        className="createGroupButton2"
                        type="button"
                        title="Add Group"
                        onClick={toggleAddUpdateGroupModal}
                      >
                        <img
                          src="/images/chat-img/createGroup2.svg"
                          alt=""
                          width={20}
                        />{' '}
                      </button>
                    </div>
                  </div>
                  <ul className="chatList">
                    {activeChatTab === 'groups' &&
                      renderFriendAndGroupList('groups')}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {activeChat && (
            <div className="chatRight">
              <div className="chatModal chattopGallery" id="chatPanel1">
                <div className="chathead d-flex align-items-center justify-content-between">
                  <div className="d-flex">
                    <button
                      type="button"
                      className="text-black arrowPrivious closeModal  me-3"
                      onClick={handleCloseActiveChat}
                    >
                      <i className="bi bi-arrow-left-short" />
                    </button>
                    <div className="d-flex align-items-center">
                      <div className="chat-profile">
                        <img src="/images/logo.png" alt="imagegirl" />
                      </div>
                      <div className="conntxt">
                        <strong>{activeChatInfo?.name || ''}</strong>
                      </div>
                    </div>
                  </div>
                  <div className="filterTab">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <button
                          className="nav-link active"
                          data-bs-toggle="tab"
                          data-bs-target="#chatTabPanel"
                          type="button"
                        >
                          Chat
                        </button>
                      </li>
                      {/* <li className="nav-item">
                        <button
                          className="nav-link"
                          data-bs-toggle="tab"
                          data-bs-target="#filesTabPanel"
                          type="button"
                        >
                          Files
                        </button>
                      </li> */}
                      {/* <li className="nav-item">
                        <button
                          className="nav-link"
                          data-bs-toggle="tab"
                          data-bs-target="#photoTabPanel"
                          type="button"
                        >
                          Photos
                        </button>
                      </li> */}
                    </ul>
                  </div>
                  {/* <button
                    className="createGroupButton"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#createGroup"
                  >
                    <img
                      src="/images/chat-img/createGroup.svg"
                      alt=""
                      width={20}
                    />{' '}
                    Create Group
                  </button> */}
                  {activeChatInfo?.isGroupAdmin && (
                    <button
                      className="createGroupButton"
                      type="button"
                      name="updateGroup"
                      onClick={toggleAddUpdateGroupModal}
                    >
                      <img
                        src="/images/chat-img/createGroup.svg"
                        alt=""
                        width={20}
                      />{' '}
                      Update Group
                    </button>
                  )}
                  {(activeChatInfo?.isShowGroupDeleteBtn ||
                    activeChatInfo?.isShowP2pDeleteBtn) && (
                      <button
                        className="createGroupButton ml-2"
                        type="button"
                        name="deleteConv"
                        onClick={() => handleDeleteConversation(activeChat)}
                      >
                        {/* <img
                        src="/images/chat-img/createGroup.svg"
                        alt=""
                        width={20}
                      />{' '} */}
                        Delete
                      </button>
                    )}
                </div>
                <div className="chatbody chatrspnveBody">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="chatTabPanel"
                    >
                      <div className="chats">
                        {isLoadingMessageList ? (
                          <CustLoader size="lg" isShow={isLoadingMessageList} />
                        ) : (
                          renderMsgItem()
                        )}
                      </div>
                    </div>
                    <div className="tab-pane fade" id="filesTabPanel">
                      <div className="table-responsive filesTabPanel">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>
                                <div className="cstmCheck">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="allCheck"
                                  />
                                  <label htmlFor="allCheck" />
                                </div>
                              </th>
                              <th>Name</th>
                              <th>Shared on</th>
                              <th>Sent by</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="cstmCheck">
                                  <input type="checkbox" name="" id="Check" />
                                  <label htmlFor="Check" />
                                </div>
                              </td>
                              <td>
                                Website &amp; Mobile response issue[1].docx
                              </td>
                              <td>August 21, 2023</td>
                              <td>Rishabh. Kumawat</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="photoTabPanel">
                      <ul className="photoList">
                        <li>
                          <a href="img/images.jpg">
                            <img src="/images/chat-img/images.jpg" />
                          </a>
                        </li>
                        <li>
                          <a href="img/image-2.jpg">
                            <img src="/images/chat-img/image-2.jpg" />
                          </a>
                        </li>
                        <li>
                          <a href="img/five-image.jpg">
                            <img src="/images/chat-img/five-image.jpg" />
                          </a>
                        </li>
                        <li>
                          <a href="img/four-image.jpg">
                            <img src="/images/chat-img/four-image.jpg" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="chatFoot">
                  {activeChatInfo?.isSendMsg && (
                    <div className="msgInputgroup align-items-center">
                      <div className="msgleft">
                        {isShowEmpojiPicker && (
                          <ClickAwayListener onClickAway={toggleEmojiPicker}>
                            <div className="emoji-elem">
                              <EmojiPicker
                                data={emojiData}
                                onEmojiSelect={handleAddEmoji}
                              />
                            </div>
                          </ClickAwayListener>
                        )}
                        <div className="msgInput position-relative">
                          <button
                            type="button"
                            className="emojitoggle"
                            onClick={toggleEmojiPicker}
                          >
                            <img
                              src="/images/chat-img/emoji-icon.svg"
                              alt=""
                              width={23}
                            />
                          </button>

                          <input
                            ref={messagesInputRef}
                            className="form-control"
                            name="message"
                            id="msg-input"
                            type="text"
                            placeholder="Type a message"
                            value={messageText}
                            onChange={handleOnChange}
                            onKeyDown={handleSendMsgOnEnter}
                          />
                        </div>
                       
                      </div>
                      <input
                        id="attachmentsFile"
                        type="file"
                        className="mstBtn addfileToggle"
                        multiple
                        name="attachments"
                        style={{ display: 'none', background: '#ececec' }}
                        onChange={handleSelectAttachments}
                      ></input>
                      <label htmlFor="attachmentsFile" className="mb-0">
                        <img
                          src="/images/chat-img/add-file.svg"
                          alt="attachments"
                          width={20}
                        />
                      </label>
                      {/* <button type="button" className="mstBtn audioToggle">
                        <img
                          src="/images/chat-img/text-style.svg"
                          alt=""
                          width={25}
                          height={20}
                        />
                      </button>
                      <button type="button" className="mstBtn createPollToggle">
                        <img
                          src="/images/chat-img/create-poll.svg"
                          alt=""
                          width={20}
                        />
                      </button> */}
                      <button
                        type="button"
                        className="mstBtn sendBtn"
                        onClick={handleOnClickSendButton}
                        disabled={!isActiveSendMsgBtn || isLoadingSendMessage}
                      >
                        {isLoadingSendMessage ? (
                          <CustLoader size="sm" isShow={true} color="primary" />
                        ) : (
                          <img
                            src="/images/chat-img/send-msg.svg"
                            alt=""
                            width={25}
                          />
                        )}
                      </button>
                    </div>
                  )}
                  {groupMemberDetails&&groupMemberDetails.length>0&&showSugestion&&
                     <div style={{display:"flex",position:"absolute",marginLeft:'50px'}}>
                     <select onChange={(e)=>handleAutoSuggest(e)}>
                       {groupMemberDetails&&groupMemberDetails.length>0&&groupMemberDetails.map((mem,item)=>(
                         <option value={mem?.name} key={mem?.emp_id}>
                         {mem?.name}
                         </option>

                       ))}
                       
                     </select>
                   </div>
                  }

                  {!activeChatInfo?.isSendMsg && (
                    <div className="text-center">
                      <span className="text-secondary">
                        Only <span className="text-primary">admins</span> can
                        send messages
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="welcomeScreen">
                <div className="d-flex align-items-center">
                  <figure className="m-0">
                    <img
                      src="/images/chat-img/profile-image.png"
                      alt="profileimage"
                      width={70}
                      height={70}
                    />
                  </figure>
                  <div className="ps-3">
                    <h1 className="fw-light mb-0 fs-5">WelCome!</h1>
                    <span className="fw-bold fs-4">Rishabh.kumawat</span>
                  </div>
                </div>
                <div className="card p-4 mt-4">
                  <div className="row">
                    <div className="col-md-6">
                      <lottie-player
                        className="m-auto"
                        src="/images/chat-img/Hand shake.json"
                        background="transparent"
                        speed={1}
                        style={{ width: 350, height: 250 }}
                        loop=""
                        autoPlay=""
                      ></lottie-player>
                    </div>
                    <div className="col-md-6">
                      <p className="fs-5 my-3 fw-normal">
                        Here are some quick actions to get you started
                      </p>
                      <div className="footerText mx-auto">
                        <span className="fw-semibold">
                          You are signed in as{' '}
                          <em>rishabh.kumawat@qdegrees.org</em>
                        </span>
                        <p className="mb-2">
                          Try switching accounts if you do not see your contacts
                          or conversation history.
                        </p>
                        <a href="#" className="fw-bold">
                          <u>Learnmore</u>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          )}
        </div>
      </div>
      <AddConnectionModal
        open={addConnectionModalOpen}
        handleCancel={toggleAddConnectionModal}
        handleConnect={handleAddConnection}
        empList={empList}
        connectingToUserData={connectingToUserData}
        isLoadingAddConnection={isLoadingAddConnection}
      />

      <AddUpdateGroupModal
        open={addUpdateGroupModalOpen}
        empList={empList}
        handleCancel={toggleAddUpdateGroupModal}
        handleOnChange={handleOnChange}
        handleAddUpdateGroup={handleAddUpdateGroup}
        handleOnSelectGroupMemeber={handleOnSelectGroupMemeber}
        selectedGroupMembers={selectedGroupMembers}
        isLoadingAddUpdateGroup={isLoadingAddUpdateGroup}
        groupType={groupType}
        groupDesc={groupDesc}
        groupName={groupName}
        groupAction={groupAction}
      />
      <ChatAttachmenstModal
        isFileAttachmentModalOpen={isFileAttachmentModalOpen}
        viewFileAttachmentModal={viewFileAttachmentModal}
        fileAttachment={fileAttachment}
        handleSendMsgOnEnter={handleSendMsgOnEnter}
        handleOnClickSendButton={handleOnClickSendButton}
        handleOnChange={handleOnChange}
        handleSelectAttachments={handleSelectAttachments}
        handleOnRemoveAttachment={handleOnRemoveAttachment}
        messageText={messageText}
      />
      <FilePreviewModal
        isOpen={isFilePreviewModal}
        toggleModal={toggleFilePreviewModal}
        fileData={previewFileData}
      />
      <ToastContainer />
    </>
  );
};

const mapStateToProps = state => {
  return {
    isMqttInstancePending: mqttSelectors.getIsMqttInstancePending(state),
    getMqttClientInstance: mqttSelectors.getMqttClientInstance(state),
    getMqttClientConnectStatus: mqttSelectors.getMqttClientConnectStatus(state),
    getMqttClientMessagePayload: mqttSelectors.getMqttClientMessagePayload(
      state,
    ),

    getUnreadMessageCounts: messageSelectors.getUnreadMessageCountsRes(state),

    isConnectedUsersPending: messageSelectors.getIsConnectedUsersPending(state),
    getConnectedUsersRes: messageSelectors.getConnectedUsersRes(state),
    getConnectedUsersResError: messageSelectors.getConnectedUsersResError(
      state,
    ),

    isConnectedGroupsPending: messageSelectors.getIsConnectedGroupsPending(
      state,
    ),
    getConnectedGroupsRes: messageSelectors.getConnectedGroupsRes(state),
    getConnectedGroupsResError: messageSelectors.getConnectedGroupsResError(
      state,
    ),
  };
};
const mapDispatchToProps = dispatch => ({
  navigateToMqttPublish: data => dispatch(mqttActions.mqttPublish(data)),
  navigateToMqttTopicSubscribe: data =>
    dispatch(mqttActions.mqttTopicSubscribe(data)),
  navigateToMqttClientOnMessage: data =>
    dispatch(mqttActions.mqttClientOnMessage(data)),
  navigateToClearMqttStore: data => dispatch(mqttActions.clearMqttStore(data)),
  navigateToGetConnectedUsersRequest: data =>
    dispatch(messageActions.getConnectedUsersRequest(data)),
  navigateToGetConnectedGroupsRequest: data =>
    dispatch(messageActions.getConnectedGroupsRequest(data)),
  navigateToGetUpdateUnreadCounts: data =>
    dispatch(messageActions.getUnreadMessageCountsSuccess(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MessagesScreen);
