import React, { useState, lazy, useEffect } from 'react'
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import {
    CBadge, CNav,
    CInputRadio,
    CNavItem,
    CNavbar,
    CListGroup, CListGroupItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CTabs,
    CButton,
    CTextarea,
    CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
    CWidgetBrand,
    CButtonGroup,
    CWidgetIcon,
    CCard,
    CLink,
    CCardBody,
    CCardFooter,
    CInputFile,
    CCardHeader,
    CCol,
    CSwitch,
    CCollapse,
    CProgress,
    CProgressBar,
    CRow,
    CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
    CCallout
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { DocsLink } from 'src/reusable'
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import './booking_meeting_room.css'
import { validation } from '../../../../utils/configCommon'
import moment from 'moment';
import Moment from 'react-moment';
// import { rightArrowClass } from '@progress/kendo-react-layout';
import validator from 'validator';
import Alert from 'src/alert/alert';
import zIndex from '@material-ui/core/styles/zIndex';
// import 'moment-timezone';
import { testWhatsappMessage, sendWhatsappMessage, sendWhatsappMessageTemplateMedia } from '../../../../common/commanAPIFun'
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';
import {toast, ToastContainer} from 'react-toastify';

const BookingMeetingRoom = ({ match }) => {
    // let {  } = props
    let api = new API()
    const [active, setActive] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [bookingRoomName, setBookingRoomName] = useState("")
    const [getBookedMeeting, setGetBookedMeeting] = useState()
    const [isLeftNavShow, setIsLeftNavShow] = useState(false)
    const [isAdvance, setIsAdvance] = useState(false)
    const [snackesWater, setSnackesWater] = useState(false)
    const [cleaningRequired, setCleaningRequired] = useState(false)
    const [meetingAttendeeName, setMeetingAttendeeName] = useState("")
    const [meetingAttendeeEmail, setMeetingAttendeeEmail] = useState("")
    const [meetingAttendee, setMeetingAttendee] = useState([])
    const [currentTime, setCurrentTime] = useState()
    const [currentDate, setCurrentDate] = useState()
    const [maxDateLimit, setMaxDateLimit] = useState()
    const [isAlert, setIsAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")

    // isAdvance
    const [meetingBookingDetails, setMeetingBookingDetails] = useState({
        number_of_persons: "",
        mobile_number: "",
        start_time: "",
        end_time: "",
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        meeting_type: "1",
        meeting_agenda: "",
        meetingAttendeeEmail: "",
        meetingAttendeeName: ""
    })

    useEffect(() => {
        // isAlertToggle("alert from my side")
        let current_time = moment(currentTime).format("hh:mm");
        let current_date = moment().format("YYYY-MM-DD");
        let max_date_limit = moment().add(2, 'M').format("YYYY-MM-DD");
        // alert(current_time)
        // alert(current_date)
        // alert(max_date_limit)
        setCurrentTime(current_time)
        setCurrentDate(current_date)
        setMaxDateLimit(max_date_limit)
        // console.log(meetingBookingDetails.start_date)
        // getAppliedResignationCanidate()
        // getAppliedRejectedResignation()
        let bookingroom = match.params.id
        setBookingRoomName(bookingroom)
        alreadyBookedMeeting(bookingroom)
    }, [])

    const isShowLeftNavSection = () => {
        setIsLeftNavShow(!isLeftNavShow)
    }
    const bookMeetingRoom = async () => {
        let data = {
            meeting_room_name: bookingRoomName && bookingRoomName,
            number_of_persons: meetingBookingDetails.number_of_persons,
            mobile_number: meetingBookingDetails.mobile_number,
            start_time: meetingBookingDetails.start_time,
            end_time: meetingBookingDetails.end_time,
            start_date: meetingBookingDetails.start_date,
            end_date: meetingBookingDetails.end_date,
            meeting_type_id: meetingBookingDetails.meeting_type,
            meeting_agenda: meetingBookingDetails.meeting_agenda,
            is_snacks_required: snackesWater,
            is_cleaning_required: cleaningRequired,
            meeting_attendee: JSON.stringify(meetingAttendee)
        }
        // console.log("data is", data)

        let result = await api.post(config.createBookingForRoom, data)
        if (result && result.code == 200) {
            // alert("Successfully booked")
            toast.success("Meeting Room Booked Successfully!");
            alreadyBookedMeeting(match.params.id)
            setMeetingAttendee([])
            setMeetingBookingDetails({
                number_of_persons: "",
                mobile_number: "",
                start_time: "",
                end_time: "",
                start_date: moment().format("YYYY-MM-DD"),
                end_date: moment().format("YYYY-MM-DD"),
                meeting_type: "1",
                meeting_agenda: "",
                meetingAttendeeEmail: "",
                meetingAttendeeName: ""
            })
            let panel_role = await getLocalDataAsObject("user")
            let params = [
                {
                    "default": JSON.parse(panel_role)[0].first_name
                },
                {
                    "default": data.meeting_room_name
                },
                {
                    "default": data.start_time
                },
            ]
            sendWhatsappMessageTemplateMedia("data.message", params, "meeting_room_booked")
            // setAppliedAssetsRequest(result.data)
        } else {
            toast.error(result && result.message)
        }
    }

    const alreadyBookedMeeting = async (bookingroom) => {
        let data = {
            meeting_room_name: bookingroom,
        }
        // console.log("data is", data)

        let result = await api.get(config.getCreatedMeetingRecords, true, data)
        if (result && result.code == 200) {
            // alert("Successfully getting booked meeting")
            // console.log("getbooking meeting", result)
            setGetBookedMeeting(result.data)
            // setAppliedAssetsRequest(result.data)
        } else {
            // alert("unable to getting booked meeting")
        }
    }


    const getAppliedRejectedAssets = async () => {
        let result = await api.get(config.getRejectedAssetRequestForEmployee)
        // console.log("geeting applied resignation", result)
        if (result && result.code == 200) {
            // setAppliedRejectedAssetsRequest(result.data)
        }
    }

    const isViewModalOpen = (data) => {
        setIsOpen(!isOpen)
    }
    const onChangeFun = (e) => {
        let { name, value } = e.target
        setMeetingBookingDetails({
            ...meetingBookingDetails,
            [name]: value
        })
    }
    const addMeetingAttendee = () => {

        if (meetingAttendeeName && meetingAttendeeEmail) {
            if (validator.isEmail(meetingAttendeeEmail)) {
                // alert("adding attendee")
                setMeetingAttendee([...meetingAttendee, { attendeeName: meetingAttendeeName, attendeeEmail: meetingAttendeeEmail }])
                // console.log("meetingatten", meetingAttendee)
                setMeetingAttendeeName("")
                setMeetingAttendeeEmail("")
            } else {
                toast.error("Email id not valid")
            }
        } else {
            toast.error("Blank field not allowed")
        }
    }

    const isAlertToggle = (msg) => {
        setIsAlert(!isAlert)
        setAlertMessage(msg)
    }
    const isAlertToggleClose = () => {
        setIsAlert(false)
    }

    return (
        <div className="meeting_room_booking_img">
            <div className="container">
                {/* <h4> book your meeting room</h4> */}
                <h2 className="text-white mediaClassForHeading" align="center"><p><b className="text-capitalize">{bookingRoomName.toLowerCase() == "gurukul" ? "Gurukool" : bookingRoomName}</b> conference&nbsp;room</p></h2>

                <div>
                    <div className="booking_form_section container mt-3">

                        <AvForm onValidSubmit={bookMeetingRoom} className="row">
                            <div className="col-lg-12">
                                {/* <h6 className="text-center mb-0">Register Now! It's Free!</h6> */}
                            </div>

                            {/* <div className="col-lg-12 input-group custom mb-0">
                            <div className="col-lg-12 custom mb-0">
                                <span className="input-group-text bg-transparent text-white border border-0">
                            <i className="fa fa-envelope" /></span>
                                <AvField type="email" name="email" disabled className="form-control rounded-0 bg-transparent text-white" placeholder="Enter Email..."
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "Email is required",
                                        },
                                        pattern: {
                                            value: validation.email,
                                            errorMessage:
                                                "Email should be correct",
                                        },

                                    }} />
                            </div>
                        </div> */}


                            <div className="col-lg-6 col-6 form-group custom mb-0">
                                <div className="custom mb-0">
                                    <span className="input-group-text bg-transparent text-white color-white border border-0">
                                        {/* <i className="fa fa-building" /> */}
                                        Meeting start date
                                    </span>
                                    {/* <AvInput type="date" format="DD-MM-YYYY" max="2003-01-01" min="1900-01-01" name="dob" errorMessage="Invalid Date" title="DD-MM-YYYY" value={registerForm.dob} onChange={onChangeInput} className="form-control rounded-0 bg-transparent text-white" placeholder="DD/MM/YYYY" */}

                                    {/* <input type="date" id="fecha-hasta" name="date" id="date" value="12-12-2021" defaultValue="12-12-2021" placeholder="date"/>
                                    */}
                                    <AvInput type="date" format="DD-MM-YYYY" min={currentDate} max={maxDateLimit} errorMessage="Invalid Date" title="DD-MM-YYYY" className="form-control rounded-2  text-black" name="start_date" onChange={onChangeFun} value={meetingBookingDetails.start_date}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Start Date is required",
                                            },
                                            dateRange: {
                                                format: 'YYYY-MM-DD', start: { value: currentDate }, end: { value: maxDateLimit }
                                                , value: true
                                                , errorMessage: "Age should be more current date",
                                            },
                                        }}
                                    />

                                </div>
                            </div>
                            <div className="col-lg-6 col-6 form-group custom mb-0">

                                <span className="input-group-text bg-transparent text-white border border-0">
                                    {/* <i className="fa fa-building" /> */}
                                    Meeting start time
                                </span>

                                {/* <AvField type="date" format="DD-MM-YYYY" max="2003-01-01" min="1900-01-01" name="dob" errorMessage="Invalid Date" title="DD-MM-YYYY" value={registerForm.dob} onChange={onChangeInput} className="form-control rounded-0 bg-transparent text-white" placeholder="DD/MM/YYYY" */}
                                <AvInput type="time" name="start_time" errorMessage="Invalid Time" title="00:00" className="form-control rounded-2  text-black" placeholder="00:00" onChange={onChangeFun} value={meetingBookingDetails.start_time}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "Start Time is required",
                                        },
                                        // dateRange: {
                                        //     format: 'DD-MM-YYYY', start: { currentTime }, end: { currentTime }
                                        //     , value: true
                                        //     , errorMessage: "Age should be more than 19 year",
                                        // },
                                    }} />

                            </div>
                            <div className="col-lg-6 col-6 form-group custom mb-0">
                                <div className=" custom mb-0">
                                    <span className="input-group-text bg-transparent text-white border border-0">
                                        {/* <i className="fa fa-building" /> */}
                                        Meeting end date
                                    </span>
                                    {/* <AvInput type="date" format="DD-MM-YYYY" max="2021-07-31" min="2022-01-01" errorMessage="Invalid Date" title="DD-MM-YYYY" className="form-control rounded-0 bg-transparent text-white" placeholder="DD-MM-YYYY" name="end_date" onChange={onChangeFun} value={meetingBookingDetails.end_date} */}
                                    <AvInput type="date" format="DD-MM-YYYY" min={meetingBookingDetails.start_date} max={maxDateLimit} errorMessage="Invalid Date" title="DD-MM-YYYY" className="form-control rounded-2  text-black" placeholder="DD-MM-YYYY" name="end_date" onChange={onChangeFun} value={meetingBookingDetails.end_date}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "End Date is required",
                                            },
                                            dateRange: {
                                                format: 'YYYY-MM-DD', start: { value: meetingBookingDetails.start_date }, end: { value: maxDateLimit }
                                                , value: true
                                                , errorMessage: "Age should be more than start date",
                                            },

                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-6 form-group custom mb-0">
                                <div className=" custom mb-0">
                                    <span className="input-group-text bg-transparent text-white border border-0">
                                        {/* <i className="fa fa-building" /> */}
                                        Meeting end time
                                    </span>
                                    {/* <AvInput type="date" format="DD-MM-YYYY" max="2003-01-01" min="1900-01-01" name="dob" errorMessage="Invalid Date" title="DD-MM-YYYY" value={registerForm.dob} onChange={onChangeInput} className="form-control rounded-0 bg-transparent text-white" placeholder="DD/MM/YYYY" */}
                                    <AvInput type="time" name="end_time" errorMessage="Invalid Time" title="00:00" className="form-control rounded-2  text-black" placeholder="00:00" onChange={onChangeFun} value={meetingBookingDetails.end_time}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "End Time is required",
                                            },
                                            // dateRange: {
                                            //     format: 'DD-MM-YYYY', start: { value: '01-01-1900' }, end: { value: '01-01-2003' }
                                            //     , value: true
                                            //     , errorMessage: "Age should be more than 19 year",
                                            // },
                                        }} />
                                </div>
                            </div>





                            <div className="col-lg-12 form-group custom mb-0">
                                <div className="custom mb-0">
                                    <span className="input-group-text bg-transparent text-white border border-0">
                                        {/* <i className="fa fa-phone" /> */}
                                        {/* Number of Member */}
                                    </span>

                                    <AvInput className="form-control rounded-2  text-black" type="number" name="number_of_persons" onChange={onChangeFun} value={meetingBookingDetails.number_of_persons}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Number of Member is required",
                                            },
                                            pattern: {
                                                value: validation.mobileno,
                                                errorMessage: "Please enter valid Number of Member",
                                            },
                                            maxLength: {
                                                value: 3,
                                                errorMessage: "Maximum 999 member allow",
                                            },

                                            // maxLength: {
                                            //     value: 10,
                                            //     errorMessage: "Mobile No. must be maximum 10 digits",
                                            // },
                                        }}
                                        placeholder="Number of Member..." />
                                </div>
                            </div>
                            {/* <div className="col-lg-12 input-group custom mb-0">
                            <div className="col-lg-12 custom mb-0">
                            <span className="input-group-text bg-transparent text-white border border-0">
                                    <i className="fa fa-building" />
                                    Name
                                </span>
                                <AvInput type="text" name="firstName" className="form-control rounded-0 bg-transparent text-white" placeholder="First name..."
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "Please Fill First Name",
                                        }, maxLength: {
                                            value: 30,
                                            errorMessage: "Your firstName must be 30 characters",
                                        },
                                    }} />
                            </div>
                        </div> */}


                            {/* </div> */}
                            {/* <div className="col-lg-12 input-group custom mb-0">
                            <div className="col-lg-12 custom mb-0">
                                <span className="input-group-text bg-transparent text-white border border-0">
                                    
                                    Mobile Number
                                </span>

                                <AvInput className="form-control rounded-0 bg-transparent text-white" type="number" name="mobile_number" onChange={onChangeFun} value={meetingBookingDetails.mobile_number}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "Mobile No. is required",
                                        },
                                        pattern: {
                                            value: validation.mobileno,
                                            errorMessage: "Please enter valid Mobile No.",
                                        },
                                        minLength: {
                                            value: 10,
                                            errorMessage: "Mobile No. must be minimum 10 digits",
                                        },
                                        maxLength: {
                                            value: 10,
                                            errorMessage: "Mobile No. must be maximum 10 digits",
                                        },
                                    }}
                                    placeholder="Enter Phone..." />
                            </div>
                        </div> */}


                            <div className="col-lg-12 form-group custom mb-0">
                                <div className=" custom mb-0">
                                    <span className="input-group-text bg-transparent text-white border border-0">
                                        {/* <i className="fa fa-building" /> */}
                                        {/* Meeting Type */}
                                    </span>
                                    <AvInput type="select" className="my-button-hide form-control rounded-2  text-black" name="meeting_type" onChange={onChangeFun} value={meetingBookingDetails.meeting_type}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Meeting Type is required",
                                            }
                                        }}>
                                        {/* <option hidden className="form-control rounded-0 bg-transparent ">Select meeting type</option> */}
                                        <option className="form-control rounded-0 bg-transparent " value="1">General Meeting</option>
                                        <option className="form-control rounded-0 bg-transparent " value="2">Internal Meeting</option>
                                        <option className="form-control rounded-0 bg-transparent " value="3">External Meeting</option>
                                        <option className="form-control rounded-0 bg-transparent " value="4">Client Meeting</option>
                                        <option className="form-control rounded-0 bg-transparent " value="5">OCM Meeting</option>
                                    </AvInput>
                                    {/* <AvInput type="text" maxLength="30" className="form-control rounded-0 bg-transparent text-white" placeholder="Meeting Type" name="meeting_agenda" onChange={onChangeFun} value={meetingBookingDetails.meeting_agenda}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "Meeting Type is required",
                                        },
                                        // pattern: {
                                        //     value: validation.city,
                                        //     errorMessage: "Please enter valid City Name.",
                                        // },

                                    }} /> */}
                                </div>
                            </div>
                            <div className="col-lg-12 form-group custom mb-0">
                                <div className="custom mb-0">
                                    <span className="input-group-text bg-transparent text-white border border-0">
                                        {/* <i className="fa fa-building" /> */}
                                        {/* Meeting Agenda */}
                                    </span>
                                    <AvInput type="text" maxLength="255" className="form-control rounded-2  text-black" placeholder="Meeting Agenda..." name="meeting_agenda" onChange={onChangeFun} value={meetingBookingDetails.meeting_agenda}
                                        validate={{
                                            required: {
                                                value: false,
                                                errorMessage: "Meeting agenda is required",
                                            },
                                        }
                                            // pattern: {
                                            //     value: validation.city,
                                            //     errorMessage: "Please enter valid City Name.",
                                            // },

                                        } />
                                    <button type="button" style={{ color: "lightblue" }} onClick={() => setIsAdvance(!isAdvance)} className="btn btn-link ml-4 mt-2 float-right" >Advance</button>
                                    {/* <div className="col-lg-12 form-group custom mb-0">
                                        <div className="custom mb-0"> */}
                                    {isAdvance &&
                                        <>
                                            <div className="my-check">

                                                <input type="checkbox" id="snackesWater" name="snackesWater" value={snackesWater} onClick={(e) => setSnackesWater(e.target.value)} />&nbsp;&nbsp;
                                                <label for="snackesWater">Snacks, tea & water for this meeting</label><br />
                                                <input type="checkbox" id="cleaningRequired" name="cleaningRequired" value={cleaningRequired} onClick={(e) => setCleaningRequired(e.target.value)} />&nbsp;&nbsp;
                                                <label for="cleaningRequired"> Cleaning is required before & after the meeting</label><br />
                                            </div>

                                            <div className="row">
                                                {meetingAttendee && meetingAttendee.length > 0 && meetingAttendee.map((attendee, index) => (
                                                    <>
                                                        <div className="col-lg-6 form-group custom mb-0">
                                                            <div className="custom mb-0">
                                                                <span className="input-group-text bg-transparent text-white border border-0">
                                                                </span>
                                                                <AvInput type="text" disabled name="name" maxLength="30" className="form-control rounded-2  text-black" value={attendee.attendeeName} />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 form-group custom mb-0">
                                                            <div className="custom mb-0">
                                                                <span className="input-group-text bg-transparent text-white border border-0">
                                                                </span>
                                                                <AvInput type="text" disabled name="email" maxLength="30" className="form-control rounded-2  text-black" value={attendee.attendeeEmail} />
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 form-group custom mb-0">
                                                    <div className="custom mb-0">
                                                        <span className="input-group-text bg-transparent text-white border border-0">
                                                            {/* <i className="fa fa-building" /> */}
                                                            {/* Meeting Agenda */}
                                                        </span>
                                                        <AvInput type="text" maxLength="30" className="form-control rounded-2  text-black" placeholder="Attendee Name..." name="meetingAttendeeName" onChange={(e) => setMeetingAttendeeName(e.target.value)} value={meetingAttendeeName}
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                    errorMessage: "Attendee name is required",
                                                                },
                                                            }
                                                            } />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 form-group custom mb-0">
                                                    <div className="custom mb-0">
                                                        <span className="input-group-text bg-transparent text-white border border-0">
                                                            {/* <i className="fa fa-building" /> */}
                                                            {/* Meeting Agenda */}
                                                        </span>
                                                        <AvInput type="email" maxLength="30" className="form-control rounded-2  text-black" placeholder="Attendee Email ID..." name="meetingAttendeeEmail" onChange={(e) => setMeetingAttendeeEmail(e.target.value)} value={meetingAttendeeEmail}
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                    errorMessage: "Attendee email is required",
                                                                },
                                                            }
                                                            } />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center mt-3 mb-5" style={{ margin: "auto" }}>
                                                {/* <button align="center" className="btn btn-outline-info btn-small" onClick={addMeetingAttendee}>Add meeting attendee</button> */}
                                                <button type="button" className="btn btn-outline-info text-white" onClick={addMeetingAttendee}><span className=""><i className="fa fa-plus text-white" aria-hidden="true"></i> &nbsp;Attendee</span></button>
                                            </div>
                                        </>


                                    }
                                    {/* </div>
                                </div> */}
                                </div>
                            </div>








                            <div className="col-lg-12 text-center text-center mt-3">
                                <button type="submit" className="btn btn-primary1 btn-block rounded-0 fixed-bottom button-room text-lg">BOOK MY ROOM</button>
                            </div>
                        </AvForm>
                    </div>
                    {isLeftNavShow == false &&
                        <div className="">
                            <button className="btn btn btn-primary btn-block rounded-0 fixed-top button-room" onClick={isShowLeftNavSection}>Meetings Scheduled for the Day</button>
                        </div>
                    }
                    {isLeftNavShow &&
                        <div className="sidenav_1">
                            <button className="btn btn-light btn-sm btn-block mt-1" onClick={isShowLeftNavSection}><b>X &nbsp;&nbsp;&nbsp;Close this tab</b></button>

                            <h6 align="center" className="text-white mt-2"><b>Meetings Scheduled for the Day</b></h6>
                            {getBookedMeeting && getBookedMeeting.map((meet, index) => (
                                <>
                                    <span style={{ padding: "0px", marginTop: "20px", width: "90%", color: "white" }}><b>{meet.meeting_type_name}</b></span>

                                    <h5 align="center" style={{ padding: "6px", margin: "5px", width: "90%", color: "black", background: "white" }}>{meet.actual_room_booked}</h5>
                                </>
                            ))}
                        </div>
                    }
                </div>



                {/* <div>
                    <h3>hello</h3>
                </div> */}
            </div>
            <Alert isAlert={isAlert} isAlertToggle={isAlertToggle} alertMessage={alertMessage} isAlertToggleClose={isAlertToggleClose} />
            <ToastContainer/>
        </div >
    )
}
export default BookingMeetingRoom