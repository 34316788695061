import React, { useState } from 'react';
import InfoHeader from '../component/InfoHeader';
import ParameterHeader from '../component/ParameterHeader';
import OverAllPerformanceOfTeam from '../component/OverAllPerformanceOfTeam';
import TeamAndManagerCard from '../component/TeamAndManagerCard';
import SummaryOfTeam from '../component/SummaryOfTeam';
import LeaveVsPlannedCard from '../component/LeaveVsPlannedCrad';
import LeaveVsPlannedScore from '../component/LeaveVsPlannedScore';
import PercentageWorkingHours from '../component/PercentageWorkingHours';
import PercentageProductivityByDepart from '../component/PercentageProductivityByDepart';
import PresentAbsentDataTable from '../component/PresentAbsentDataTable';
import LeavesData from '../component/LeavesData';
import TwoDDountChart from 'src/Chart/TwoDDountChart';
import moment from 'moment';
import EmployeeList from '../component/EmployeeList';
import Skeleton from 'react-loading-skeleton';

const OverAllDashbord = ({
  dashboardata,
  dashboardotherdata,
  setTab,
  setTlId,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  showEmployeeList,
  filterEmployeeList,
  setEmpId,
}) => {
  return (
    <div>
      {!showEmployeeList ? (
        <>
          <OverAllPerformanceOfTeam teamPerformanceData={dashboardata} />
          <div className="row">
            <TeamAndManagerCard
              setEndDate={setEndDate}
              endDate={endDate}
              startDate={startDate}
              setStartDate={setStartDate}
              teamPerformanceData={dashboardata}
              allManagerData={dashboardotherdata?.AllManagers}
              setTab={setTab}
              setTlId={setTlId}
            />
            <SummaryOfTeam teamSummary={dashboardotherdata?.employeegross} />
          </div>
          <div className="row">
            <LeaveVsPlannedCard
              leavevsplannedleavedata={dashboardotherdata?.plannedLeave}
            />
            <LeaveVsPlannedScore />
          </div>
          <div className="row">
            <PercentageWorkingHours />

            <PercentageProductivityByDepart
              shrinkage={dashboardotherdata?.shrinkage}
            />
          </div>

          <PresentAbsentDataTable
            presentabsentdata={dashboardotherdata?.TotalPresentAbsents}
          />
          <LeavesData allLeaveDetails={dashboardotherdata?.getLeavedetail} />
          <div className="row">
            <div className="col-md-5 mb-3 pe-md-1">
              <div className="cardBox h-100">
                <h3 className="fs-6 fw-bold mb-3">Exceeding Corrections</h3>
                <div className="row">
                  <div className="col">
                    <div className="position-relative">
                      <div id="correctionChart" style={{ height: '180px' }}>
                        {dashboardotherdata ? (
                          <TwoDDountChart
                            graphData={[
                              parseInt(
                                dashboardotherdata?.ExceedingCorrections
                                  ?.ExceedingCorrection
                                  ? dashboardotherdata?.ExceedingCorrections
                                      ?.ExceedingCorrection
                                  : '0',
                              ),
                              100 -
                                parseInt(
                                  dashboardotherdata?.ExceedingCorrections
                                    ?.ExceedingCorrection
                                    ? dashboardotherdata?.ExceedingCorrections
                                        ?.ExceedingCorrection
                                    : '0',
                                ),
                            ]}
                            label={['achived', 'not achived']}
                            labemName=""
                            labelColor={[
                              'rgb(254, 123, 30)',
                              'rgb(245, 245, 245)',
                            ]}
                            borderWidth={10}
                          />
                        ) : (
                          <Skeleton
                            style={{
                              width: '100px',
                              height: '100px',
                              borderRadius: '100%',
                            }}
                          />
                        )}
                      </div>
                      <strong className="innerScore text-primary">
                        {parseInt(
                          dashboardotherdata?.ExceedingCorrections
                            ?.ExceedingCorrection
                            ? dashboardotherdata?.ExceedingCorrections
                                ?.ExceedingCorrection
                            : '0',
                        )}
                        %
                      </strong>
                    </div>
                    <span className="fs-8 text-center d-block fw-medium text-secondary">
                      % Of Employees exceeding the limit of corrections
                    </span>
                  </div>
                  <div className="col">
                    <div className="versusTxt flex-wrap border-0">
                      <strong className="w-100 mb-4">
                        <b className="text-orange">
                          {parseInt(
                            dashboardotherdata?.ExceedingCorrections
                              ?.OverallCorrections
                              ? dashboardotherdata?.ExceedingCorrections
                                  ?.OverallCorrections
                              : '0',
                          )}
                        </b>
                        Overall <br /> Corrections
                      </strong>
                      <strong className="w-100">
                        <b className="text-orange">4</b>
                        Monthly <br /> correction limit
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 mb-3">
              <div className="cardBox h-100">
                <h3 className="fs-6 fw-bold mb-3">Brakes and Approvals</h3>
                <div className="row mb-4">
                  <div className="col text-center">
                    <div className="position-relative">
                      <div id="exceedBrakeChart" style={{ height: '110px' }}>
                        <TwoDDountChart
                          graphData={[
                            parseInt(
                              dashboardotherdata?.BrakesApprovals
                                ?.ExceedBrakesPercentage
                                ? dashboardotherdata?.BrakesApprovals
                                    ?.ExceedBrakesPercentage
                                : '0',
                            ),
                            100 -
                              parseInt(
                                dashboardotherdata?.BrakesApprovals
                                  ?.ExceedBrakesPercentage
                                  ? dashboardotherdata?.BrakesApprovals
                                      ?.ExceedBrakesPercentage
                                  : '0',
                              ),
                          ]}
                          label={['achived', 'not achived']}
                          labemName=""
                          labelColor={[
                            'rgb(254, 123, 30)',
                            'rgb(245, 245, 245)',
                          ]}
                          borderWidth={10}
                        />
                      </div>
                      <strong className="innerScore text-orange fs-6">
                        {parseInt(
                          dashboardotherdata?.BrakesApprovals
                            ?.ExceedBrakesPercentage
                            ? dashboardotherdata?.BrakesApprovals
                                ?.ExceedBrakesPercentage
                            : '0',
                        )}
                        %
                      </strong>
                    </div>
                    <span className="text-primary fw-medium">Exceed Brake</span>
                  </div>
                  <div className="col text-center">
                    <div className="position-relative">
                      <div
                        id="CorrectionApprovedChart"
                        style={{ height: '110px' }}
                      >
                        <TwoDDountChart
                          graphData={[
                            parseInt(
                              dashboardotherdata?.BrakesApprovals
                                ?.CorrectionApproved
                                ? dashboardotherdata?.BrakesApprovals
                                    ?.CorrectionApproved
                                : '0',
                            ),
                            100 -
                              parseInt(
                                dashboardotherdata?.BrakesApprovals
                                  ?.CorrectionApproved
                                  ? dashboardotherdata?.BrakesApprovals
                                      ?.CorrectionApproved
                                  : '0',
                              ),
                          ]}
                          label={['achived', 'not achived']}
                          labemName=""
                          labelColor={[
                            'rgb(254, 123, 30)',
                            'rgb(245, 245, 245)',
                          ]}
                          borderWidth={10}
                        />
                      </div>
                      <strong className="innerScore text-orange fs-6">
                        {parseInt(
                          dashboardotherdata?.BrakesApprovals
                            ?.CorrectionApproved
                            ? dashboardotherdata?.BrakesApprovals
                                ?.CorrectionApproved
                            : '0',
                        )}
                        %
                      </strong>
                    </div>
                    <span className="text-primary fw-medium">
                      Correction Approved
                    </span>
                  </div>
                  <div className="col text-center">
                    <div className="position-relative">
                      <div id="compOffChart" style={{ height: '110px' }}>
                        <TwoDDountChart
                          graphData={[
                            parseInt(
                              dashboardotherdata?.BrakesApprovals?.TotalCoff
                                ? dashboardotherdata?.BrakesApprovals?.TotalCoff
                                : '0',
                            ),
                            100 -
                              parseInt(
                                dashboardotherdata?.BrakesApprovals?.TotalCoff
                                  ? dashboardotherdata?.BrakesApprovals
                                      ?.TotalCoff
                                  : '0',
                              ),
                          ]}
                          label={['achived', 'not achived']}
                          labemName=""
                          labelColor={[
                            'rgb(254, 123, 30)',
                            'rgb(245, 245, 245)',
                          ]}
                          borderWidth={10}
                        />
                      </div>
                      <strong className="innerScore text-orange fs-6">
                        {parseInt(
                          dashboardotherdata?.BrakesApprovals?.TotalCoff
                            ? dashboardotherdata?.BrakesApprovals?.TotalCoff
                            : '0',
                        )}
                        %
                      </strong>
                    </div>
                    <span className="text-primary fw-medium">
                      Comp-off Appproved
                    </span>
                  </div>
                </div>
                <div className="text-end">
                  <a href="/" className="btn btn-primary">
                    Download Report
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <TeamAndManagerCard
              setEndDate={setEndDate}
              endDate={endDate}
              startDate={startDate}
              setStartDate={setStartDate}
              teamPerformanceData={dashboardata}
              allManagerData={dashboardotherdata?.AllManagers}
              setTab={setTab}
              setTlId={setTlId}
            />
            <SummaryOfTeam teamSummary={dashboardotherdata?.employeegross} />
          </div>
          <EmployeeList
            filterEmployeeList={filterEmployeeList}
            setEmpId={setEmpId}
            setTab={setTab}
          />
        </>
      )}
    </div>
  );
};

export default OverAllDashbord;
