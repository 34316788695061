const dashboardDataPending = state =>
  state.dashboardDetail.dashboardDataPending;
const dashboardDataRes = state => state.dashboardDetail.dashboardDataRes;
const dashboardDataError = state => state.dashboardDetail.dashboardDataError;
const dashboardOtherDataPending = state =>
  state.dashboardDetail.dashboardOtherDataPending;
const dashboardOtherDataRes = state =>
  state.dashboardDetail.dashboardOtherDataRes;
const dashboardOtherDataError = state =>
  state.dashboardDetail.dashboardOtherDataError;
//////////////////////////////////TL///////
const tldashboardDataPending = state =>
  state.dashboardDetail.tldashboardDataPending;
const tldashboardDataRes = state => state.dashboardDetail.tldashboardDataRes;
const tldashboardDataError = state =>
  state.dashboardDetail.tldashboardDataError;
export default {
  dashboardDataPending,
  dashboardDataRes,
  dashboardDataError,
  dashboardOtherDataPending,
  dashboardOtherDataRes,
  dashboardOtherDataError,
  tldashboardDataPending,
  tldashboardDataRes,
  tldashboardDataError,
};
