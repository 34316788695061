// import React, { useState, lazy, useEffect } from 'react'
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link
// } from "react-router-dom";

// import {
//   CBadge, CNav,
//   CInputRadio,
//   CNavItem,
//   CNavbar,
//   CListGroup, CListGroupItem,
//   CNavLink,
//   CTabContent,
//   CTabPane,
//   CTabs,
//   CButton,
//   CTextarea,
//   CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
//   CWidgetBrand,
//   CButtonGroup,
//   CWidgetIcon,
//   CCard,
//   CLink,
//   CCardBody,
//   CCardFooter,
//   CInputFile,
//   CCardHeader,
//   CCol,
//   CSwitch,
//   CCollapse,
//   CProgress,
//   CProgressBar,
//   CRow,
//   CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
//   CCallout
// } from '@coreui/react'
// import CIcon from '@coreui/icons-react'
// import API from 'src/utils/apiCalling';
// import { config } from 'src/utils/apiUrl';
// import swal from 'sweetalert'
// import Alert from "src/alert/alert";
// import { toast } from 'react-toastify';


// const SocialDetails = (props) => {
//   let { getEmployeeDetailStatus,empStatus, empDetails } = props
//   const api = new API()
//   const [isDisabled, setIsDisabled] = useState(false)
//   const [SocialDetails, setSocialDetails] = useState({
//     emp_linkdin_id: "",
//     emp_facebook_id: "",
//     emp_twitter_id: "",
//     emp_instagram_id: "",
    
//   });
//   const [isAlert, setIsAlert] = useState(false);
//   const [alertMessage, setAlertMessage] = useState("");

//   const isAlertToggle = (msg) => {
//   setIsAlert(!isAlert);
//   setAlertMessage(msg);
//   };
//    const isAlertToggleClose = () => {
//   setIsAlert(false);
//    }
//   useEffect(()=>{
//     getSocialDetails()
//   },[])
//     const getSocialDetails = async () => {
//       let data = {
//         user_id : empDetails && empDetails.id
//       }
//       if(!data.user_id){
//         delete data.user_id
//       }
//       let result = await api.get(config.getEmployeeSocialDetails, true, data);
//       if (result && result.code === 200) {
//         if(empStatus && empStatus.social_detail_status==1) {
//           setIsDisabled(true)
//           // alert("inside if bank details")
//           // console.log("_________________________________", result)
//           setSocialDetails(result.data[0])
//         }
//       } else {
//         // alert(result && result.message);
//       }
//     };
//   const saveSocialDetails = async () => {
//     let data = SocialDetails
//     data.user_id = empDetails && empDetails.id
    
//     if(!data.user_id){
//       delete data.user_id
//     }
//     console.log("social detaisl", data)
//     swal({
//       html: true,
//       title: "Are you sure?",
//       text: `Are you sure to add Social Details`,
//       icon: "success",
//       buttons: ["No, cancel it!", "Submit"],
//       dangerMode: true,
//   }).then(async function (isConfirm) {
//       if (isConfirm) {
//         let result = await api.post(config.socialdetails, data);
//         if (result &&result.code === 200) {
//           toast.success("Social Details Added Successfully")
//           // isAlertToggle("Social Details Added Successfully");
 
//            getEmployeeDetailStatus()
//         } else {
//           toast.error(result && result.message)
//           // isAlertToggle(result && result.message);
        
//         }

          
//       }
//   })
   
//   }
//   const onChangeInputSocialDetails = (e) => {
//     const { name, value } = e.target;
//     setSocialDetails({
//       ...SocialDetails,
//       [name]: value,
//     });
//   };
//   return (
//     <div>
//       <form>
        
//         <div className="row">
//           <div className="col-md-6">
//             <div className="form-group">
//               <label for="exampleInputEmail1">Linkedin Id <span style={{ color: 'red' }}>*</span></label>
//               <input disabled={isDisabled ? true : false} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="emp_linkdin_id"
//                 value={SocialDetails && SocialDetails.emp_linkdin_id}
//                 onChange={onChangeInputSocialDetails}
                
//               />
//             </div>

//             <div className="form-group">
//               <label for="exampleInputEmail1">Facebook Id <span style={{ color: 'red' }}>*</span></label>
//               <input disabled={isDisabled ? true : false} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
//                 name="emp_facebook_id"
//                 value={SocialDetails && SocialDetails.emp_facebook_id}
//                 onChange={onChangeInputSocialDetails}
               
//               />
//             </div>
//           </div>

//           <div className="col-md-6">

//             <div className="form-group">
//               <label for="exampleInputEmail1">Instagram Id <span style={{ color: 'red' }}>*</span></label>
//               <input disabled={isDisabled ? true : false} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
//                 name="emp_instagram_id"
//                 value={SocialDetails && SocialDetails.emp_instagram_id}
//                 onChange={onChangeInputSocialDetails}
              
//               />
//             </div>
//             <div className="form-group">
//               <label for="exampleInputEmail1">Twitter Id <span style={{ color: 'red' }}>*</span></label>
//               <input disabled={isDisabled ? true : false} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
//                 name="emp_twitter_id"
//                 value={SocialDetails && SocialDetails.emp_twitter_id}
//                 onChange={onChangeInputSocialDetails}
              
//               />
//             </div>
//                {isDisabled == false &&
//         <button className="btn btn-primary float-right" type="button" onClick={saveSocialDetails}>Submit</button>
//       }
//           </div>
         
         
//         </div>
        
       
//       </form>
//       <Alert
//         isAlert={isAlert}
//         isAlertToggle={isAlertToggle}
//         alertMessage={alertMessage}
//         isAlertToggleClose={isAlertToggleClose}
//       />

//     </div>
//   )
// }
// export default SocialDetails


import React, { useState, lazy, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {toast, ToastContainer} from 'react-toastify';
import {
  CBadge, CNav,
  CInputRadio,
  CNavItem,
  CNavbar,
  CListGroup, CListGroupItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CTextarea,
  CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
  CWidgetBrand,
  CButtonGroup,
  CWidgetIcon,
  CCard,
  CLink,
  CCardBody,
  CCardFooter,
  CInputFile,
  CCardHeader,
  CCol,
  CSwitch,
  CCollapse,
  CProgress,
  CProgressBar,
  CRow,
  CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
  CCallout
} from '@coreui/react'
import CIcon from '@coreui/icons-react'


import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import swal from 'sweetalert'
import Alert from "src/alert/alert";


const SocialDetails = (props) => {
  let { getEmployeeDetailStatus, empStatus, empDetails, onClickActive } = props
  const api = new API()
  const [isDisabled, setIsDisabled] = useState(false)
  const [isSubmit, setIsSubmit] = useState(true);
  const [isCancelBtn, setIsCancelBtn] = useState(false)
  const [SocialDetails, setSocialDetails] = useState({
    emp_linkdin_id: "",
    emp_facebook_id: "",
    emp_twitter_id: "",
    emp_instagram_id: "",

  });
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isSubmitBtn, setIsSubmitBtn] = useState(false)

  const isAlertToggle = (msg) => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
  };
  const isAlertToggleClose = () => {
    setIsAlert(false);
  }
  useEffect(() => {
    getSocialDetails()
  }, [])
  const getSocialDetails = async () => {
    let data = {
      user_id: empDetails && empDetails.id
    }
    if (!data.user_id) {
      delete data.user_id
    }
    let result = await api.get(config.getEmployeeSocialDetails, true, data);
    if (result && result.code === 200) {
      if (empStatus && empStatus.social_detail_status == 1) {
        setIsDisabled(true)
        // alert("inside if bank details")
        // console.log("_________________________________", result)
        setSocialDetails(result.data[0])
        setIsSubmit(false);
        setIsDisabled(false)
      }
    } else {
      // alert(result && result.message);
    }
  };
  const saveSocialDetails = async () => {
    let socialDetailArray = Object.keys(SocialDetails)
    let unFilledSocialDetailCount = 0;
    for (let i = 0; i <= socialDetailArray.length; i++) {
      if (SocialDetails[socialDetailArray[i] === ""]) {
        unFilledSocialDetailCount = unFilledSocialDetailCount + 1;

      } else {

      }
    }
    if (unFilledSocialDetailCount === 4) {
      toast.error("If you don't have any social link then skip it.")
      return
    }

    let data = SocialDetails
    data.user_id = empDetails && empDetails.id

    if (!data.user_id) {
      delete data.user_id
    }
    console.log("social detaisl", data)
    swal({
      html: true,
      title: "Are you sure?",
      text: `Are you sure to add Social Details`,
      icon: "success",
      buttons: ["No, cancel it!", "Submit"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.socialdetails, data);
        if (result.code === 200) {
          toast.success("Social Details Added Successfully");
          onClickActive(7);
          getEmployeeDetailStatus()
          setIsSubmit(false)
        } else {
          toast.error(result && result.message);

        }


      }
    })

  }
  const onChangeInputSocialDetails = (e) => {
    const { name, value } = e.target;
    setSocialDetails({
      ...SocialDetails,
      [name]: value,
    });
  };

  const onEdit = () => {
    setIsDisabled(!isDisabled)
    setIsCancelBtn(true)
  }
  const oncancel = () => {
    setIsDisabled(!isDisabled)
    setIsCancelBtn(!isCancelBtn)
  }

  const updateSocialDetails = async () => {
    // return
    let data = SocialDetails;
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to update Social Details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Update'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.updatesocialdetails, data);
        if (result && result.code === 200) {
          toast.success(result.message);
          getEmployeeDetailStatus();
          setIsSubmit(false);
          onClickActive(7);
        } else {
          toast.error(result.message);
        }
      }
    });
  };
  useEffect(() => {
    let userDetails = SocialDetails
    for (const key in userDetails) {
      if (userDetails.hasOwnProperty(key)) {
        const value = userDetails[key];
        // Check for both empty string and null (or undefined if required)
        if (value !== "" && value !== null && value !== undefined) {
          // return false;
          setIsSubmitBtn(true)
          return
        }
      }
    }
    setIsSubmitBtn(false)
    return;
  }, [onChangeInputSocialDetails])
  return (
    <div>
      <form>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label for="exampleInputEmail1">Linkedin ID 
              {/* <span style={{ color: 'red' }}>*</span> */}
              </label>
              <input disabled={isDisabled ? true : false} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="emp_linkdin_id"
                value={SocialDetails && SocialDetails.emp_linkdin_id} placeholder='Enter Linkedin ID'
                onChange={onChangeInputSocialDetails}

              />
            </div>

            <div className="form-group">
              <label for="exampleInputEmail1">Facebook ID 
              {/* <span style={{ color: 'red' }}>*</span> */}
              </label>
              <input disabled={isDisabled ? true : false} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                name="emp_facebook_id" placeholder='Enter Facebook ID'
                value={SocialDetails && SocialDetails.emp_facebook_id}
                onChange={onChangeInputSocialDetails}

              />
            </div>
          </div>

          <div className="col-md-6">

            <div className="form-group">
              <label for="exampleInputEmail1">Instagram ID 
              {/* <span style={{ color: 'red' }}>*</span> */}
              </label>
              <input disabled={isDisabled ? true : false} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                name="emp_instagram_id" placeholder='Enter Instagram ID'
                value={SocialDetails && SocialDetails.emp_instagram_id}
                onChange={onChangeInputSocialDetails}

              />
            </div>
            <div className="form-group">
              <label for="exampleInputEmail1">Twitter ID 
              {/* <span style={{ color: 'red' }}>*</span> */}
              </label>
              <input disabled={isDisabled ? true : false} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                name="emp_twitter_id" placeholder='Enter Twitter ID'
                value={SocialDetails && SocialDetails.emp_twitter_id}
                onChange={onChangeInputSocialDetails}

              />
            </div>


            {isSubmit ? (
              <button
                className="btn btn-primary float-right mt-3"
                type="button"
                onClick={saveSocialDetails}
                disabled={!isSubmitBtn}
              >
                Submit
              </button>
            ) : (
              <>
                <button
                  className="btn btn-success float-right mt-3 ml-1"
                  type="button"
                  onClick={e => onClickActive(7)}
                >
                  Next{' '}
                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </button>
                <button
                  className="btn btn-info float-right mt-3"
                  type="button"
                  onClick={updateSocialDetails}
                >
                  Update
                </button>
              </>
            )}   
          </div>


        </div>


      </form>
      <Alert
        isAlert={isAlert}
        isAlertToggle={isAlertToggle}
        alertMessage={alertMessage}
        isAlertToggleClose={isAlertToggleClose}
      />
      <ToastContainer />
    </div>
  )
}
export default SocialDetails