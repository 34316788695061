import React from 'react';
import SummaryOfTeam from './SummaryOfTeam';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const TlInfoCard = ({ teamSummary, managerInfo, teamMember }) => {
  const {
    role_name,
    panel_role,
    id,
    full_name,
    emp_id,
    department_name,
    NetWorkingHours,
    LeavesTaken,
    GrossWorkingHours,
    Corrections,
  } = managerInfo && managerInfo.length > 0 ? managerInfo[0] : {};
  return (
    <div className="row">
      <div className="col-md-6 mb-3 pe-md-2">
        <div className="cardBox h-100">
          {managerInfo ? (
            <div className="row">
              <div className="col-md-8 border-end">
                <h3 className="fs-6 fw-bold">{`${panel_role}(${role_name})`}</h3>
                <div className="d-flex align-items-center border-bottom">
                  <h4 className="fs-5 fw-bold">{full_name} </h4>
                  <span className="text-secondary fs-8 fw-semibold ps-2">
                    {role_name}
                  </span>
                </div>
                <div className="row px-md-2 mt-3">
                  <div className="col-md-6 px-md-1 border-bottom border-end">
                    <div className="py-2">
                      <span className="text-orange fw-bold mb-1 d-block">
                        Gross Working
                      </span>
                      <strong className="text-black fs-9">
                        {GrossWorkingHours}{' '}
                        <b className="fw-normal text-secondary">hrs</b>
                      </strong>
                    </div>
                  </div>
                  <div className="col-md-6 px-md-1 border-bottom">
                    <div className="py-2 ps-2">
                      <span className="text-orange fw-bold mb-1 d-block">
                        Net Working
                      </span>
                      <strong className="text-black fs-9">
                        {NetWorkingHours}{' '}
                        <b className="fw-normal text-secondary">hrs</b>
                      </strong>
                    </div>
                  </div>
                  <div className="col-md-6 px-md-1 border-end">
                    <div className="py-2">
                      <span className="text-orange fw-bold mb-1 d-block">
                        Leaves Taken
                      </span>
                      <strong className="text-black fs-9">{LeavesTaken}</strong>
                    </div>
                  </div>
                  <div className="col-md-6 px-md-1">
                    <div className="py-2 ps-2">
                      <span className="text-orange fw-bold mb-1 d-block">
                        Corrections
                      </span>
                      <strong className="text-black fs-9">
                        <span className="text-danger">
                          {Corrections ? Corrections.split('/')[0] : '00'}
                        </span>{' '}
                        / {Corrections ? Corrections.split('/')[1] : '00'}{' '}
                        <b>( Limit )</b>
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <h3 className="fs-6 fw-bold">Team Members</h3>
                <div className="allManagers">
                  <span>Total Managers </span>
                  <strong className="d-block mb-2">
                    {teamMember && teamMember.length}
                  </strong>
                  <ul className="mt-5">
                    {teamMember &&
                      teamMember.length > 0 &&
                      teamMember.slice(0, 5).map(item => (
                        <li key={item?.emp_id}>
                          <img
                            src={item?.url ? item?.url : 'images/fav.png'}
                            alt={item?.full_name}
                          />
                        </li>
                      ))}

                    <li className="ps-3 d-flex align-items-center justify-content-center fw-bold text-nowrap">
                      {teamMember && teamMember.length > 5
                        ? `+ ${teamMember?.length - 5}`
                        : null}
                    </li>
                  </ul>
                  {/* <button class="btn-outline-secondary" type="button"
                                              data-bs-target="#allManagers" data-bs-toggle="modal"><img
                                                  src="images/eye.svg" class="me-2" alt="eye" width="16">
                                              View All</button> */}
                </div>
              </div>
            </div>
          ) : (
            <Skeleton count={9} />
          )}
        </div>
      </div>
      <SummaryOfTeam teamSummary={teamSummary} />
    </div>
  );
};

export default TlInfoCard;
