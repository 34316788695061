import { createAction } from 'redux-act';

const toggleSidebar = createAction('GET_MINIMIZE_SIDEBAR', data => data);

const handleClearCommonStore = createAction(
  'HANDLE_CLEAR_COMMON_STORE',
  data => data,
);
const handleMessageNotificationRequest = createAction(
  'HANDLE_MESSAGE_NOTIFICATION_REQUEST',
  data => data,
);
const handleMessageNotificationSuccess = createAction(
  'HANDLE_MESSAGE_NOTIFICATION_SUCCESS',
  data => data,
);
const handleNotificationSuccess = createAction(
  'HANDLE_NOTIFICATION_SUCCESS',
  data => data,
);
const handleNotificationRequest = createAction(
  'HANDLE_NOTIFICATION_REQUEST',
  data => data,
);

const handleClearAllNotification = createAction(
  'HANDLE_CLEAR_NOTIFICATION',
  data => data,
);

export default {
  toggleSidebar,
  handleClearCommonStore,
  handleMessageNotificationRequest,
  handleMessageNotificationSuccess,
  handleNotificationSuccess,
  handleNotificationRequest,
  handleClearAllNotification
};
