import { getFomatedError } from 'src/core/services/ApiService';
import { config } from 'src/utils/apiUrl';
import API from 'src/utils/apiCalling';
const api = new API();

const getManagerDashboardData = payload => {
  let apiRes = api
    .post(config.getOverAllPerformance, payload)
    .then(res => {
      return res;
    })
    .catch(error => {
      return getFomatedError(error);
    });
  return apiRes;
};
const getManagerDashboardOtherData = payload => {
  let apiRes = api
    .post(config.getDashboardHOD, payload)
    .then(res => {
      return res;
    })
    .catch(error => {
      return getFomatedError(error);
    });
  return apiRes;
};
const getTeamLaederDashboardData = payload => {
  let apiRes = api
    .post(config.getManagerDashboard, payload)
    .then(res => {
      return res;
    })
    .catch(error => {
      return getFomatedError(error);
    });
  return apiRes;
};

export default {
  getManagerDashboardData,
  getManagerDashboardOtherData,
  getTeamLaederDashboardData,
};
