import React from 'react';

const LeaveVsPlannedScore = () => {
  return (
    <div className="col-md-4 mb-3">
      <div className="cardBox plannedScore h-100">
        <h3 className="fs-6 fw-bold mb-3">Leave vs. Planned Score</h3>

        <></>
        <strong>
          45{' '}
          <lottie-player
            src="images/arrow-triplet.json"
            background="transparent"
            speed={1}
            style={{ width: '54px', height: '44px' }}
            loop
            autoPlay
          />
        </strong>
        <span>
          People on Leave <b>Increased</b> This Month
        </span>
        <div className="versusTxt">
          <strong>
            <b>40</b>
            Days Expected
          </strong>
          <span>VS.</span>
          <strong>
            <b>31</b>
            Days Actual
          </strong>
        </div>
        <div className="text-center">
          <a href="/" className="btn btn-primary">
            Download Report
          </a>
        </div>
      </div>
    </div>
  );
};

export default LeaveVsPlannedScore;
