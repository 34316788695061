import { createReducer } from 'redux-act';
import action from './otpsActions';
const initialState = {
  verifyOtpPending: false,
  verifyOtpRes: null,
  verifyOtpError: null,
  sendOtpPending: false,
  sendOtpRes: null,
  sendOtpError: null,
};

const handleVerifyOtpRequest = state => {
  return {
    ...state,
    ...{
      verifyOtpPending: true,
      verifyOtpRes: null,
      verifyOtpError: null,
    },
  };
};
const handleVerifyOtpSuccess = (state, payload) => {
  console.log("ekfjofkp error", payload);
  return {
    ...state,
    ...{
      verifyOtpPending: false,
      verifyOtpRes: payload,
      verifyOtpError: null,
    },
  };
};
const handleVerifyOtpError = (state, error) => {
  console.log("ekfjofkp error", error);
  return {
    ...state,
    ...{
      verifyOtpPending: false,
      verifyOtpRes: null,
      verifyOtpError: error,
    },
  };
};

const handlesendOtpRequest = state => {
    return {
      ...state,
      ...{
        sendOtpPending: true,
        sendOtpRes: null,
        sendOtpError: null,
      },
    };
  };
  const handlesendOtpSuccess = (state, payload) => {
    return {
      ...state,
      ...{
        sendOtpPending: false,
        sendOtpRes: payload,
        sendOtpError: null,
      },
    };
  };
  const handlesendOtpError = (state, error) => {
    return {
      ...state,
      ...{
        sendOtpPending: false,
        sendOtpRes: null,
        sendOtpError: error,
      },
    };
  };

  const clearOtpStore = (state, payload) => {
    switch (payload) {
      case 'HANDLE_CLEAR_VERIFY_OTP':
        return {
          ...state,
          ...{
            verifyOtpPending: false,
            verifyOtpRes: null,
            verifyOtpError: null,
          },
        };
      default:
        return state;
    }
  };

export default createReducer(
  {
    [action.verifyOtpRequestAction]: handleVerifyOtpRequest,
    [action.verifyOtpSuccessAction]: handleVerifyOtpSuccess,
    [action.verifyOtpFailureAction]: handleVerifyOtpError,
    [action.sendOtpRequestAction]: handlesendOtpRequest,
    [action.sendOtpSuccessAction]: handlesendOtpSuccess,
    [action.sendOtpFailureAction]: handlesendOtpError,
    [action.handleClearOtpStore]: clearOtpStore,
  },
  initialState,
);
