import { createAction } from 'redux-act';

const getSettingsByAdminRequest = createAction('GET_SETTINGSBY_ADMIN_REQUEST',data => data,);
const getSettingsByAdminSuccess = createAction('GET_SETTINGSBY_ADMIN_SUCCESS',data => data,);
const getSettingsByAdminError = createAction('GET_SETTINGSBY_ADMIN_ERROR');

const updateSettingDataRequest = createAction('UPDATE_SETTING_DATA_REQUEST',data => data,);
const updateSettingDataSuccess = createAction(
  'UPDATE_SETTING_DATA_SUCCESS',
  data => data,
);
const updateSettingDataError = createAction('UPDATE_SETTING_DATA_ERROR');

export default {
  getSettingsByAdminRequest,
  getSettingsByAdminSuccess,
  getSettingsByAdminError,

  updateSettingDataRequest,
  updateSettingDataSuccess,
  updateSettingDataError,
};
