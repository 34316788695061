import React, { useState, useEffect } from 'react';
import './maindashbord.css';
import SideBar from './component/SideBar';
import OverAllDashbord from './OverAllDashbord/OverAllDashbord';
import EmployeeWiseDashboard from './OverAllDashbord/EmployeeWiseDashboard';
import TeamWiseDashboard from './OverAllDashbord/TeamWiseDashboard';
import InfoHeader from './component/InfoHeader';
import moment from 'moment';
import { connect } from 'react-redux';
import dashboardDataAction from 'src/core/resource/dashboard/dashboardData.Action.js';
import dashboardDataSelector from 'src/core/resource/dashboard/dashboard.Selector.js';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';

import { toast } from 'react-toastify';
const MainDashbord = ({
  managerdashboardDataRes,
  managerdashboardOtherDataRes,
  tldashboardDataRes,
}) => {
  const api = new API();
  const [tab, setTab] = useState('manager');
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(30, 'days')
      .format('YYYY-MM-DD'),
  );
  const [sideBarData, setSideBarData] = useState([]);

  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [tlId, setTlId] = useState('');
  const [showEmployeeList, setShowEmployeeList] = useState(false);
  const [filterEmployeeList, setFilterEmployeeList] = useState([]);
  const [employeeData, setEmployeeData] = useState(null);
  const [emp_id, setEmpId] = useState(null);

  const getSideBarData = () => {
    if (tab === 'manager') {
      api
        .post(config.managerDashboardSidebar, {
          startDate,
          endDate,
        })
        .then(res => {
          console.log('side bar data 33', res?.data);

          setSideBarData(res?.data);
        })
        .catch(err => {
          toast.error(err?.message);
        });
    }
    if (tab === 'team') {
      api
        .post(config.DashboardSidebarTL, {
          startDate,
          endDate,
          emp_user_id: tlId,
        })
        .then(res => {
          console.log('side bar data 44', res?.data);

          setSideBarData(res?.data);
        })
        .catch(err => {
          toast.error(err?.message);
        });
    } else {
    }
  };

  const filterEmployeeListFun = type => {
    let employyeList = [];
    switch (type) {
      case 'total_employee':
        sideBarData &&
          sideBarData.length > 0 &&
          sideBarData.map(item => {
            return employyeList.push({
              [item?.full_name]: item?.role_name,
              'Emp ID': item?.emp_id,
              Department: `${item?.department_name}(${item?.panel_role})`,
            });
          });

        break;
      case 'total_present':
        sideBarData &&
          sideBarData.length > 0 &&
          sideBarData.map(item => {
            return employyeList.push({
              [item?.full_name]: item?.role_name,
              'Emp ID': item?.emp_id,
              Department: `${item?.department_name}(${item?.panel_role})`,
              'Total Working Days': item?.totalworkingdays,
              'Present Days': item?.totalPresentDay,
            });
          });

        break;
      case 'total_absent':
        sideBarData &&
          sideBarData.length > 0 &&
          sideBarData.map(item => {
            return employyeList.push({
              [item?.full_name]: item?.role_name,
              'Emp ID': item?.emp_id,
              Department: `${item?.department_name}(${item?.panel_role})`,
              'Total Working Days': item?.totalworkingdays,
              'Absent Days': item?.totalAbsentDay,
            });
          });

        break;
      case 'timely_in':
        sideBarData &&
          sideBarData.length > 0 &&
          sideBarData.map(item => {
            return employyeList.push({
              [item?.full_name]: item?.role_name,
              'Emp ID': item?.emp_id,
              Department: `${item?.department_name}(${item?.panel_role})`,
              'Total Working Days': item?.totalworkingdays,
              'Timely In': item?.timely_in,
            });
          });

        break;
      case 'late_in':
        sideBarData &&
          sideBarData.length > 0 &&
          sideBarData.map(item => {
            return employyeList.push({
              [item?.full_name]: item?.role_name,
              'Emp ID': item?.emp_id,
              Department: `${item?.department_name}(${item?.panel_role})`,
              'Total Working Days': item?.totalworkingdays,
              'Late In': item?.late_in,
            });
          });

        break;
      case 'planned_leaves':
        sideBarData &&
          sideBarData.length > 0 &&
          sideBarData.map(item => {
            return employyeList.push({
              [item?.full_name]: item?.role_name,
              'Emp ID': item?.emp_id,
              Department: `${item?.department_name}(${item?.panel_role})`,
              'Total Working Days': item?.totalworkingdays,
              'Planned Leave': item?.plannedLeave,
            });
          });

        break;
      case 'unplanned_leaves':
        sideBarData &&
          sideBarData.length > 0 &&
          sideBarData.map(item => {
            return employyeList.push({
              [item?.full_name]: item?.role_name,
              'Emp ID': item?.emp_id,
              Department: `${item?.department_name}(${item?.panel_role})`,
              'Total Working Days': item?.totalworkingdays,
              'Unplanned Leave': item?.unplannedLeave,
            });
          });

        break;

      default:
        break;
    }

    setFilterEmployeeList(employyeList);
  };

  const getEmployeeStatus = type => {
    api
      .post(config.getEmployeeDashboard, {
        emp_id: emp_id,
        startDate: '2024-02-01',
        endDate: '2024-02-29',
        type: type ? type : '',
      })
      .then(res => {
        let employyeList = [];
        setEmployeeData(res?.data);
        res?.data?.OverallAttendance.map(item => {
          return employyeList.push({
            Date: moment(item?.date).format('DD-MM-YYYY'),
            'First In': moment(item?.first_check_time).format('hh:mm:ss a'),
            'Last Out': moment(item?.last_check_time).format('hh:mm:ss a'),
            'First Half': item?.first_half_status
              ? item?.first_half_status
              : '-',

            'Second Half': item?.second_half_status
              ? item?.second_half_status
              : '-',
            'Late In': '-',
            'Early Out': '-',
            'Work Hours': item?.total_office_time_working_hour
              ? item?.total_office_time_working_hour
              : '-',
            'Extra Work': '-',
            'Net Work': item?.total_real_time_working_hours
              ? item?.total_real_time_working_hours
              : '-',
            'Break Hours': item?.total_break_time
              ? item?.total_break_time
              : '-',
          });
        });
        setFilterEmployeeList(employyeList);
      })
      .catch(error => {
        alert(error?.message);
      });
  };
  useEffect(() => {
    getSideBarData();
  }, [endDate, tab]);
  return (
    <div className="d-flex flex-wrap justify-content-between">
      <SideBar
        getEmployeeStatus={getEmployeeStatus}
        tab={tab}
        sideBarData={sideBarData}
        filterEmployeeListFun={filterEmployeeListFun}
        setTab={setTab}
        showEmployeeList={showEmployeeList}
        setShowEmployeeList={setShowEmployeeList}
      />
      <div className="teamContent">
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <InfoHeader
              setEndDate={setEndDate}
              endDate={endDate}
              startDate={startDate}
              setStartDate={setStartDate}
            />
            {tab === 'team' ? (
              <TeamWiseDashboard
                tlDashboardData={tldashboardDataRes}
                tlId={tlId}
                startDate={startDate}
                endDate={endDate}
                showEmployeeList={showEmployeeList}
                filterEmployeeList={filterEmployeeList}
                setTab={setTab}
                setEmpId={setEmpId}
              />
            ) : tab === 'employee' ? (
              <EmployeeWiseDashboard
                getEmployeeStatus={getEmployeeStatus}
                employeeData={employeeData}
                filterEmployeeList={filterEmployeeList}
              />
            ) : (
              <OverAllDashbord
                setTab={setTab}
                setEmpId={setEmpId}
                setTlId={setTlId}
                dashboardata={managerdashboardDataRes}
                dashboardotherdata={managerdashboardOtherDataRes}
                showEmployeeList={showEmployeeList}
                filterEmployeeList={filterEmployeeList}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    managerdashboardDataRes: dashboardDataSelector.dashboardDataRes(state),
    managerdashboardOtherDataRes: dashboardDataSelector.dashboardOtherDataRes(
      state,
    ),
    tldashboardDataRes: dashboardDataSelector.tldashboardDataRes(state),
  };
};
const mapDispatchToProps = dispatch => ({
  managerdashboardRequestAction: data =>
    dispatch(dashboardDataAction.dashboardDataRequestAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MainDashbord);

//export default MainDashbord;
