import { createReducer } from 'redux-act';
import actions from './settingActions';

const initialState = {
  getSettingsByAdminPending: false,
  getSettingsByAdminRes: null,
  getSettingsByAdminResError: null,

  updateSettingDataPending: false,
  updateSettingDataRes: null,
  updateSettingDataResError: null,
};
const handleGetSettingsByAdminRequest = (state, payload) => {
  return {
    ...state,
    ...{
      getSettingsByAdminPending: true,
      getSettingsByAdminRes: null,
      getSettingsByAdminResError: null,
    },
  };
};
const handleGetSettingsByAdminSuccess = (state, payload) => {
  return {
    ...state,
    ...{
      getSettingsByAdminPending: false,
      getSettingsByAdminRes: payload,
      getSettingsByAdminResError: null,
    },
  };
};
const handleGetSettingsByAdminError = (state, payload) => {
  return {
    ...state,
    ...{
      getSettingsByAdminPending: false,
      getSettingsByAdminRes: null,
      getSettingsByAdminResError: payload,
    },
  };
};
/*update setting  */
const handleUpdateSettingDataRequest = (state, payload) => {
  return {
    ...state,
    ...{
      updateSettingDataPending: true,
      updateSettingDataRes: null,
      updateSettingDataResError: null,
    },
  };
};
const handleUpdateSettingDataSuccess = (state, payload) => {
  return {
    ...state,
    ...{
      updateSettingDataPending: false,
      updateSettingDataRes: payload,
      updateSettingDataResError: null,
    },
  };
};
const handleUpdateSettingDataError = (state, payload) => {
  return {
    ...state,
    ...{
      updateSettingDataPending: false,
      updateSettingDataRes: null,
      updateSettingDataResError: payload,
    },
  };
};

export default createReducer(
  {
    [actions.getSettingsByAdminRequest]: handleGetSettingsByAdminRequest,
    [actions.getSettingsByAdminSuccess]: handleGetSettingsByAdminSuccess,
    [actions.getSettingsByAdminError]: handleGetSettingsByAdminError,

    [actions.updateSettingDataRequest]: handleUpdateSettingDataRequest,
    [actions.updateSettingDataSuccess]: handleUpdateSettingDataSuccess,
    [actions.updateSettingDataError]: handleUpdateSettingDataError,
  },
  initialState,
);
