import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CDropdown,
  CDropdownMenu,
} from '@coreui/react';

import { TheHeaderDropdown } from './index';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { connect } from 'react-redux';
import { commonActions, commonSelectors } from 'src/core/resource/common';
import {
  attendanceSelector,
  attendanceActions,
} from 'src/core/resource/attendance';
import {
  leaveSelector,
  leaveDateAction,
} from 'src/core/resource/leaveDateList';
import shiftDetailAction from 'src/core/resource/shiftDeatilOfUser/shiftDetail.Action';
import shiftDetailSelector from 'src/core/resource/shiftDeatilOfUser/shiftDetail.Selector';

import moment from 'moment';
import { AttendanceLogsModal } from 'src/modals/index';
import { getUserInfoFromLocal } from 'src/utils/common';
import { getLocalDataAsObject } from '../utils/CoustomStorage';
import { settingActions } from 'src/core/resource/Setting';
import { ContourSeries } from 'react-vis';
// import AllNotifications from './notificationModel'

const TheHeader = props => {
  const {
    navigateToToggleSidebar,
    getSidebarOptions,
    attendanceBasicDetails,
    getAttendanceBasicDetail,

    issAttendanceLogsWithDurationPending,
    getAttendanceLogsWithDurationRes,
    getAttendanceLogsWithDurationResError,
    navigateToGetAttendanceLogsWithDuration,
    getAllEmployeeLeaveDates,
    getAllLeaveDateWithinDate,
    messageNotificationRequest,
    messageNotifications,
    shiftDetailRequestAction,
    getShiftDetailOfEmployee,
    navigateTogetSettingsByAdminRequest,
    getAllNotification,
    getNotifications,
    getAllNotificationRequest
  } = props;

  const history = useHistory();
  const containerRef = useRef();
  const api = new API();
  let panel_role = getLocalDataAsObject('user');
  let getPanel_role = panel_role && panel_role[0] && panel_role[0].panel_role;
  const [emp_id, setemp_id] = useState('');
  const [email, setemail] = useState('');
  const [firstname, setfirstname] = useState('');
  const [profileImageShow, setProfileImageShow] = useState('');

  const [timer, setTimer] = useState('00:00:00');
  const [totalWorkTime, setTotalWorkTime] = useState('00:00:00');
  const [totalBreakTime, setTotalBreakTime] = useState('00:00:00');

  const [atndType, setAtndType] = useState();
  const [checkInStatus, setCheckInStatus] = useState(false);
  const [attendanceLogsModalOpen, setAttendanceLogsModalOpen] = useState(false);
  const [attendanceLogsData, setAttendanceLogsData] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);
  const [allNotifications, setAllNotifications] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  //const [notificationMessage, setNotificationMessage] = useState('');
  const notificationMessage =
    messageNotifications &&
    messageNotifications?.data &&
    messageNotifications?.data.length > 0 &&
    messageNotifications?.data[0]?.message;


  const userDetails = getUserInfoFromLocal();
  useEffect(() => {
    userProfile();
      getAttendanceBasicDetail();
    // getAllLeaveDateWithinDate();
     getAttendanceLogs();

    messageNotificationRequest();
    //shiftDetailRequestAction();
    //navigateTogetSettingsByAdminRequest();
    getAllNotificationRequest()
  }, []);

  useEffect(() => {
    if (!issAttendanceLogsWithDurationPending) {
      if (getAttendanceLogsWithDurationResError) {
        return;
      }
      if (getAttendanceLogsWithDurationRes?.error) {
        return;
      }
      if (getAttendanceLogsWithDurationRes) {
        if (getAttendanceLogsWithDurationRes?.data) {
          setAttendanceLogsData(getAttendanceLogsWithDurationRes.data);

          const {
            firstEntryTime,
            workHours,
            workMinutes,
            workSeconds,
            breakHours,
            breakMinutes,
            breakSeconds,
          } = getAttendanceLogsWithDurationRes.data?.times;
          if (firstEntryTime) {
            let workTime = `${workHours}:${workMinutes}:${workSeconds}`;
            let breakTime = `${breakHours}:${breakMinutes}:${breakSeconds}`;

            setTotalWorkTime(moment(workTime, 'hh:mm:ss').format('HH:mm:ss'));
            setTotalBreakTime(moment(breakTime, 'hh:mm:ss').format('HH:mm:ss'));

            const intervalFun = setInterval(() => {
              workTime = moment(workTime, 'hh:mm:ss')
                .add(1, 'seconds')
                .format('HH:mm:ss');
              setTimer(workTime);
            }, 1000);
            return () => clearInterval(intervalFun);
          }
        }
      }
    }
  }, [
    issAttendanceLogsWithDurationPending,
    getAttendanceLogsWithDurationRes,
    getAttendanceLogsWithDurationResError,
  ]);
  const getAttendanceLogs = (clearCache = 0) => {
    navigateToGetAttendanceLogsWithDuration({
      date: moment().format('YYYY-MM-DD'),
      emp_id: userDetails?.emp_id || '',
      clear_cache: clearCache
    });
  };
  useEffect(() => {
    if (getNotifications?.data) {
      setAllNotifications([...allNotifications, ...getNotifications.data]);
      getCount([...allNotifications, ...getNotifications.data]);
    }
  }, [getNotifications]);

  const getCount = (data) => {
    let count = 0
    if (data?.length === 0) {
      count = 0
    } else {
      data?.map(item => {
        if (item?.is_read == 0) {
          count = count + 1
        }
      })
    }
    setNotificationCount(count)
  }

  const toggleAttendanceLogsModal = () => {
    setAttendanceLogsData(null);
    setAttendanceLogsModalOpen(!attendanceLogsModalOpen);
    if (!attendanceLogsModalOpen) {
      getAttendanceLogs(1);
    }
  };

  const userProfile = async () => {
    let result = await api.get(config.profile);
    if (result && result.code === 200) {
      setemail(result?.data[0]?.email);
      setemp_id(result?.data[0]?.emp_id);
      setfirstname(
        result?.data[0]?.first_name + ' ' + result?.data[0]?.last_name,
      );
      getAttendanceLogs();
    }
  };

  const dispatch = useDispatch();
  const sidebarShow = useSelector(state => state.sidebarShow);

  const toggleSidebar = () => {
    const options = {
      minimize: getSidebarOptions?.minimize ? false : true,
      show: 'responsive',
    };
    navigateToToggleSidebar(options);
  };

  const toggleSidebarMobile = () => {
    const options = {
      minimize: getSidebarOptions?.minimize ? false : true,
      show: getSidebarOptions?.minimize ? false : true,
    };
    navigateToToggleSidebar(options);
  };

  function previousDay(date) {
    if (date.weekday() == 0) {
      return moment(date)
        .subtract(1, 'd')
        .format('DD-MMM-YYYY');
    }
    if (date.weekday() == 6) {
      let dateNo = date.date();
      dateNo = parseInt(dateNo / 7) + 1;
      if (dateNo === 2 || dateNo === 4) {
        return moment(date)
          .subtract(2, 'd')
          .format('DD-MMM-YYYY');
      } else {
        return moment(date).format('DD-MMM-YYYY');
      }
    } else {
      return moment(date).format('DD-MMM-YYYY');
    }
  }

  useEffect(() => {
    if(page != 1){
      console.log("sfsdfsdfsdf")
    getAllNotificationRequest({ page: page,pageSize: 10})
  }
  }, [page])

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5 && !loading && hasMore) {
          setPage(prev => prev + 1);
        }
      }
    };

    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => container.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore]);

  const handleChick = async (item) => {
    if (item.is_read == 0) {
      let data = {
        notifi_id: item.id
      }
      let result = await api.get(config.notifictionSeen, true, data)
      if (result.code == 200) {
        setAllNotifications([])
        setPage(1)
        getAllNotificationRequest()
        history.push('/dashboard/leave-management')
      }
    } else {
      history.push('/dashboard/leave-management')
    }
  }
  const ClearAllNotification = async()=>{
        let result = await api.get(config.clearAllNotification)
        if(result && result.code == 200){
          setAllNotifications([])
          setPage(1)
          getAllNotificationRequest()
        }
  } 
  const clearOneNotif = async(item)=>{
    let data = {
      notification_id:item.id
    }
    let result = await api.delete(config.clearOneNotification,data)
    if(result && result.code == 200){
      setAllNotifications([])
      setPage(1)
      getAllNotificationRequest()
    }
  }

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/"></CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3"></CHeaderNavItem>

        <CHeaderNavItem className="px-3">
          <CDropdown className="m-1">
            <CDropdownMenu>
              <Link to="/dashboard/ChangePassword">Change Password</Link>
            </CDropdownMenu>
          </CDropdown>
        </CHeaderNavItem>
      </CHeaderNav>

      <CHeaderNav className="">
        {getPanel_role !== 'onboarding_employee' && (
          <>
            <marquee className="d-none d-md-block marqueeTG" style={{ width: '500px', paddingTop: '10px' }}>
              <div
                dangerouslySetInnerHTML={{ __html: notificationMessage }}
              ></div>
            </marquee>
            <div className="mr-2">
              <div className="text-center" style={{ display: 'inline-block' }}>
                <div class="d-flex">
                  <div
                    className="clock-1"
                    style={{
                      fontSize: '20px',
                      backgroundColor: '#eb7c45',
                      color: 'white',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '5px',
                      padding: '5px',
                      height: '100%',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={toggleAttendanceLogsModal}
                  >
                    <div class="clock-icon">
                      <i className="fa fa-history mr-1" aria-hidden="true"></i>
                    </div>
                    <div className="hours">
                      <div className="">
                        <div className="">
                          {timer && timer.length > 0 && timer[0]}
                        </div>
                      </div>
                      <div className="">
                        <div className="">
                          {timer && timer.length > 0 && timer[1]}
                        </div>
                      </div>
                    </div>
                    <div className="tick">:</div>
                    <div className="minutes">
                      <div className="">
                        <div className="">
                          {timer && timer.length > 0 && timer[3]}
                        </div>
                      </div>
                      <div className="">
                        <div className="">
                          {timer && timer.length > 0 && timer[4]}
                        </div>
                      </div>
                    </div>
                    <div className="tick">:</div>
                    <div
                      className={
                        attendanceBasicDetails?.data?.attendance_basic_details
                          ?.last_check_status === 'in'
                          ? 'second-move'
                          : 'second'
                      }
                    >
                      <div className="">
                        <div className="">
                          {timer && timer.length > 0 && timer[6]}
                        </div>
                      </div>
                      <div className="second infinite">
                        <div className="">
                          {timer && timer.length > 0 && timer[7]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="clock-1"
                    style={{
                      fontSize: '20px',
                      backgroundColor: '#eb7c45',
                      color: 'white',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '5px',
                      padding: '5px',
                      height: '100%',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      backgroundColor: 'red',
                    }}
                  >
                    <div className="clock-icon">
                      <i className="fa fa-coffee mr-1" aria-hidden="true"></i>
                    </div>
                    <div className="hours">
                      <div className="">
                        <div className="">
                          {totalBreakTime &&
                            totalBreakTime.length > 0 &&
                            totalBreakTime[0]}
                        </div>
                      </div>
                      <div className="">
                        <div className="">
                          {totalBreakTime &&
                            totalBreakTime.length > 0 &&
                            totalBreakTime[1]}
                        </div>
                      </div>
                    </div>
                    <div className="tick">:</div>
                    <div className="minutes">
                      <div className="">
                        <div className="">
                          {totalBreakTime &&
                            totalBreakTime.length > 0 &&
                            totalBreakTime[3]}
                        </div>
                      </div>
                      <div className="">
                        <div className="">
                          {totalBreakTime &&
                            totalBreakTime.length > 0 &&
                            totalBreakTime[4]}
                        </div>
                      </div>
                    </div>
                    <div className="tick">:</div>
                    <div
                      className={
                        attendanceBasicDetails?.data?.attendance_basic_details
                          ?.last_check_status === 'in'
                          ? 'second-move'
                          : 'second'
                      }
                    >
                      <div className="">
                        <div className="">
                          {totalBreakTime &&
                            totalBreakTime.length > 0 &&
                            totalBreakTime[6]}
                        </div>
                      </div>
                      <div className="second infinite">
                        <div className="">
                          {totalBreakTime &&
                            totalBreakTime.length > 0 &&
                            totalBreakTime[7]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className='me-2' style={{ position: "relative", display: "inline-block", cursor: "pointer" }}>
          <span className='' type="button" id="dropdownMenuButton"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          >
            <i className="fa fa-bell mr-1" aria-hidden="true"
              style={{ fontSize: "20px" }}
            ></i>
            {notificationCount > 0 &&
              <span style={{
                position: "absolute", top: "-8px", right: "-2px", backgroundColor: "red",
                color: "white", borderRadius: "50%", padding: "1px", fontSize: "10px",
                fontWeight: "bold", width: "18px", height: "18px", textAlign: "center"
              }}

              >
                {notificationCount}
              </span>}
          </span>
          <div className=''>
          <div className="notification-dropdown dropdown-menu dropdown-menu-right notificationMain" >
            <div className="notification-header d-flex justify-content-between align-items-center gap-2"  >{notificationCount == 0 ? '' : notificationCount} Notifications

             {allNotifications?.length !== 0 && <button type='button' className='clearBtn' onClick={ClearAllNotification}>Clear All</button>}
            </div>
            <div className='notiInner' ref={containerRef}  style={{ height: '400px', overflowY: 'auto' }}>
            {allNotifications?.length == 0 ? <p className='NoNotificaation'>No New Notifications</p> :''}
            {allNotifications && allNotifications?.map((item, index) => (
              <>
              <div className="dropdown-item notification-item" onClick={() => handleChick(item)} style={{ backgroundColor: item?.is_read === 0 ? "#e5e5e5" : '' }}>
                <div className='pe-2'>
                  <div class="d-flex align-items-center gap-2">
                    {item?.is_read == 0 ? <i className="fa fa-envelope me-2" aria-hidden="true" style={{ fontSize: "22px" }}></i>
                      : <i class="fa fa-envelope-open me-2 " aria-hidden="true" style={{ fontSize: "22px" }}></i>}
                    <div>
                      {item.message}
                      <span className="notification-time d-block">{moment(item.created_at).format('DD-MM-YYYY hh:mm a')}</span>
                    </div>
                  </div>
                </div>
                <div className=''>
                
                {item?.is_read == 0 &&<div className='unreadDot'></div>}
                </div>
                <button type="button" className='closeNme text-black' onClick={()=>clearOneNotif(item)}><i class="fa fa-times "></i></button>
                </div>

                </>
            ))
            }
            </div>
            {loading && <p>Loading more items...</p>}
            {/* {!hasMore && <p>No more notifications</p>} */}

          </div>

          </div>
        </div>

        <h6>{firstname}</h6>
        <TheHeaderDropdown />
      </CHeaderNav>
      <marquee className="d-md-none" style={{ width: '100%', paddingTop: '10px' }}>
        <div
          dangerouslySetInnerHTML={{ __html: notificationMessage }}
        ></div>
      </marquee>
      <AttendanceLogsModal
        open={attendanceLogsModalOpen}
        toggleModal={toggleAttendanceLogsModal}
        attendanceLogs={attendanceLogsData}
        loadingData={issAttendanceLogsWithDurationPending}
        timer={timer}
        totalWorkTime={totalWorkTime}
        totalBreakTime={totalBreakTime}
      />
      {/* <AllNotifications
      setOpenModal={setOpenModal}
      openModal={openModal}
      allNotifications={allNotifications}
      /> */}
    </CHeader>
  );
};

const mapStateToProps = state => {
  return {
    getSidebarOptions: commonSelectors.getSidebarOptions(state),
    messageNotifications: commonSelectors.getMessageNotification(state),
    getNotifications: commonSelectors.getNotification(state),
    attendanceBasicDetails: attendanceSelector.getAttendanceBasicDetailsRes(
      state,
    ),

    issAttendanceLogsWithDurationPending: attendanceSelector.getIsAttendanceLogsWithDurationPending(
      state,
    ),
    getAttendanceLogsWithDurationRes: attendanceSelector.getAttendanceLogsWithDurationRes(
      state,
    ),
    getAttendanceLogsWithDurationResError: attendanceSelector.getAttendanceLogsWithDurationResError(
      state,
    ),
    getAllEmployeeLeaveDates: leaveSelector.getAllLeaveForEmployeeRes(state),
    getShiftDetailOfEmployee: shiftDetailSelector.getShiftDetailRes(state),
  };
};
const mapDispatchToProps = dispatch => ({
  navigateToToggleSidebar: data => dispatch(commonActions.toggleSidebar(data)),
  messageNotificationRequest: data =>
    dispatch(commonActions.handleMessageNotificationRequest(data)),
  getAttendanceBasicDetail: data =>
    dispatch(attendanceActions.getAttendanceBasicDetailsRequest(data)),
  navigateToGetAttendanceLogsWithDuration: data =>
    dispatch(attendanceActions.getAttendanceLogsWithDurationRequest(data)),
  getAllLeaveDateWithinDate: data =>
    dispatch(leaveDateAction.getAllLeaveDateWithinDateRange(data)),
  shiftDetailRequestAction: data =>
    dispatch(shiftDetailAction.shiftDetailRequestAction(data)),
  navigateTogetSettingsByAdminRequest: data =>
    dispatch(settingActions.getSettingsByAdminRequest(data)),
  getAllNotification: data =>
    dispatch(commonActions.handleNotificationSuccess(data)),
  getAllNotificationRequest: data =>
    dispatch(commonActions.handleNotificationRequest(data))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(TheHeader));
