// import { all, call, put, select, take } from 'redux-saga/effects';
import {
  take,
  put,
  call,
  all,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { messageActions as actions, messageService } from './index';
import { mqttActions } from '../mqtt';

function* handleGetMessagesRequest() {
  const action = yield take(actions.getMessagesRequest.getType());
  const dataParams = action.payload;

  let res;
  try {
    res = yield call(messageService.getMessages, dataParams);
    if (res.isError) {
      yield put(actions.getMessagesError(res));
      //history.push('/home');
    } else {
      yield put(actions.getMessagesSuccess(res));
    }
  } catch (error) {
    yield put(actions.getMessagesError());
  }
}

function* handleGetConnectedUsersRequest() {
  const action = yield take(actions.getConnectedUsersRequest.getType());
  const dataParams = action.payload;
  let res;
  try {
    //yield put(mqttActions.mqttClientInstanceRequest());
    res = yield call(messageService.getConnectedUsers, dataParams);
    if (res.isError) {
      yield put(actions.getConnectedUsersError(res));
      //history.push('/home');
    } else {
      yield put(actions.getConnectedUsersSuccess(res));

      if (res?.data) {
        // subscribe topic
        yield all(
          res.data.map((item, key) => {
            // console.log('connection topic: ', { id: item._id });
            return put(
              mqttActions.mqttTopicSubscribe({ topic: item._id, qos: 1 }),
            );
          }),
        );
        // const topics = {};
        // res.data.map((item) => {
        //   console.log('connection topic: ', {id: item._id});
        //   topics[item._id] = { qos: 1 };
        // });
        // yield put(mqttActions.mqttTopicSubscribe({topics}));
      }
    }
  } catch (error) {
    yield put(actions.getConnectedUsersError());
  }
}

function* handleGetConnectedGroupsRequest() {
  const action = yield take(actions.getConnectedGroupsRequest.getType());
  const dataParams = action.payload;
  let res;
  try {
    res = yield call(messageService.getConnectedGroups, dataParams);
    if (res.isError) {
      yield put(actions.getConnectedGroupsError(res));
      //history.push('/home');
    } else {
      yield put(actions.getConnectedGroupsSuccess(res));
      if (res?.data) {
        // subscribe topic
        yield all(
          res.data.map((item, key) => {
            console.log('group topic: ', { id: item._id });
            return put(
              mqttActions.mqttTopicSubscribe({ topic: item._id, qos: 1 }),
            );
          }),
        );
        // const topics = {};
        // res.data.map((item) => {
        //   console.log('group topic: ', {id: item._id});
        //   topics[item._id] = { qos: 1 };
        // });
        // yield put(mqttActions.mqttTopicSubscribe({topics}));
      }
    }
  } catch (error) {
    yield put(actions.getConnectedGroupsError());
  }
}

function* handleGetUnreadMessageCountsRequest() {
  const action = yield take(actions.getUnreadMessageCountsRequest.getType());
  const dataParams = action.payload;

  let res;
  try {
    res = yield call(messageService.getUnreadMessageCounts, dataParams);
    if (res.isError) {
      yield put(actions.getUnreadMessageCountsSuccess(null));
    } else {
      yield put(actions.getUnreadMessageCountsSuccess(res));
    }
  } catch (error) {
    yield put(actions.getUnreadMessageCountsSuccess(null));
  }
}

function* handleGetMessagesRequestWatcher() {
  yield takeEvery(actions.getMessagesRequest, handleGetMessagesRequest);
}
function* handleGetConnectedUsersRequestWatcher() {
  yield takeEvery(
    actions.getConnectedUsersRequest,
    handleGetConnectedUsersRequest,
  );
}
function* handleGetConnectedGroupsRequestWatcher() {
  yield takeEvery(
    actions.getConnectedGroupsRequest,
    handleGetConnectedGroupsRequest,
  );
}
function* handleGetUnreadMessageCountsRequestWatcher() {
  yield takeEvery(
    actions.getUnreadMessageCountsRequest,
    handleGetUnreadMessageCountsRequest,
  );
}

function* rootMessageSaga() {
  yield all([
    handleGetMessagesRequest(),
    handleGetMessagesRequestWatcher(),

    handleGetConnectedUsersRequest(),
    handleGetConnectedUsersRequestWatcher(),

    handleGetConnectedGroupsRequest(),
    handleGetConnectedGroupsRequestWatcher(),

    handleGetUnreadMessageCountsRequest(),
    handleGetUnreadMessageCountsRequestWatcher(),
  ]);
}

export default rootMessageSaga;
