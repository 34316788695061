import { all, takeEvery, put, call, take, takeLatest } from 'redux-saga/effects';
import otpDataService from './otpsService';
import otpDataAction from './otpsActions';

function* handleVerifyOtpDataRequest() {
  const action = yield take(
    otpDataAction.verifyOtpRequestAction.getType(),
  );
  const dataparams = action.payload;
  let res;
  try {
    res = yield call(otpDataService.verifyOtpData, dataparams);
    console.log('data in saga', res);
    if (res.error) {
      yield put(otpDataAction.verifyOtpFailureAction(res));
    } else {
      yield put(otpDataAction.verifyOtpSuccessAction(res));
    }
  } catch {
    yield put(otpDataAction.verifyOtpFailureAction(res));
  }
}
function* handleVerifyOtpDataRequestWatcher() {
  yield takeEvery(
    otpDataAction.verifyOtpRequestAction,
    handleVerifyOtpDataRequest,
  );
}

function* handleSendOtpDataRequest() {
    const action = yield take(
      otpDataAction.sendOtpRequestAction.getType(),
    );
    let res;
    try {
      res = yield call(otpDataService.sendOtpData);
      console.log('data in saga otp', res);
      if (res.error) {
        yield put(otpDataAction.sendOtpFailureAction);
      } else {
        yield put(otpDataAction.sendOtpSuccessAction(res?.data));
      }
    } catch {
      yield put(otpDataAction.sendOtpFailureAction);
    }
  }
  function* handleSendOtpDataRequestWatcher() {
    yield takeEvery(
      otpDataAction.sendOtpRequestAction,
      handleSendOtpDataRequest,
    );
  }

function* rootOtpSaga() {
  yield all([
    handleVerifyOtpDataRequest(),
    handleVerifyOtpDataRequestWatcher(),
    handleSendOtpDataRequest(),
    handleSendOtpDataRequestWatcher(),
  ]);
}

export default rootOtpSaga;
