import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const LeavesData = ({ allLeaveDetails }) => {
  return (
    <div className="cardBox mb-3">
      <div className="row">
        <div className="col-md-9">
          <h3 className="fs-6 fw-bold mb-3">Leaves</h3>
          <div className="progress-stacked leavesProgress mb-3">
            {allLeaveDetails ? (
              <>
                <div
                  className="progress active"
                  role="progressbar"
                  data-count={
                    allLeaveDetails?.paid_leave
                      ? allLeaveDetails?.paid_leave
                      : '0'
                  }
                  aria-label="Sick Leave"
                  aria-valuenow={
                    allLeaveDetails?.paid_leave
                      ? allLeaveDetails?.paid_leave
                      : '0'
                  }
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{
                    width: `${
                      allLeaveDetails?.paid_leave
                        ? allLeaveDetails?.paid_leave
                        : '0'
                    }%`,
                  }}
                >
                  <div className="progress-bar bg-orange">
                    {allLeaveDetails?.paid_leave
                      ? allLeaveDetails?.paid_leave
                      : '0'}
                    %
                  </div>
                </div>
                <div
                  className="progress"
                  role="progressbar"
                  data-count={parseInt(
                    allLeaveDetails?.comp_off ? allLeaveDetails?.comp_off : '0',
                  )}
                  aria-label="Short Leave"
                  aria-valuenow={parseInt(
                    allLeaveDetails?.comp_off ? allLeaveDetails?.comp_off : '0',
                  )}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{
                    width: `${
                      allLeaveDetails?.comp_off
                        ? allLeaveDetails?.comp_off
                        : '0'
                    }%`,
                  }}
                >
                  <div className="progress-bar bg-primary">
                    {allLeaveDetails?.comp_off
                      ? allLeaveDetails?.comp_off
                      : '0'}
                    %
                  </div>
                </div>
                <div
                  className="progress"
                  role="progressbar"
                  data-count={parseInt(
                    allLeaveDetails?.leave_without_pay
                      ? allLeaveDetails?.leave_without_pay
                      : '0',
                  )}
                  aria-label="Paternity Leave"
                  aria-valuenow={parseInt(
                    allLeaveDetails?.leave_without_pay
                      ? allLeaveDetails?.leave_without_pay
                      : '0',
                  )}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{
                    width: `${
                      allLeaveDetails?.leave_without_pay
                        ? allLeaveDetails?.leave_without_pay
                        : '0'
                    }%`,
                  }}
                >
                  <div className="progress-bar bg-info">
                    {allLeaveDetails?.leave_without_pay
                      ? allLeaveDetails?.leave_without_pay
                      : '0'}
                    %
                  </div>
                </div>
                <div
                  className="progress"
                  role="progressbar"
                  data-count={parseInt(
                    allLeaveDetails?.other
                      ? allLeaveDetails?.leave_without_pay
                      : '0',
                  )}
                  aria-label="Maternity Leave"
                  aria-valuenow={parseInt(
                    allLeaveDetails?.other
                      ? allLeaveDetails?.leave_without_pay
                      : '0',
                  )}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{
                    width: `${
                      allLeaveDetails?.other
                        ? allLeaveDetails?.leave_without_pay
                        : '0'
                    }%`,
                  }}
                >
                  <div className="progress-bar bg-pink">
                    {allLeaveDetails?.other
                      ? allLeaveDetails?.leave_without_pay
                      : '0'}
                    %
                  </div>
                </div>
              </>
            ) : (
              <Skeleton />
            )}
          </div>
          <ul className="cstmLagend">
            <li className="sleave">Paid Leave</li>
            <li className="shleave">Comp-Off</li>
            <li className="pleave">Unpaid Leave</li>
            <li className="maleave">Other</li>
          </ul>
        </div>
        <div className="col-md-3">
          <div className="leavesTxt">
            <strong className="leave-sick-leave">
              {allLeaveDetails?.leave_without_pay
                ? allLeaveDetails?.leave_without_pay
                : '0'}
            </strong>
            <span>Unpaid Leave</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeavesData;
