/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import {
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCollapse,
  CLabel,
} from '@coreui/react';
import { getLocalDataAsObject } from '../../utils/CoustomStorage';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import moment from 'moment';
import {
  InitCallInfoModal,
  PreConfirmInitCallInfoModal,
  CallHistoryModal,
  CallFeedbackAddModal,
  CallReports,
  ViewCandidateModal,
} from '../../modals';
import { CustButton, CustMultiSelect } from 'src/reusable';
import { isPermission } from 'src/utils/common';

const CandidateLibrary = props => {
  let api = new API();

  const [isSearchLoader, setIsSearchLoader] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [details, setDetails] = useState([]);
  const [selectedCandidatedData, setSelectedCandidatedData] = useState('all');
  const [selectedItem, setSelectedItem] = useState(null);
  const [feedbackAddModalOpen, setFeedbackAddModalOpen] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [candidateModalOpen, setCandidateModalOpen] = useState(false);
  const [candidateCallHistory, setCandidateCallHistory] = useState(null);
  const [callInfoData, setCallInfoData] = useState(null);
  const [callHistoryModalOpen, setCallHistoryModalOpen] = useState(false);
  const [initCallInfoModalOpen, setInitCallInfoModalOpen] = useState(false);
  const [
    preConfirmInitCallInfoModalOpen,
    setPreConfirmInitCallInfoModalOpen,
  ] = useState(false);
  const [currAgentNumer, setCurrAgentNumber] = useState('');
  const [currCandidateNumer, setCurrCandidateNumber] = useState('');
  const [allFieldsReqErrMsg, setAllFieldsReqErrMsg] = useState(false);

  //
  const [isGetAccessNumLoader, setIsGetAccessNumLoader] = useState(false);
  const [isGetCallHistoryLoader, setIsGetCallHistoryLoader] = useState(false);

  // search inputes
  const [searchText, setSearchText] = useState('');
  const [location, setLocation] = useState([]);
  const [education, setEducation] = useState([]);
  const [experience, setExperience] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedEducation, setSelectedEducation] = useState([]);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [candidateName, setCandidateName] = useState('');
  const [candidateMobile, setCandidateMobile] = useState('');
  const [locationOptions, setLocationOptions] = useState([]);
  const [experienceOptions, setExperieneceOptions] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [createdByOptions, setCreatedByOptions] = useState([]);
  const startOfMonth = moment()
    .startOf('month')
    .format('YYYY-MM-DD');
  const endOfMonth = moment()
    .endOf('month')
    .format('YYYY-MM-DD');

  useEffect(() => {
    getCandidates();
    getDynamicDetails();
  }, []);

  const resetSearchFields = () => {
    setLocation([]);
    setEducation([]);
    setExperience([]);
    setCreatedBy([]);
    setSelectedLocation([]);
    setSelectedEducation([]);
    setSelectedExperience([]);
    setSelectedCreatedBy([]);
    setSearchText('');
    setCandidateName('');
  };

  const handleOnChangeInput = e => {
    setAllFieldsReqErrMsg(false);
    const { name, value } = e.target;
    console.log(name, value);
    switch (name) {
      case 'candidate_num':
        setCurrCandidateNumber(value.replace(/\D/gm, ''));
        break;
      case 'agent_num':
        setCurrAgentNumber(value.replace(/\D/gm, ''));
        break;
      default:
        console.log(name, value);
        break;
    }
  };

  const handleOnSelectOptions = (data, type) => {
    switch (type) {
      case 'experience':
        setExperience(data);
        const expArr = [];
        data.map(data => {
          expArr.push(data?.value);
        });
        setSelectedExperience(expArr);
        break;
      case 'location':
        setLocation(data);
        let locArr = [];
        data.map(data => {
          locArr.push(data?.value);
        });
        setSelectedLocation(locArr);
        break;
      case 'education':
        setEducation(data);
        let eduArr = [];
        data.map(data => {
          eduArr.push(data?.value);
        });
        setSelectedEducation(eduArr);
        break;
      case 'createdBy':
        setCreatedBy(data);
        let createdByArr = [];
        data.map(data => {
          createdByArr.push(data?.value);
        });
        setSelectedCreatedBy(createdByArr);
        break;
      default:
        break;
    }
  };

  const handleOnSearchChangeInput = e => {
    const { name, value } = e.target;
    console.log(name, value);
    switch (name) {
      case 'search_text':
        setSearchText(value);
        break;
      case 'candidate_name':
        setCandidateName(value);
        break;
      case 'candidate_mobile':
        setCandidateMobile(value);
        break;
      default:
        console.log(name, value);
        break;
    }
  };

  const getCandidates = async e => {
    let data = null;
    if (e?.target?.name === 'resetSearch') {
      data = {};
      resetSearchFields();
    }
    if (e?.target?.name === 'search') {
      data = {
        search_text: searchText,
        location: selectedLocation.toString(),
        experience: selectedExperience.toString(),
        education: selectedEducation.toString(),
        recruiter: selectedCreatedBy.toString(),
        candidate_name: candidateName,
        candidate_mobile: candidateMobile,
        startDate: '',
        endDate: '',
      };
    } else {
      data = {
        startDate: startOfMonth,
        endDate: endOfMonth,
      };
    }
    setIsSearchLoader(true);
    try {
      let result = await api.get(config.getCandidates, true, data);
      console.log({ result });
      if (result && result.code === 200) {
        setCandidates(result.data);
      } else {
      }
    } catch (err) {
      console.log('--getCandidates ERR--', err);
    }
    setIsSearchLoader(false);
  };

  const getCallHistoryByCandidate = async () => {
    setIsGetCallHistoryLoader(true);
    setCandidateCallHistory(null);
    try {
      let data = {
        candidate_id: selectedCandidatedData.candidate_id,
      };
      let result = await api.get(config.getCallHistoryByCandidate, true, data);
      console.log({ result });
      setCandidateCallHistory(result?.data || null);
      toggleCallHistoryModal();
    } catch (err) {
      console.log('--getCallHistoryByCandidate ERR--', err);
    }
    setIsGetCallHistoryLoader(false);
  };

  const toggleCallHistoryModal = () => {
    setCallHistoryModalOpen(!callHistoryModalOpen);
  };

  const toggleInitCallInfoModal = () => {
    setInitCallInfoModalOpen(!initCallInfoModalOpen);
  };

  const togglePreConfirmInitCallInfoModal = () => {
    const user = getLocalDataAsObject('user');
    const empMobile = user?.length ? user[0].mobile : '';

    setAllFieldsReqErrMsg(false);
    setCurrCandidateNumber(selectedCandidatedData?.mobile || '');
    setCurrAgentNumber(empMobile);
    setPreConfirmInitCallInfoModalOpen(!preConfirmInitCallInfoModalOpen);
  };

  const handleInitiateCall = async () => {
    if (!currAgentNumer || !currCandidateNumer) {
      setAllFieldsReqErrMsg(true);
      return;
    }
    setIsGetAccessNumLoader(true);
    try {
      const user = getLocalDataAsObject('user');
      const empId = user?.length ? user[0].id : '';
      const empQId = user?.length ? user[0].emp_id : '';
      const payload = {
        candidate_id: selectedCandidatedData.candidate_id,
        agent_id: empId,
        agent_qid: empQId,
        agent_number: `91${currAgentNumer}`,
        candidate_number_1: `91${currCandidateNumer}`,
        candidate_name_1: selectedCandidatedData.name || 'Candidate',
      };
      const result = await api.post(config.intiateCall, payload);
      console.log('result', result);
      if (result?.data && result?.code === 200) {
        togglePreConfirmInitCallInfoModal();
        setCallInfoData(result.data);
        toggleInitCallInfoModal();
      }
    } catch (err) {
      console.log('--getCallHistoryByCandidate ERR--', err);
    }
    setIsGetAccessNumLoader(false);
  };

  const toggleFeedbackModal = data => {
    setSelectedItem(data);
    setFeedbackAddModalOpen(!feedbackAddModalOpen);
  };

  const toggleCandidateModal = data => {
    setSelectedItem(data);
    setCandidateModalOpen(!candidateModalOpen);
  };

  const toggleReportModal = data => {
    setSelectedItem(data);
    setReportModalOpen(!reportModalOpen);
  };

  const getDynamicDetails = async () => {
    let result = await api.get(config.mrfdynamicdetailsform);
    if (result && result.code === 200) {
      let temp = [];
      result?.data[0]?.Locations?.map(data => {
        temp.push({ label: data?.location_name, value: data?.location_name });
      });
      setLocationOptions(temp);
      temp = [];
      result?.data[0]?.Job_Experiences?.map(data => {
        temp.push({ label: data?.experience, value: data?.key });
      });
      setExperieneceOptions(temp);
      temp = [];
      result?.data[0]?.Education?.map(data => {
        temp.push({ label: data?.education, value: data?.education });
      });
      setEducationOptions(temp);
      temp = [];
      result?.data[0]?.Recruiter?.map(data => {
        temp.push({
          label: data?.recruiter + ' (' + data?.emp_id + ')',
          value: data?.id,
        });
      });
      setCreatedByOptions(temp);
      temp = [];
    }
  };

  const fields = [
    { label: 'ID', key: 'candidate_id', _style: { width: '5%' } },
    { label: 'Name', key: 'name', _style: { width: '10%' } },
    { label: 'Mobile', key: 'mobile', _style: { width: '10%' } },
    { label: 'Email', key: 'email', _style: { width: '10%' } },
    { label: 'Experience', key: 'experience', _style: { width: '10%' } },
    { label: 'Designation', key: 'designation', _style: { width: '10%' } },
    { label: 'MRF Status', key: 'status', _style: { width: '5%' } },
    {
      label: 'Call Duration',
      key: 'call_duration',
      _style: { width: '10%' },
      sorter: false,
      filter: false,
    },
    {
      label: 'Call Status',
      key: 'call_status',
      _style: { width: '10%' },
      sorter: false,
      filter: false,
    },
    {
      key: 'show_details',
      label: 'Action',
      _style: { width: '5%' },
      sorter: false,
      filter: false,
    },
  ];
  const toggleDetails = (index, item) => {
    setSelectedCandidatedData(item);

    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      //newDetails = [...details, index];
      newDetails = [index];
    }
    setDetails(newDetails);
  };
  const getBadge = status => {
    switch (status) {
      case 'close':
        return 'danger';
      case 'hold':
        return 'warning';
      default:
        return 'info';
    }
  };

  return (
    <div className="">
      <div className="container mt-5">
        <CCard className={'ats-search-card-sect'}>
          <CCardBody>
            <div className="row">
              <div className="col-sm-9">
                <CLabel>Skills / Designation</CLabel>
                <input
                  id="search-text"
                  type="text"
                  name="search_text"
                  className="form-control"
                  placeholder="Enter skills / designations"
                  value={searchText}
                  onChange={handleOnSearchChangeInput}
                />
              </div>
              <div className="col-sm-3">
                <CLabel>Candidate Created By</CLabel>
                <CustMultiSelect
                  onChange={handleOnSelectOptions}
                  values={createdBy}
                  optionsData={createdByOptions}
                  type="createdBy"
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-3">
                <CLabel>Candidate Name</CLabel>
                <input
                  type="text"
                  name="candidate_name"
                  className="form-control"
                  placeholder="Enter Candidate Name"
                  value={candidateName}
                  onChange={handleOnSearchChangeInput}
                />
              </div>
              <div className="col-sm-3">
                <CLabel>Education</CLabel>
                <CustMultiSelect
                  onChange={handleOnSelectOptions}
                  values={education}
                  optionsData={educationOptions}
                  type="education"
                />
              </div>
              <div className="col-sm-3">
                <CLabel>Location</CLabel>
                <CustMultiSelect
                  onChange={handleOnSelectOptions}
                  values={location}
                  optionsData={locationOptions}
                  type="location"
                />
              </div>
              <div className="col-sm-3">
                <CLabel>Experience</CLabel>
                <CustMultiSelect
                  onChange={handleOnSelectOptions}
                  values={experience}
                  optionsData={experienceOptions}
                  type="experience"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 text-right mt-3">
                <CustButton
                  type="reset"
                  name="resetSearch"
                  text="Reset"
                  size="md"
                  color="danger"
                  onClick={getCandidates}
                  className="btn-loading btn-ladda ml-1"
                  icon=""
                />
                <CustButton
                  type="button"
                  name="search"
                  text="Search"
                  size="md"
                  color="info"
                  onClick={getCandidates}
                  className="btn-loading btn-ladda ml-1"
                  icon=""
                  isDisabled={isSearchLoader || false}
                  isLoading={isSearchLoader || false}
                />
              </div>
            </div>
          </CCardBody>
        </CCard>
        <div className="row">
          <div className="col-sm-10">
            <h3 className="text-uppercase text-center mt-2 mb-4">
              <u>List Of Candidates</u>
            </h3>
          </div>
          {/* {isPermission('export_call_reports') &&  */}
          <div className="col-sm-2 mt-2">
            <button
              type="button"
              className="btn btn-primary float-right"
              onClick={toggleReportModal}
              size="md"
            >
              Export Reports &nbsp;
              <i className="fa fa-download" aria-hidden="true"></i>
            </button>
          </div>
          {/* } */}
        </div>
        <CCard>
          <CCardBody>
            <CDataTable
              items={candidates}
              fields={fields}
              loading={isSearchLoader}
              hover={false}
              sorter
              pagination
              scopedSlots={{
                mobile: (item, index) => (
                  <td>{item.mobile ? item.mobile : item.c_mobile}</td>
                ),
                email: (item, index) => (
                  <td>{item.email ? item.email : item.c_email}</td>
                ),
                status: item => (
                  <td>
                    <CBadge color={getBadge(item.active_status)}>
                      {item.active_status}
                    </CBadge>
                  </td>
                ),
                call_duration: (item, index) => (
                  <td>
                    {item.call_duration
                      ? item.call_duration.replace(/"/g, '')
                      : ''}
                  </td>
                ),
                call_status: (item, index) => (
                  <td>
                    {item.call_status ? item.call_status.replace(/"/g, '') : ''}
                  </td>
                ),
                show_details: (item, index) => {
                  return (
                    <td className="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        shape="square"
                        size="sm"
                        onClick={() => {
                          toggleDetails(index, item);
                        }}
                      >
                        {details.includes(index) ? 'Hide' : 'Show'}
                      </CButton>
                    </td>
                  );
                },

                details: (item, index) => {
                  return (
                    <CCollapse show={details.includes(index)}>
                      <CCardBody>
                        {isPermission('view_call_history') &&
                        <CustButton
                          type="button"
                          name="vh"
                          text="View Call History"
                          size="sm"
                          color="info"
                          onClick={getCallHistoryByCandidate}
                          className="btn-loading btn-ladda ml-1"
                          icon=""
                          isDisabled={isGetCallHistoryLoader || false}
                          isLoading={isGetCallHistoryLoader || false}
                        />
                        }
                        {isPermission('new_call') &&
                        <CButton
                          onClick={() => togglePreConfirmInitCallInfoModal()}
                          size="sm"
                          color="danger"
                          className="ml-1"
                        >
                          New Call
                        </CButton>
                        }
                        {isPermission('add_feedback') &&
                        <CustButton
                          type="button"
                          name="vh"
                          text="Add Fedback"
                          size="sm"
                          color="primary"
                          onClick={() =>
                            toggleFeedbackModal({ id: item?.ats_id })
                          }
                          className="btn-loading btn-ladda ml-1"
                          icon=""
                        />
                        }
                        {isPermission('candidate_details') &&
                        <CustButton
                          type="button"
                          name="candidate_details"
                          text="View Candidate Details"
                          size="sm"
                          color="success"
                          onClick={() => toggleCandidateModal(item)}
                          className="btn-loading btn-ladda ml-1"
                          icon=""
                        />
                      }
                      </CCardBody>
                    </CCollapse>
                  );
                },
              }}
            />
          </CCardBody>
        </CCard>
      </div>

      <InitCallInfoModal
        open={initCallInfoModalOpen}
        handleCancel={toggleInitCallInfoModal}
        callInfoData={callInfoData}
      />

      <PreConfirmInitCallInfoModal
        open={preConfirmInitCallInfoModalOpen}
        handleCancel={togglePreConfirmInitCallInfoModal}
        onChangeInput={handleOnChangeInput}
        onInitiateCall={handleInitiateCall}
        agentNum={currAgentNumer}
        candidateNum={currCandidateNumer}
        allFieldsReqErrMsg={allFieldsReqErrMsg}
        isGetAccessNumLoader={isGetAccessNumLoader}
      />

      <CallHistoryModal
        open={callHistoryModalOpen}
        handleCancel={toggleCallHistoryModal}
        candidateCallHistory={candidateCallHistory}
      />

      <CallFeedbackAddModal
        open={feedbackAddModalOpen}
        handleCancel={toggleFeedbackModal}
        dropdownList={null}
        selectedItem={selectedItem}
        setFeedbackAddModalOpen={setFeedbackAddModalOpen}
      />

      <CallReports
        open={reportModalOpen}
        handleCancel={toggleReportModal}
        dropdownList={null}
        selectedItem={selectedItem}
        setReportModalOpen={setReportModalOpen}
      />

      <ViewCandidateModal
        toggleCandidateModal={toggleCandidateModal}
        candidateModalOpen={candidateModalOpen}
        selectedItem={selectedItem}
      />
      <ToastContainer />
    </div>
  );
};
export default CandidateLibrary;
