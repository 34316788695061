import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';
import React from 'react';

const ViewAllTeamPerformanceModal = ({
  teamPerformanceData,
  showAllPerformance,
  handleAllPerformanceModal,
}) => {
  return (
    <CModal
      show={showAllPerformance}
      onClose={() => handleAllPerformanceModal(!showAllPerformance)}
      size="lg"
    >
      <CModalHeader closeButton>
        <CModalTitle>All Team</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="table-responsive overallTable">
          <table className="table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Teams</th>
                <th>Count</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {teamPerformanceData &&
                teamPerformanceData.length > 0 &&
                teamPerformanceData.map((item, index) => (
                  <tr key={item?.name}>
                    <td>{index + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.team_count}</td>
                    <td>{`${parseInt(
                      item?.Percentage ? item?.Percentage : 0,
                    )}%`}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </CModalBody>
    </CModal>
  );
};

export default ViewAllTeamPerformanceModal;
