import React, { useState, useEffect } from 'react';
import { TheFooter, TheHeader, TheSidebar } from 'src/containers';
import { toast, ToastContainer } from 'react-toastify';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';
import { Button, Label, FormGroup, CustomInput } from 'reactstrap';

import { Country, State, City } from 'country-state-city';

import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CFormText,
  CTextarea,
  CInput,
  CLabel,
  CSelect,
  CRow,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import swal from 'sweetalert';
import Alert from 'src/alert/alert';

const NewSignupForm = () => {
  const api = new API();

  const [allDepartment, setAllDepartment] = useState();
  const [allDepartmentWiseRole, setAllDepartmentWiseRole] = useState();
  const [allEmpForReportingTo, setAllEmpForReportingTo] = useState();
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [allCity, setAllCity] = useState();
  const [signupform, setsignupform] = useState({
    emp_id: '',
    first_name: '',
    last_name: '',
    mobile_no: '',
    email_id: '',
    joining_date: '',
    location: '',
    department: '',
    designation: '',
    reporting_manager: '',
    // pdfData: '',
  });
  useEffect(() => {
    getAllDepartment();
    getAllEmpForReportingTo();
  }, []);
  const onChangeInput = e => {
    const { name, value } = e.target;
    if (name == 'department') {
      getAllDesignationByDpt(value);
    }
    setsignupform({
      ...signupform,
      [name]: value,
    });
  };
  const getAllDepartment = async () => {
    let result = await api.get(config.getAllDepartmentList);
    if (result && result.code == 200) {
      setAllDepartment(result.data);
    }
  };

  const getAllDesignationByDpt = async deptId => {
    let data = {
      department_id: deptId,
    };
    let result = await api.get(config.getDepartmentWiseRoles, true, data);

    if (result && result.code == 200) {
      setAllDepartmentWiseRole(result.data);
    }
  };

  const getAllEmpForReportingTo = async deptId => {
    // let data = {
    //     department_id: deptId
    // }
    let result = await api.get(config.getAllEmployeeList);

    if (result && result.code == 200) {
      setAllEmpForReportingTo(result.data);
    }
  };

  const resetFunc = () => {
    setsignupform({
      emp_id: '',
      first_name: '',
      last_name: '',

      mobile_no: '',
      email_id: '',
      joining_date: '',
      location: '',
      department: '',
      designation: '',
      reporting_manager: '',
    });
  };

  const Submitformdata = async () => {
    // console.log("data is", signupform)
    let data = {
      emp_id: signupform.emp_id,
      first_name: signupform.first_name,
      last_name: signupform.last_name,
      mobile: signupform.mobile_no,
      email: signupform.email_id,
      joining_date: signupform.joining_date,
      location: signupform.location,
      department_id: signupform.department,
      role_id: signupform.designation,
      reported_to: signupform.reporting_manager,
    };
    // console.log("data is", data)
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to submit details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.createEmployeeByAdmin, data);
        if (result && result.code == 200) {
          toast.success('successfully submitted details');
        }
      }
    });
  };
  const isAlertToggle = msg => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
  };
  const isAlertToggleClose = () => {
    setIsAlert(false);
  };
  const getAllCity = () => {
    let allCity = City.getCitiesOfCountry('IN');
    setAllCity(allCity);
  };
  useEffect(() => {
    getAllCity();
  }, []);

  return (
    <div className="container">
      <div style={{ width: '70%', margin: 'auto' }}>
        {/* <h1>Kindly Fill Form  </h1> */}
        <CRow>
          <CCol xs="12">
            <CCard>
              <CCardHeader>
                New Signup Form Accounts
                {/* <small> Elements</small> */}
              </CCardHeader>
              <CCardBody>
                <AvForm
                  action=""
                  method="post"
                  encType="multipart/form-data"
                  className="form-horizontal"
                  onValidSubmit={Submitformdata}
                >
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="">Emp.ID</CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <AvInput
                        name="emp_id"
                        value={signupform.emp_id}
                        onChange={onChangeInput}
                        required
                      />
                      <CFormText>Please enter Emp ID</CFormText>
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="">First Name</CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <AvInput
                        name="first_name"
                        value={signupform.first_name}
                        onChange={onChangeInput}
                        required
                      />
                      <CFormText>Please enter first name</CFormText>
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="">Last Name</CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <AvInput
                        name="last_name"
                        value={signupform.last_name}
                        onChange={onChangeInput}
                        required
                      />
                      <CFormText>Please enter last name</CFormText>
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="">Mobile No.</CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <AvInput
                        name="mobile_no"
                        value={signupform.mobile_no}
                        onChange={onChangeInput}
                        required
                      />
                      <CFormText>Please enter mobile no.</CFormText>
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="">Email ID</CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <AvInput
                        name="email_id"
                        value={signupform.email_id}
                        onChange={onChangeInput}
                        required
                      />
                      <CFormText>Please enter Email ID</CFormText>
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="">Joining Date</CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <AvInput
                        type="date"
                        name="joining_date"
                        value={signupform.joining_date}
                        onChange={onChangeInput}
                        required
                      />
                      <CFormText>Please enter joining date</CFormText>
                    </CCol>
                  </CFormGroup>

                  <CFormGroup>
                    <AvField
                      type="select"
                      name="location"
                      value={signupform.location}
                      label="Location"
                      onChange={onChangeInput}
                      helpMessage="select your Location."
                    >
                      <option hidden>Select City</option>
                      {allCity &&
                        allCity.length > 0 &&
                        allCity.map((city, index) => (
                          <option key={index} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                    </AvField>
                  </CFormGroup>

                  <CFormGroup>
                    <AvField
                      type="select"
                      name="department"
                      value={signupform.department}
                      onChange={onChangeInput}
                      label="Department"
                      helpMessage="Please select your Department"
                    >
                      <option hidden>Select Department</option>
                      {allDepartment &&
                        allDepartment.length > 0 &&
                        allDepartment.map((dep, index) => (
                          <option value={dep.id}>{dep.name}</option>
                        ))}
                    </AvField>
                  </CFormGroup>

                  <CFormGroup>
                    <AvField
                      type="select"
                      name="designation"
                      value={signupform.designation}
                      label="Designation"
                      onChange={onChangeInput}
                      helpMessage="Please select your Designation"
                    >
                      <option hidden>Select Designation</option>
                      {allDepartmentWiseRole &&
                        allDepartmentWiseRole.length > 0 &&
                        allDepartmentWiseRole.map((designation, index) => (
                          <option key={index} value={designation.id}>
                            {designation.role_name}
                          </option>
                        ))}
                    </AvField>
                  </CFormGroup>

                  <CFormGroup>
                    <AvField
                      type="select"
                      name="reporting_manager"
                      value={signupform.reporting_manager}
                      label="Reporting Manager"
                      onChange={onChangeInput}
                      helpMessage="Please select your Reporting Manager"
                    >
                      <option hidden>Select Reporting Manager</option>
                      {allEmpForReportingTo &&
                        allEmpForReportingTo.length > 0 &&
                        allEmpForReportingTo.map((reportingManager, index) => (
                          <option value={reportingManager.id}>
                            {reportingManager.emp_id} - {reportingManager.email}{' '}
                            - {reportingManager.first_name}{' '}
                            {reportingManager.last_name}{' '}
                          </option>
                        ))}
                    </AvField>
                  </CFormGroup>
                  <Button
                    className="mr-2"
                    type="submit"
                    size="sm"
                    color="primary"
                  >
                    <CIcon name="cil-scrubber" className="mr-1" />
                    Submit
                  </Button>
                  <CButton
                    type="reset"
                    size="sm"
                    color="danger"
                    onClick={resetFunc}
                  >
                    <CIcon name="cil-ban" /> Reset
                  </CButton>
                </AvForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
      <Alert
        isAlert={isAlert}
        isAlertToggle={isAlertToggle}
        alertMessage={alertMessage}
        isAlertToggleClose={isAlertToggleClose}
      />
      <ToastContainer/>
    </div>
      );
};
export default NewSignupForm;
